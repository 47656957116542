import { Route, Routes } from 'react-router-dom';

import { HealthProfessionals } from './HealthProfessionals';
import { HealthProfessionalRegister } from './HealthProfessionalRegister';

export function MenuHealthProfessionals() {
  return (
    <Routes>
      <Route path="/" element={<HealthProfessionals />} />
      <Route path="register" element={<HealthProfessionalRegister />} />
    </Routes>
  );
}
