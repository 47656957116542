import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

:root {
  --background: #0A81C7;
  --white: #FFFFFF;
  --clean: #eee;
  --input-background: #e7e9ee;
  --input-border: #d7d7d7;

  --red: #C61E24;
  --yellow: #F9ED70;
  --blue: #0A81C7;
  --green: #1EC74A; 

  --text-title: #333;
  --text-body: #606161;
}

  //Modals
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px){
      font-size: 93.75%;
    }
    @media (max-width: 720px){
      font-size: 87.5%;
    }
  }

  body {
    background-color: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h6, strong, button {
    font-weight: 700;
  }

  h2.section {
    text-align: center;
    font-size: 2rem;
    padding: 5px;
    background-color: var(--red);
    color: #fff;
    border-radius: 5px;
    margin-top: -15px;
    margin-bottom: 20px;
  }

  button {
    cursor: pointer;
    border: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }

  input, textarea, select {
    border: 1px solid var(--input-border);
    display: block;
    font-size: 1rem;
    padding: 0.8rem;
    border-radius: 0.4rem;
    background: var(--input-background);
    transition: 0.2s;
    margin-bottom: 1rem;
    resize: none
  }

  input[type="date"] {
    width: 170px;
  }

  textarea {
    min-height: 7rem;
    width: 100%;
  }
  
  textarea:hover,
  textarea:focus,
  input:focus,
  input:hover,
  select:hover,
  select:focus,
  .editor:focus,
  .editor:hover {
    outline: none; 
    border-color: var(--red);
    background: 'white';
    box-shadow: 0 0 0 2px var(--red);
  }
  
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span.checkMark {
    min-height: 25px;
    min-width: 25px;
    background-color: var(--input-border);
    border: 2px solid var(--red);
    margin-right: 7.5px;
    border-radius: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
  }

  span.radio {
    border-radius: 100%;
  }

  input ~ span.checkMark:hover {
    background-color: #ccc; 
  }

  input:checked ~ span.checkMark {
    background-color: var(--red);
    transition-duration: 0.5s;
  }

  span.checkMark:after {
    content: "";
    position: relative;
    display: none;
  }

  input:checked ~ span.checkMark:after {
    display: block;
  }

  span.checkMark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span.radio:after {
    left: 5px;
    top: 5px;
    width: 7px;
    height: 7px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 100%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  input[type="checkbox"]:hover,
  input[type="radio"]:hover,
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    border-color: none;
    box-shadow: none;
  }

  label {
    display: block;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed
  }

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animePage 1s forwards; 
}

.animeShow {
  opacity: 0;
  animation: animePage 1s forwards; 
}

.animeDown {
  opacity: 0;
  transform: translateY(-20px);
  animation: animePage 1s forwards; 
}

.animeUp {
  opacity: 0;
  transform: translateY(20px);
  animation: animePage 1s forwards; 
}

a {
  text-decoration: none;
  color: inherit;
}

@keyframes animePage {
  to {
    opacity: 1;
    transform: initial;
  }
}
`;
