import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../../components/Table';
import { GlobalContext } from '../../../../GlobalContext';

import { Button } from '../../../../styles/Button';
import { HeaderEntries, Title } from '../style';

export function HealthProfessionals() {
  const navigate = useNavigate();

  const { load, loadSpecialties, specialtiesData, checkUser, actions } =
    React.useContext(GlobalContext);

  React.useEffect(() => {
    async function loadSpecialtiesData() {
      await loadSpecialties();
    }

    loadSpecialtiesData();
  }, [loadSpecialties]);

  !localStorage.getItem('login') && checkUser();

  return (
    <div className="animeUp">
      <HeaderEntries>
        <Title>Setores Cadastrados</Title>
        {actions.some(action => action.id === 31) && (
          <Button disabled={load} primary onClick={() => navigate('register')}>
            Cadastrar Setor
          </Button>
        )}
      </HeaderEntries>
      <Table
        content={specialtiesData}
        headers={{ description: 'Descrição' }}
        buttons={{ edit: actions.some(action => action.id === 32), delete: false }}
        typeTable="health-professionals"
      />
    </div>
  );
}
