/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect } from 'react';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { DataGrid, Historic } from './style';
import { FaRegEdit, FaRegTrashAlt, FaHistory, FaFileDownload } from 'react-icons/fa';
import { GlobalContext } from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import {
  deleteDoc,
  delEvolution,
  downloadDoc,
  generateHistoric,
  getPatient,
  getPatientHealth,
  getSpeciality,
  getUser
} from '../../utils/api';
import { danger, success } from '../Alerts';
import { Tooltip } from 'react-hover-tooltip';

export function Table({ content, setContent, headers, typeTable, buttons, id, patient, revertClosing }) {
  const [headerColumns, setHeaderColumns] = React.useState();
  const [contentTable, setContentTable] = React.useState();
  const [showHistoric, setShowHistoric] = React.useState(false);
  const historicList = [
    'Todos',
    'Abertura',
    'Triagem Global',
    'Projeto Terapêutico',
    'Documentos Anexados',
    'Triagens',
    'Evoluções',
    'Acompanhamento Familiar',
    'Reavaliação',
    'Fechamento'
  ];
  const [selectedHistoric, setSelectedHistoric] = React.useState([]);

  const { load, storeSpeciality, storePatient, storeUser, currentUser, storeEvolution, storeArea, actions, userData } =
    React.useContext(GlobalContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    function loadContentTable() {
      setHeaderColumns(Object.values(headers));
      setContentTable(content);
    }

    loadContentTable();
  }, [headers, content]);

  function handleGetPatient(id, year) {
    getPatient(id, year).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        const patientData = json.data.objPaciente;
        let CIDs = []
        getPatientHealth(patientData.PAC_CODIGO, patientData.PAC_ANO).then(json => {
          if (json.error) {
            danger(`Ocorreu um erro ao resgatar CIDS: ${json.message}`);
          } else {
            const { PS_CID_CODIGOS } = json.data.objPacienteSaude;
            CIDs = PS_CID_CODIGOS;
            const patient = {
              code: patientData.PAC_CODIGO,
              year: patientData.PAC_ANO,
              registerDate: patientData.PAC_DATCAD,
              name: patientData.PAC_NOME,
              birth: patientData.PAC_DATA_NASCIMENTO,
              race: patientData.PAC_COR_RACA,
              sex: patientData.PAC_SEXO,
              gender: patientData.PAC_GENERO,
              n_register: patientData.PAC_NUMR_REGISTRO,
              rg: patientData.PAC_NUMR_RG,
              cpf: patientData.PAC_NUMR_CPF,
              nis: patientData.PAC_NUMR_NIS,
              sus: patientData.PAC_NUMR_SUS,
              email: patientData.PAC_EMAIL,
              phone: patientData.PAC_FONE,
              isWpp: patientData.PAC_WHTS === 1 ? true : false,
              address: patientData.PAC_ENDERECO,
              uf: patientData.PAC_END_UF,
              cidade: patientData.PAC_END_CIDADE,
              bairro: patientData.PAC_END_BAIRRO,
              tipoLogradouro: patientData.PAC_END_TIPO_LOGRADOURO,
              logradouro: patientData.PAC_END_LOGRADOURO,
              temNumero: patientData.PAC_END_NUMERO != 0 ? ['1'] : [],
              numero: patientData.PAC_END_NUMERO,
              complemento: patientData.PAC_END_COMPLEMENTO,
              cep: `${patientData.PAC_END_CEP}`,
              responsibleName: patientData.PAC_NOME_RESPONSAVEL,
              responsibleRelationship: patientData.PAC_GRAU_RESPONSAVEL,
              responsiblePhone: patientData.PAC_FONE_RESPONSAVEL,
              responsibleIsWpp: patientData.PAC_WHTS_RESPONSAVEL === 1 ? true : false,
              responsibleEmail: patientData.PAC_EMAIL_RESPONSAVEL,
              haveParent: patientData.PAC_FAMILIARES_NO_PODE.length ? '1' : '0',
              parentName: patientData.PAC_FAMILIARES_NO_PODE,
              notes: patientData.PAC_OBS,
              forwarded: patientData.PAC_ENCAMINHAMENTO,
              CIDs,
              creator: patientData.PAC_USRCAD_FIRST,
              lastEditor: patientData.PAC_USRCAD_LAST
              // sector: patientData.PAC_SETCAD
            };
            storePatient(patient);
            navigate('register');
          }
        });
      }
    });
  }

  function handleGetUser(id) {
    getUser(id).then(json => {
      if (json.error) {
        danger('Ocorreu um erro ao pegar informações do paciente!');
      } else {
        storeUser(json.data.objUsuario);
        navigate('register');
      }
    });
  }

  function handleGetSpeciality(id) {
    getSpeciality(id).then(json => {
      if (json.error) {
        danger('Ocorreu um erro ao resgatar informações do setor!');
      } else {
        storeSpeciality({ id, label: json.data.descritivo });
        navigate('register');
      }
    });
  }

  function handleGetEvolution(evo, id) {
    storeEvolution({ ...evo, id });
    navigate(`new/${id}`);
  }

  function handleGenerateHistoric(id, year) {
    const noZero = selectedHistoric.filter(historic => historic !== 0);
    generateHistoric(id, year, noZero, selectedHistoric).then(json => {
      if (json.error) {
        danger(`Erro ao gerar histórico: ${json.message}`);
      } else {
        triggerBase64Download(json.data.base64_string, json.data.file_name.replace('.pdf', ''));
      }
    });
  }

  function handleDownloadDoc(id, year, seq, fileName) {
    downloadDoc(id, year, seq).then(json => {
      if (json.error) {
        danger(`Erro ao baixar arquivo: ${json.message}`);
      } else {
        triggerBase64Download(json.data.base64_string_com_MIME, fileName);
      }
    });
  }

  function handleDeleteDoc(id, year, seq, deleteFunc) {
    deleteDoc(id, year, seq).then(json => {
      if (json.error) {
        danger(`Erro ao deletar documento: ${json.message}`);
      } else {
        deleteFunc();
        success('Arquivo deletado com sucesso!');
      }
    });
  }

  useEffect(() => {
    selectedHistoric.length === 8 && setSelectedHistoric(old => [0, ...old]);
  }, [selectedHistoric]);

  return (
    <DataGrid>
      <table>
        <thead>
          <tr>
            {headerColumns && headerColumns.map((el, i) => <th key={i}>{el}</th>)}
            {typeTable !== 'access-profiles' && <th>Ações</th>}
          </tr>
        </thead>

        <tbody>
          {typeTable === 'access-profiles' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  return (
                    <tr key={el.id}>
                      <td>{el.label}</td>
                      <td>
                        {buttons?.edit && (
                          <Tooltip content="Editar">
                            <button disabled={load}>
                              <FaRegEdit size={20} color="#fff0" />
                            </button>
                          </Tooltip>
                        )}
                        {buttons?.delete && (
                          <Tooltip content="Deletar">
                            <button disabled={load}>
                              <FaRegTrashAlt size={20} />
                            </button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'health-professionals' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>{el.description}</td>
                      <td>
                        {buttons?.edit ? (
                          <Tooltip content="Editar">
                            <button onClick={() => handleGetSpeciality(el.id)} disabled={load}>
                              <FaRegEdit size={20} />
                            </button>
                          </Tooltip>
                        ) : (
                          <span></span>
                        )}
                        {buttons?.delete && (
                          <Tooltip content="Deletar">
                            <button disabled={load}>
                              <FaRegTrashAlt size={20} />
                            </button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'areas' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>{el.label}</td>
                      <td>{el.sector.label}</td>
                      <td>
                        {buttons?.edit ? (
                          <Tooltip content="Editar">
                            <button
                              onClick={() => {
                                storeArea(el);
                                navigate('register');
                              }}
                              disabled={load}
                            >
                              <FaRegEdit size={20} />
                            </button>
                          </Tooltip>
                        ) : (
                          <span></span>
                        )}
                        {buttons?.delete && (
                          <Tooltip content="Deletar">
                            <button disabled={load}>
                              <FaRegTrashAlt size={20} />
                            </button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'patients' && (
            <>
              {contentTable &&
                contentTable.map((el, index) => {
                  return (
                    <tr key={index}>
                      {/* <td>{`${el.sector.id}.${`${el.id}`.padStart(3, '0')}/${el.year}`}</td> */}
                      <td>{el.name}</td>
                      <td className={el.active ? '' : 'inative'}>{el.active ? 'Ativo' : 'Inativo'}</td>
                      <td>
                        {actions.some(action => action.id === 22 && el.active) && (
                          <Tooltip content="Editar">
                            <button
                              onClick={() => handleGetPatient(el.id, el.year)}
                              disabled={load}
                            >
                              <FaRegEdit size={20} />
                            </button>
                          </Tooltip>
                        )}
                        <Historic
                          showContent={showHistoric === index}
                          onClick={() => {
                            setShowHistoric(index);
                          }}
                        >
                          <Tooltip content="Histórico">
                            <FaHistory size={20} />
                          </Tooltip>
                          <div className="content animeDown">
                            <span className="marker" />
                            {historicList.map((historic, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  const have = selectedHistoric.some(
                                    historic => historic === index
                                  );
                                  if (index === 0) {
                                    have
                                      ? setSelectedHistoric([])
                                      : setSelectedHistoric(historicList.map((his, i) => i));
                                  } else {
                                    if (have) {
                                      setSelectedHistoric(old =>
                                        old.filter(el => el !== index && el !== 0)
                                      );
                                    } else {
                                      setSelectedHistoric(old => [...old, index]);
                                    }
                                  }
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedHistoric.some(historic => historic === index)}
                                  onChange={() => setSelectedHistoric(old => [...old, index])}
                                />
                                <span className="checkMark" />
                                <p>{historic}</p>
                              </div>
                            ))}
                            <button onClick={() => handleGenerateHistoric(el.id, el.year)}>
                              Gerar
                            </button>
                          </div>
                        </Historic>
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'revert-closing' && (
            <>
              {contentTable &&
                contentTable.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td>{`${`${el.id}`.padStart(3, '0')}/${el.year}`}</td>
                      <td>{el.name}</td>
                      <td>
                        <Tooltip content="reverter">
                          <button
                            onClick={() => revertClosing(el.id, el.year)}
                            disabled={load}
                            className="background"
                          >
                            Reverter
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'users' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>{el.USR_NOME}</td>
                      {/* <td>{el.USR_LOGIN}</td> */}
                      <td>{el.PER_DESCRITIVO}</td>
                      <td>{el.ARE_DESCRITIVO}</td>
                      <td>
                        {buttons?.edit ? (
                          <Tooltip content="Editar">
                            <button
                              disabled={load}
                              onClick={() => {
                                handleGetUser(el.USR_CODIGO);
                              }}
                            >
                              <FaRegEdit size={20} />
                            </button>
                          </Tooltip>
                        ) : (
                          <span></span>
                        )}
                        {buttons?.delete && (
                          <Tooltip content="Deletar">
                            <button disabled={load}>
                              <FaRegTrashAlt size={20} />
                            </button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'evolutions' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  let array = el.date.split('-');
                  const currentDate = `${array[2]}/${array[1]}/${array[0]}`;
                  let array2 = el.realDate.split('-');
                  const realDate = `${array2[2]}/${array2[1]}/${array2[0]}`;

                  return (
                    <tr key={i}>
                      <td>
                        {currentDate}
                        {currentUser.sector.value == 3 && <>
                          <br />
                          {el.hour}
                        </>}
                      </td>
                      {actions.some(action => action.id === 1033) && (
                        <td>
                          {realDate}
                          <br />
                          {el.realHour}
                        </td>
                      )}
                      <td>{el.label}</td>
                      <td>{el.sector}</td>
                      <td>{el.responsible}</td>
                      <td style={{ display: 'table-cell', textAlign: 'center' }}>
                        {/* {true ? ( */}
                        {(el.responsibleId == currentUser.id || currentUser.sector.value == '3') && buttons?.edit ? (
                          <Tooltip content="Editar">
                            <button
                              disabled={load}
                              onClick={() => {
                                handleGetEvolution(el, id);
                              }}
                            >
                              <FaRegEdit size={20} />
                            </button>
                          </Tooltip>
                        ) : (
                          <span></span>
                        )}
                        {el.responsibleId === currentUser.id && buttons?.delete && actions.some(action => action.id === 503) && (
                          <Tooltip content="Deletar">
                            <button
                              disabled={load}
                              style={{ marginLeft: 20 }}
                              onClick={() =>
                                delEvolution(el, patient).then(json => {
                                  if (json.error) {
                                    danger(`Falha ao remover evolução: ${json.message}`);
                                  } else {
                                    setContent(content.filter((el, index) => index !== i));
                                    success('Evolução removida com sucesso!');
                                    navigate('/user/movements/evolution');
                                  }
                                })
                              }
                            >
                              <FaRegTrashAlt size={20} />
                            </button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'docs' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {el.date}
                        <br />
                        {el.hour}
                      </td>
                      <td>{el.type}</td>
                      <td>{el.name}</td>
                      <td>{el.responsible}</td>
                      <td style={{ display: 'table-cell', textAlign: 'center' }}>
                        {buttons?.download ? (
                          <Tooltip content="Baixar">
                            <button
                              disabled={load}
                              onClick={() => handleDownloadDoc(el.id, el.year, el.seq, el.name)}
                            >
                              <FaFileDownload size={20} />
                            </button>
                          </Tooltip>
                        ) : (
                          <span></span>
                        )}
                        {/* AGUARDANDO BACK-END PERMISSÕES  */}
                        {buttons?.delete && (
                          <Tooltip content="Deletar">
                            <button
                              disabled={load}
                              style={{ marginLeft: 20 }}
                              onClick={() =>
                                handleDeleteDoc(el.id, el.year, el.seq, buttons.deleteFunc)
                              }
                            >
                              <FaRegTrashAlt size={20} />
                            </button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}

          {typeTable === 'revaluations' && (
            <>
              {contentTable &&
                contentTable.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {el.date}
                      </td>
                      <td style={{ display: 'table-cell', textAlign: 'center' }}>
                        {buttons?.edit ? (
                          <Tooltip content="Editar">
                            <button
                              disabled={load}
                              onClick={() => {
                                setContent(el);
                              }}
                            >
                              <FaRegEdit size={20} />
                            </button>
                          </Tooltip>
                        ) : (
                          <span></span>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          )}
        </tbody>
      </table>
    </DataGrid>
  );
}
