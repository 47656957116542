import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../../components/Table';
import { GlobalContext } from '../../../../GlobalContext';

import { Button } from '../../../../styles/Button';
import { getUsers } from '../../../../utils/api';
import { HeaderEntries, Title } from '../style';

export function Users() {
  const navigate = useNavigate();
  const handleSubmit = () => navigate('register');
  const [data, setData] = useState([]);
  const { actions } = useContext(GlobalContext);

  useEffect(() => {
    getUsers().then(json => {
      setData(json.data.arrUsuarios);
    });
  }, []);

  return (
    <div className="animeUp">
      <HeaderEntries>
        <Title>Usuários Cadastrados</Title>
        {actions.some(action => action.id === 51) && (
          <Button primary onClick={handleSubmit}>
            Cadastrar Usuário
          </Button>
        )}
      </HeaderEntries>
      <Table
        content={data}
        headers={{
          name: 'Nome',
          profile: 'Perfil',
          area: 'Area'
        }}
        typeTable="users"
        buttons={{ edit: actions.some(action => action.id === 52), delete: false }}
      />
    </div>
  );
}
