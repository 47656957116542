import React, { useState, useEffect } from 'react';
import { FormField } from '../../pages/Content/Entries/Patients/styles';

export default function Select({ label, name, handleChange, values, responses, isPrint }) {
  const [currentToPrint, setCurrentToPrint] = useState({});

  useEffect(() => {
    const current = responses[name].find(response => response.value === values[name]);
    current && setCurrentToPrint(current);
  });

  return (
    <>
      <label htmlFor={name}>{label}</label>

      {isPrint ? (
        <input type="text" readOnly value={currentToPrint.label} />
      ) : (
        <FormField as="select" id={name} name={name} onChange={handleChange} value={values[name]}>
          <option value="0">Selecione</option>
          {responses[name].map(response => (
            <option key={response.value} value={response.value}>
              {response.label}
            </option>
          ))}
        </FormField>
      )}
    </>
  );
}
