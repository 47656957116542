/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState } from 'react';
import { BsBoxArrowInLeft, BsBoxArrowInRight } from 'react-icons/bs';

import { getActions, getPermissions, sendActions } from '../../../../utils/api';
import { Container } from '../../Entries/style';
import { danger, success } from '../../../../components/Alerts';
import { Profile, ProfilesContainer, PermissionsContainer } from './styles';
import { FormRow, SubmitButton } from '../../Entries/Patients/styles';
import { useNavigate } from 'react-router';

export default function Permissions() {
  const [actions, setActions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({ permissions: [] });
  const [selectedAction, setSelectedAction] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    getActions().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as ações: ${json.data.arrAcao}`);
      } else {
        let arr = [];
        json.data.arrAcao.forEach(action => {
          arr.push({
            id: action[0],
            label: action[1]
          });
        });
        setActions(arr);
      }
    });

    getPermissions().then(json => {
      if (json.error) {
        danger(`Erro ao recuperar permissões: ${json.message}`);
      } else {
        let arr = [];
        json.data.arrPermissoes.forEach(permission => {
          const permissionObj = {
            id: permission.ARE_CODIGO,
            label: permission.ARE_DESCRITIVO,
            permissions: permission.ACOES.map(action => ({
              id: action.PER_ACAO,
              label: action.ACA_DESCRITIVO
            }))
          };
          arr.push(permissionObj);
        });
        setPermissions([...arr]);
      }
    });
  }, []);

  function handleChangeProfile(profile) {
    setSelectedProfile(permissions.find(element => element.id == profile));
  }

  function handleAddAction() {
    if (selectedAction.id || selectedAction.id === 0) {
      let profile = { ...selectedProfile };
      const found = profile.permissions.find(permission => permission.id == selectedAction.id);
      !found && profile.permissions.push(selectedAction);
      setSelectedProfile({ ...profile });
    }
  }

  function handleRemoveAction() {
    if (selectedAction.label) {
      let profile = { ...selectedProfile };
      const found = profile.permissions.findIndex(
        permission => permission.id === selectedAction.id
      );
      found > -1 && profile.permissions.splice(found, 1);
      setSelectedProfile(profile);
    }
  }

  function handleSend() {
    const toSend = permissions.map(element => ({
      id: element.id,
      label: element.label,
      permissions: element.permissions.map(permission => permission.id)
    }));
    sendActions(toSend).then(json => {
      if (json.error) {
        danger(`Erro ao salvar permissões: ${json.message}`);
      } else {
        navigate('/user');
        success(`Dados enviados!`);
      }
    });
  }

  return (
    <Container className="animeUp">
      <h2 className="section">Permissões</h2>
      <FormRow style={{ justifyContent: 'center' }}>
        {permissions.length > 0 && (
          <ProfilesContainer className="animeUp">
            {permissions.map(permission => {
              return (
                <Profile
                  key={permission.id}
                  active={permission.id === selectedProfile.id}
                  onClick={e => handleChangeProfile(permission.id)}
                >
                  <label>{permission.label}</label>
                </Profile>
              );
            })}
          </ProfilesContainer>
        )}
      </FormRow>

      <PermissionsContainer>
        <div>
          <h2>{selectedProfile.label}</h2>
          {selectedProfile.permissions.map(permission => {
            const actionObj = actions.find(action => action.id == permission.id);
            return (
              <button
                className="animeShow"
                key={actionObj.id}
                onClick={() => setSelectedAction(actionObj)}
                onBlur={() => setTimeout(() => setSelectedAction({}), 200)}
              >
                {actionObj.label && actionObj.label}
              </button>
            );
          })}
        </div>

        <div className="controls">
          <button className="action" onClick={handleAddAction}>
            <BsBoxArrowInLeft size={30} color="#fff" />
          </button>
          <button className="action" onClick={handleRemoveAction}>
            <BsBoxArrowInRight size={30} color="#fff" />
          </button>
        </div>

        <div>
          <h2>Todas as Ações</h2>
          {actions.map(action => (
            <button
              key={action.id}
              onClick={() => setSelectedAction(action)}
              onBlur={() => setTimeout(() => setSelectedAction({}), 200)}
            >
              {action.label}
            </button>
          ))}
        </div>
      </PermissionsContainer>
      <FormRow>
        <SubmitButton style={{ marginTop: 20 }} primary onClick={handleSend}>
          Enviar Dados
        </SubmitButton>
      </FormRow>
    </Container>
  );
}
