import React, { useEffect } from 'react';
import { FormField } from '../../pages/Content/Entries/Patients/styles';

export default function RadioOrCheck({ response, isPrint, initialValues, setFieldValue }) {
  useEffect(() => {
    response.isDefault && !isPrint && setFieldValue(response.name, response.value);
  }, [response, isPrint, setFieldValue]);

  return (
    <label key={response.value}>
      {isPrint ? (
        <input
          type={response.type}
          checked={initialValues[response.name].includes(response.value)}
          readOnly
        />
      ) : (
        <FormField type={response.type} name={response.name} value={response.value} />
      )}
      <span className={`checkMark ${response.type === 'radio' ? 'radio' : ''}`}></span>
      {response.label}
    </label>
  );
}
