import React, { useEffect, useState } from 'react';
import { SearchButton, SearchInput, SearchWrapper, Container, OptionsFilter } from './styles';

import SearchIcon from '../../assets/searchIcon.png';
import FilterIcon from '../../assets/filterIcon.png';
import { Button } from '../../styles/Button';
import { BsPlus } from 'react-icons/bs';

export default function Search({ data, value, setValue, placeholder, search, notPatient, searchLabel, selectLabel }) {
  const [searchText, setSearchText] = useState('');
  const [filteredContent, setFilteredContent] = useState([]);
  const [filterType, setFilterType] = useState('name');

  function handleFilter() {
    let filterArr;
    if (searchText.startsWith('%') && searchText.endsWith('%')) {
      const searchContent = searchText.slice(1, -1).toLowerCase().trim();
      filterArr = data.filter(el => {
        if(notPatient) {
          return el.id.toLowerCase().startsWith(searchContent);
        } else {
          console.log(filterType);

          return filterType === 'name' 
            ? el.name.toLowerCase().includes(searchContent)
            : el.cpf.toLowerCase().startsWith(searchContent.replace('.', '').replace('.', '').replace('-', ''))
        }
      });
    } else {
      const trimmedSearchText = searchText.toLowerCase().trim();
      filterArr = data.filter(el => {
        if(notPatient) {
          return el.id.toLowerCase().startsWith(trimmedSearchText);
        } else {
          return filterType === 'name' 
            ? el.name.toLowerCase().startsWith(trimmedSearchText)
            : el.cpf.toLowerCase().startsWith(trimmedSearchText.replace('.', '').replace('.', '').replace('-', ''))
        }
      });
    }
  
    if (filterArr.length > 0) {
      setFilteredContent(filterArr);
      notPatient 
        ? setValue(filterArr[0].id)
        : setValue(`${filterArr[0].id}-${filterArr[0].year}`);
    }
  }

  useEffect(() => {
    setSearchText('');
    setFilteredContent([]);
  }, [filterType]);

  return (
    <Container style={{ width: '100%' }}> 
    {!notPatient &&
      <OptionsFilter>
        <label onClick={() => setFilterType('name')}>
          <input name="filterType" type="radio" checked={filterType === 'name'} />
          <span className="checkMark radio" />
          Nome
        </label>
        <label onClick={() => setFilterType('cpf')}>
          <input name="filterType" type="radio" checked={filterType === 'cpf'} />
          <span className="checkMark radio" />
          CPF
        </label>
      </OptionsFilter>
    }

      <div style={{ display: 'flex', flexDirection: 'column', width: notPatient ? '20%' : '50%' }}>
        {searchLabel && <label style={{ marginBottom: 2 }}>{searchLabel}</label>}
        <SearchWrapper>
          <SearchInput
            autoFocus
            value={searchText}
            onKeyDown={e => {
              e.key === 'Enter' && handleFilter();
            }}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder={`Filtrar ${placeholder}`}
          />
          
          <SearchButton onClick={handleFilter} type="button">
            <img src={FilterIcon} alt="" />
          </SearchButton>
        </SearchWrapper>
      </div>

      <div style={{ display: 'flex', width: notPatient ? '100%' : '50%', flexDirection: 'column', }}>
        {selectLabel && <label style={{ marginLeft: 20, marginBottom: 2}}>{selectLabel}</label>}
        <div style={{ display: 'flex'}}>
          <select style={{ width: '100%' }} value={value} onChange={e => setValue(e.target.value)}>
            {filteredContent.length > 0 ? (
              filteredContent.map((content, index) => (
                <option key={index} value={`${content.id}${content.year ? `-${content.year}` : ''}`}>
                  {content.name}
                </option>
              ))
            ) : data.length > 0 ? (
              data.map((content, index) => (
                <option key={index} value={`${content.id}${content.year ? `-${content.year}` : ''}`}>
                  {content.name}
                </option>
              ))
            ) : (
              <option value={null}>SEM {placeholder.toUpperCase()} CADASTRADOS</option>
            )}
          </select>

          <Button primary onClick={search} type="button">
            {notPatient 
              ? <BsPlus size={30}/> 
              : <img src={SearchIcon} alt="Resgatar dados do paciente" />
            }
          </Button>
        </div>
      </div>
    </Container>
  );
}
