/* eslint eqeqeq: "off", curly: "error" */
import React, { useContext } from 'react';

import RadioOrCheck from '../../../../../components/RadioOrCheck';
import { FormRow, TitleSection } from '../../../Entries/Patients/styles';
import { AttendanceRow, RadioRow, Select } from '../styles';
import { FamiliarType, FamiliarInput } from '../../Revaluation/styles';
import { GlobalContext } from '../../../../../GlobalContext';
import PrintHeader from '../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../components/PrintHeader/styles';

const PrintTP = React.forwardRef(
  (
    {
      initialValues,
      selectedPatient,
      responses,
      patients,
      selectedAttendances,
      frequencies,
      selectedFamiliars,
      selectedProfessionals
    },
    ref
  ) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    const { currentUser } = useContext(GlobalContext);
    const array = initialValues.previsao.split('-');
    const revaluation = `${array[2]}/${array[1]}/${array[0]}`;
   
    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <TitleSection>Impacto do diagnóstico</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Aspectos Individuais</label>
              <RadioRow>
                {responses.aspectosIndividuais.map(response => (
                  <RadioOrCheck response={response} isPrint initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Atividades de vida diária</label>
              <RadioRow>
                {responses.atividadesDiarias.map(response => (
                  <RadioOrCheck response={response} isPrint initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Vida familiar</label>
              <RadioRow>
                {responses.vidaFamiliar.map(response => (
                  <RadioOrCheck response={response} isPrint initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Fatores de saúde</label>
              <RadioRow>
                {responses.fatoresSaude.map(response => (
                  <RadioOrCheck response={response} isPrint initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Fatores Emocionais</label>
              <RadioRow>
                {responses.fatoresEmocionais.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Fatores Sociais</label>
              <RadioRow>
                {responses.fatoresSociais.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Objetivos</TitleSection>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolver/aprimorar os Aspectos Individuais</label>
              <RadioRow>
                {responses.desenvolverAspectosIndividuais.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow style={{ marginTop: 50, paddingTop: 10 }}>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">
                Desenvolver/aprimorar a execução das atividades do cotidiano
              </label>
              <RadioRow>
                {responses.desenvolverVidaDiaria.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolver/aprimorar a Organização Familiar</label>
              <RadioRow>
                {responses.desenvolverOrganizacaoFamiliar.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolver/aprimorar a saúde</label>
              <RadioRow>
                {responses.desenvolverSaude.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolver/aprimorar a situação Emocional</label>
              <RadioRow>
                {responses.desenvolverEmocional.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolver/aprimorar Fatores Sociais</label>
              <RadioRow>
                {responses.desenvolverSocial.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    isPrint
                    initialValues={initialValues}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Atendimento(s)</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <div>
                {selectedAttendances.map(attendance => {
                  const days = [
                    '',
                    'Domingo',
                    'Segunda',
                    'Terça',
                    'Quarta',
                    'Quinta',
                    'Sexta',
                    'Sábado'
                  ];
                  const frequency = frequencies.find(el => el.id == attendance.frequency);
                  return (
                    <AttendanceRow key={attendance.id} className="animeUp">
                      <label style={{ marginRight: 'auto' }}>
                        {attendance.area ? attendance.area.label : ''}: {attendance.label}
                      </label>
                      <Select>
                        <label>{frequency.label}</label>
                      </Select>
                      <Select>
                        <label>{days[attendance.day]}</label>
                      </Select>
                      <Select
                        className="time animeLeft"
                        style={{
                          paddingLeft: 10,
                          paddingTop: 5,
                          paddingRight: 10,
                          paddingBottom: 5
                        }}
                      >
                        {attendance.initialHour}:{attendance.initialMinute}
                      </Select>
                      até
                      <Select
                        className="time animeLeft"
                        style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}
                      >
                        {attendance.finalHour}:{attendance.initialMinute}
                      </Select>
                    </AttendanceRow>
                  );
                })}
              </div>
            </div>
          </FormRow>

          <TitleSection>Observação complementar</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea readyOnly value={initialValues.notes} />
            </div>
          </FormRow>

          <TitleSection>Previsão de reavaliação do projeto terapêutico</TitleSection>
          <FormRow>
            <div className="smallInput">
              <input value={revaluation} />
            </div>
            <div className="fullInput" />
          </FormRow>

          <TitleSection>Componentes familiares que participaram</TitleSection>

          <FormRow style={{ flexDirection: 'column' }}>
            {selectedFamiliars.map(familiar => (
              <AttendanceRow className="animeUp" style={{ maxHeight: 50 }}>
                <div className="smallInput">
                  <FamiliarType>{familiar.label}</FamiliarType>
                </div>
                <div className="mediumInput" style={{ marginRight: 20 }}>
                  <FamiliarInput type="text" value={familiar.name} readyOnly />
                </div>
                <div className="mediumInput">
                  <span>______________________________________________</span>
                </div>
              </AttendanceRow>
            ))}
          </FormRow>
          <TitleSection style={{ marginTop: 50, paddingTop: 10 }}>
            Profissionais que participaram
          </TitleSection>
          <FormRow style={{ flexDirection: 'column' }}>
            {selectedProfessionals.map(professional => (
              <AttendanceRow style={{ maxHeight: 50 }}>
                <div className="mediumInput">
                  <FamiliarInput
                    style={{ marginLeft: -5 }}
                    type="text"
                    value={`Área ${professional.area.label}: ${professional.label} - ${professional.class}`}
                    readyOnly
                  />
                </div>
                <div className="mediumInput">
                  <span>______________________________________________</span>
                </div>
                <div className="mediumInput" />
              </AttendanceRow>
            ))}
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintTP;
