import React, { useContext } from 'react';

import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import PrintHeader from '../../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import { GlobalContext } from '../../../../../../GlobalContext';

const PrintPedagogia = React.forwardRef(
  ({ initialValues, selectedPatient, responses, patients, types, pedType }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    const { currentUser } = useContext(GlobalContext);
    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <h2 className="section" style={{ marginTop: 3 }}>
            Avaliação Pedagógica
          </h2>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <RadioRow style={{ justifyContent: 'space-between' }}>
                {types.map(el => (
                  <label style={{ maxWidth: '24%', minWidth: '0%' }}>
                    <input type="radio" readOnly checked={el.value === pedType} />
                    <span className="checkMark"></span>
                    {el.label}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <TitleSection style={{ fontSize: 26 }}>
            Apresenta coerência com qual fase do desenvolvimento referida por Piaget?
          </TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sensório-motor</label>
              <RadioRow>
                {responses.sensorioMotor.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Pré-operatório</label>
              <RadioRow>
                {responses.preOperario.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Operações concretas</label>
              <RadioRow>
                {responses.operacoesConcretas.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Operações formais</label>
              <RadioRow>
                {responses.operacoesFormais.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection style={{ fontSize: 24 }}>
            Aponte a situação do(a) beneficiário(a) nos aspectos postos abaixo
          </TitleSection>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Compreensão do novo</label>
              <RadioRow>
                {responses.compreensaoNovo.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Compreensão global dos contextos</label>
              <RadioRow>
                {responses.compreensaoGlobal.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Criatividade</label>
              <RadioRow>
                {responses.criatividade.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Manutenção de determinada aprendizagem</label>
              <RadioRow>
                {responses.manutencaoAprendizagem.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção visual</label>
              <RadioRow>
                {responses.percepcaoVisual.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção auditiva</label>
              <RadioRow>
                {responses.percepcaoAuditiva.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção olfativa</label>
              <RadioRow>
                {responses.percepcaoOlfativa.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção gustativa</label>
              <RadioRow>
                {responses.percepcaoGustativa.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Aspectos tátil-cinestésico de reconhecimento</label>
              <RadioRow>
                {responses.aspectosReconhecimento.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Coordenação motora grossa</label>
              <RadioRow>
                {responses.motoraGrossa.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Coordenação motora fina</label>
              <RadioRow>
                {responses.motoraFina.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Lateralidade</label>
              <RadioRow>
                {responses.lateralidade.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autoestima</label>
              <RadioRow>
                {responses.autoestima.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autonomia</label>
              <RadioRow>
                {responses.autonomia.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Interação social e comunicacional</label>
              <RadioRow>
                {responses.interacaoSocial.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autopreservação</label>
              <RadioRow>
                {responses.autopreservacao.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autocrítica</label>
              <RadioRow>
                {responses.autocritica.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow style={{ marginTop: 20, paddingTop: 10 }}>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Tomada de decisão e execução</label>
              <RadioRow>
                {responses.decisaoExecucao.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea readOnly value={initialValues.notes} />
            </div>
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintPedagogia;
