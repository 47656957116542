import React, { useContext, useState } from 'react';
import { triggerBase64Download } from 'common-base64-downloader-react';

import { SearchBox, SearchRow } from '../styles';
import { SubmitButton } from '../../Entries/Patients/styles';
import { getDadosSociais } from '../../../../utils/api';
import { danger, info } from '../../../../components/Alerts';
import { GlobalContext } from '../../../../GlobalContext';

export default function SocialData() {
  const [situation, setSituation] = useState('0');
  const [format, setFormat] = useState('S');
  const { actions } = useContext(GlobalContext);

  function handleSubmit(e) {
    e.preventDefault();
    if (!format || !situation) {
      info('Verifique o formato');
    } else {
      getDadosSociais(situation, format).then(json => {
        if (json.error) {
          danger(`${json.message}`);
        } else {
          triggerBase64Download(json.data.base64_string, json.data.file_name.replace('.pdf', ''));
        }
      });
    }
  }

  return (
    <div className="animeUp">
      <h2 className="section">Dados Sociais</h2>
      <form disabled={!actions.some(action => action.id === 1022)}>
        <SearchBox width="70%">
          <SearchRow>
            <div className="mediumInput">
              <label className="main">Situação econômica: </label>
              <select value={situation} onChange={e => setSituation(e.target.value)}>
                <option value="0">
                  Extrema pobreza (com renda mensal por pessoa de até R$ 89,00)
                </option>
                <option value="1">
                  Pobreza (com renda mensal por pessoa entre R$ 89,01 e R$ 178,00)
                </option>
                <option value="2">
                  Não se encaixa na categoria de extrema pobreza e/ou pobreza
                </option>
              </select>
            </div>
          </SearchRow>

          <SearchRow>
            <div style={{ marginTop: 10, marginBottom: 10 }} className="mediumInput radioGroup">
              <label className="">Formato: </label>
              <div className="radioGroupRow">
                <label>
                  <input type="radio" checked={format === 'S'} onChange={() => setFormat('S')} />
                  <span className="checkMark radio"></span>Sintético
                </label>
                <label>
                  <input type="radio" checked={format === 'A'} onChange={() => setFormat('A')} />
                  <span className="checkMark radio"></span>Analítico
                </label>
              </div>
            </div>
          </SearchRow>
          <SubmitButton primary onClick={e => handleSubmit(e)}>
            Gerar relatório
          </SubmitButton>
        </SearchBox>
      </form>
    </div>
  );
}
