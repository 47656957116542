import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { danger, success } from '../../../../../../components/Alerts';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { setTriagemFisio } from '../../../../../../utils/api';
import { GlobalContext } from '../../../../../../GlobalContext';
import Select from '../../../../../../components/Select';

export default function FormFisio({
  initialValues,
  selectedPatient,
  print,
  responses,
  patientInfos,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(GlobalContext);
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      const data = selectedPatient.split('-');
      setInitialValues(values);
      const obj = {
        TRI_FISIO_PACIENTE: data[0],
        TRI_FISIO_ANO: data[1],
        TRI_FISIO_USUARIO: currentUser.id,
        TRI_FISIO_SEMANAS_DE_GRAVIDEZ: values.semanasGravidez,
        TRI_FISIO_TIPO_DE_PARTO: values.tipoParto,
        TRI_FISIO_COMPLICACOES_DA_GRVDZ: values.complicacoesGravidez,
        TRI_FISIO_TIPO_DE_NEONATO: values.tipoNeonato,
        TRI_FISIO_CHOROU_AO_NASCER: values.chorouNascer,
        TRI_FISIO_APGAR: values.apgar,
        TRI_FISIO_CONVULSAO: values.convulsao,
        TRI_FISIO_TEMPO_NA_INCUBADORA: values.tempoIncubadora,
        TRI_FISIO_NEONATAL_OBS: values.neonatalNotes,
        TRI_FISIO_ALTERAC_MOTORA: values.relato,
        TRI_FISIO_ALTERAC_VISUAL: values.alteracaoVisual,
        TRI_FISIO_ALTERAC_AUDITIVA: values.alteracaoAuditiva,
        TRI_FISIO_ALTERAC_RESPIRATORIA: values.alteracaoRespiratoria,
        TRI_FISIO_IDADE_MOTORA_ATUAL: values.idadeMotoraAtual || 0,
        TRI_FISIO_IDADE_MOTORA_ATUAL_MS : values.idadeMotoraAtualMes || 0,
        TRI_FISIO_IDADE_CRONOLOGICA: values.idadeCronologica || 0,
        TRI_FISIO_IDADE_CRONOLOGICA_MS: values.idadeCronologicaMes || 0,
        TRI_FISIO_TIPO_DE_PC: values.tipoPC,
        TRI_FISIO_TROFISMO: values.trofismo,
        TRI_FISIO_TONUS: values.tonus,
        TRI_FISIO_AVALIACAO_DO_TONUS: values.avaliacaoTonus,
        TRI_FISIO_POSTURA_SUPINO_MMSS: values.supinoMMSS,
        TRI_FISIO_POSTURA_SUPINO_MMII: values.supinoMMII,
        TRI_FISIO_POSTURA_EM_PRONO_MMSS: values.pronoMMSS,
        TRI_FISIO_POSTURA_EM_PRONO_MMII: values.pronoMMII,
        TRI_FISIO_DISSOCIACAO_PELVICA: values.dissociacaoPelvica,
        TRI_FISIO_DISSOCIACAO_ESCAPULAR: values.dissociacaoEscapular,
        TRI_FISIO_CONTROLE_DE_CABECA: values.controleCabeca,
        TRI_FISIO_ROLAR: values.rolar,
        TRI_FISIO_ROLAR_OBS: values.rolarNote || '',
        TRI_FISIO_SUPINO_PRONO: values.supinoProno,
        TRI_FISIO_PRONO_SUPINO: values.pronoSupino,
        TRI_FISIO_PUPPY: values.puppy,
        TRI_FISIO_SUPINO_SENTADO: values.supinoSentado,
        TRI_FISIO_PRONO_SENTADO: values.pronoSentado,
        TRI_FISIO_SENTADO: values.sentado,
        TRI_FISIO_PONTE: values.ponte,
        TRI_FISIO_SENTADO_EM_PE: values.sentadoEmPe,
        TRI_FISIO_ORTOSTATISMO: values.ortostatimo,
        TRI_FISIO_MARCHA_TIPO: values.marcha,
        TRI_FISIO_MARCHA_FASE_ALTERADA: values.faseMarcha,
        TRI_FISIO_MARCHA_EQUILIBRIO: values.equilibrio,
        TRI_FISIO_MARCHA_OBS: values.marchaNote,
        TRI_FISIO_ORTESES: values.orteses,
        TRI_FISIO_ORTESES_OBS: values.ortesesNote,
        TRI_FISIO_RFLX_PRNSA_DEDO_MAO_E: values.reflexoMaoEsquerdo,
        TRI_FISIO_RFLX_PRNSA_DEDO_MAO_D: values.reflexoMaoDireito,
        TRI_FISIO_RFLX_PRNSA_DEDO_PEH_E: values.reflexoPeEsquerdo,
        TRI_FISIO_RFLX_PRNSA_DEDO_PRH_D: values.reflexoPeDireito,
        TRI_FISIO_MARCHA_REFLEXA: values.marchaReflexa,
        TRI_FISIO_REFLEXO_DE_MORO: values.reflexoMoro,
        TRI_FISIO_REFLEXO_DE_BABINSKI_E: values.babinskEsquerdo,
        TRI_FISIO_REFLEXO_DE_BABINSKI_D: values.babinskDireito,
        TRI_FISIO_REACAO_DE_PROTC_VAS: values.VAS,
        TRI_FISIO_RTCA_E: values.RTCAEsquerdo,
        TRI_FISIO_RTCA_D: values.RTCADireito,
        TRI_FISIO_RTCS: values.RTCS,
        TRI_FISIO_TESTES_ESPECIAIS: values.testes,
        TRI_FISIO_OBS: values.notes
      };

      console.log(obj);

      setIsLoading(true);
      setTriagemFisio(data[0], data[1], obj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Ocorreu um erro ao enviar os dados: ${json.message}`);
        } else {
          success('Dados enviados com sucesso!');
          print();
          navigate('/user');
        }
      });
    }
  }
  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form disabled={!patientInfos.active}>
          <FormRow>
            <div className="animeDown">
              <label htmlFor="">Semanas de gravidez</label>
              <FormField style={{ textAlign: 'center' }} type="number" name="semanasGravidez" />
            </div>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tipo de parto</label>
              <RadioRow>
                {responses.tipoParto.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Complicações da gravidez</label>
              <FormField
                as="textarea"
                name="complicacoesGravidez"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>
          <TitleSection>Neonatal</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tipo de neonato</label>
              <RadioRow>
                {responses.tipoNeonato.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Chorou imediatamente ao nascer</label>
              <RadioRow>
                {responses.chorouNascer.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label htmlFor="">Ventilação mecânica</label>
              <RadioRow>
                {responses.ventilacaoMecanica.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              <label htmlFor="">Tempo</label>
              <FormField name="ventilacaoMecanicaTempo" type="text" maxLength={256} />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label htmlFor="">APGAR</label>
              <FormField name="apgar" type="text" maxLength={256} />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Convulsão</label>
              <FormField name="convulsao" type="text" maxLength={256} />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Tempo na incubadora (dias)</label>
              <FormField name="tempoIncubadora" type="number" maxLength={256} />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Observações</label>
              <FormField name="neonatalNotes" type="text" maxLength={256} />
            </div>
          </FormRow>
          <TitleSection>Pessoa</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Breve relato do início da alteração motora</label>
              <FormField
                as="textarea"
                name="relato"
                onChange={handleChange}
                type="text"
                maxLength={256}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label htmlFor="">Alteração visual</label>
              <div className="radioGroupRow">
                {responses.alteracaoVisual.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
            </div>
            <div className="mediumInput radioGroup">
              <label htmlFor="">Alteração auditiva</label>
              <div className="radioGroupRow">
                {responses.alteracaoAuditiva.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
            </div>
            <div className="mediumInput radioGroup">
              <label htmlFor="">Alteração respiratória</label>
              <div className="radioGroupRow">
                {responses.alteracaoRespiratoria.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
            </div>
          </FormRow>
          <TitleSection>Função Motora</TitleSection>
          <FormRow>
            <div className="mediumInput">
              <label htmlFor="">Idade motora atual (anos)</label>
              <input name="idadeMotoraAtual" type="text" maxLength={2} value={values.idadeMotoraAtual} onChange={(e) => {
                !isNaN(e.target.value) && setFieldValue('idadeMotoraAtual',e.target.value.trim()); 
              }}/>
            </div>
            <div className="mediumInput">
              <label htmlFor="">Idade motora atual (meses)</label>
              <input name="idadeMotoraAtualMes" value={values.idadeMotoraAtualMes} onChange={(e) => {
                !isNaN(e.target.value) && setFieldValue('idadeMotoraAtualMes',e.target.value.trim()); 
              }} type="text" maxLength={2} />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label htmlFor="">Idade cronológica (anos)</label>
              <input name="idadeCronologica" value={values.idadeCronologica} onChange={(e) => {
                !isNaN(e.target.value) && setFieldValue('idadeCronologica',e.target.value.trim()); 
              }} type="text" maxLength={2} />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Idade cronológica (meses)</label>
              <input name="idadeCronologicaMes" value={values.idadeCronologicaMes} onChange={(e) => {
                !isNaN(e.target.value) && setFieldValue('idadeCronologicaMes',e.target.value.trim()); 
              }} type="text" maxLength={2} />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tipo de PC</label>
              <RadioRow>
                {responses.tipoPC.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Trofismo</label>
              <RadioRow>
                {responses.trofismo.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tônus</label>
              <RadioRow>
                {responses.tonus.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
            <div className="fullInput">
              <Select
                label="Avaliação do tônus"
                name="avaliacaoTonus"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Postura supino MMSS</label>
              <FormField
                as="textarea"
                name="supinoMMSS"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
            <div className="fullInput">
              <label htmlFor="">Postura supino MMII</label>
              <FormField
                as="textarea"
                name="supinoMMII"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Postura em prono MMSS</label>
              <FormField
                as="textarea"
                name="pronoMMSS"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
            <div className="fullInput">
              <label htmlFor="">Postura em prono MMII</label>
              <FormField
                as="textarea"
                name="pronoMMII"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Dissociação pélvica</label>
              <RadioRow>
                {responses.dissociacaoPelvica.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Dissociação escapular</label>
              <RadioRow>
                {responses.dissociacaoEscapular.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <TitleSection>Padrões Motores</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Controle de cabeça</label>
              <RadioRow>
                {responses.controleCabeca.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Rolar</label>
              <RadioRow>
                {responses.rolar.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
                <FormField
                  style={{ marginTop: 10 }}
                  placeholder="Observação"
                  type="text"
                  name="rolarNote"
                />
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Supino-prono</label>
              <RadioRow>
                {responses.supinoProno.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Prono-supino</label>
              <RadioRow>
                {responses.pronoSupino.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Puppy</label>
              <RadioRow>
                {responses.puppy.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Supino/sentado</label>
              <RadioRow>
                {responses.supinoSentado.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Prono/sentado</label>
              <RadioRow>
                {responses.pronoSentado.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Sentado</label>
              <RadioRow>
                {responses.sentado.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Ponte</label>
              <RadioRow>
                {responses.ponte.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Sentado/em pé</label>
              <RadioRow>
                {responses.sentadoEmPe.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Ortostatismo</label>
              <RadioRow>
                {responses.ortostatimo.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <TitleSection>Marcha</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <RadioRow>
                {responses.marcha.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Fase da marcha alterada</label>
              <RadioRow>
                {responses.faseMarcha.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Equilíbrio</label>
              <RadioRow>
                {responses.equilibrio.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Observações</label>
              <FormField name="marchaNote" type="text" maxLength={256} />
            </div>
          </FormRow>
          <TitleSection>Órteses</TitleSection>
          <FormRow>
            <div className="smallInput radioGroup">
              <label>Órteses</label>
              <div className="radioGroupRow">
                {responses.orteses.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </div>
            </div>
            <div className="fullInput">
              <label htmlFor="">Observações</label>
              <FormField name="ortesesNote" type="text" maxLength={256} />
            </div>
          </FormRow>
          <TitleSection>Avaliação dos reflexos e reações</TitleSection>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo de preensão dos dedos das mãos</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="reflexoMaoEsquerdo"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="reflexoMaoDireito"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo de preensão dos dedos dos pés</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="reflexoPeEsquerdo"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="reflexoPeDireito"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo cutâneo plantar (babinsk)</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="babinskEsquerdo"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="babinskDireito"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow className="borderBottom">
            <div className="mediumInput">
              <label className="center">RTCA</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="RTCAEsquerdo"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="RTCADireito"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">RTCS</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="RTCS"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Marcha reflexa</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="marchaReflexa"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo de moro</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="reflexoMoro"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reação de proteção das VAS</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="VAS"
                handleChange={handleChange}
                values={values}
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <TitleSection>Testes Especiais</TitleSection>
          <FormRow>
            <div className="fullInput">
              <FormField
                as="textarea"
                id="name"
                name="testes"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>
          <TitleSection>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <FormField
                as="textarea"
                id="name"
                name="notes"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>
          <FormRow>
            {patientInfos.active &&
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : (
                  'Enviar Dados'
                )}
              </SubmitButton>
            }
          </FormRow>
        </Form>
      )}
    </Formik>
  );
}
