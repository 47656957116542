import styled from 'styled-components';

export const DataGrid = styled.div`
  margin-top: 1rem;

  table {
    width: 100%;
    border-spacing: 0 0.5rem;

    tr:hover {
      filter: brightness(0.9);

      .content {
        filter: brightness(1.1);
      }
    }

    th {
      color: var(--text-body);
      font-weight: 700;
      padding: 1rem 2rem;
      text-align: left;
      line-height: 1.5rem;
      /* text-align: center; */

      &:last-of-type {
        text-align: center;
      }
    }

    td {
      padding: 1rem;
      border: 0;
      background: var(--clean);
      color: var(--text-body);
      word-wrap: break-word;

      button.background {
        background-color: var(--red);
        padding: 3px 8px;
        color: #fff;
        border-radius: 5px;
      }

      &:first-child {
        color: var(--text-title);
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &:last-child {
        display: flex;
        justify-content: space-evenly;
        border-radius: 0 0.5rem 0.5rem 0;
      }

      &.inative {
        color: var(--red)
      }

      span {
        height: 20px;
      }
    }
  }
`;

export const Historic = styled.button`
  position: relative;
  display: inline-block;

  .marker {
    display: ${props => (props.showContent ? 'flex' : 'none')};
    width: 20px;
    height: 20px;
    background: var(--red);
    z-index: 2;
    left: -10px;
    top: 5px;
    border: 2px solid #fff;
    position: absolute;
    transform: rotate(45deg);
    z-index: 3;
  }

  .content {
    position: absolute;
    display: ${props => (props.showContent ? 'flex' : 'none')};
    flex-direction: column;
    z-index: 1;
    left: 100%;
    top: -10%;
    background-color: var(--red);
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border: 2px solid #fff;

    div {
      font-size: 0.7rem;
      display: flex;
      width: 100%;
      padding: 0 0.5rem;
      align-items: center;
      color: #fff;
      text-align: start;
      border-bottom: 1px solid white;

      &:hover {
        background: var(--yellow);
        color: #000;
      }
    }
    button {
      background: var(--yellow);
      padding: 0.3rem;
    }
  }
`;
