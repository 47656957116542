import React from 'react';
import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';
import { RadioRow, Select } from '../../../Therapeutic Project/styles';
import PrintHeader from '../../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';

const PrintPsicologia = React.forwardRef(
  ({ initialValues, selectedPatient, currentUser, responses, frequencies, patients }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])

    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />

        <PrintContainer>
          <TitleSection>Avaliação Psicológica</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Humor frequente</label>
              <RadioRow>
                {responses.humorFrequente.map(response => (
                  <RadioOrCheck isPrint response={response} initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Interação social</label>
              <RadioRow>
                {responses.interacaoSocial.map((response, index) => (
                  <RadioOrCheck isPrint response={response} initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Comportamentos</label>
              <RadioRow>
                {responses.comportamentos.map(response => (
                  <RadioOrCheck isPrint response={response} initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="smallInput animeDown radioGroup">
              <label className="highlight">Alteração do sono</label>
              <RadioRow>
                {responses.alteracaoSono.map((response, index) => (
                  <RadioOrCheck isPrint response={response} initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="smallInput radioGroup">
              <label className="highlight">Pesadelos recorrentes</label>
              <RadioRow>
                {responses.pesadelosRecorrentes.map((response, index) => (
                  <RadioOrCheck isPrint response={response} initialValues={initialValues} />
                ))}
              </RadioRow>
            </div>
            {initialValues.pesadelosRecorrentes === '0701' && (
              <Select className="animeLeft" style={{ marginRight: 'auto' }}>
                <select value={initialValues.pesadelosPeriodicidade} readOnly>
                  {frequencies.map(frequency => (
                    <option key={frequency.id} value={frequency.id}>
                      {frequency.label}
                    </option>
                  ))}
                </select>
              </Select>
            )}
          </FormRow>

          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Alteração de conduta</label>
              <textarea defaultValue={initialValues.alteracaoConduta} />
            </div>
            <div className="fullInput">
              <label htmlFor="">Problemas na relação familiar/interpessoal</label>
              <textarea defaultValue={initialValues.problemasFamiliar} />
            </div>
          </FormRow>

          <TitleSection style={{ marginTop: 0 }}>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea defaultValue={initialValues.notes} style={{ minHeight: 65 }} />
            </div>
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintPsicologia;
