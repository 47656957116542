import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import useWindowDimensions from '../../utils/DimensionsHook';
import { MenuMobileButton } from '../../styles/Button';
import { BiRightArrow } from 'react-icons/bi';
import { DropDown, DropDownContent } from './style';
import { GlobalContext } from '../../GlobalContext';

export function DropDownMenu({ name }) {
  const { actions } = useContext(GlobalContext);
  function validateUser(e) {
    // Checar
    if (false) {
      e.preventDefault();
    }
  }
  const { width } = useWindowDimensions();

  if (name === 'cadastros') {
    return (
      <DropDown width={width}>
        <MenuMobileButton primary>Cadastros</MenuMobileButton>
        <DropDownContent>
          {actions.some(action => action.id === 21) && (
            <Link onClick={validateUser} to="entries/patients/register">
              Abertura
            </Link>
          )}
          {actions.some(action => action.id === 20) && (
            <Link onClick={validateUser} to="entries/patients">
              Pacientes
            </Link>
          )}
          {actions.some(action => action.id === 30) && (
            <Link onClick={validateUser} to="entries/health-professionals">
              Setores
            </Link>
          )}
          {actions.some(action => action.id === 40) && (
            <Link onClick={validateUser} to="entries/areas">
              Areas
            </Link>
          )}
          {actions.some(action => action.id === 50) && (
            <Link onClick={validateUser} to="entries/users">
              Usuários
            </Link>
          )}
          {actions.some(action => action.id === 60) && (
            <Link onClick={validateUser} to="entries/access-profiles">
              Perfis de Acesso
            </Link>
          )}
        </DropDownContent>
      </DropDown>
    );
  } else if (name === 'movimentações') {
    return (
      <DropDown width={width}>
        <MenuMobileButton primary>Movimentações</MenuMobileButton>
        <DropDownContent>
          {actions.some(action => action.id === 100) && (
            <Link onClick={validateUser} to="movements/global">
              Triagem Global
            </Link>
          )}
          {actions.some(action => action.id === 201) && (
            <Link onClick={validateUser} to="extras/upload">
              Anexar Documentos
            </Link>
          )}
          {actions.some(action => action.id === 300) && (
            <Link onClick={validateUser} to="movements/therapeutic-project">
              Projeto Terapeutico
            </Link>
          )}
          {actions.some(action => action.id === 400) && (
            <div className="subMenu">
              <span className="subMenu">Triagens</span>
              <BiRightArrow className="arrow" size={13} />
              <div className="wrapper">
                <div className="content animeLeft">
                  <Link onClick={validateUser} to="movements/screening/enfermagem">
                    Enfermagem
                  </Link>
                  <Link onClick={validateUser} to="movements/screening/pedagogia-psicopedagogia">
                    Pedagogia
                  </Link>
                  <Link onClick={validateUser} to="movements/screening/fonoaudiologia">
                    Fonoaudiologia
                  </Link>
                  <Link onClick={validateUser} to="movements/screening/fisioterapia">
                    Fisioterapia
                  </Link>
                  <Link onClick={validateUser} to="movements/screening/psicologia">
                    Psicologia
                  </Link>
                  <Link onClick={validateUser} to="movements/screening/educacao-fisica">
                    Educação Física
                  </Link>
                  <Link onClick={validateUser} to="movements/screening/musicalizacao">
                    Musicalização
                  </Link>
                  <div className="subMenu">
                    <span className="subMenu">Audiologia</span>
                    <BiRightArrow className="arrow" size={13} />
                    <div className="wrapper">
                      <div className="content animeLeft">
                        <Link onClick={validateUser} to="movements/screening/audiologia-crianca">
                          Criança
                        </Link>
                        <Link onClick={validateUser} to="movements/screening/audiologia-adulto">
                          Adolescente/Adulto/Idoso
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {actions.some(action => action.id === 500) && (
            <Link onClick={validateUser} to="movements/evolution">
              Evoluções
            </Link>
          )}
          {actions.some(action => action.id === 600) && (
            <Link onClick={validateUser} to="movements/family-accompaniment">
              Acompanhamento Familiar
            </Link>
          )}
          {actions.some(action => action.id === 700) && (
            <Link onClick={validateUser} to="movements/revaluation">
              Reavaliação
            </Link>
          )}
          {actions.some(action => action.id === 800) && (
            <Link onClick={validateUser} to="movements/closing">
              Fechamento
            </Link>
          )}
          {actions.some(action => action.id === 800) && (
            <Link onClick={validateUser} to="movements/revert-closing">
              Reverter Fechamento
            </Link>
          )}
        </DropDownContent>
      </DropDown>
    );
  } else if (name === 'relatórios') {
    return (
      <DropDown width={width}>
        <MenuMobileButton primary>Relatórios</MenuMobileButton>
        <DropDownContent>
          <Link onClick={validateUser} to="reports/dados-gerais-beneficiarios">
            Dados Gerais dos Beneficiários
          </Link>
          <div className="subMenu">
            <span className="subMenu">Demonstrativo <br/>de Frequência</span>
            <BiRightArrow className="arrow" size={13} />
            <div className="wrapper">
              <div className="content animeLeft" style={{ width: 200 }}>
                <Link onClick={validateUser} to="reports/demonstrativo-geral-frequencia-ambulatorial">
                  Geral - Terapêutico Ambulatorial
                </Link>
                <Link onClick={validateUser} to="reports/demonstrativo-pormenorizado-frequencia-ambulatorial">
                  Pormenorizado - Terapêutico Ambulatorial
                </Link>
              </div>
            </div>
          </div>
          <div className="subMenu">
            <span className="subMenu">Demonstrativo Quantitativo</span>
            <BiRightArrow className="arrow" size={13} />
            <div className="wrapper">
              <div className="content animeLeft" style={{ width: 200 }}>
                <Link onClick={validateUser} to="reports/demonstrativo-quantitativo-municipio-genero-idade-deficiencia">
                  Mun/Gên/Ida/Def
                </Link>
                <Link onClick={validateUser} to="reports/demonstrativo-quantitativo-deficiencia">
                  Tipos de Deficiência
                </Link>
                <Link onClick={validateUser} to="reports/demonstrativo-quantitativo-beneficiarios">
                  Beneficiários
                </Link>
              </div>
            </div>
          </div>
          <div className="subMenu">
            <span className="subMenu">Horários de Atendimento</span>
            <BiRightArrow className="arrow" size={13} />
            <div className="wrapper">
              <div className="content animeLeft" style={{ width: 200 }}>
                <Link onClick={validateUser} to="reports/horario-geral-atendimento-ambulatorial">
                  Geral - Terapêutico Ambulatorial
                </Link>
                <Link onClick={validateUser} to="reports/horario-pormenorizado-atendimento-ambulatorial">
                  Pormenorizado - Terapêutico Ambulatorial
                </Link>
              </div>
            </div>
          </div>
          {/* {actions.some(action => action.id === 1010) && (
            <Link onClick={validateUser} to="reports/frequencia-atendimentos">
              Frequência de Atendimentos
            </Link>
          )}
          {actions.some(action => action.id === 1021) && (
            <Link onClick={validateUser} to="reports/dados-sociais">
              Dados Sociais
            </Link>
          )}
          {actions.some(action => action.id === 1031) && (
            <Link onClick={validateUser} to="reports/resumo-procedimentos">
              Resumo de Procedimentos
            </Link>
          )} */}
          <div className="subMenu">
            <span className="subMenu">SUS</span>
            <BiRightArrow className="arrow" size={13} />
            <div className="wrapper">
              <div className="content animeLeft" style={{ width: 200 }}>
                <Link onClick={validateUser} to="reports/geral/profile">
                  Procedimentos por Profissional
                </Link>
                <Link onClick={validateUser} to="reports/pormenorizado-atendimento-profissional">
                  Pormenorizado de Atendimento por Profissional
                </Link>
                <Link onClick={validateUser} to="reports/pormenorizado-frequencia-profissional">
                  Pormenorizado de Faltas por Profissional
                </Link>
              </div>
            </div>
          </div>
        </DropDownContent>
      </DropDown>
    );
  } else if (name === 'extras') {
    return (
      <DropDown width={width}>
        <MenuMobileButton primary>Extra</MenuMobileButton>
        <DropDownContent>
          {actions.some(action => action.id === 1) && (
            <Link onClick={validateUser} to="extras/permissions">
              Permissões
            </Link>
          )}
        </DropDownContent>
      </DropDown>
    );
  } else {
    return <></>;
  }
}
