import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import calculateAge from 'calculate-age';
import { BsPlus } from 'react-icons/bs';
import { FaSearchLocation } from 'react-icons/fa';

import countries from '../../../../utils/countries';
import { messageTranslate } from '../../../../utils/validators';
import useWindowDimensions from '../../../../utils/DimensionsHook';
import { GlobalContext } from '../../../../GlobalContext';
import { IoIosArrowBack } from 'react-icons/io';
import { Error } from '../../../../styles/GlobalComponents';
import { danger, info, success, warning } from '../../../../components/Alerts';
import { HiUserRemove } from 'react-icons/hi';
import {
  AddParentButton,
  BackButton,
  FormField,
  FormRow,
  Parent,
  SubmitButton,
  SubTitleSection,
  TitleSection
} from './styles';
import {
  editMedicalRecord,
  getCEPInfos,
  getCIDs,
  getForwardings,
  getGenders,
  getPatient,
  getPatientsWithSector,
  getRelationship,
  getTiposLogradouro,
  searchCPF,
  setPatient
} from '../../../../utils/api';
import Search from '../../../../components/Search';
import { Button } from '../../../../styles/Button';
import { EmptyContent, Select } from '../../Movements/Therapeutic Project/styles';
import Selected from '../../Movements/Global/Components/Selected';
import isValidCPF from '../../../../utils/validadeCPF';

const schema = Yup.object().shape({
  name: Yup.string().required(messageTranslate.otherRequired),
  birth: Yup.date().required(messageTranslate.otherRequired),
  n_register: Yup.string().required(messageTranslate.otherRequired),
  rg: Yup.string(),
  cpf: Yup.string().required(messageTranslate.otherRequired).min(14, 'Verifique o CPF'),
  nis: Yup.string(),
  responsible: Yup.string()
});

export function PatientRegister() {
  const currentDate = new Date();
  const { patientData, storePatient, currentUser, specialtiesData, loadSpecialties } = React.useContext(GlobalContext);
  // const [sector, setSector] = useState(2);
  const [regDate, setRegDate] = useState([]);
  const { width } = useWindowDimensions();
  const [initialValues, setInitialValues] = useState({
    code: 0,
    name: '',
    birth: '',
    race: 0,
    gender: 0,
    n_register: '',
    rg: '',
    cpf: '',
    nis: '',
    sus: '',
    sex: 0,
    cep: '',
    cidade: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    uf: '',
    forwarded: 0,
    temNumero: ['1'],
    email: '',
    phone: '',
    isWpp: false,
    responsibleName: '',
    responsibleRelationship: 0,
    responsiblePhone: '',
    responsibleEmail: '',
    responsibleIsWpp: true,
    haveParent: '0',
    notes: ''
  });
  const [genders, setGenders] = useState([]);
  const [forwardings, setForwardings] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [patients, setPatients] = useState([]);
  const [typesLogradouro, setTypesLogradouro] = useState([]);
  const [selectedParent, setSelectedParent] = useState({});
  const [selectedParents, setSelectedParents] = useState([]);
  const [age, setAge] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [CIDs, setCIDs] = useState([]);
  const [allCIDS, setAllCIDS] = useState([]);
  const [whoCreated, setWhoCreated] = useState(null); 
  const navigate = useNavigate();

  function handleAddParent() {
    const current = patients[selectedParent];
    const have = selectedParents.some(parent => parent.id == selectedParent);
    current && !have && setSelectedParents(selectedParents => [...selectedParents, current]);
  }

  function handleRemoveParent(parent) {
    const array = [...selectedParents];
    const index = array.findIndex(element => element === parent);
    array.splice(index, 1);
    setSelectedParents(array);
  }
  
  function handleSubmit(values, resetForm) {
    const isCPFValid = values.cpf === '000.000.000-00' || isValidCPF(values.cpf);
    if(isCPFValid) {
      if (!isLoading) {
        setIsLoading(true);
        setPatient(values, selectedParents, currentUser.id).then(json => {
          if (json.error) {
            danger(json.message);
            storePatient({});
            setIsLoading(false);
          } else {
            success(
              `Paciente ${
                Object.keys(patientData).length === 0 ? 'cadastrado' : 'editado'
              } com sucesso!`
            );
            setWhoCreated(null);
            storePatient({});
            resetForm();
            setIsLoading(false);
            navigate('/user/entries/patients');
          }
        });
      }
    } else {
      info('Verifique o CPF digitado!');
    } 
  }

  useEffect(() => { 
    loadSpecialties();
    getRelationship().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrGrauParentesco.forEach(grau => {
          const grauObj = {
            id: grau[0],
            name: grau[1]
          };
          arr.push(grauObj);
        });
        setRelationships(arr);
      }
    });

    getCIDs().then(json => {
      if(json.error) {
        danger(json.message);
      } else {
        let array = [];
        json.data.arrCID.map(cid => {
          const obj = {
            id: cid[0],
            name: cid[1]
          }
          array.push(obj);
        })
        setAllCIDS(array);
      }
    })

    getPatientsWithSector(0).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [{ id: null, name: 'Selecione', year: 0 }];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        if (Object.keys(patientData).length !== 0) {
          setInitialValues(patientData);
        }
      }
    });

    getGenders().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrGenero.forEach(el => {
          const obj = {
            id: el[0],
            label: el[1]
          };
          arr.push(obj);
        });
        setGenders(arr);
      }
    });

    getForwardings().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrEncaminhamento.forEach(forwarding => {
          const forwardingObj = {
            id: forwarding[0],
            label: forwarding[1]
          };
          arr.push(forwardingObj);
        });
        setForwardings(arr);
      }
    });

    getTiposLogradouro().then(json => {
      if (json.error) {
        danger(`Erro ao buscar tipos de logradouro: ${json.message}`);
      } else {
        const array = json.data.arrTipoLogradouro.map(type => ({
          id: type[0],
          label: type[1]
        }));
        setTypesLogradouro(array);
      }
    });

    Object.keys(patientData).length > 0 && setRegDate(patientData.registerDate.split('-'));
    
    const currentYear = currentDate.getFullYear();
    const currentMouth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    let array = [];
    if (Object.keys(patientData).length > 0) {
      array = patientData.birth.split('-');
    }

    const year = Number(array[0]);
    const mouth = Number(array[1]);
    const day = Number(array[2]);

    const age = currentYear - year;

    if (currentMouth <= mouth && currentDay < day) {
      setAge(age - 1);
    } else {
      setAge(age);
    }

    // patientData.sector && setSector(patientData.sector);

    return () => {
      storePatient({});
    };

  }, []);

  useEffect(() => {
    if(patientData.CIDs) {
      const array = patientData.CIDs.map(cid => {
        return allCIDS.find(element => element.id == cid);
      });
      setCIDs(array);
    }
  }, [patientData, allCIDS]);

  useEffect(() => {
    if(patients.length) {
      patientData.parentName?.length && setSelectedParents(patientData.parentName.map(patient => {
        return patients.find(el => {
          if(el.id == patient.PF_FAMILIAR_NO_PODE_CODIGO){
            if(el.year == patient.PF_FAMILIAR_NO_PODE_ANO) {
              return true
            }
          } 
        });
      }))
    }
  }, [patients]);

  async function handleGetCEP(cep) {
    const result = cep.replace(/_/g, '');
    if (result.length === 9) {
      return getCEPInfos(cep);
    } else {
      return {
        error: true,
        message: 'Verifique o CEP digitado'
      };
    }
  }

  function handleSearchCPF(cpf) {
    if(cpf.length === 14 && Object.keys(patientData).length === 0) {
      if(cpf !== '000.000.000-00') {
        searchCPF(cpf).then(json => {
          if(json.error) {
            danger(`${json.message}`);
          }
        })
      }
    }
  }

  return (
    <div className="animeUp">
      <BackButton
        onClick={() => {
          Object.keys(patientData).length === 0
            ? navigate('/user')
            : navigate('/user/entries/patients');
        }}
      >
        <IoIosArrowBack size={22} style={{ marginRight: 5, marginBottom: -5 }} />
        Voltar
      </BackButton>
      
      {Object.keys(patientData).length === 0 ? (
        <TitleSection>Cadastro de Paciente</TitleSection>
      ) : (
        <>
          <TitleSection>
            Editar Paciente: <span className="patient">{patientData.name}</span>
          </TitleSection>
          <SubTitleSection>
            Cadastrado em{' '} 
            <span>
              {regDate[2]}/{regDate[1]}/{regDate[0]}
            </span>
            {patientData.creator &&
              <> por <span>{patientData.creator}</span></>
            }
          </SubTitleSection>
        </>
      )}
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({ values, errors, handleChange, setFieldValue }) => {
          return (
            <Form>
              {Object.keys(patientData).length > 0 
                && 
                <Button primary style={{ height: 50, position: 'absolute', right: 0, top: 60 }}
                >Enviar Dados</Button>
              }
              {/* <FormRow>
                <div>
                  <label>Setor</label>
                  <select 
                    value={sector} onChange={e => setSector(e.target.value)}>
                    {specialtiesData.filter((_, i) => i < 2).map((sector) => 
                      <option value={sector.id} key={sector.id}>{sector.description}</option>
                    )}
                </select>
                </div>
              </FormRow>                 */}
              <ConnectedFocusError />
              <FormRow>
                <div className="largeInput">
                  <label htmlFor="name">Nome</label>
                  <FormField id="name" name="name" type="text" />
                  {values && <Error>{errors.name}</Error>}
                </div>
                <div className={!values.birth ? 'animeLeft' : ''}>
                  <label htmlFor="birth">Data de Nascimento</label>
                  <FormField
                    id="birth"
                    name="birth"
                    type="date"
                    onChange={e => {
                      setFieldValue('birth', e.target.value);
                      handleChange('birth', e.target.value);
                      const currentYear = currentDate.getFullYear();
                      const currentMouth = currentDate.getMonth() + 1;
                      const currentDay = currentDate.getDate();

                      const array = e.target.value.split('-');
                      const year = Number(array[0]);
                      const mouth = Number(array[1]);
                      const day = Number(array[2]);

                      if (e.target.value) {
                        if (currentYear < year) {
                          setAge('');
                        } else if (currentYear == year && currentMouth < mouth) {
                          setAge('');
                        } else if (
                          currentYear == year &&
                          currentMouth == mouth &&
                          currentDay < day
                        ) {
                          setAge('');
                        } else {
                          const age = new calculateAge(
                            e.target.value,
                            `${currentYear}-${currentMouth}-${currentDay}`
                          ).getObject();

                          age.years > 0
                            ? setAge(`${age.years} ano${age.years > 1 ? 's' : ''}`)
                            : age.months > 0
                            ? setAge(`${age.months} ${age.months > 1 ? 'meses' : 'mês'}`)
                            : setAge(`${age.days} dia${age.days > 1 ? 's' : ''}`);
                        }
                      } else {
                        setAge('');
                      }
                    }}
                    max="2999-12-31"
                    onBlur={() => {
                      const array = values.birth.split('-');
                        if(array[0].length > 4) {
                          const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                          setFieldValue('birth', newDate);
                          handleChange('birth', newDate);
                        }
                    }}
                  />
                  {errors.birth && <Error>{errors.birth}</Error>}
                </div>

                {values.birth && age && (
                  <div className="animeLeft">
                    <label htmlFor="age">Idade</label>
                    <input readOnly value={age} />
                  </div>
                )}
              </FormRow>
              <FormRow>
                <div className="mediumInput">
                  <label htmlFor="race">Cor/Raça</label>
                  <FormField
                    as="select"
                    id="race"
                    name="race"
                    type="select"
                    onChange={handleChange}
                    value={values.race}
                  >
                    <option value={0}>Branca</option>
                    <option value={1}>Preta</option>
                    <option value={2}>Parda</option>
                    <option value={3}>Amarela</option>
                    <option value={4}>Indígena</option>
                  </FormField>
                  {errors.race && <Error>{errors.race}</Error>}
                </div>

                <div className="mediumInput">
                  <label htmlFor="sex">Sexo</label>
                  <FormField
                    as="select"
                    id="sex"
                    name="sex"
                    onChange={handleChange}
                    value={values.sex}
                  >
                    <option value={0}>Masculino</option>
                    <option value={1}>Feminino</option>
                    <option value={2}>Intersexo</option>
                  </FormField>
                </div>

                <div className="mediumInput">
                  <label htmlFor="gender">Gênero</label>
                  <FormField
                    as="select"
                    id="gender"
                    name="gender"
                    onChange={handleChange}
                    value={values.gender}
                  >
                    {genders.map(gender => (
                      <option key={gender.id} value={gender.id}>
                        {gender.label}
                      </option>
                    ))}
                  </FormField>
                  {errors.gender && <Error>{errors.gender}</Error>}
                </div>
              </FormRow>
              <FormRow>
                <div className="mediumInput">
                  <label htmlFor="n_register">
                    {width > 660 ? 'Registro do nascimento' : 'Nº Registro'}
                  </label>
                  <FormField id="n_register" name="n_register" type="text" />
                  {errors.n_register && <Error>{errors.n_register}</Error>}
                </div>

                <div className="mediumInput">
                  <label htmlFor="rg">RG</label>
                  <FormField
                    id="rg"
                    name="rg"
                    type="text"
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        onChange={handleChange}
                        value={values.rg}
                        alwaysShowMask
                        mask=""
                        maskPlaceholder={null}
                      />
                    )}
                  />
                  {errors.rg && <Error>{errors.rg}</Error>}
                </div>

                <div className="mediumInput">
                  <label htmlFor="cpf">CPF</label>
                  <FormField
                    id="cpf"
                    name="cpf"
                    type="text"
                    maxLength={14}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        onChange={handleChange}
                        value={values.cpf}
                        alwaysShowMask
                        mask="999.999.999-99"
                        maskPlaceholder={null}
                        onBlur={() => handleSearchCPF(values.cpf)}
                      />
                    )}
                  />
                  {errors.cpf && <Error>{errors.cpf}</Error>}
                </div>
              </FormRow>
              <FormRow>
                <div className="mediumInput">
                  <label htmlFor="nis">NIS</label>
                  <FormField id="nis" name="nis" type="text" maxLength={11} />
                  {errors.nis && <Error>{errors.nis}</Error>}
                </div>

                <div className="mediumInput">
                  <label htmlFor="sus">SUS</label>
                  <FormField id="sus" name="sus" type="text" maxLength={15} />
                  {errors.sus && <Error>{errors.sus}</Error>}
                </div>

                {width > 660 && <div className="mediumInput" />}
              </FormRow>
              <FormRow>
                <div className={width > 660 ? 'smallInput' : 'mediumInput'}>
                  <label htmlFor="phone">Telefone</label>
                  <FormField
                    id="phone"
                    name="phone"
                    type="phone"
                    maxLength={11}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        onChange={handleChange}
                        value={values.phone}
                        mask="(99) 99999-9999"
                      />
                    )}
                  />
                </div>

                <div className="smallInput animeDown radioGroup">
                  <label htmlFor="" />
                  <label htmlFor="" />
                  <label htmlFor="" />
                  <label htmlFor="" />
                  <div className="radioGroupRow">
                    <label>
                      <FormField type="checkbox" name="isWpp" />
                      <span className="checkMark" style={{ marginLeft: 5 }}></span>É whatsapp?
                    </label>
                  </div>
                </div>

                <div className="mediumInput">
                  <label htmlFor="email">E-mail</label>
                  <FormField id="email" name="email" type="email" maxLength={320} />
                  {errors.email && <Error>{errors.email}</Error>}
                </div>

                {width > 660 && <div className="smallInput" />}
              </FormRow>
              <FormRow>
                <div className="smallInput">
                  <label htmlFor="cep">CEP</label>
                  <FormField
                    id="cep"
                    name="cep"
                    type="text"
                    minLength={9}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        onChange={handleChange}
                        value={values.cep}
                        mask="99999-999"
                        style={{ paddingLeft: 5, paddingRight: 0 }}
                      />
                    )}
                  />
                  {errors.cep && <Error>{errors.cep}</Error>}
                </div>
                <div className="smallInput">
                  <Button
                    primary
                    type="button"
                    style={{ marginTop: 21, paddingLeft: 10, paddingRight: 10 }}
                    onClick={() =>
                      handleGetCEP(values.cep).then(response => {
                        if (response.error) {
                          info(response.message);
                        } else {
                          setFieldValue('logradouro', response.logradouro);
                          const array = response.logradouro.split(' ');
                          const type = typesLogradouro.find(type => type.label === array[0]);
                          type 
                            ? setFieldValue('tipoLogradouro', type.id)
                            : info('Tipo de logradouro não encontrado, selecione a esquerda!');
                          setFieldValue('complemento', response.complemento);
                          setFieldValue('bairro', response.bairro);
                          setFieldValue('cidade', response.localidade);
                          setFieldValue('uf', response.uf);
                        }
                      })
                    }
                  >
                    <FaSearchLocation size={30} />
                  </Button>
                </div>
                <div className="fullInput" />
              </FormRow>
              <FormRow>
                <div className="mediumInput">
                  <label htmlFor="tipoLogradouro">Tipo de Logradouro</label>
                  <FormField
                    as="select"
                    onChange={handleChange}
                    name="tipoLogradouro"
                    value={values.tipoLogradouro}
                  >
                    {typesLogradouro.map(type =>   
                      <option key={type.id} value={type.id}>
                        {type.label}
                      </option>
                    )}
                  </FormField>
                </div>
                <div className="fullInput">
                  <label htmlFor="logradouro">Logradouro</label>
                  <FormField id="logradouro" name="logradouro" type="text" maxLength={256} />
                </div>
                <div className="radioGroup" style={{ marginRight: -25 }}>
                  <div style={{ marginTop: 30 }}>
                    <label>
                      <FormField id="temNumero" name="temNumero" type="checkbox" value="1" />
                      <span className="checkMark"></span>
                    </label>
                  </div>
                </div>
                <div className="smallInput">
                  <label htmlFor="numero">Nº</label>
                  <FormField
                    id="numero"
                    name="numero"
                    type="text"
                    render={({ field }) => (
                      <input
                        {...field}
                        disabled={values.temNumero == 0}
                        value={values.temNumero == '1' ? values.numero : 'S/N'}
                        maxLength={6}
                        onChange={e => {
                          setFieldValue('numero', e.target.value.replace(/\D/g, ''));
                        }}
                      />
                    )}
                  />
                </div>
                <div className="fullInput">
                  <label htmlFor="complemento">Complemento</label>
                  <FormField id="complemento" name="complemento" type="text" maxLength={256} />
                </div>
              </FormRow>
              <FormRow>
                <div className="largeInput">
                  <label htmlFor="bairro">Bairro</label>
                  <FormField id="bairro" name="bairro" type="text" maxLength={256} />
                </div>
                <div className="largeInput">
                  <label htmlFor="cidade">Cidade</label>
                  <FormField id="cidade" name="cidade" type="text" maxLength={256} />
                </div>
                <div className="smallInput">
                  <label htmlFor="uf">UF</label>
                  <FormField as="select" onChange={handleChange} name="uf" value={values.uf}>
                    <option value={null}>Selecione</option>
                    {countries.map(country => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </FormField>
                </div>
              </FormRow>
              <FormRow>
                <div className="mediumInput">
                  <label htmlFor="forwarded">Encaminhado por:</label>
                  <FormField
                    as="select"
                    id="forwarded"
                    name="forwarded"
                    onChange={handleChange}
                    value={values.forwarded}
                  >
                    {forwardings.map(forwarding => (
                      <option key={forwarding.id} value={forwarding.id}>
                        {forwarding.label}
                      </option>
                    ))}
                  </FormField>
                </div>
              </FormRow>
              <FormRow>
                <div className="mediumInput radioGroup">
                  <div>
                    <label>
                      Possui parente (pessoa com deficiência, que resida na mesma casa) também em
                      atendimento sistemático pela Associação PODE?
                    </label>
                    <label>
                      <FormField name="haveParent" type="radio" value="1" />
                      <span className="checkMark" />
                      Sim
                    </label>
                    <label>
                      <FormField name="haveParent" type="radio" value="0" />
                      <span className="checkMark" />
                      Não
                    </label>
                  </div>
                </div>
                {values.haveParent !== 1 ? (
                  values.haveParent == '1' && (
                    <>
                      <div
                        className="mediumInput animeLeft"
                        style={{
                          marginTop: 'auto',
                          marginBottom: 'auto'
                        }}
                      >
                        <label htmlFor="parent">Selecione e adicione o parente</label>
                        <div style={{ display: 'flex' }}>
                          <select
                            style={{ width: '100%' }}
                            id="parent"
                            name="parent"
                            onChange={e => setSelectedParent(e.target.value)}
                            value={selectedParent}
                          >
                            {patients.map((parent, index) => {
                              return (
                                <option key={`${parent.id}/${parent.year}`} value={index}>
                                  {parent.name}
                                </option>
                              );
                            })}
                          </select>
                          <AddParentButton primary type="button" onClick={handleAddParent}>
                            <BsPlus size={30} color="#fff" />
                          </AddParentButton>
                        </div>

                        {selectedParents.length > 0 ? (
                          selectedParents.map(parent => (
                            <Parent className="animeUp" key={parent.id}>
                              <HiUserRemove
                                size={30}
                                color="#C61E24"
                                onClick={() => handleRemoveParent(parent)}
                              />
                              {parent.name}
                            </Parent>
                          ))
                        ) : (
                          <EmptyContent className="animeUp">Sem Parentes Adicionados</EmptyContent>
                        )}
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
              </FormRow>
              {CIDs.length 
                ? <>
                    <TitleSection>CIDs</TitleSection>
                    <FormRow 
                      style={{ borderBottomWidth: 2, borderBottomStyle: 'solid', borderColor: '#bbb', marginBottom: 16}}
                    >
                      <Selected
                        selected={CIDs}
                        handleRemove={() => {}}
                        disabled
                        placeholder="Nenhum CID"
                        isCID
                      />
                    </FormRow>
                  </> 
                : <></>  
              }
              <TitleSection>Acompanhante</TitleSection>
              <FormRow>
                <div className="largeInput">
                  <label htmlFor="responsibleName">Nome</label>
                  <FormField id="responsibleName" name="responsibleName" type="text" />
                  {errors.responsibleName && <Error>{errors.responsibleName}</Error>}
                </div>

                <div className="smallInput">
                  <label htmlFor="responsibleRelationship">Grau de parentesco</label>
                  <FormField
                    as="select"
                    id="responsibleRelationship"
                    name="responsibleRelationship"
                    onChange={handleChange}
                    value={values.responsibleRelationship}
                  >
                    {relationships.map(relationship => {
                      return (
                        <option key={relationship.id} value={relationship.id}>
                          {relationship.name}
                        </option>
                      );
                    })}
                  </FormField>
                  {errors.responsibleRelationship && (
                    <Error>{errors.responsibleRelationship}</Error>
                  )}
                </div>
              </FormRow>

              <FormRow>
                <div className="smallInput">
                  <label htmlFor="responsiblePhone">Telefone</label>
                  <FormField
                    id="responsiblePhone"
                    name="responsiblePhone"
                    type="phone"
                    maxLength={11}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        onChange={handleChange}
                        value={values.responsiblePhone}
                        mask="(99) 99999-9999"
                      />
                    )}
                  />
                  {errors.responsiblePhone && <Error>{errors.responsiblePhone}</Error>}
                </div>

                <div className="smallInput animeDown radioGroup">
                  <label htmlFor="" />
                  <label htmlFor="" />
                  <label htmlFor="" />
                  <label htmlFor="" />
                  <div className="radioGroupRow">
                    <label>
                      <FormField type="checkbox" name="responsibleIsWpp" />
                      <span className="checkMark" style={{ marginLeft: 5 }}></span>É whatsapp?
                    </label>
                  </div>
                </div>

                <div className="mediumInput">
                  <label htmlFor="responsibleEmail">E-mail</label>
                  <FormField
                    id="responsibleEmail"
                    name="responsibleEmail"
                    type="email"
                    maxLength={320}
                  />
                  {errors.responsibleEmail && <Error>{errors.responsibleEmail}</Error>}
                </div>

                <div className="smallInput" />
              </FormRow>

              <FormRow>
                <div className="fullInput notes">
                  <label htmlFor="notes">Observações Complementares</label>
                  <FormField id="notes" name="notes" type="text" maxLength={256} />
                </div>
              </FormRow>
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : 
                  'Enviar Dados'
                }
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export function EditMedicalReport() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [selectedPatient, setSelectedPatient] = useState({});
  const [id, setId] = useState('');
  const [year, setYear] = useState('');
  const navigate = useNavigate();

  function handleGetPatient() {
    const data = patient.split('-');
    getPatient(data[0], data[1]).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        const patientData = json.data.objPaciente;
        if(patientData.PAC_ALTA_INSTITUCIONAL) {
          warning('Paciente inativo!');
        } else {
          const patient = {
            code: patientData.PAC_CODIGO,
            name: patientData.PAC_NOME,
            year: patientData.PAC_ANO,
          };
          
          setSelectedPatient(patient);
          setId(`${patientData.PAC_CODIGO}`);
          setYear(`${patientData.PAC_ANO}`);
        }
      }
    });
  }

  function handleSubmit() {
    if (id && year) {
      if (Number(year) > 2002 && Number(year) <= new Date().getFullYear()) {
        editMedicalRecord(selectedPatient.code, selectedPatient.year, id, year).then(json => {
          if (json.error) {
            danger(`${json.message}`);
          } else {
            success('Prontuário editado com sucesso!');
            navigate('/user/entries/patients');
          }
        });
      } else {
        danger('Insira um ano válido!');
      }
    } else {
      danger('Preencha todos os campos!');
    }
  }

  useEffect(() => {
    getPatientsWithSector(0).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
      }
    });
  }, []);

  useEffect(() => {
    patients.length > 0 && setPatient(`${patients[0].id}-${patients[0].year}`);
  }, [patients]);

  return (
    <div className="animeUp">
      <h2 className="section">Edição de Prontuário</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatient}
        placeholder="pacientes"
      />
      {selectedPatient.name ? (
        <div className="animeUp">
          <h1>Editar prontuário do paciente {selectedPatient.name}</h1>
          <FormRow>
            <div className="mediumInput">
              <label>Antigo Prontuário</label>
              <input value={selectedPatient.code} disabled />
            </div>

            <div className="mediumInput">
              <label>Ano</label>
              <input value={selectedPatient.year} disabled />
            </div>

            <div className="fullInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput">
              <label htmlFor="code">Novo Prontuário</label>
              <input
                value={id}
                onChange={e => {
                  !isNaN(e.target.value) && setId(e.target.value);
                }}
                maxLength={3}
                onKeyDown={e => {
                  e.key === 'Enter' && handleSubmit();
                }}
              />
            </div>

            <div className="mediumInput">
              <label htmlFor="year">Ano</label>
              <input
                value={year}
                onChange={e => {
                  !isNaN(e.target.value) && setYear(e.target.value);
                }}
                maxLength={4}
                onKeyDown={e => {
                  e.key === 'Enter' && handleSubmit();
                }}
              />
            </div>

            <div className="fullInput" />
          </FormRow>
          <Button style={{ marginLeft: 20 }} primary onClick={handleSubmit}>
            Enviar
          </Button>
        </div>
      ) : (
        <h1>É necessário resgatar o paciente</h1>
      )}
    </div>
  );
}
