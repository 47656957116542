import React from "react";
import { Table } from '../../../../../components/Table';

export default function ListRevaluations({ revaluations, handleEditRevaluation }) {
  return (
    <Table
      content={revaluations}
      setContent={handleEditRevaluation}
      headers={{
        date: 'Data',
      }}
      buttons={{ edit: true }}
      typeTable="revaluations"
    />
  )
}