import styled from 'styled-components';
import { AddButton } from '../Therapeutic Project/styles';

export const RemoveButton = styled(AddButton)`
  padding: 10px;
  margin-right: 0px;
  margin-left: ${props => (props.noMarginLeft ? '0' : '20px')};
  background-color: #0000;
`;

export const FamiliarType = styled.label`
  min-width: 80px;
  margin-bottom: 0;
  border-bottom: 2px solid var(--red);
  padding: 4px 0;
  text-align: center;
`;

export const FamiliarInput = styled.input`
  margin-left: 10px;
  width: 50%;
  margin-bottom: 0;
`;
