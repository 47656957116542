import { PatientNameWrapper } from './styles';

export function PatientName({ patientInfos }) {
  return (
    <PatientNameWrapper>
      {/* {`${patientInfos.sector}.`}{`${patientInfos.id}`.padStart(3, '0')}/{patientInfos.year} -{' '} */}
      <span className="name">{patientInfos.name}</span>
    </PatientNameWrapper>
  );
}
