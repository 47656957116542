import styled from 'styled-components';

export const ProfilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
  /* background-color: var(--red); */
  padding: 5px;
  margin-bottom: 15px;
  /* border-bottom: 2px solid var(--red); */
`;

export const Profile = styled.button`
  display: flex;
  width: 100%;
  font-size: 1em;
  margin: 2px 0;
  background-color: ${props => (props.active ? 'var(--red)' : 'var(--input-border)')};
  color: ${props => (props.active ? '#fff' : 'var(--text-title)')};
  padding: 5px;
  font-weight: bold;
  transition: ease-out 0.2s;
  border: ${props => (props.active ? '2px solid black' : '2px solid var(--input-border)')};
  border-radius: ${props => (props.active ? '5px' : '0')};

  &:hover {
    transition: background-color 0.25s;
    background-color: ${props => (props.active ? 'var(--red)' : '#aaa')};
  }
`;

export const PermissionsContainer = styled.div`
  display: flex;
  width: 100%;

  > div {
    padding: 5px 0;
    border: 2px solid var(--red);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 30vh;
    overflow-y: auto;

    &.controls {
      align-items: center;
      justify-content: center;
      width: 120px;
      border: none;
      margin: 5px;
      overflow: hidden;

      > button.action {
        padding: 10px;
        margin: 10px 5px;
        border-radius: 5px;
        background-color: var(--red);

        &:hover {
          transition: filter 0.25s;
          filter: brightness(1.5);
        }

        &:focus {
          border: 2;
        }
      }
    }

    h2 {
      text-align: center;
      color: #444;
    }

    > button {
      display: flex;
      font-size: 1em;
      margin: 2px 5px;
      background-color: var(--input-border);
      color: var(--text-title);
      padding: 5px;
      font-weight: bold;
      transition: ease-out 0.2s;
      border: 2px solid var(--input-border);

      &:hover {
        transition: background-color 0.25s;
        background-color: #aaa;
      }

      &:focus {
        transition: ease-in 0.2s;
        border: 2px solid black;
        border-radius: 5px;
        background-color: var(--red);
        color: #fff;
      }
    }
  }
`;
