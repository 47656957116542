import styled from 'styled-components';

export const PatientNameWrapper = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;

  .name {
    color: var(--red);
  }
`;
