/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';

import { danger, success, info, warning } from '../../../../components/Alerts';
import { FormField, FormRow, SubmitButton, TitleSection } from '../../Entries/Patients/styles';
import {
  getCommitments,
  setPatientHealth,
  getAnamnese,
  getPatientSocial,
  getPatientHealth,
  setPatientSocial,
  getPatientsWithSector,
  getScholarity,
  getSocialPrograms,
  getPatientScholarity,
  setPatientScholarity,
  setAnamnese,
  getInclusiveScholarity,
  getCirurgias,
  getCIDs
} from '../../../../utils/api';
import Search from '../../../../components/Search';
import Modal from '../../../../components/Modal';
import { GlobalContext } from '../../../../GlobalContext';
import { ModalContent, ThinButton } from './styles';
import { AddButton, RadioRow } from '../Therapeutic Project/styles';
import SelectAdd from './Components/SelectAdd';
import Selected from './Components/Selected';
import { PatientName } from '../../../../components/PatientName';
import { BsPlus, BsTrash } from 'react-icons/bs';

const schema = Yup.object().shape({
  // ubs: Yup.string().required(messageTranslate.otherRequired),
  // bloodType: Yup.string().max(3)
});


export function ScreeningGlobal() {
  const { setModalIsOpen, modalIsOpen, actions, currentUser} = React.useContext(GlobalContext);
  const bloodTypes = ['A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-', 'N/I'];
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [patientInfos, setPatientInfos] = useState({});
  const [commitments, setCommitments] = useState([]);
  // const [syndromes, setSyndromes] = useState([]);
  const [socialPrograms, setSocialPrograms] = useState([]);
  const [scholarities, setScholarities] = useState([]);
  const [inclusiveScholarities, setInclusiveScholarities] = useState([]);
  const [initialValues, setInitialValues] = useState({
    code: 0,
    ubs: '',
    bloodType: 'A+',
    diagnostic: '',
    cid: '',
    commitments: [],
    previousTreatments: '',
    haveCadUnico: '0',
    socialPrograms: [],
    haveWork: '0',
    workDescription: '',
    income: '',
    cras: '',
    n_componentsFamily: '',
    n_worksFamily: '',
    familyIncome: '0',
    familySituation: '0',
    
    haveStudy: '0',
    studyDescription: '',
    studyYear: '',
    studyInclusive: [],
    responsibleScholarity: 0,
    responsibleScholarity2: 0,
    notes: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCID, setSelectedCID] = useState('');
  const [CIDs, setCIDs] = useState([]);
  const [allCIDS, setAllCIDS] = useState([]);
  
  useEffect(() => {
    getCIDs().then(json => {
      if(json.error) {
        danger(json.message);
      } else {
        let array = [];
        json.data.arrCID.map(cid => {
          const obj = {
            id: cid[0],
            name: cid[1]
          }
          array.push(obj);
        })
        setAllCIDS(array);
        array.length > 0 && setSelectedCID(array[0].id);
      }
    })

    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0] ? setPatient(`${arr[0].id}-${arr[0].year}`) : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getSocialPrograms().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrProgramaSocial.forEach(program => {
          const programObj = {
            id: `${program[0]}`,
            name: program[1]
          };
          arr.push(programObj);
        });
        setSocialPrograms(arr);
      }
    });

    getCommitments().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrComprometimento.forEach(commitment => {
          const commitmentObj = {
            id: commitment[0],
            name: commitment[1]
          };
          arr.push(commitmentObj);
        });
        setCommitments(arr);
      }
    });

    getScholarity().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrEscolaridade.forEach(scholarity => {
          const scholarityObj = {
            id: scholarity[0],
            name: scholarity[1]
          };
          arr.push(scholarityObj);
        });
        setScholarities(arr);
        setInitialValues(old => (
          {...old, 
            responsibleScholarity: arr[0].id,  
            responsibleScholarity2: arr[0].id,  
          }))
      }
    });

    getInclusiveScholarity().then(json => {
      if (json.error) {
        danger(json.message);
      } else {
        let arr = [];
        json.data.arrEducacaoInclusiva.forEach(inclusivity => {
          const inclusivityObj = {
            id: inclusivity[0],
            name: inclusivity[1]
          };
          arr.push(inclusivityObj);
        });
        setInclusiveScholarities(arr);
      }
    });
  }, []);

  function handleGetPatientInfos() {
    const data = patient.split('-');
    const patientData = patients.find(patient => patient.id == data[0] && patient.year == data[1]);
    !patientData.active && warning('Paciente inativo!')
    setPatientInfos(patientData);

    getPatientHealth(data[0], data[1]).then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar os dados de saúde: ${json.message}`);
      } else {
        const {
          PS_CID_CODIGOS,
          PS_COMPROMETIMENTOS,
          PS_TIPO_SANGUINEO,
          PS_TRATAMENTOS_ANTERIORES,
          PS_UBS_NOME,
          PS_SITUACAO_ECONOMICA_FAMILIA,
          PS_TRATAMENTOS_CONCOMITANTES,
          PS_HIST_CLINICO_PACIENTE,
          PS_HIST_CLINICO_FAMILIAR
        } = json.data.objPacienteSaude;

        const arrayCIDs = PS_CID_CODIGOS.map(cid => {
          return allCIDS.find(element => element.id == cid);
        });
        
        let obj = { ...initialValues };
        setCIDs(arrayCIDs);
        obj.code = patient;
        obj.ubs = PS_UBS_NOME;
        obj.bloodType = PS_TIPO_SANGUINEO;
        obj.previousTreatments = PS_TRATAMENTOS_ANTERIORES;
        obj.commitTreatments = PS_TRATAMENTOS_CONCOMITANTES;
        obj.commitments = PS_COMPROMETIMENTOS;
        obj.clinicHistoric = PS_HIST_CLINICO_PACIENTE;
        obj.clinicHistoricFamily = PS_HIST_CLINICO_FAMILIAR;
        obj.familySituation = `${PS_SITUACAO_ECONOMICA_FAMILIA}`;
        setInitialValues({ ...obj });
        getPatientSocial(data[0], data[1]).then(json => {
          if (json.error) {
            danger(`Ocorreu um erro ao resgatar os dados sociais: ${json.message}`);
          } else {
            const {
              PS_COMPONENTES_FAMILIARES,
              PS_EH_TRABALHADOR,
              PS_QTD_FAMILIARES_Q_TRABALHAM,
              PS_RENDA_FAMILIAR,
              PS_TRABALHO_RENDA,
              PS_TRABALHO_TIPO,
              PS_EH_INSCRITO_NO_CADUNICO,
              PS_PROGRAMAS_SOCIAIS,
              PS_SITUACAO_ECONOMICA_FAMILIA
            } = json.data.objPacienteSocial;

            obj.haveWork = `${PS_EH_TRABALHADOR}`;
            obj.workDescription = PS_TRABALHO_TIPO;
            obj.income = Number(PS_TRABALHO_RENDA);
            obj.n_componentsFamily = PS_COMPONENTES_FAMILIARES;
            obj.familySituation = PS_SITUACAO_ECONOMICA_FAMILIA;
            obj.n_worksFamily = PS_QTD_FAMILIARES_Q_TRABALHAM;
            obj.familyIncome = PS_RENDA_FAMILIAR;
            obj.haveCadUnico = `${PS_EH_INSCRITO_NO_CADUNICO}`;
            obj.socialPrograms = PS_PROGRAMAS_SOCIAIS;

            getPatientScholarity(data[0], data[1]).then(json => {
              if (json.error) {
                danger(`Ocorreu um erro ao resgatar dados de escolaridade: ${json.message}`);
              } else {
                const {
                  PE_EH_ESTUDANTE,
                  PE_ESCOLARIDADE_RESPONSAVEL,
                  PE_ESCOLARIDADE_GENITOR,
                  PE_EDUCACOES_INCLUSIVAS,
                  PE_ESCOLA_NOME,
                  PE_ESCOLA_SERIE
                } = json.data.objPacienteEscolar;

                obj.haveStudy = `${PE_EH_ESTUDANTE}`;
                obj.studyDescription = PE_ESCOLA_NOME;
                obj.studyYear = PE_ESCOLA_SERIE;
                obj.responsibleScholarity = PE_ESCOLARIDADE_RESPONSAVEL;
                obj.responsibleScholarity2 = PE_ESCOLARIDADE_GENITOR;
                obj.studyInclusive = PE_EDUCACOES_INCLUSIVAS;

                getAnamnese(data[0], data[1]).then(json => {
                  if (json.error) {
                    danger(`Ocorreu um erro ao resgatar dados da anamnese`);
                  } else {
                    obj.notes = json.data.objAnamnese.PA_OBS;
                    setInitialValues(obj);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  function handleAddCID() {
    const exist = CIDs.some(cid => cid.id === selectedCID);
    !exist && setCIDs(old => {
      const current = allCIDS.find(cid => cid.id === selectedCID);
      return [current, ...old];
    })
  }

  function handleRemoveCID(element) {
    const array = [...CIDs];
  
    const index = array.findIndex(el => el.id === element.id);
    array.splice(index, 1);
    setCIDs(array);
  }

  function handleSubmit(values) {
    if (!isLoading) {
      setIsLoading(true);
      const data = patient.split('-');
      setPatientHealth(
        values,
        data[0],
        data[1],
        CIDs
      ).then(json => {
        if (json.error) {
          danger(`Ocorreu um erro ao enviar dados de saúde do paciente: ${json.message}`);
          setIsLoading(false);
        } else {
          setPatientSocial(values, data[0], data[1]).then(json => {
            if (json.error) {
              danger(`Ocorreu um erro ao enviar dados sociais do paciente: ${json.message}`);
              setIsLoading(false);
            } else {
              setPatientScholarity(values, data[0], data[1]).then(json => {
                if (json.error) {
                  danger(`Ocorreu um erro ao enviar dados escolares do paciente: ${json.message}`);
                  setIsLoading(false);
                } else {
                  setAnamnese(values, data[0], data[1]).then(json => {
                    if (json.error) {
                      danger(`Ocorreu um erro ao enviar observação da anamnese: ${json.message}`);
                      setIsLoading(false);
                    } else {
                      setInitialValues({
                        code: 0,
                        ubs: '',
                        bloodType: 'A+',
                        diagnostic: '',
                        cid: '',
                        commitments: [],
                        previousTreatments: '',
                        clinicHistoric: '',
                        clinicHistoricFamily: '',
                        haveCadUnico: '0',
                        socialPrograms: [],
                        haveWork: '0',
                        workDescription: '',
                        income: '',
                        cras: '',
                        n_componentsFamily: '',
                        n_worksFamily: '',
                        familyIncome: '0',
                        familySituation: '0',

                        haveStudy: '0',
                        studyDescription: '',
                        studyYear: '',
                        studyInclusive: [],
                        responsibleScholarity: 0,
                        responsibleScholarity2: 0,
                        notes: ''
                      });
                      setIsLoading(false);
                      success('Todos os dados foram enviados com sucesso!');
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  return (
    <div className="animeUp">
      <h2 className="section">Triagem Global</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {initialValues.code !== 0 && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <TitleSection>Dados de Saúde</TitleSection>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={(values, { resetForm, setFieldValue }) => handleSubmit(values)}
            disabled={!patientInfos.active}
          >
            {({ values, errors, handleChange, setFieldValue, resetForm }) => {
              function handleClose() {
                values.socialPrograms.length === 0 && setFieldValue('haveCadUnico', '0');
              }
              return (
                <Form disabled={!actions.some(action => action.id === 101)}>
                  <Modal handleClose={handleClose}>
                    <ModalContent>
                      <h1>Programas Sociais</h1>
                      {socialPrograms.map(program => (
                        <label key={program.id}>
                          <FormField type="checkbox" name="socialPrograms" value={`${program.id}`} />
                          <span className="checkMark"></span>
                          {program.name}
                        </label>
                      ))}
                    </ModalContent>
                  </Modal>
                  <FormRow>
                    <div className="fullInput">
                      <label htmlFor="ubs">Nome da Unidade Básica de Saúde da Família (UBS) de referência</label>
                      <FormField id="ubs" name="ubs" type="text" />
                    </div>
                    <div className="smallInput">
                      <label htmlFor="bloodType">Tipo Sanguíneo</label>
                      <FormField
                        as="select"
                        id="bloodType"
                        name="bloodType"
                        onChange={handleChange}
                        value={values.bloodType}
                      >
                        {bloodTypes.map(type => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </FormField>
                    </div>
                  </FormRow>
                  <FormRow>
                  <Search
                    data={allCIDS}
                    value={selectedCID}
                    setValue={setSelectedCID}
                    search={handleAddCID}
                    placeholder="CIDs"
                    notPatient
                    searchLabel="CID 10"
                    selectLabel="Diagnóstico"
                  />
                  </FormRow>
                  <FormRow 
                    style={{ borderBottomWidth: 2, borderBottomStyle: 'solid', borderColor: '#bbb',
                      marginBottom: 16
                    }}>
                    <Selected
                      selected={CIDs}
                      handleRemove={handleRemoveCID}
                      placeholder="Nenhum CID"
                      isCID
                    />
                  </FormRow>
                  <FormRow>
                    <div className="mediumInput animeDown radioGroup">
                      <label htmlFor="">Tipo de comprometimento</label>
                      <div className="radioGroupRow">
                        {commitments.map(commitment => (
                          <label key={commitment.id}>
                            <FormField type="checkbox" name="commitments" value={`${commitment.id}`} />
                            <span className="checkMark"></span>
                            {commitment.name}
                          </label>
                        ))}
                      </div>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="fullInput">
                      <label className="highlight">Histórico clínico</label>
                      <FormField id="clinicHistoric" name="clinicHistoric" type="text" maxLength={512} />
                    </div>
                  </FormRow>

                  <FormRow>
                    <div className="fullInput">
                      <label htmlFor="previousTreatments">
                        Acompanhamento(s) de habilitação/reabilitação realizado(s) anteriormente (citar a instituição)
                      </label>
                      <FormField id="previousTreatments" name="previousTreatments" type="text" />
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="fullInput">
                      <label htmlFor="commitTreatments">
                        Acompanhamento(s) de habilitação/reabilitação realizado(s) concomitantemente (citar a
                        instituição)
                      </label>
                      <FormField id="commitTreatments" name="commitTreatments" type="text" />
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="fullInput">
                      <label className="highlight">Histórico clínico familiar</label>
                      <FormField id="clinicHistoricFamily" name="clinicHistoricFamily" type="text" maxLength={512} />
                    </div>
                  </FormRow>
                  <TitleSection>Dados Sociais</TitleSection>
                  <FormRow>
                    <div className="mediumInput radioGroup">
                      <label htmlFor="">Está inscrito no CadÙnico?</label>
                      <div className="radioGroupRow">
                        <label>
                          <FormField name="haveCadUnico" type="radio" value="1" onClick={() => setModalIsOpen(true)} />
                          <span className="checkMark" />
                          Sim
                        </label>
                        <label>
                          <FormField
                            name="haveCadUnico"
                            type="radio"
                            value="0"
                            onClick={() => setFieldValue('socialPrograms', [])}
                          />
                          <span className="checkMark" />
                          Não
                        </label>
                        {values.haveCadUnico === '1' && values.socialPrograms.length === 0 && !modalIsOpen && (
                          <ThinButton className="animeUp" onClick={() => setModalIsOpen(true)} primary type="button">
                            Selecionar Programas
                          </ThinButton>
                        )}
                      </div>
                    </div>
                  </FormRow>
                  <FormRow>
                    {values.haveCadUnico == 1 && (
                      <div className="mediumInput animeDown radioGroup">
                        {values.socialPrograms.length > 0 && <label>Programas Selecionados:</label>}
                        <div>
                          {socialPrograms
                            .filter(program => values.socialPrograms.includes(program.id))
                            .map(program => {
                              return (
                                <label key={program.id} className="animeUp">
                                  <FormField type="checkbox" name="socialPrograms" value={`${program.id}`} />
                                  <span className="checkMark"></span>
                                  {program.name}
                                </label>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </FormRow>
                  <FormRow>
                    <div className="mediumInput radioGroup">
                      <label htmlFor="">Trabalha</label>
                      <div className="radioGroupRow">
                        <label>
                          <FormField name="haveWork" type="radio" value="1" />
                          <span className="checkMark" />
                          Sim
                        </label>
                        <label>
                          <FormField name="haveWork" type="radio" value="0" />
                          <span className="checkMark" />
                          Não
                        </label>
                      </div>
                    </div>
                    {values.haveWork == 1 && (
                      <>
                        <div className="animeLeft fullInput">
                          <label htmlFor="workDescription">Tipo de trabalho</label>
                          <FormField id="workDescription" name="workDescription" type="text" />
                        </div>
                        <div className="animeLeft fullInput">
                          <label htmlFor="familyIncome">Renda</label>
                          <FormField
                            as="select"
                            id="income"
                            name="income"
                            onChange={handleChange}
                            value={values.income}
                          >
                            <option value="0">Menos de 1 salário mínimo</option>
                            <option value="1">1 a 2 salários mínimos</option>
                            <option value="2">2 a 3 salários mínimos</option>
                            <option value="3">Mais de 3 salários mínimos</option>
                          </FormField>
                        </div>
                      </>
                    )}
                  </FormRow>
                  <FormRow>
                    <div className="fullInput">
                      <label htmlFor="n_componentsFamily">Nº de componentes familiares (casa)</label>
                      <FormField id="n_componentsFamily" name="n_componentsFamily" type="text" />
                    </div>
                    <div className="fullInput">
                      <label htmlFor="n_worksFamily">Nº de pessoas que trabalham na familia</label>
                      <FormField id="n_worksFamily" name="n_worksFamily" type="text" />
                    </div>

                    <div className="fullInput">
                      <label htmlFor="familyIncome">Renda familiar em salários mínimos</label>
                      <FormField
                        as="select"
                        id="familyIncome"
                        name="familyIncome"
                        onChange={handleChange}
                        value={values.familyIncome}
                      >
                        <option value="0">Menos de 1 salário mínimo</option>
                        <option value="1">1 a 2 salários mínimos</option>
                        <option value="2">2 a 3 salários mínimos</option>
                        <option value="3">Mais de 3 salários mínimos</option>
                      </FormField>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="mediumInput">
                      <label htmlFor="familySituation">Situação econômica da família</label>
                      <FormField
                        as="select"
                        id="familySituation"
                        name="familySituation"
                        onChange={handleChange}
                        value={values.familySituation}
                      >
                        <option value="0">Extrema pobreza (com renda mensal por pessoa de até R$ 89,00)</option>
                        <option value="1">Pobreza (com renda mensal por pessoa entre R$ 89,01 e R$ 178,00)</option>
                        <option value="2">Não se encaixa na categoria de extrema pobreza e/ou pobreza</option>
                      </FormField>
                    </div>
                  </FormRow>

                  <TitleSection>Dados Escolares</TitleSection>
                  <FormRow>
                    <div className="mediumInput radioGroup">
                      <label htmlFor="">Estuda?</label>
                      <div className="radioGroupRow">
                        <label>
                          <FormField name="haveStudy" type="radio" value="1" />
                          <span className="checkMark radio" />
                          Sim
                        </label>
                        <label>
                          <FormField name="haveStudy" type="radio" value="0" />
                          <span className="checkMark radio" />
                          Não
                        </label>
                        <label>
                          <FormField name="haveStudy" type="radio" value="2" />
                          <span className="checkMark radio" />
                          Não tem idade escolar
                        </label>
                      </div>
                    </div>
                    {values.haveStudy == 1 && (
                      <>
                        <div className="animeLeft fullInput">
                          <label htmlFor="studyDescription">Nome da escola</label>
                          <FormField id="studyDescription" name="studyDescription" type="text" />
                        </div>
                        <div className="animeLeft fullInput">
                          <label htmlFor="studyYear">Série/ano</label>
                          <FormField id="studyYear" name="studyYear" type="text" />
                        </div>
                      </>
                    )}
                  </FormRow>
                  {values.haveStudy == 1 && (
                    <FormRow>
                      <div className="fullInput animeDown radioGroup">
                        <label htmlFor="">É atendido em qual modalidade de Educação Inclusiva?</label>
                        {inclusiveScholarities.map(inclusivity => (
                          <div key={inclusivity.id}>
                            <label>
                              <FormField type="checkbox" name="studyInclusive" value={`${inclusivity.id}`} />
                              <span className="checkMark"></span>
                              {inclusivity.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormRow>
                  )}

                  <FormRow style={{ marginBottom: -30 }}>
                    <div className="fullInput">
                      <label htmlFor="responsibleScholarity">Escolaridade da genitora</label>
                      <FormField
                        as="select"
                        id="responsibleScholarity"
                        name="responsibleScholarity"
                        onChange={handleChange}
                        value={values.responsibleScholarity}
                      >
                        {scholarities.map(scholarity => (
                          <option key={scholarity.id} value={scholarity.id}>
                            {scholarity.name}
                          </option>
                        ))}
                      </FormField>
                    </div>
                    <div className="fullInput">
                      <label htmlFor="responsibleScholarity2">Escolaridade do genitor</label>
                      <FormField
                        as="select"
                        id="responsibleScholarity2"
                        name="responsibleScholarity2"
                        onChange={handleChange}
                        value={values.responsibleScholarity2}
                      >
                        {scholarities.map(scholarity => (
                          <option key={scholarity.id} value={scholarity.id}>
                            {scholarity.name}
                          </option>
                        ))}
                      </FormField>
                    </div>
                  </FormRow>

                  <FormRow>
                    <div className="fullInput notes">
                      <label htmlFor="notes">Observações Complementares</label>
                      <FormField id="notes" name="notes" type="text" />
                    </div>
                  </FormRow>
                  {patientInfos.active &&
                    <SubmitButton primary type="submit">
                      {isLoading ? (
                        <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                      ) : (
                        'Enviar Dados'
                      )}
                    </SubmitButton>
                  }
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}
