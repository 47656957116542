import { Route, Routes } from 'react-router-dom';
import { Container } from '../Entries/style';

import { ScreeningGlobal } from './Global';
import ListEvolutions from './Evolution/ListEvolutions';
import NewEvolution from './Evolution/NewEvolution';
import TherapeuticProject from './Therapeutic Project';
import Revaluation from './Revaluation';
import Enfermagem from './Screening/Enfermagem';
import Pedagogia from './Screening/Pedagogia';
import Fonoaudiologia from './Screening/Fonoaudiologia';
import Fisioterapia from './Screening/Fisioterapia';
import Psicologia from './Screening/Psicologia';
import AudiologiaCrianca from './Screening/Audiologia/AudiologiaCrianca';
import AudiologiaAdulto from './Screening/Audiologia/AudiologiaAdulto';
import Closing from './Closing';
import RevertClosing from './RevertClosing';
import FamilyAccopaniment from './FamilyAccopaniment';
import EducacaoFisica from './Screening/EducacaoFisica';
import Musicalizacao from './Screening/Musicalizacao';

export function MenuMovements() {
  return (
    <Container>
      <Routes>
        <Route path="/global" element={<ScreeningGlobal />} />
        <Route path="/evolution" element={<ListEvolutions />} />
        <Route path="/evolution/new/:id" element={<NewEvolution />} />
        <Route path="/therapeutic-project" element={<TherapeuticProject />} />
        <Route path="/revaluation" element={<Revaluation />} />
        <Route path="/screening/enfermagem" element={<Enfermagem />} />
        <Route path="/screening/pedagogia-psicopedagogia" element={<Pedagogia />} />
        <Route path="/screening/fonoaudiologia" element={<Fonoaudiologia />} />
        <Route path="/screening/fisioterapia" element={<Fisioterapia />} />
        <Route path="/screening/psicologia" element={<Psicologia />} />
        <Route path="/screening/educacao-fisica" element={<EducacaoFisica />} />
        <Route path="/screening/musicalizacao" element={<Musicalizacao />} />
        <Route path="/screening/audiologia-crianca" element={<AudiologiaCrianca />} />
        <Route path="/screening/audiologia-adulto" element={<AudiologiaAdulto />} />
        <Route path="/closing" element={<Closing />} />
        <Route path="/revert-closing" element={<RevertClosing />} />
        <Route path="/family-accompaniment" element={<FamilyAccopaniment />} />
      </Routes>
    </Container>
  );
}
