import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { danger, success } from '../../../../../../components/Alerts';
import { useNavigate } from 'react-router';
import ReactLoading from 'react-loading';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { RadioRow, Select } from '../../../Therapeutic Project/styles';
import { setTriagemPsicologia } from '../../../../../../utils/api';

export default function FormMusic({
  initialValues,
  selectedPatient,
  currentUser,
  print,
  responses,
  patientInfos,
  frequencies,
  isPrint,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      if (
        false
        // !values.alteracaoSono ||
        // (values.pesadelosRecorrentes === '000411' &&
        //   values.pesadelosPeriodicidade === 'Frequência') ||
        // (values.pesadelosRecorrentes === '000411' && !values.pesadelosPeriodicidade)
      ) {
        danger('Preencha os campos corretamente!');
      } else {
        setIsLoading(true);
        const data = selectedPatient.split('-');
        
        // AGUARDANDO BACK-END

        // setTriagemPsicologia(data[0], data[1], objPsi).then(json => {
        //   setIsLoading(false);
        //   if (json.error) {
        //     danger(`${json.message}`);
        //   } else {
        //     success('Dados enviados com sucesso!');
        //     print();
        //     navigate('/user');
        //   }
        // });
      }
    }
  }

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!patientInfos.active}>
            <TitleSection>Coordenação motora grossa/ampla</TitleSection>
            <p style={{ marginBottom: 16 }}>
              Primeira condição a ser desenvolvida no espaço infantil. É o trabalho que aperfeiçoa os movimentos dos membros superiores e inferiores.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue caminhar com qualidade?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue correr?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue saltar?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Coordenação motora fina</TitleSection>
            <p style={{ marginBottom: 16 }}>
              Ocorre a partir da reação conjunta do olho e da mão dominante. É a capacidade de realizar movimentos coordenados utilizando pequenos grupos musculares das extremidades.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue segurar um objeto?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue recepcionar um objeto?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue aplicar força palmar?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue pintar um espaço de modo satisfatório? </label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Lateralidade</TitleSection>
            <p style={{ marginBottom: 16 }}>
              É a dominância lateral de um lado em relação ao  outro. É a noção que a criança adquire durante uma atividade de deslocamento, qual lado do corpo está sendo trabalhado.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente apresenta alguma desses transtornos de lateralidade?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue distinguir os lados?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Equilíbrio</TitleSection>
            <p style={{ marginBottom: 16 }}>
              Habilidade da criança de manter o controle do corpo. Pode-se utilizar ambos os lados ao mesmo tempo, apenas um lado ou ambos alternadamente.
            </p>
            <FormRow>      
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue andar sobre a corda utilizando os dois pés?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente consegue saltar bambolês apenas com uma perna?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Estruturação espacial</TitleSection>
            <p style={{ marginBottom: 16 }}>
              Quando se tem noção de como deve agir, movimentar em um determinado lugar adaptando-se às limitações do espaço.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">O paciente sabe se localizar dentro do espaço?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Consegue identificar que está dentro ou fora?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Consegue identificar se sua massa cabe em determinado espaço?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            
            <TitleSection>Orientação temporal</TitleSection>
            <p style={{ marginBottom: 16 }}>
              Quando se tem noção de como deve agir, movimentar em um determinado lugar adaptando-se às limitações do espaço.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue identificar o antes e depois?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>              
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue identificar a duração de um intervalo (longo ou curto)?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            
            <TitleSection>Ritmo</TitleSection>
            <p style={{ marginBottom: 16 }}>
              É a capacidade da criança de perceber um fenômeno que acontece em uma determinada duração, ordem e, também, alternância. A percepção acontece de forma individual e espontânea.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue realizar uma contagem (1,2,3,4) no mesmo ritmo que estalos do dedo do professor?</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            
            <TitleSection>Esquema corporal</TitleSection>
            <p style={{ marginBottom: 16 }}>
              É o conhecimento que a criança adquire do próprio corpo e suas partes. Por meio desse conhecimento consegue-se manipular e utilizar o corpo para o relacionamento com  meio ambiente.
            </p>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Paciente consegue identificar corretamente as partes do corpo a partir da música: Cabeça, ombro joelho e pé? </label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
}
