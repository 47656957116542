import { Route, Routes } from 'react-router-dom';

import { Container } from '../../Dashboard/style';

import Permissions from './Permissions';
import Upload from './Upload';

export function MenuExtras() {
  return (
    <Container>
      <Routes>
        <Route path="/permissions" element={<Permissions />} />
        <Route path="/upload" element={<Upload />} />
      </Routes>
    </Container>
  );
}
