import React from 'react';
import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';
import { RadioRow, Select } from '../../../Therapeutic Project/styles';
import PrintHeader from '../../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';

const PrintEdFisica = React.forwardRef(
  ({ initialValues, selectedPatient, currentUser, responses, frequencies, patients }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])

    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />

        <PrintContainer>
          <TitleSection>Avaliação Psicológica</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Humor frequente</label>
              {/* <RadioRow>
                {responses.humorFrequente.map(response => (
                  <RadioOrCheck isPrint response={response} initialValues={initialValues} />
                ))}
              </RadioRow> */}
            </div>
          </FormRow>  
        </PrintContainer>
      </div>
    );
  }
);

export default PrintEdFisica;
