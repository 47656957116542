import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import ReactLoading from 'react-loading';
import { danger, success } from '../../../../../../components/Alerts';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { useNavigate } from 'react-router';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import { setTriagemAudioCrianca } from '../../../../../../utils/api';

export default function FormAudiologiaCrianca({
  initialValues,
  selectedPatient,
  currentUser,
  print,
  responses,
  isPrint,
  patientInfos,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(values) {
    setInitialValues(values);

    if (!isLoading) {
      if (false) {
        danger('Preencha os campos corretamente!');
      } else {
        const data = selectedPatient.split('-');
        const obj = {
          TRI_AC_PACIENTE: data[0],
          TRI_AC_ANO: data[1],
          TRI_AC_USUARIO: currentUser.id,
          TRI_AC_ANTECEDENTES_FAMLR_HERDT: values.antecedentes,
          TRI_AC_ANTECEDENTES_ESPECIFICAR: values.antecedentesNote,
          TRI_AC_CONSANGUINIDADE: values.consanguinidade,
          TRI_AC_ABORTOS_DA_GENITORA: values.abortosGenitora,
          TRI_AC_ABORTOS_DA_GENITORA_QTDE: values.abortosQuantidade,
          TRI_AC_FILHOS_VIVOS: values.filhosVivos,
          TRI_AC_FILHOS_VIVOS_QTDE: values.filhosQuantidade,
          TRI_AC_NATIMORTO: values.natimorto,
          TRI_AC_NATIMORTO_QTDE: values.natimortoQuantidade,
          TRI_AC_RUBEOLA: values.rubeola,
          TRI_AC_ALERGIAS: values.alergias,
          TRI_AC_ALERGIAS_QUAIS: values.alergiasQuais,
          TRI_AC_GRIPES: values.gripes,
          TRI_AC_GRIPES_PERIODO_GESTACAO: values.gripesNote,
          TRI_AC_HEMORRAGIAS: values.hemorragias,
          TRI_AC_HEMORRAGIAS_PERIODO_GSTC: values.hemorragiasNote,
          TRI_AC_RAIO_X: values.raioX,
          TRI_AC_RAIO_X_PERIODO_GESTACAO: values.raioXNote,
          TRI_AC_TENTATIVA_DE_ABORTO: values.aborto,
          TRI_AC_TENTATIVA_DE_ABORTO_TIPO: values.tentativaAbortoNote,
          TRI_AC_INFECCAO_URINARIA: values.infeccaoUrinaria,
          TRI_AC_SIFILIS: values.sifilis,
          TRI_AC_TOXOPLASMOSE: values.toxoplasmose,
          TRI_AC_HIV: values.hiv,
          TRI_AC_DIABETES_GESTACIONAL: values.diabetesGestacional,
          TRI_AC_DIABETES_GESTACIONAL_PRD: values.diabetesGestacionalNote,
          TRI_AC_COVID19: values.covid19,
          TRI_AC_COVID19_PERIODO_GESTACAO: values.covid19Note,
          TRI_AC_HIPERTENSAO_ARTERIAL: values.hipertensao,
          TRI_AC_HTA_PERIODO_GESTACIONAL: values.hipertensaoNote,
          TRI_AC_MEDICACAO_UTLZD_NA_GESTC: values.medicacao,
          TRI_AC_PRE_NATAL_OUTRAS_DOENCAS: values.outrasDoencasPreNatal,
          TRI_AC_PRE_OUTRAS_DOENCAS_QUAIS: values.outrasDoencasPreNatalNote,
          TRI_AC_TIPO_DE_PARTO: values.tipoParto,
          TRI_AC_GESTACAO_EM_SEMANAS: values.tempoGestacao,
          TRI_AC_PESO_AO_NASCER: values.pesoNascer,
          TRI_AC_APGAR_1MIN: values.APGAR1,
          TRI_AC_APGAR_5MIN: values.APGAR5,
          TRI_AC_APGAR_PC: values.APGARPC,
          TRI_AC_DEMOROU_PARA_CHORAR: values.demorouChorar,
          TRI_AC_OUTROS_FATOS_OCORRIDOS: values.outrosFatores.join('|'),
          TRI_AC_MALFORMACAO_CONGENITA: values.malformacao,
          TRI_AC_MALFORMACAO_CONGNT_QUAIS: values.malformacaoNote,
          TRI_AC_INCUBADORA: values.incubadora,
          TRI_AC_INCUBADORA_TEMPO: values.incubadoraNote,
          TRI_AC_ICTERICIA: values.ictericia,
          TRI_AC_FOTO_TERAPIA: values.fotoTerapia,
          TRI_AC_FOTO_TERAPIA_TEMPO: values.fotoTerapiaNote,
          TRI_AC_VENTILACAO_MECANICA: values.ventilacaoMecanica,
          TRI_AC_VENTILACAO_MECANICA_TIPO: values.ventilacaoMecanicaTipo,
          TRI_AC_VENTILACAO_MECNICA_TEMPO: values.ventilacaoMecanicaDuracao,
          TRI_AC_SONDA_ALIMENTAR: values.sondaAlimentar,
          TRI_AC_SONDA_ALIMENTAR_TEMPO: values.sondaAlimentarNote,
          TRI_AC_BEBE_MOLINHO: values.bebeMolinho,
          TRI_AC_DIFICULDADE_PARA_SUGAR: values.dificuldadeSugar,
          TRI_AC_ENGASGO_AO_ENGOLIR: values.engasgo,
          TRI_AC_MENINGITE: values.meningite,
          TRI_AC_CAXUMBA: values.caxumba,
          TRI_AC_SARAMPO: values.sarampo,
          TRI_AC_OTITES: values.otites,
          TRI_AC_TRAUMA_FISICO: values.traumaFisico,
          TRI_AC_TRAUMA_ACUSTICO: values.traumaAcustico,
          TRI_AC_SINDROME: values.sindrome,
          TRI_AC_SINDROME_QUAIS: values.sindromeQuais,
          TRI_AC_POS_NATAL_OUTRAS_DOENCAS: values.outrasDoencasPosNatal,
          TRI_AC_POS_OUTRAS_DOENCAS_QUAIS: values.outrasDoencasPosNatalQuais,
          TRI_AC_OBS: values.notes
        };
        // console.log(obj);
        setIsLoading(true);
        setTriagemAudioCrianca(data[0], data[1], obj).then(json => {
          setIsLoading(false);
          if (json.error) {
            danger(`${json.message}`);
          } else {
            success('Dados enviados com sucesso!');
            print();
            navigate('/user');
          }
        });
      }
    }
  }

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!patientInfos.active}>
            <TitleSection>Histórico</TitleSection>
            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Antecedentes familiares e hereditários</label>
                <RadioRow>
                  {responses.antecedentes.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                {values.antecedentes === '000005' && (
                  <>
                    <label className="animeLeft">Se sim, especificar:</label>
                    <FormField
                      className="animeLeft"
                      id="antecedentesNote"
                      name="antecedentesNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Abortos da genitora</label>
                <RadioRow>
                  {responses.abortosGenitora.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.abortosGenitora === '000001' && (
                  <>
                    <label className="animeLeft">Se sim, quantos:</label>
                    <FormField
                      style={{ width: 80, textAlign: 'center' }}
                      className="animeLeft"
                      id="abortosQuantidade"
                      name="abortosQuantidade"
                      type="number"
                      min="1"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Filhos vivos</label>
                <RadioRow>
                  {responses.filhosVivos.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.filhosVivos === '000028' && (
                  <>
                    <label className="animeLeft">Se sim, quantos:</label>
                    <FormField
                      style={{ width: 80, textAlign: 'center' }}
                      className="animeLeft"
                      id="filhosQuantidade"
                      name="filhosQuantidade"
                      type="number"
                      min="1"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Natimorto</label>
                <RadioRow>
                  {responses.natimorto.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.natimorto === '000048' && (
                  <>
                    <label className="animeLeft">Se sim, quantos:</label>
                    <FormField
                      style={{ width: 80, textAlign: 'center' }}
                      className="animeLeft"
                      id="natimortoQuantidade"
                      name="natimortoQuantidade"
                      type="number"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Consanguinidade</label>
                <RadioRow>
                  {responses.consanguinidade.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput" />
            </FormRow>

            <TitleSection>Fatores pré-natal</TitleSection>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Alergias</label>
                <RadioRow>
                  {responses.alergias.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.alergias === '000003' && (
                  <>
                    <label className="animeLeft">Se sim, qual(is)?</label>
                    <FormField
                      className="animeLeft"
                      id="alergiasQuais"
                      name="alergiasQuais"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Gripes</label>
                <RadioRow>
                  {responses.gripes.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.gripes === '000032' && (
                  <>
                    <label className="animeLeft">Se sim, em qual período da gestação?</label>
                    <FormField
                      className="animeLeft"
                      id="gripesNote"
                      name="gripesNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Hemorragias</label>
                <RadioRow>
                  {responses.hemorragias.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.hemorragias === '000036' && (
                  <>
                    <label className="animeLeft">Se sim, em qual período da gestação?</label>
                    <FormField
                      className="animeLeft"
                      id="hemorragiasNote"
                      name="hemorragiasNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Realizou Raio X</label>
                <RadioRow>
                  {responses.raioX.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.raioX === '000056' && (
                  <>
                    <label className="animeLeft">Se sim, em qual período da gestação?</label>
                    <FormField className="animeLeft" id="raioXNote" name="raioXNote" type="text" />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Diabetes gestacional</label>
                <RadioRow>
                  {responses.diabetesGestacional.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.diabetesGestacional === '000022' && (
                  <>
                    <label className="animeLeft">Se sim, em qual período da gestação?</label>
                    <FormField
                      className="animeLeft"
                      id="diabetesGestacionalNote"
                      name="diabetesGestacionalNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">COVID-19</label>
                <RadioRow>
                  {responses.covid19.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.covid19 === '000011' && (
                  <>
                    <label className="animeLeft">Se sim, em qual período da gestação?</label>
                    <FormField
                      className="animeLeft"
                      id="covid19Note"
                      name="covid19Note"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Hipertensão arterial:</label>
                <RadioRow>
                  {responses.hipertensao.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.hipertensao === '000038' && (
                  <>
                    <label className="animeLeft">Se sim, em qual período da gestação?</label>
                    <FormField
                      className="animeLeft"
                      id="hipertensaoNote"
                      name="hipertensaoNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow style={{ marginBottom: 20 }}>
              <div className="fullInput radioGroup">
                <label className="highlight">Tentativa de aborto</label>
                <RadioRow>
                  {responses.aborto.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.aborto === '000068' && (
                  <>
                    <label className="animeLeft">Se sim, tipo:</label>
                    <FormField
                      className="animeLeft"
                      id="tentativaAbortoNote"
                      name="tentativaAbortoNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Rubéola</label>
                <RadioRow>
                  {responses.rubeola.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Infecção urinaria</label>
                <RadioRow>
                  {responses.infeccaoUrinaria.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Toxoplasmose</label>
                <RadioRow>
                  {responses.toxoplasmose.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">HIV</label>
                <RadioRow>
                  {responses.hiv.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              
            <div className="fullInput radioGroup">
                <label className="highlight">Sífilis</label>
                <RadioRow>
                  {responses.sifilis.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput" />
            </FormRow>

            <FormRow style={{ marginTop: 20 }}>
              <div className="fullInput">
                <label className="highlight">Medicação utilizada durante a gestação</label>
                <FormField type="text" name="medicacao" />
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Outras doenças</label>
                <RadioRow>
                  {responses.outrasDoencasPreNatal.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                {values.outrasDoencasPreNatal === '000052' && (
                  <>
                    <label className="animeLeft">Se sim, qual(is):</label>
                    <FormField className="animeLeft" name="outrasDoencasPreNatalNote" type="text" />
                  </>
                )}
              </div>
            </FormRow>

            <TitleSection>Fatores Perinatais</TitleSection>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Tipo de parto</label>
                <RadioRow>
                  {responses.tipoParto.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                <label>Tempo de gestação em semanas</label>
                <FormField
                  type="number"
                  min="0"
                  style={{ textAlign: 'center' }}
                  name="tempoGestacao"
                />
              </div>
              <div className="fullInput">
                <label>Peso ao nascer</label>
                <FormField
                  type="number"
                  min="0"
                  step="0.01"
                  style={{ textAlign: 'center' }}
                  name="pesoNascer"
                />
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput">
                <label className="highlight">APGAR</label>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div style={{ width: '31%' }}>
                    <label>1m:</label>
                    <FormField
                      type="number"
                      style={{ textAlign: 'center' }}
                      min="0"
                      name="APGAR1"
                    />
                  </div>
                  <div style={{ width: '31%' }}>
                    <label>5m:</label>
                    <FormField
                      type="number"
                      style={{ textAlign: 'center' }}
                      min="0"
                      name="APGAR5"
                    />
                  </div>
                  <div style={{ width: '31%' }}>
                    <label>PC:</label>
                    <FormField
                      style={{ textAlign: 'center' }}
                      type="number"
                      min="0"
                      name="APGARPC"
                    />
                  </div>
                </div>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Demorou pra chorar</label>
                <RadioRow>
                  {responses.demorouChorar.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Outros fatores ocorridos</label>
                <RadioRow>
                  {responses.outrosFatores.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Apresentou malformação congênita</label>
                <RadioRow>
                  {responses.malformacao.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                {values.malformacao === '000007' && (
                  <>
                    <label className="animeLeft">Se sim, qual(is)?</label>
                    <FormField
                      className="animeLeft"
                      id="malformacaoNote"
                      name="malformacaoNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Foto terapia</label>
                <RadioRow>
                  {responses.fotoTerapia.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                {values.fotoTerapia === '000030' && (
                  <>
                    <label className="animeLeft">Se sim, quanto tempo?</label>
                    <FormField className="animeLeft" name="fotoTerapiaNote" type="text" />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Incubadora</label>
                <RadioRow>
                  {responses.incubadora.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                {values.incubadora === '000042' && (
                  <>
                    <label className="animeLeft">Se sim, quanto tempo?</label>
                    <FormField
                      className="animeLeft"
                      id="incubadoraNote"
                      name="incubadoraNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Ventilação mecânica</label>
                <RadioRow>
                  {responses.ventilacaoMecanica.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              {values.ventilacaoMecanica === '000080' ? (
                <>
                  <div className="fullInput animeLeft">
                    <label>Se sim, qual o tipo?</label>
                    <FormField
                      className="animeLeft"
                      id="ventilacaoMecanicaTipo"
                      name="ventilacaoMecanicaTipo"
                      type="text"
                    />
                  </div>
                  <div className="fullInput animeLeft">
                    <label>Duração</label>
                    <FormField
                      className="animeLeft"
                      id="ventilacaoMecanicaDuracao"
                      name="ventilacaoMecanicaDuracao"
                      type="text"
                    />
                  </div>
                </>
              ) : (
                <div className="fullInput" />
              )}
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Sonda alimentar</label>
                <RadioRow>
                  {responses.sondaAlimentar.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput">
                {values.sondaAlimentar === '000062' && (
                  <>
                    <label className="animeLeft">Se sim, qual o tempo de permanência?</label>
                    <FormField
                      className="animeLeft"
                      id="sondaAlimentarNote"
                      name="sondaAlimentarNote"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Icterícia</label>
                <RadioRow>
                  {responses.ictericia.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput" />
            </FormRow>

            <TitleSection>Fatores pós-natal</TitleSection>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Bebê molinho</label>
                <RadioRow>
                  {responses.bebeMolinho.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Dificuldade para sugar</label>
                <RadioRow>
                  {responses.dificuldadeSugar.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Engasgo ao engolir</label>
                <RadioRow>
                  {responses.engasgo.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Meningite</label>
                <RadioRow>
                  {responses.meningite.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Caxumba</label>
                <RadioRow>
                  {responses.caxumba.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Sarampo</label>
                <RadioRow>
                  {responses.sarampo.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput radioGroup">
                <label className="highlight">Otites</label>
                <RadioRow>
                  {responses.otites.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Trauma físico</label>
                <RadioRow>
                  {responses.traumaFisico.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput radioGroup">
                <label className="highlight">Trauma acústico</label>
                <RadioRow>
                  {responses.traumaAcustico.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow style={{ marginTop: 20 }}>
              <div className="fullInput radioGroup">
                <label className="highlight">Síndrome</label>
                <RadioRow>
                  {responses.sindrome.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.sindrome === '000066' && (
                  <>
                    <label className="animeLeft">Se sim, qual(is)?</label>
                    <FormField
                      className="animeLeft"
                      id="sindromeQuais"
                      name="sindromeQuais"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow style={{ marginBottom: 20 }}>
              <div className="fullInput radioGroup">
                <label className="highlight">Outras doenças</label>
                <RadioRow>
                  {responses.outrasDoencasPosNatal.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="fullInput animeLeft">
                {values.outrasDoencasPosNatal === '000054' && (
                  <>
                    <label className="animeLeft">Se sim, qual(is)?</label>
                    <FormField
                      className="animeLeft"
                      id="outrasDoencasPosNatalQuais"
                      name="outrasDoencasPosNatalQuais"
                      type="text"
                    />
                  </>
                )}
              </div>
            </FormRow>

            <TitleSection>Informações Complementares</TitleSection>
            <FormRow>
              <div className="fullInput">
                <FormField
                  as="textarea"
                  name="notes"
                  type="text"
                  onChange={handleChange}
                  maxLength={256}
                />
              </div>
            </FormRow>
            {patientInfos.active &&
              <FormRow>
                {!isPrint && (
                  <SubmitButton primary type="submit">
                    {isLoading ? (
                      <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                    ) : (
                      'Enviar Dados'
                    )}
                  </SubmitButton>
                )}
              </FormRow>
            }
          </Form>
        );
      }}
    </Formik>
  );
}
