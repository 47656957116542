import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { Form, Formik } from 'formik';
import { danger, success } from '../../../../../../components/Alerts';
import { useNavigate } from 'react-router';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { setTriagemAudioAdulto } from '../../../../../../utils/api';

export default function FormAudiologiaAdulto({
  initialValues,
  selectedPatient,
  currentUser,
  print,
  responses,
  isPrint,
  patientInfos,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      //Validar
      if (false) {
        danger('Preencha os campos corretamente!');
      } else {
        const data = selectedPatient.split('-');
        const obj = {
          TRI_ANC_PACIENTE: data[0],
          TRI_ANC_ANO: data[1],
          TRI_ANC_USUARIO: currentUser.id,
          TRI_ANC_OBS: values.notes,
          TRI_ANC_MEATOSCOPIA: values.meatoscopia,
          TRI_ANC_DIFICULDADE_EM_OUVIR: values.dificuldadeOuvir,
          TRI_ANC_DIFICULDADE_EM_OUVIR_SM: values.dificuldadeOuvirSim.join('|'),
          TRI_ANC_APRESENTA_ZUMBIDO: values.apresentaZumbido,
          TRI_ANC_APRESENTA_ZUMBIDO_SIM: values.apresentaZumbidoSim.join('|'),
          TRI_ANC_APRESENTA_TONTURAS: values.apresentaTonturas,
          TRI_ANC_DIFICULDADE_AMB_RUIDOSO: values.dificuldadesEntenderFalaAmbientesRuidosos,
          TRI_ANC_APRESENTA_OITITE: values.apresentaOtites,
          TRI_ANC_APRESENTA_OITITE_SIM: values.apresentaOtitesSim.join('|'),
          TRI_ANC_APRESENTA_OITITE_SIM_QD: values.apresentaOtitesNote,
          TRI_ANC_MEDICACOES_OTOTOXICAS: values.medicacoesOtotoxicas,
          TRI_ANC_MEDICACOES_OTOTOXICAS_Q: values.medicacoesOtotoxicasNote,
          TRI_ANC_TRABALHA_AMB_COM_RUIDOS: values.atividadesProfissionaisRuido,
          TRI_ANC_TRABLH_AMB_RUIDO_SIM_QD: values.atividadesProfissionaisRuidosNote,
          TRI_ANC_HIST_FAMILIAR_PERDA_AUD: values.familiarPerdaAuditiva,
          TRI_ANC_TEM_PROBLEMA_SAUDE: values.problemaSaude,
          TRI_ANC_TEM_PROBLEMA_SAUDE_SM_Q: values.problemaSaudeNote,
          TRI_ANC_USA_MEDICACOES: values.usoRegularMedicacoes,
          TRI_ANC_USA_MEDICACOES_SM_Q: values.usoRegularMedicacoesNote
        };
        setIsLoading(true);
        setTriagemAudioAdulto(data[0], data[1], obj).then(json => {
          setIsLoading(false);
          if (json.error) {
            danger(`Erro ao enviar dados: ${json.message}`);
          } else {
            success('Dados enviados com sucesso!');
            print();
            navigate('/user');
          }
        });
      }
    }
  }

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!patientInfos.active}>
            <TitleSection>Avaliação Audiológica - Adolescente/Adulto/Idoso</TitleSection>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label htmlFor="queixas">Queixas:</label>
              </div>
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Meatoscopia</label>
                <RadioRow>
                  {responses.meatoscopia.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput" />
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Sente dificuldades em ouvir?</label>
                <RadioRow>
                  {responses.dificuldadeOuvir.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput animeLeft radioGroup">
                {values.dificuldadeOuvir === '000097' && (
                  <>
                    <label className="highlight animeLeft">Se sim</label>
                    <RadioRow className="animeLeft">
                      {responses.dificuldadeOuvirSim.map(response => (
                        <RadioOrCheck
                          key={response.value}
                          response={response}
                          setFieldValue={setFieldValue}
                        />
                      ))}
                    </RadioRow>
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Apresenta zumbido?</label>
                <RadioRow>
                  {responses.apresentaZumbido.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput radioGroup">
                {values.apresentaZumbido === '000091' && (
                  <>
                    <label className="highlight animeLeft">Se sim</label>
                    <RadioRow className="animeLeft">
                      {responses.apresentaZumbidoSim.map(response => (
                        <RadioOrCheck
                          key={response.value}
                          response={response}
                          setFieldValue={setFieldValue}
                        />
                      ))}
                    </RadioRow>
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Apresenta tonturas?</label>
                <RadioRow>
                  {responses.apresentaTonturas.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput" />
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">
                  Apresenta dificuldades para entender a fala em ambientes ruidosos?
                </label>
                <RadioRow>
                  {responses.dificuldadesEntenderFalaAmbientesRuidosos.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput" />
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Apresentou ou apresenta episódios de otites?</label>
                <RadioRow>
                  {responses.apresentaOtites.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput animeLeft radioGroup">
                {values.apresentaOtites === '000095' && (
                  <>
                    <label className="highlight animeLeft">Se sim</label>
                    <RadioRow className="animeLeft">
                      {responses.apresentaOtitesSim.map(response => (
                        <RadioOrCheck
                          key={response.value}
                          response={response}
                          setFieldValue={setFieldValue}
                        />
                      ))}
                    </RadioRow>
                  </>
                )}
              </div>
              {values.apresentaOtites === '000095' && (
                <div className="mediumInput animeLeft">
                  <label className="highlight">Se sim, quando?</label>
                  <FormField type="text" name="apresentaOtitesNote" />
                </div>
              )}
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Já fez uso de medicações ototóxicas?</label>
                <RadioRow>
                  {responses.medicacoesOtotoxicas.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput">
                {values.medicacoesOtotoxicas === '000107' && (
                  <>
                    <label className="highlight animeLeft">Se sim, quais?</label>
                    <FormField
                      className="animeLeft"
                      type="text"
                      name="medicacoesOtotoxicasNote"
                      maxLength={256}
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">
                  Já exerceu ou exerce atividades profissionais em ambientes com ruído intenso?
                </label>
                <RadioRow>
                  {responses.atividadesProfissionaisRuido.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput">
                {values.atividadesProfissionaisRuido === '000105' && (
                  <>
                    <label className="highlight animeLeft">Se sim, qual período?</label>
                    <FormField
                      className="animeLeft"
                      type="text"
                      name="atividadesProfissionaisRuidosNote"
                      maxLength={256}
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Há historia familiar de perda auditiva?</label>
                <RadioRow>
                  {responses.familiarPerdaAuditiva.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput" />
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Apresenta algum problema de saúde?</label>
                <RadioRow>
                  {responses.problemaSaude.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput">
                {values.problemaSaude === '000083' && (
                  <>
                    <label className="highlight animeLeft">Se sim, quais?</label>
                    <FormField
                      className="animeLeft"
                      type="text"
                      name="problemaSaudeNote"
                      maxLength={256}
                    />
                  </>
                )}
              </div>
            </FormRow>

            <FormRow>
              <div className="mediumInput radioGroup">
                <label className="highlight">Faz uso regular de medicações?</label>
                <RadioRow>
                  {responses.usoRegularMedicacoes.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              <div className="mediumInput">
                {values.usoRegularMedicacoes === '000101' && (
                  <>
                    <label className="highlight animeLeft">Se sim, quais?</label>
                    <FormField
                      className="animeLeft"
                      type="text"
                      name="usoRegularMedicacoesNote"
                      maxLength={256}
                    />
                  </>
                )}
              </div>
            </FormRow>

            <TitleSection>Informações Complementares</TitleSection>
            <FormRow>
              <div className="fullInput">
                <FormField
                  as="textarea"
                  id="notes"
                  name="notes"
                  type="text"
                  onChange={handleChange}
                  maxLength={256}
                />
              </div>
            </FormRow>
            {patientInfos.active &&
              <FormRow>
                {!isPrint && (
                  <SubmitButton primary type="submit">
                    {isLoading ? (
                      <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                    ) : (
                      'Enviar Dados'
                    )}
                  </SubmitButton>
                )}
              </FormRow>
            }
          </Form>
        );
      }}
    </Formik>
  );
}
