import { decode } from 'html-entities';
import React from 'react';
import { FormField, FormRow } from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';

export default function DefaultRadioRow({
  label,
  responses,
  name,
  obs,
  obsLabel,
  ifYes,
  ifYesLabel,
  initialValues,
  isPrint,
  className
}) {
  !responses && alert(name);
  return (
    <FormRow>
      <div className={`fullInput radioGroup ${className ? className : ''}`}>
        <label className="highlight">{label}</label>
        <RadioRow>
          {responses.map(response => {
            return (
              <label>
                {isPrint ? (
                  <input
                    type={response.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                    checked={initialValues[name].includes(response.TAG)}
                  />
                ) : (
                  <FormField
                    type={response.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                    name={name}
                    value={response.TAG}
                  />
                )}
                <span
                  className={`checkMark ${response.SYS_P_E_R_CHECKBOX === 'N' ? 'radio' : ''}`}
                ></span>
                {decode(response.SYS_P_E_R_RESPOSTA)}
              </label>
            );
          })}
        </RadioRow>
      </div>
      {ifYes && (
        <div className="fullInput radioGroup animeShow">
          <label className="highlight">{ifYesLabel}</label>
          <RadioRow>
            {ifYes.map(response => (
              <label>
                {isPrint ? (
                  <input
                    type={response.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                    checked={initialValues[`${name}SeSim`].includes(response.TAG)}
                  />
                ) : (
                  <FormField
                    type={response.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                    name={`${name}SeSim`}
                    value={response.TAG}
                  />
                )}
                <span
                  className={`checkMark ${response.SYS_P_E_R_CHECKBOX === 'N' ? 'radio' : ''}`}
                ></span>
                {response.SYS_P_E_R_RESPOSTA}
              </label>
            ))}
          </RadioRow>
        </div>
      )}
      {obs && (
        <div className="fullInput">
          <label>{obsLabel ? obsLabel : 'Observação'}</label>
          {isPrint ? (
            <input type="text" value={initialValues[`${name}Obs`]} readOnly />
          ) : (
            <FormField type="text" name={`${name}Obs`} />
          )}
        </div>
      )}
    </FormRow>
  );
}
