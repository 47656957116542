import React from 'react';
import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import PrintHeader from '../../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';

const PrintAudiologiaAdulto = React.forwardRef(
  ({ initialValues, selectedPatient, currentUser, responses, frequencies, patients }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])

    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />

        <PrintContainer>
          <TitleSection>Avaliação Audiológica - Adolescente/Adulto/Idoso</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="queixas">Queixas</label>
              <input name="queixas" type="text" />
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Meatoscopia</label>
              <RadioRow>
                {responses.meatoscopia.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Sente dificuldades em ouvir?</label>
              <RadioRow>
                {responses.dificuldadeOuvir.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput animeLeft radioGroup">
              {initialValues.dificuldadeOuvir === 'J002' && (
                <>
                  <label className="highlight animeLeft">Se sim</label>
                  <RadioRow className="animeLeft">
                    {responses.dificuldadeOuvirSim.map(response => (
                      <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                    ))}
                  </RadioRow>
                </>
              )}
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Apresenta zumbido?</label>
              <RadioRow>
                {responses.apresentaZumbido.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Apresenta tonturas?</label>
              <RadioRow>
                {responses.apresentaTonturas.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">
                Apresenta dificuldades para entender a fala em ambientes ruidosos?
              </label>
              <RadioRow>
                {responses.dificuldadesEntenderFalaAmbientesRuidosos.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Apresentou ou apresenta episódios de otites?</label>
              <RadioRow>
                {responses.apresentaOtites.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput animeLeft radioGroup">
              {initialValues.apresentaOtites === 'P002' && (
                <>
                  <label className="highlight animeLeft">Se sim</label>
                  <RadioRow className="animeLeft">
                    {responses.apresentaOtitesSim.map(response => (
                      <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                    ))}
                  </RadioRow>
                </>
              )}
            </div>
            {initialValues.apresentaOtites === 'P002' && (
              <div className="mediumInput animeLeft">
                <label className="highlight">Se sim, quando?</label>
                <input type="text" readOnly value={initialValues.apresentaOtites} />
              </div>
            )}
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Já fez uso de medicações ototóxicas?</label>
              <RadioRow>
                {responses.medicacoesOtotoxicas.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              {initialValues.medicacoesOtotoxicas === 'R002' && (
                <>
                  <label className="highlight animeLeft">Se sim, quais?</label>
                  <input type="text" readOnly value={initialValues.medicacoesOtotoxicasNote} />
                </>
              )}
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">
                Já exerceu ou exerce atividades profissionais em ambientes com ruído intenso?
              </label>
              <RadioRow>
                {responses.atividadesProfissionaisRuido.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              {initialValues.atividadesProfissionaisRuido === 'S002' && (
                <>
                  <label className="highlight animeLeft">Se sim, qual período?</label>
                  <input
                    type="text"
                    readOnly
                    value={initialValues.atividadesProfissionaisRuidosNote}
                  />
                </>
              )}
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Há historia familiar de perda auditiva?</label>
              <RadioRow>
                {responses.familiarPerdaAuditiva.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Apresenta algum problema de saúde?</label>
              <RadioRow>
                {responses.problemaSaude.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              {initialValues.problemaSaude === 'U002' && (
                <>
                  <label className="highlight animeLeft">Se sim, quais?</label>
                  <input
                    className="animeLeft"
                    type="text"
                    readOnly
                    value={initialValues.problemaSaudeNote}
                  />
                </>
              )}
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Faz uso regular de medicações?</label>
              <RadioRow>
                {responses.usoRegularMedicacoes.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              {initialValues.usoRegularMedicacoes === 'V002' && (
                <>
                  <label className="highlight animeLeft">Se sim, quais?</label>
                  <input
                    className="animeLeft"
                    readOnly
                    value={initialValues.usoRegularMedicacoesNote}
                  />
                </>
              )}
            </div>
          </FormRow>

          <TitleSection>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea readOnly value={initialValues.notes} />
            </div>
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintAudiologiaAdulto;
