/* eslint eqeqeq: "off", curly: "error" */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Formik } from 'formik';
import ReactLoading from 'react-loading';

import RadioOrCheck from '../../../../../components/RadioOrCheck';
import { danger, info, success, warning } from '../../../../../components/Alerts';
import { FormField, FormRow, SubmitButton, TitleSection } from '../../../Entries/Patients/styles';
import { AddButton, AttendanceRow, RadioRow, Select, EmptyContent } from '../styles';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { HiUserRemove } from 'react-icons/hi';
import {
  getAreasSaude,
  getRelationship,
  getUsers,
  setProjetoTerapeutico
} from '../../../../../utils/api';
import addZero from '../../../../../utils/addZero';
import { useNavigate } from 'react-router-dom';
import { FamiliarInput, FamiliarType, RemoveButton } from '../../Revaluation/styles';
import { GlobalContext } from '../../../../../GlobalContext';

export default function FormTP({
  initialValues,
  selectedPatient,
  print,
  responses,
  setInitialValues,
  frequencies,
  specialtiesData,
  professionals,
  selectedAttendances,
  setSelectedAttendances,
  relationships,
  selectedFamiliars,
  setSelectedFamiliars,
  selectedProfessionals,
  setSelectedProfessionals
}) {
  const [areas, setAreas] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState('');
  const [selectedFamiliar, setSelectedFamiliar] = useState('');
  const [selectedProfessional, setSelectedProfessional] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const hours = [
    { value: '08', label: '8' },
    { value: '09', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' }
  ];
  const minutes = [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' }
  ];
  const navigate = useNavigate();
  const currentDate = new Date();
  const { actions } = useContext(GlobalContext);

  function handleAddAttendance() {
    const have = selectedAttendances.some(el => el.id == selectedAttendance);
    if (!have) {
      let array = [...selectedAttendances];
      const newAttendance = {
        ...areas.find(el => el.id == selectedAttendance),
        frequency: null,
        day: '2',
        initialHour: '08',
        initialMinute: '00',
        finalHour: '09',
        finalMinute: '00'
      };

      array.push(newAttendance);
      setSelectedAttendances(array);
    }
  }

  function handleRemoveAttendance(index) {
    let array = [...selectedAttendances];
    array.splice(index, 1);
    setSelectedAttendances(array);
  }

  function handleChangeAttendance(attendance, field, newValue) {
    const attendances = [...selectedAttendances];
    const index = attendances.findIndex(element => element === attendance);
    attendances[index][field] = newValue;
    setSelectedAttendances(attendances);
  }

  function handleRemoveFamiliar(familiar) {
    let array = [...selectedFamiliars];
    array.splice(array.indexOf(familiar), 1);
    setSelectedFamiliars(array);
  }

  function handleAddFamiliar() {
    const have = selectedFamiliars.some(el => el.id == selectedFamiliar);
    if (selectedFamiliar && !have) {
      let array = [...selectedFamiliars];
      const newFamiliar = { ...relationships.find(el => el.id == selectedFamiliar), name: '' };
      array.push(newFamiliar);
      setSelectedFamiliars(array);
    }
  }

  function handleChangeFamiliar(familiar, newValue) {
    const familiars = [...selectedFamiliars];
    const index = familiars.findIndex(element => element === familiar);
    familiars[index].name = newValue;
    setSelectedFamiliars(familiars);
  }

  function handleAddProfessional() {
    const have = selectedProfessionals.some(el => el.id == selectedProfessional);
    if (selectedProfessional && !have) {
      let array = [...selectedProfessionals];
      const newProfessional = professionals.find(el => el.id == selectedProfessional);
      array.push(newProfessional);
      setSelectedProfessionals(array);
    }
  }

  function handleRemoveProfessional(professional) {
    let array = [...selectedProfessionals];
    array.splice(array.indexOf(professional), 1);
    setSelectedProfessionals(array);
  }

  function handleSubmit(values) {
    if(!values.previsao) {
      info('É necessário selecionar a data de reavaliação do projeto terapêutico')
    } else if (!isLoading) {
      setIsLoading(true);
      setInitialValues(values);
      const data = selectedPatient.split('-');
      const previsao = values.previsao.split('-');
      const attendances = [];
      const familiars = [];
      const professionals = [];
      selectedAttendances.forEach(attendance => {
        attendances.push({
          PAF_AREA: Number(attendance.id),
          PAF_FREQUENCIA: Number(attendance.frequency),
          PAF_DOW: Number(attendance.day),
          PAF_HORINI: `${attendance.initialHour.padStart(
            2,
            '0'
          )}:${attendance.initialMinute.padStart(2, '0')}:00`,
          PAF_HORFIM: `${attendance.finalHour.padStart(2, '0')}:${attendance.finalMinute.padStart(
            2,
            '0'
          )}:00`
        });
      });
      selectedFamiliars.forEach(familiar => {
        familiars.push({
          P_PT_F_GRAU_PARENTESCO: familiar.id,
          P_PT_F_FAMILIAR: familiar.name
        });
      });
      selectedProfessionals.forEach(professional => {
        professionals.push(professional.id);
      });
      const obj = {
        PPT_PACIENTE: data[0],
        PPT_ANO: data[1],
        PPT_DIAG_ASPECTOS_INDIVIDUAIS: values.aspectosIndividuais.join('|'),
        PPT_DIAG_ATIVID_DE_VIDA_DIARIA: values.atividadesDiarias.join('|'),
        PPT_DIAG_VIDA_FAMILIAR: values.vidaFamiliar.join('|'),
        PPT_DIAG_FATORES_DE_SAUDE: values.fatoresSaude.join('|'),
        PPT_DIAG_FATORES_EMOCIONAIS: values.fatoresEmocionais.join('|'),
        PPT_DIAG_FATORES_SOCIAIS: values.fatoresSociais.join('|'),
        PPT_OBJT_DESENV_ASPCT_INDIVID: values.desenvolverAspectosIndividuais.join('|'),
        PPT_OBJT_ATIVID_DE_VIDA_DIARIA: values.desenvolverVidaDiaria.join('|'),
        PPT_OBJT_ORGANIZACAO_FAMILIAR: values.desenvolverOrganizacaoFamiliar.join('|'),
        PPT_OBJT_SAUDE: values.desenvolverSaude.join('|'),
        PPT_OBJT_SITUACAO_EMOCIONAL: values.desenvolverEmocional.join('|'),
        PPT_OBJT_FATORES_SOCIAIS: values.desenvolverSocial.join('|'),
        PPT_OBS: values.notes,
        PPT_PREVISAO_REAVALIACAO: `${previsao[2]}.${previsao[1]}.${previsao[0]}`,
        PPT_AGENDAMENTOS: attendances,
        PPT_FAMILIARES: familiars,
        PPT_PROFISSIONAIS: professionals
      };

      setProjetoTerapeutico(data[0], data[1], obj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Ocorreu um erro ao enviar os dados: ${json.message}`);
        } else {
          success('Dados enviados com sucesso!');
          print();
          navigate('/user');
        }
      });
    }
  }

  useEffect(() => {
    getAreasSaude().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as áreas: ${json.message}`);
      } else {
        const array = json.data.arrProfissionaisDeSaude.map(area => ({
          id: area.USR_CODIGO,
          label: area.USR_NOME,
          area: {
            id: area.USR_AREA,
            label: area.ARE_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setAreas(array);
        array.length > 0 && setSelectedAttendance(array[0].id);
      }
    });

    setSelectedFamiliar(`${relationships[0].id}`);
    setSelectedProfessional(`${professionals[0].id}`);
  }, []);

  console.log(selectedPatient);

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!actions.some(action => action.id === 301)}>
            <TitleSection>Impacto do diagnóstico</TitleSection>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Aspectos individuais</label>
                <RadioRow>
                  {responses.aspectosIndividuais.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Cotidiano</label>
                <RadioRow>
                  {responses.atividadesDiarias.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Vida familiar</label>
                <RadioRow>
                  {responses.vidaFamiliar.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Fatores de saúde</label>
                <RadioRow>
                  {responses.fatoresSaude.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Fatores emocionais</label>
                <RadioRow>
                  {responses.fatoresEmocionais.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Fatores sociais</label>
                <RadioRow>
                  {responses.fatoresSociais.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Objetivos</TitleSection>

            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolver/aprimorar os aspectos individuais</label>
                <RadioRow>
                  {responses.desenvolverAspectosIndividuais.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">
                  Desenvolver/aprimorar a execução das atividades do cotidiano
                </label>
                <RadioRow>
                  {responses.desenvolverVidaDiaria.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolver/aprimorar a organização familiar</label>
                <RadioRow>
                  {responses.desenvolverOrganizacaoFamiliar.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolver/aprimorar a saúde</label>
                <RadioRow>
                  {responses.desenvolverSaude.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolver/aprimorar a situação emocional</label>
                <RadioRow>
                  {responses.desenvolverEmocional.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolver/aprimorar fatores sociais</label>
                <RadioRow>
                  {responses.desenvolverSocial.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Atendimento(s)</TitleSection>
            <FormRow style={{ marginBottom: 20, flexDirection: 'column' }}>
              <Select>
                <select
                  onChange={e => setSelectedAttendance(e.target.value)}
                  value={selectedAttendance}
                >
                  {areas.map(el => (
                    <option key={el.id} value={el.id}>
                      {el.label}
                    </option>
                  ))}
                </select>
                <AddButton type="button" primary onClick={handleAddAttendance}>
                  <BsPlus size={30} color="#fff" />
                </AddButton>
              </Select>
            </FormRow>
            <FormRow style={{ flexDirection: 'column' }}>
              {selectedAttendances.length > 0 ? (
                selectedAttendances.map((attendance, index) => {
                  return (
                    <AttendanceRow key={index} className="animeUp">
                      <label style={{ minWidth: 170 }}>
                        {attendance.area ? attendance.area.label : ''}: {attendance.label}
                      </label>
                      <Select className="animeLeft">
                        <select
                          defaultValue={attendance.frequency}
                          onChange={e =>
                            handleChangeAttendance(attendance, 'frequency', e.target.value)
                          }
                        >
                          {frequencies.map(frequency => (
                            <option key={frequency.id} value={frequency.id}>
                              {frequency.label}
                            </option>
                          ))}
                        </select>
                      </Select>
                      <Select>
                        <select
                          defaultValue={attendance.day}
                          onChange={e => handleChangeAttendance(attendance, 'day', e.target.value)}
                        >
                          <option value={2}>Segunda</option>
                          <option value={3}>Terça</option>
                          <option value={4}>Quarta</option>
                          <option value={5}>Quinta</option>
                          <option value={6}>Sexta</option>
                          <option value={7}>Sábado</option>
                          <option value={1}>Domingo</option>
                        </select>
                      </Select>
                      <Select className="time animeLeft">
                        <select
                          defaultValue={attendance.initialHour}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'initialHour', e.target.value)
                          }
                        >
                          {hours.map((hour, index) => (
                            <option value={hour.value} key={index}>{hour.label}</option>
                          ))}
                        </select>
                        :
                        <select
                          defaultValue={attendance.initialMinute}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'initialMinute', e.target.value)
                          }
                        >
                          {minutes.map((minute, index) => (
                            <option value={minute.value} key={index}>{minute.label}</option>
                          ))}
                        </select>
                      </Select>
                      até
                      <Select className="time animeLeft">
                        <select
                          defaultValue={attendance.finalHour}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'finalHour', e.target.value)
                          }
                        >
                          {hours.map((hour, index) => (
                            <option value={hour.value} key={index}>{hour.label}</option>
                          ))}
                        </select>
                        :
                        <select
                          defaultValue={attendance.finalMinute}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'finalMinute', e.target.value)
                          }
                        >
                          {minutes.map((minute, index) => (
                            <option value={minute.value} key={index}>{minute.label}</option>
                          ))}
                        </select>
                      </Select>
                      <AddButton
                        type="button"
                        className="animeUp"
                        primary
                        onClick={() => handleRemoveAttendance(index)}
                      >
                        <BsTrash size={20} color="#fff" />
                      </AddButton>
                    </AttendanceRow>
                  );
                })
              ) : (
                <EmptyContent className="animeUp">Nenhum Atendimento Adicionado</EmptyContent>
              )}
            </FormRow>

            <TitleSection>Observação complementar</TitleSection>
            <FormRow>
              <div className="fullInput">
                {/* <label htmlFor="name">Participação Social</label> */}
                <FormField
                  as="textarea"
                  id="name"
                  name="notes"
                  type="text"
                  defaultValue={initialValues.notes}
                  onChange={handleChange}
                  maxLength={256}
                />
              </div>
            </FormRow>

            <TitleSection>Previsão de reavaliação do projeto terapêutico</TitleSection>
            <FormRow>
              <div className="fullInput">
                {/* <label htmlFor="name">Participação Social</label> */}
                <FormField
                  name="previsao"
                  type="date"
                  min={`${currentDate.getFullYear()}-${addZero(
                    currentDate.getMonth() + 1
                  )}-${addZero(currentDate.getDate())}`}
                  max="2999-12-31"
                />
              </div>
            </FormRow>

            <TitleSection>Componentes familiares que participaram</TitleSection>
            <FormRow style={{ marginBottom: 10 }}>
              <Select>
                <select
                  onChange={e => setSelectedFamiliar(e.target.value)}
                  value={selectedFamiliar}
                >
                  {relationships.map(relationship => {
                    return (
                      <option key={relationship.id} value={relationship.id}>
                        {relationship.label}
                      </option>
                    );
                  })}
                </select>
                <AddButton type="button" primary onClick={handleAddFamiliar}>
                  <BsPlus size={30} color="#fff" />
                </AddButton>
              </Select>
            </FormRow>
            <FormRow style={{ flexDirection: 'column' }}>
              {selectedFamiliars.length > 0 ? (
                selectedFamiliars.map(familiar => (
                  <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                    <RemoveButton
                      type="button"
                      className="animeUp"
                      primary
                      onClick={() => handleRemoveFamiliar(familiar)}
                    >
                      <HiUserRemove size={30} color="#C61E24" />
                    </RemoveButton>
                    <FamiliarType key={familiar.id}>{familiar.label}</FamiliarType>
                    <FamiliarInput
                      type="text"
                      value={familiar.name}
                      placeholder="Nome do Familiar"
                      onChange={e => handleChangeFamiliar(familiar, e.target.value)}
                    />
                  </AttendanceRow>
                ))
              ) : (
                <EmptyContent className="animeUp">Nenhum Familiar Adicionado</EmptyContent>
              )}
            </FormRow>
            <TitleSection>Profissionais que participaram</TitleSection>
            <FormRow style={{ marginBottom: 10 }}>
              <Select>
                <select
                  onChange={e => setSelectedProfessional(e.target.value)}
                  value={selectedProfessional}
                >
                  {professionals.map(professional => {
                    return (
                      <option key={professional.id} value={professional.id}>
                        {professional.label}
                      </option>
                    );
                  })}
                </select>
                <AddButton type="button" primary onClick={handleAddProfessional}>
                  <BsPlus size={30} color="#fff" />
                </AddButton>
              </Select>
            </FormRow>
            <FormRow style={{ flexDirection: 'column' }}>
              {selectedProfessionals.length > 0 ? (
                selectedProfessionals.map(professional => (
                  <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                    <RemoveButton
                      type="button"
                      className="animeUp"
                      primary
                      onClick={() => handleRemoveProfessional(professional)}
                    >
                      <HiUserRemove size={30} color="#C61E24" />
                    </RemoveButton>
                    <FamiliarType key={professional.id}>
                      Área {professional.area.label}: {professional.label} {professional.class}
                    </FamiliarType>
                  </AttendanceRow>
                ))
              ) : (
                <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
              )}
            </FormRow>
            <FormRow>
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : (
                  'Enviar Dados'
                )}
              </SubmitButton>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
}
