import React from 'react';
import ReactModal from 'react-modal';

import { GlobalContext } from '../../GlobalContext';

export default function Modal({ children, handleClose }) {
  const { modalIsOpen, setModalIsOpen } = React.useContext(GlobalContext);

  const customStyles = {
    content: {
      top: '50%', left: '50%',
      // width: '70%', height: '70%',
      right: 'auto', bottom: 'auto',
      marginRight: '-50%', transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      borderTopLeftRadius: 10, borderBottomLeftRadius: 10,
      borderWidth: 3, 
      borderColor: '#0A81C7'
    },

    overlay: {
      backgroundColor: '#00000099',
    },
  }

  return (
    <ReactModal isOpen={modalIsOpen} style={customStyles} closeTimeoutMS={300} shouldCloseOnEsc shouldCloseOnOverlayClick 
      onRequestClose={() => {
        handleClose && handleClose();
        setModalIsOpen(false)
      }} ariaHideApp={false}>
      {children}
    </ReactModal>
  )
}