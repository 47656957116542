import React, { useEffect, useState, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';

import Search from '../../../../../components/Search';
import { danger, info } from '../../../../../components/Alerts';
import { getPatientsWithSector } from '../../../../../utils/api';
import handleGetRespostas from '../../../../../utils/handleGetRespostas';
import FormEnfermagem from './Components/FormEnfermagem';
import PrintEnfermagem from './Components/PrintEnfermagem';
import { PatientName } from '../../../../../components/PatientName';
import { GlobalContext } from '../../../../../GlobalContext';

export default function Enfermagem() {
  const { currentUser } = useContext(GlobalContext);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [initialValues, setInitialValues] = useState({
    alergia: '',
    alergiaNotes: '',
    alimentaSozinho: '',
    controlaFezes: '',
    controlaUrina: '',
    dieta: '',
    escaras: '',
    escarasNotes: '',
    estomias: '',
    intercorrencias: [],
    medicamentos: '',
    medicamentosNotes: '',
    necessidades: [],
    notes: '',
    resisteAlimentado: '',
    sistemaGastrointestinal: '',
    sistemaRespiratorio: '',
    tipoAlimentacao: [],
    vesicalIntestinal: ''
  });
  const [responses, setResponses] = useState({});

  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    const array = patient.split('-');
    setPatientInfos(patients.find(patient => patient.id == array[0] && patient.year == array[1]));
    if (Object.keys(responses).length < 15) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      setSelectedPatient(patient);
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    handleGetRespostas([
      {
        label: 'Triagem Enfermagem - Sistema respiratório',
        name: 'sistemaRespiratorio'
      },
      {
        label: 'Triagem Enfermagem - Sistema gastroinstestinal',
        name: 'sistemaGastrointestinal'
      },
      {
        label: 'Triagem Enfermagem - Sistema vesical/intestinal',
        name: 'vesicalIntestinal'
      },
      {
        label: 'Triagem Enfermagem - Estomias',
        name: 'estomias'
      },
      {
        label: 'Triagem Enfermagem - Dieta',
        name: 'dieta'
      },
      {
        label: 'Triagem Enfermagem - Resiste em ser alimentado',
        name: 'resisteAlimentado'
      },
      {
        label: 'Triagem Enfermagem - Necessidades domiciliares',
        name: 'necessidades'
      },
      {
        label: 'Triagem Enfermagem - Presença de escaras',
        name: 'escaras'
      },
      {
        label: 'Triagem Enfermagem - Controla urina',
        name: 'controlaUrina'
      },
      {
        label: 'Triagem Enfermagem - Controla fezes',
        name: 'controlaFezes'
      },
      {
        label: 'Triagem Enfermagem - Tipo de alimentação (consistência)',
        name: 'tipoAlimentacao'
      },
      {
        label: 'Triagem Enfermagem - Alimenta-se sozinho',
        name: 'alimentaSozinho'
      },
      {
        label: 'Triagem Enfermagem - Intercorrências',
        name: 'intercorrencias'
      },
      {
        label: 'Triagem Enfermagem - Alergia/intolerância alimentar e ou medicamentosa',
        name: 'alergia'
      },
      {
        label: 'Triagem Enfermagem - Faz ingestão de medicamentos',
        name: 'medicamentos'
      }
    ]).then(result => setResponses(result));

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Triagem - Enfermagem</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <FormEnfermagem
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            print={print}
            responses={responses}
            setInitialValues={setInitialValues}
            patientInfos={patientInfos}
          />
          <div style={{ display: 'none' }}>
            <PrintEnfermagem
              initialValues={initialValues}
              patients={patients}
              selectedPatient={selectedPatient}
              responses={responses}
              ref={printRef}
            />
          </div>
        </div>
      )}
    </div>
  );
}
