import React, { useContext, useState } from 'react';
import ReactLoading from 'react-loading';
import { triggerBase64Download } from 'common-base64-downloader-react';

import { SearchBox, SearchRow } from '../styles';
import { SubmitButton } from '../../Entries/Patients/styles';
import { danger, info } from '../../../../components/Alerts';
import { GlobalContext } from '../../../../GlobalContext';
import { getGeralFrequencyAmbu } from '../../../../utils/api';

export default function QuantitativeStatementBeneficiaries() {
  const [isLoading, setIsLoading] = useState(false);
  const { actions, currentUser } = useContext(GlobalContext);
  const [entryDate, setEntryDate] = useState('');
  const [exitDate, setExitDate] = useState('');
  const status = [
    {label: 'Apenas Ativos', value: '0'},
    {label: 'Apenas Inativos', value: '1'},
    {label: 'Todos', value: '-1'},
  ]
  const [selectedStatus, setSelectedStatus] = useState('0');

  function handleSubmit(e) {
    e.preventDefault();
    if(!isLoading) {
      if (!entryDate || !exitDate) {
        info('Verifique o formato');
      } else {
        const array1 = entryDate.split('-');
        const array2 = exitDate.split('-');
        const date1 = `${array1[2]}.${array1[1]}.${array1[0]}`;
        const date2 = `${array2[2]}.${array2[1]}.${array2[0]}`;
        danger('Em construção.');
        // setIsLoading(true);
        // getGeralFrequencyAmbu(date1, date2, currentUser.id, selectedStatus).then(json => {
        //   if (json.error) {
        //     danger(`${json.message}`);
        //   } else {
        //     triggerBase64Download(json.data.base64_string, json.data.file_name.replace('.pdf', ''));
        //   }
        //   setIsLoading(false);
        // });
      }
    }
  }

  return (
    <div className="animeUp">
      <h2 className="section">DEMONSTRATIVO QUANTITATIVO DE BENEFICIÁRIOS</h2>
      <form disabled={!actions.some(action => action.id === 1022)}>
        <SearchBox width="50%">
        <SearchRow>
            <div className="mediumInput">
              <label className="main">Período: </label>
              <input type="date" value={entryDate} onChange={e => setEntryDate(e.target.value)} max="2999-12-31"
                onBlur={() => {
                  const array = entryDate.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setEntryDate(newDate);
                    }
                }}
              />
              <label>até</label>
              <input type="date" value={exitDate} onChange={e => setExitDate(e.target.value)} max="2999-12-31"
                onBlur={() => {
                  const array = exitDate.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setExitDate(newDate);
                    }
                }}
              />
            </div>
          </SearchRow>
          <div className="mediumInput">
            <label className="main">Situação: </label>
            <select value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
              {status.map(el => (
                <option key={el.value} value={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
          </div>
            <SubmitButton primary onClick={e => handleSubmit(e)}>
              {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : 'Gerar relatório'
              }
            </SubmitButton>
          {/* {territory === 'Todas' && 
            <FormRow className='animeUp'>
              <div className="fullInput radioGroup" style={{ marginLeft: -5 }}>
                <div className="radioGroupRow">
                  <label>
                    <input type="checkbox" checked={allCities} onChange={() => setAllCities(!allCities)} />
                    <span className="checkMark" style={{ marginLeft: 5 }}></span>Incluir cidades fora da 8º microregião de saúde
                  </label>
                </div>
              </div>
            </FormRow>
          } */}

        </SearchBox>
      </form>
    </div>
  );
}
