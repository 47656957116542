import styled from 'styled-components';
import { FormRow } from '../Entries/Patients/styles';

export const SearchBox = styled.div`
  width: ${props => props.width};
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 6rem;

  > div {
    display: flex;
    align-items: center;

    label {
      font-size: 1.2rem;
      margin-left: 10px;
      margin-right: 10px;

      &.main {
        min-width: 6.5rem;
        /* border-bottom: 2px solid var(--red); */
      }
    }
  }

  button {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SearchRow = styled(FormRow)`
  min-height: 0;
  height: 0;

  > div.mediumInput {
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;
  }

  > div.fullInput {
    display: flex;
    align-items: center;
    margin: 0;
  }
`;
