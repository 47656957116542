import React, { useContext, useEffect, useRef, useState } from 'react';

import { TitleSection } from '../../../Entries/Patients/styles';
import Search from '../../../../../components/Search';
import { danger, info, warning } from '../../../../../components/Alerts';
import { getPatientsWithSector, getTriagemFisiologia } from '../../../../../utils/api';
import handleGetRespostas from '../../../../../utils/handleGetRespostas';
import FormFisio from './Components/FormFisio';
import { useReactToPrint } from 'react-to-print';
import PrintFisio from './Components/PrintFisio';
import { PatientName } from '../../../../../components/PatientName';
import { GlobalContext } from '../../../../../GlobalContext';

export default function Fisioterapia() {
  const { currentUser } = useContext(GlobalContext);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [initialValues, setInitialValues] = useState({
    RTCADireito: '',
    RTCAEsquerdo: '',
    RTCS: '',
    VAS: '',
    alteracaoAuditiva: '',
    alteracaoRespiratoria: '',
    alteracaoVisual: '',
    apgar: '',
    avaliacaoTonus: '',
    babinskDireito: '',
    babinskEsquerdo: '',
    chorouNascer: '',
    complicacoesGravidez: '',
    controleCabeca: '',
    convulsao: '',
    dissociacaoEscapular: '',
    dissociacaoPelvica: '',
    equilibrio: '',
    faseMarcha: '',
    idadeCronologica: '',
    idadeMotoraAtual: '',
    marcha: '',
    marchaNote: '',
    marchaReflexa: '',
    neonatalNotes: '',
    notes: '',
    orteses: '',
    ortesesNote: '',
    ortostatimo: '',
    ponte: '',
    pronoMMII: '',
    pronoMMSS: '',
    pronoSentado: '',
    pronoSupino: '',
    puppy: '',
    reflexoMaoDireito: '',
    reflexoMaoEsquerdo: '',
    reflexoMoro: '',
    reflexoPeDireito: '',
    reflexoPeEsquerdo: '',
    relato: '',
    rolar: '',
    semanasGravidez: 0,
    sentado: '',
    sentadoEmPe: '',
    supinoMMII: '',
    supinoMMSS: '',
    supinoProno: '',
    supinoSentado: '',
    tempoIncubadora: 0,
    testes: '',
    tipoNeonato: '',
    tipoPC: '',
    tipoParto: '',
    tonus: '',
    trofismo: '',
    ventilacaoMecanica: '',
    ventilacaoMecanicaTempo: ''
  });
  const [responses, setResponses] = useState({});

  function handleGetPatientInfos() {
    const data = patient.split('-');
    const patientData = patients.find(patient => patient.id == data[0] && patient.year == data[1]);
    setPatientInfos(patientData);
    !patientData.active && warning('Paciente inativo!');
    if (Object.keys(responses).length >= 41) {
      setSelectedPatient(patient);
      getTriagemFisiologia(data[0], data[1]).then(json => console.log(json));
    } else {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    }
  }

  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    handleGetRespostas([
      { label: 'Triagem Fisio - Tipo de parto', name: 'tipoParto' },
      { label: 'Triagem Fisio - Tipo de neonato', name: 'tipoNeonato' },
      { label: 'Triagem Fisio - Chorou ao nascer', name: 'chorouNascer' },
      {
        label: 'Triagem Fisio - Ventilação mecânica',
        name: 'ventilacaoMecanica'
      },
      { label: 'Triagem Fisio - Convulsão', name: 'convulsao' },
      { label: 'Triagem Fisio - Alteração Visual', name: 'alteracaoVisual' },
      {
        label: 'Triagem Fisio - Alteração Auditiva',
        name: 'alteracaoAuditiva'
      },
      {
        label: 'Triagem Fisio - Alteração respiratória',
        name: 'alteracaoRespiratoria'
      },
      {
        label: 'Triagem Fisio - Tipo de PC',
        name: 'tipoPC'
      },
      {
        label: 'Triagem Fisio - Tônus',
        name: 'tonus'
      },
      {
        label: 'Triagem Fisio - Avaliação do Tônus',
        name: 'avaliacaoTonus'
      },
      {
        label: 'Triagem Fisio - Trofismo',
        name: 'trofismo'
      },
      {
        label: 'Triagem Fisio - Dissociação pélvica',
        name: 'dissociacaoPelvica'
      },
      {
        label: 'Triagem Fisio - Dissociação escapular',
        name: 'dissociacaoEscapular'
      },
      {
        label: 'Triagem Fisio - Controle de cabeça',
        name: 'controleCabeca'
      },
      {
        label: 'Triagem Fisio - Rolar',
        name: 'rolar'
      },
      {
        label: 'Triagem Fisio - Supino-prono',
        name: 'supinoProno'
      },
      {
        label: 'Triagem Fisio - Prono-supino',
        name: 'pronoSupino'
      },
      {
        label: 'Triagem Fisio - Puppy',
        name: 'puppy'
      },
      {
        label: 'Triagem Fisio - Supino/sentado',
        name: 'supinoSentado'
      },
      {
        label: 'Triagem Fisio - Prono/sentado',
        name: 'pronoSentado'
      },
      {
        label: 'Triagem Fisio - Sentado',
        name: 'sentado'
      },
      {
        label: 'Triagem Fisio - Ponte',
        name: 'ponte'
      },
      {
        label: 'Triagem Fisio - Sentado/em pé',
        name: 'sentadoEmPe'
      },
      {
        label: 'Triagem Fisio - Ortostatimo',
        name: 'ortostatimo'
      },
      {
        label: 'Triagem Fisio - Marcha',
        name: 'marcha'
      },
      {
        label: 'Triagem Fisio - Fase da marcha alterada',
        name: 'faseMarcha'
      },
      {
        label: 'Triagem Fisio - Equilíbrio',
        name: 'equilibrio'
      },
      {
        label: 'Triagem Fisio - Órteses',
        name: 'orteses'
      },
      {
        label: 'Triagem Fisio - Reflexo de preensão dos dedos das mãos [Esquerdo]',
        name: 'reflexoMaoEsquerdo'
      },
      {
        label: 'Triagem Fisio - Reflexo de preensão dos dedos das mãos [Direito]',
        name: 'reflexoMaoDireito'
      },
      {
        label: 'Triagem Fisio - Reflexo de preensão dos dedos dos pés [Esquerdo]',
        name: 'reflexoPeEsquerdo'
      },
      {
        label: 'Triagem Fisio - Reflexo de preensão dos dedos dos pés [Direito]',
        name: 'reflexoPeDireito'
      },
      {
        label: 'Triagem Fisio - Marcha reflexa',
        name: 'marchaReflexa'
      },
      {
        label: 'Triagem Fisio - Reflexo de moro',
        name: 'reflexoMoro'
      },
      {
        label: 'Triagem Fisio - Reflexo cutâneo plantar (babinsk) [Esquerdo]',
        name: 'babinskEsquerdo'
      },
      {
        label: 'Triagem Fisio - Reflexo cutâneo plantar (babinsk) [Direito]',
        name: 'babinskDireito'
      },
      {
        label: 'Triagem Fisio - Reação de proteção das VAS',
        name: 'VAS'
      },
      {
        label: 'Triagem Fisio - RTCA [Esquerdo]',
        name: 'RTCAEsquerdo'
      },
      {
        label: 'Triagem Fisio - RTCA [Direito]',
        name: 'RTCADireito'
      },
      {
        label: 'Triagem Fisio - RTCS',
        name: 'RTCS'
      }
    ]).then(result => setResponses(result));

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Triagem - Fisioterapia</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <TitleSection>História da Gravidez</TitleSection>
          <FormFisio
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            print={print}
            responses={responses}
            setInitialValues={setInitialValues}
            patientInfos={patientInfos}
          />
          <div style={{ display: 'none' }}>
            <PrintFisio
              initialValues={initialValues}
              patients={patients}
              selectedPatient={selectedPatient}
              responses={responses}
              ref={printRef}
            />
          </div>
        </div>
      )}
    </div>
  );
}
