import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { danger, success } from '../../../../../../components/Alerts';
import { useNavigate } from 'react-router';
import ReactLoading from 'react-loading';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { RadioRow, Select } from '../../../Therapeutic Project/styles';
import { setTriagemPsicologia } from '../../../../../../utils/api';

export default function FormMusic({
  initialValues,
  selectedPatient,
  currentUser,
  print,
  responses,
  patientInfos,
  frequencies,
  isPrint,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      if (
        false
        // !values.alteracaoSono ||
        // (values.pesadelosRecorrentes === '000411' &&
        //   values.pesadelosPeriodicidade === 'Frequência') ||
        // (values.pesadelosRecorrentes === '000411' && !values.pesadelosPeriodicidade)
      ) {
        danger('Preencha os campos corretamente!');
      } else {
        setIsLoading(true);
        const data = selectedPatient.split('-');
        
        // AGUARDANDO BACK-END

        // setTriagemPsicologia(data[0], data[1], objPsi).then(json => {
        //   setIsLoading(false);
        //   if (json.error) {
        //     danger(`${json.message}`);
        //   } else {
        //     success('Dados enviados com sucesso!');
        //     print();
        //     navigate('/user');
        //   }
        // });
      }
    }
  }

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!patientInfos.active}>
            <TitleSection>Musicalização</TitleSection>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Expressão Corporal</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Criatividade</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Coordenação</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Relacionamento Interpessoal</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Memória Visual</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Autonomia</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Memória Auditiva</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Disciplina</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Concentração</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Linguagem Oral</label>
                <RadioRow>
                  {/* {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))} */}
                </RadioRow>
              </div>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
}
