import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { danger } from '../../../../components/Alerts';
import { Table } from '../../../../components/Table';
import { SearchWrapper, SearchInput, SearchButton, OptionsFilter } from '../../../../components/Search/styles';

import { Button } from '../../../../styles/Button';
import SearchIcon from '../../../../assets/searchIcon.png';
import { getPatientsWithSector, getSpecialties } from '../../../../utils/api';
import { HeaderEntries, Title, FilterContainer } from '../style';
import { GlobalContext } from '../../../../GlobalContext';

export function Patients() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [filterType, setFilterType] = useState('name');
  const { currentUser } = useContext(GlobalContext);
  // console.log(currentUser);
  function handleGetPatients() {
    getSpecialties().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar os setores: ${json.message}`);
      } else {
        getPatientsWithSector(currentUser.id).then(json => {
          if (json.error) {
            danger(`${json.message}`);
          } else {
            let arr = [];
            json.data.arrPacientes.forEach(patient => {
              const patientObj = {
                id: patient.PAC_CODIGO,
                name: decodeURIComponent(escape(patient.PAC_NOME)),
                active: patient.PAC_ALTA_INSTITUCIONAL === 0,
                year: patient.PAC_ANO,
                sector: patient.PAC_SETCAD,
                status: patient.PAC_ALTA_INSTITUCIONAL,
                cpf: patient.PAC_NUMR_CPF,
              };
              arr.push(patientObj);
            });
            setPatients(arr);
          }
        });
      }
    });
  }

  function handleFilter() {
    let filterArr;
    if (filter.startsWith('%') && filter.endsWith('%')) {
      const filterContent = filter.slice(1, -1);
      filterArr = patients.filter(el => {
        return filterType === 'name' 
          ? el.name.toLowerCase().includes(filterContent.toLowerCase())
          : el.cpf.toLowerCase().startsWith(filterContent)
      });
    } else {
      filterArr = patients.filter(el => {
        return filterType === 'name' 
          ? el.name.toLowerCase().startsWith(filter.toLowerCase().replace('%', ''))
          : el.cpf.toLowerCase().startsWith(filter.toLowerCase().replace('%', ''))
      });
    }
    setFilteredPatients(filterArr);
  }

  useEffect(() => {
    handleGetPatients();
  }, []);

  return (
    <div className="animeUp">
      <HeaderEntries>
        <Title>Pacientes Cadastrados {currentUser.sector.label}</Title>
        <Button
          style={{ marginLeft: 'auto' }}
          border
          onClick={() => {
            navigate('editMedicalReport');
          }}
        >
          Editar Prontuário
        </Button>
      </HeaderEntries>

      <FilterContainer>
      <OptionsFilter>
          <label onClick={() => setFilterType('name')}>
            <input name="filterType" type="radio" checked={filterType === 'name'} />
            <span className="checkMark radio" />
            Nome
          </label>
          <label onClick={() => setFilterType('cpf')}>
            <input name="filterType" type="radio" checked={filterType === 'cpf'} />
            <span className="checkMark radio" />
            CPF
          </label>
        </OptionsFilter>
        <SearchWrapper>
          <SearchInput
            autoFocus
            value={filter}
            onKeyDown={e => {
              e.key === 'Enter' && handleFilter();
            }}
            onChange={e => {
              setFilter(e.target.value);
            }}
            placeholder="Buscar Paciente"
          />
        </SearchWrapper>
        
        <SearchButton onClick={handleFilter}>
          <img src={SearchIcon} alt="Filtrar Pacientes" />
        </SearchButton>
      </FilterContainer>

      <Table
        content={!filter ? patients : filteredPatients.length > 0 ? filteredPatients : patients}
        typeTable="patients"
        headers={{
          // id: 'Prontuário',
          description: 'Nome',
          status: 'Status'
        }}
        buttons={{ edit: true, delete: false }}
      />
    </div>
  );
}
