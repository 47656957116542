import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { danger } from '../../../../components/Alerts';
import { Table } from '../../../../components/Table';
import { GlobalContext } from '../../../../GlobalContext';

import { Button } from '../../../../styles/Button';
import { getAreas } from '../../../../utils/api';
import { HeaderEntries, Title } from '../style';

export function Areas() {
  const navigate = useNavigate();
  const [areas, setAreas] = useState([]);

  const { load, loadSpecialties, specialtiesData, checkUser, actions } =
    React.useContext(GlobalContext);

  // React.useEffect(() => {
  //   async function loadSpecialtiesData() {
  //     await loadSpecialties();
  //   }

  //   loadSpecialtiesData();
  // }, [loadSpecialties]);

  useEffect(() => {
    getAreas().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as áreas: ${json.message}`);
      } else {
        const array = json.data.arrAreas.map(area => ({
          id: area.ARE_CODIGO,
          label: area.ARE_DESCRITIVO,
          sector: {
            id: area.ARE_SETOR,
            label: area.STR_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setAreas(array);
      }
    });
  }, []);

  !localStorage.getItem('login') && checkUser();

  return (
    <div className="animeUp">
      <HeaderEntries>
        <Title>Áreas Cadastradas</Title>
        {actions.some(action => action.id === 42) && (
          <Button disabled={load} primary onClick={() => navigate('register')}>
            Cadastrar Área
          </Button>
        )}
      </HeaderEntries>
      <Table
        content={areas}
        headers={{ description: 'Área', Sector: 'Setor' }}
        buttons={{ edit: actions.some(action => action.id === 42), delete: false }}
        typeTable="areas"
      />
    </div>
  );
}
