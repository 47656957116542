import React from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { RemoveButton } from '../../../Revaluation/styles';
import { Empty, Item, Label } from './styles';

export default function Selected({ selected, handleRemove, placeholder, isCID, disabled}) {
  return (
    <div className={isCID ? 'fullInput' : "mediumInput"}>
      {selected.length > 0 ? (
        selected.map(el => {
          if(el) {
            return (
              <Item key={el.id} className="animeUp">
                {!disabled &&
                  <RemoveButton
                    type="button"
                    className="animeUp"
                    primary
                    noMarginLeft
                    onClick={() => handleRemove(el)}
                  >
                    <AiOutlineMinus size={30} color="#C61E24" />
                  </RemoveButton>
                }
                {isCID 
                  ? <Label isCID>{el.id}  {el.name}</Label>
                  : <Label>{el.label}</Label>
                }
              </Item>
            );
          }
        })
      ) : (
        <Empty className="animeUp">{placeholder}</Empty>
      )}
    </div>
  );
}
