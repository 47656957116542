import React from 'react';
import { ImExit } from 'react-icons/im';
import { useNavigate } from 'react-router';
import { MenuMobileButton } from '../../styles/Button';
import { DropDownMenu } from '../DropDownMenu';
import { ExitButton } from '../Header/style';
import { Ul } from './style';

const RightNav = ({ open }) => {
  const navigate = useNavigate();

  return (
    <Ul open={open}>
      <li>
        <DropDownMenu name="cadastros" />
      </li>
      <li>
        <DropDownMenu name="movimentações" />
      </li>
      <li>
        <DropDownMenu name="relatórios" />
      </li>
      <li>
        <DropDownMenu name="extras" />
      </li>
      <li>
        <ExitButton onClick={() => navigate('/')}>
          Sair
          <ImExit style={{ marginLeft: 10, marginBottom: -2 }} size={15} />
        </ExitButton>
      </li>
    </Ul>
  );
};

export default RightNav;
