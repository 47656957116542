import React, { useEffect, useState } from 'react';
import { Table } from '../../../../components/Table';

import { getProfiles } from '../../../../utils/api';
import { HeaderEntries, Title } from '../style';

export function AccessProfiles() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getProfiles().then(json => {
      let arr = [];
      json.data.arrPerfil.forEach(profile => {
        arr.push({ id: profile[0], label: profile[1] });
      });
      setData(arr);
    });
  }, []);

  return (
    <div className="animeUp">
      <HeaderEntries>
        <Title>Perfis de Acesso Cadastrados</Title>
      </HeaderEntries>
      <Table
        content={data}
        headers={{
          description: 'Descrição'
        }}
        buttons={{ edit: true, delete: false }}
        typeTable="access-profiles"
      />
    </div>
  );
}
