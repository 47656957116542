import { Route, Routes } from 'react-router-dom';

import { Areas } from './Areas';
import { AreasRegister } from './AreasRegister';

export function MenuAreas() {
  return (
    <Routes>
      <Route path="/" element={<Areas />} />
      <Route path="register" element={<AreasRegister />} />
    </Routes>
  );
}
