import React, { useContext } from 'react';
import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';

import PrintHeader from '../../../../../../components/PrintHeader';
import { GlobalContext } from '../../../../../../GlobalContext';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import DefaultRadioRow from './DefaultRadioRow';
import { RadioRow } from '../../../Therapeutic Project/styles';

const PrintFono = React.forwardRef(
  ({ initialValues, selectedPatient, responses, patients }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    const { currentUser } = useContext(GlobalContext);
    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <h2 className="section" style={{ marginTop: 0 }}>
            Fonoaudiologia
          </h2>
          <TitleSection>Tipo Facial</TitleSection>
          <DefaultRadioRow
            label="Tipo"
            responses={responses['Tipo Facial']['Tipo']}
            name="tipoFacialTipo"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Perfil"
            responses={responses['Tipo Facial']['Perfil']}
            name="tipoFacialPerfil"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Ângulo Nasolabial"
            responses={responses['Tipo Facial']['Ângulo nasolabial']}
            name="tipoFacialAngulo"
            initialValues={initialValues}
            isPrint
          />

          <TitleSection>Bochechas</TitleSection>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Mucosa</label>
              <RadioRow columns={2}>
                {responses.Bochechas.Mucosa.map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues['bochechaMucosa'].includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Tônus</label>
              <RadioRow columns={2}>
                {responses.Bochechas.Tonus.map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues['bochechaTonus'].includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label>Mobilidade</label>
              <input type="text" value={initialValues.bochechasMobilidade} readOnly />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label>Observação</label>
              <input type="text" value={initialValues.bochechasObs} readOnly />
            </div>
          </FormRow>

          <TitleSection style={{ marginTop: 20, paddingTop: 10 }}>Lábios</TitleSection>
          <DefaultRadioRow
            label="Posição habitual"
            responses={responses['Labios']['Posicao Habitual']}
            name="labiosPosicao"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Mucosa"
            responses={responses['Labios']['Mucosa']}
            name="labiosMucosa"
            initialValues={initialValues}
            isPrint
          />
          <FormRow>
            <div className="fullInput">
              <label>Mobilidade</label>
              <input type="text" value={initialValues.labiosMobilidade} readOnly />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label>Observação</label>
              <input type="text" value={initialValues.labiosObs} readOnly />
            </div>
          </FormRow>

          <TitleSection>Língua</TitleSection>
          <DefaultRadioRow
            label="Sulco Longitudinal"
            responses={responses['Lingua']['Sulco longitudinal']}
            name="linguaSulco"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Posição habitual"
            responses={responses['Lingua']['Posicao habitual']}
            name="linguaPosicao"
            initialValues={initialValues}
            isPrint
          />

          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Simetria</label>
              <RadioRow>
                {responses.Lingua.Simetria.map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues['linguaSimetria'].includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {initialValues.linguaSimetria === '000546' && (
              <div className="fullInput animeLeft">
                <label>Se não, descrever</label>
                <input type="text" readOnly value={initialValues.linguaSimetriaObs} />
              </div>
            )}
          </FormRow>
          <DefaultRadioRow
            label="Largura"
            responses={responses['Lingua']['Largura']}
            name="linguaLargura"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Tremor"
            responses={responses['Lingua']['Tremor']}
            name="linguaTremor"
            initialValues={initialValues}
            isPrint
          />
          <FormRow style={{ marginBottom: 20, paddingBottom: 10 }}>
            <div className="fullInput radioGroup">
              <label className="highlight">Mucosa</label>
              <RadioRow>
                {responses.Lingua.Mucosa.map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues['linguaMucosa'].includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {initialValues.linguaMucosa === '000557' && (
              <div className="fullInput animeLeft">
                <label>Local</label>
                <input type="text" readOnly value={initialValues.linguaMucosaMarcadaLocal} />
              </div>
            )}
            {initialValues.linguaMucosa === '000558' && (
              <div className="fullInput animeLeft">
                <label>Local</label>
                <input type="text" value={initialValues.linguaMucosaFeridaLocal} />
              </div>
            )}
          </FormRow>
          <DefaultRadioRow
            label="Frênulo"
            responses={responses['Lingua']['Frenulo']}
            name="linguaFrenulo"
            initialValues={initialValues}
            isPrint
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label>Outras características</label>
              <input type="text" value={initialValues.linguaObs} readOnly />
            </div>
          </FormRow>

          <TitleSection>Mandíbula</TitleSection>
          <DefaultRadioRow
            label="Postura"
            responses={responses['Mandibula']['Postura']}
            name="mandibulaPostura"
            initialValues={initialValues}
            isPrint
          />

          <DefaultRadioRow
            label="Abertura da boca"
            responses={responses['Mandibula']['Abertura da boca']}
            name="mandibulaAbertura"
            initialValues={initialValues}
            isPrint
          />

          {initialValues.mandibulaAbertura === '000575' && (
            <DefaultRadioRow
              label="Caso desvio na abertura"
              responses={responses['Mandibula']['Abertura da boca (com desvio)']}
              name="mandibulaAberturaDesvio"
              initialValues={initialValues}
              isPrint
            />
          )}
          <DefaultRadioRow
            label="Fechamento da boca"
            responses={responses['Mandibula']['Fechamento da boca']}
            name="mandibulaFechamento"
            initialValues={initialValues}
            isPrint
          />
          {initialValues.mandibulaFechamento === '000578' && (
            <DefaultRadioRow
              label="Caso desvio no fechamento da boca"
              responses={responses['Mandibula']['Fechamento da boca (com desvio)']}
              name="mandibulaFechamentoDesvio"
              initialValues={initialValues}
              isPrint
            />
          )}
          <DefaultRadioRow
            label="Lateralidade à direita"
            responses={responses['Mandibula']['Lateralidade a direita']}
            name="mandibulaLateralidadeDireita"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Lateralidade à esquerda"
            responses={responses['Mandibula']['Lateralidade a esquerda']}
            name="mandibulaLateralidadeEsquerda"
            initialValues={initialValues}
            isPrint
          />

          <TitleSection>Dentes</TitleSection>
          <DefaultRadioRow
            label="Dentadura"
            responses={responses['Dentes']['Dentadura']}
            name="dentesDentadura"
            initialValues={initialValues}
            isPrint
          />
          <FormRow>
            <div className="fullInput">
              <label className="highlight">Nº de dentes superior direito</label>
              <input type="number" value={initialValues.dentesSuperiorDireito} readOnly />
            </div>
            <div className="fullInput">
              <label className="highlight">Nº de dentes superior esquerdo</label>
              <input type="number" value={initialValues.dentesSuperiorEsquerdo} readyOnly />
            </div>
            <div className="fullInput">
              <label className="highlight">Nº de dentes inferior direito</label>
              <input type="number" value={initialValues.dentesInferiorDireito} readOnly />
            </div>
            <div className="fullInput">
              <label className="highlight">Nº de dentes inferior esquerdo</label>
              <input type="number" value={initialValues.dentesInferiorEsquerdo} readOnly />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Falha dentária</label>
              <RadioRow>
                {responses.Dentes['Falha dentária'].map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues['dentesFalha'].includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {initialValues.dentesFalha === '000595' && (
              <div className="fullInput animeLeft">
                <label>Se presente, especificar elementos</label>
                <input type="text" value={initialValues.dentesFalhaObs} readyOnly />
              </div>
            )}
          </FormRow>
          <DefaultRadioRow
            label="Conservação dentária"
            responses={responses['Dentes']['Conservação dentária']}
            name="dentesConservacaoDentaria"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Conservação gengival"
            responses={responses['Dentes']['Conservação gengival']}
            name="dentesConservacaoGengival"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Linha média"
            responses={responses['Dentes']['Linha média']}
            name="dentesLinha"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Classificação de Angle Direito"
            responses={responses['Dentes']['Classificação de Angle D']}
            name="dentesClassificacaoDireito"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Classificação de Angle Esquerdo"
            responses={responses['Dentes']['Classificação de Angle E']}
            name="dentesClassificacaoEsquerdo"
            initialValues={initialValues}
            isPrint
          />

          <TitleSection>Palato Duro</TitleSection>
          <DefaultRadioRow
            label="Profundidade"
            responses={responses['Palato']['Duro - Profundidade']}
            name="palatoDuroProfundidade"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Largura"
            responses={responses['Palato']['Duro - Largura']}
            name="palatoDuroLargura"
            initialValues={initialValues}
            isPrint
          />

          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Fissura</label>
              <RadioRow>
                {responses.Palato['Duro - Fissura'].map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues['palatoDuroFissura'].includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {initialValues.palatoDuroFissura === '000618' && (
              <>
                <div className="fullInput animeLeft">
                  <label>Tipo</label>
                  <input type="text" value={initialValues.palatoDuroFissuraTipo} readOnly />
                </div>
                <div className="fullInput animeLeft">
                  <label>Localização</label>
                  <input type="text" value={initialValues.palatoDuroFissuraLocalizacao} readOnly />
                </div>
              </>
            )}
          </FormRow>

          <TitleSection>Palato Mole</TitleSection>
          <DefaultRadioRow
            label="Simetria"
            responses={responses['Palato']['Mole - Simetria']}
            name="palatoMoleSimetria"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Extensão"
            responses={responses['Palato']['Mole - Extensão']}
            name="palatoMoleExtensao"
            initialValues={initialValues}
            isPrint
          />
          <DefaultRadioRow
            label="Úvula"
            responses={responses['Palato']['Mole - Úvula']}
            name="palatoMoleUvula"
            initialValues={initialValues}
            isPrint
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Fissura</label>
              <RadioRow>
                {responses.Palato['Mole - Fissura'].map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues.palatoMoleFissura.includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {initialValues.palatoMoleFissura === '000629' && (
              <>
                <div className="fullInput animeLeft">
                  <label>Tipo</label>
                  <input type="text" value={initialValues.palatoMoleFissuraTipo} readyOnly />
                </div>
                <div className="fullInput animeLeft">
                  <label>Localização</label>
                  <input type="text" value={initialValues.palatoMoleFissuraLocalizacao} readyOnly />
                </div>
              </>
            )}
          </FormRow>
          <TitleSection>Véu palatino</TitleSection>
          <DefaultRadioRow
            label='Falar "a" repetidamente'
            responses={responses['Véu Palatino']['falar {a} repetidamente']}
            name="veuA"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Eliciar o reflexo nauseante"
            responses={responses['Véu Palatino']['Eliciar o reflexo nauseante']}
            name="veuEliciar"
            initialValues={initialValues}
            isPrint
            obs
          />
          <TitleSection>Respiração</TitleSection>
          <DefaultRadioRow
            label="Tipo"
            responses={responses['Respiração']['Tipo']}
            name="respiracaoTipo"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Modo"
            responses={responses['Respiração']['Modo']}
            name="respiracaoModo"
            initialValues={initialValues}
            isPrint
            obs
          />

          <TitleSection>Deglutição</TitleSection>
          <FormRow>
            <div className="fullInput">
              <label className="highlight">Tipo de via alimentar</label>
              <input type="text" value={initialValues.degluticaoVia} readOnly />
            </div>
          </FormRow>
          <DefaultRadioRow
            label="Difuculdade"
            responses={responses['Deglutição']['Dificuldade']}
            name="degluticaoDificuldade"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Ruído"
            responses={responses['Deglutição']['Ruído']}
            name="degluticaoRuido"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Engasgos"
            responses={responses['Deglutição']['Engasgos']}
            name="degluticaoEngasgos"
            initialValues={initialValues}
            isPrint
            obs
            ifYesLabel="Se presente"
            ifYes={
              initialValues.degluticaoEngasgos === '000648'
                ? responses['Deglutição']['Engasgos (se presente)']
                : false
            }
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <DefaultRadioRow
            label="Odinofagia"
            responses={responses['Deglutição']['Odinofagia (dor ao deglutir)']}
            name="degluticaoOdinofagia"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Refluxo nasal"
            responses={responses['Deglutição']['Refluxo nasal']}
            name="degluticaoRefluxo"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Escape anterior"
            responses={responses['Deglutição']['Escape anterior']}
            name="degluticaoEscape"
            initialValues={initialValues}
            isPrint
            obs
          />
          <DefaultRadioRow
            label="Pigarro"
            responses={responses['Deglutição']['Pigarro']}
            name="degluticaoPigarro"
            initialValues={initialValues}
            isPrint
            obs
            ifYesLabel="Se sim"
            ifYes={
              initialValues.degluticaoPigarro === '000664'
                ? responses['Deglutição']['Pigarro (se sim)']
                : false
            }
          />
          <DefaultRadioRow
            label="Tosse"
            responses={responses['Deglutição']['Tosse']}
            name="degluticaoTosse"
            initialValues={initialValues}
            isPrint
            obs
            ifYesLabel="Se sim"
            ifYes={
              initialValues.degluticaoTosse === '000669'
                ? responses['Deglutição']['Tosse (se sim)']
                : false
            }
          />
          <DefaultRadioRow
            label="Resíduos após a deglutição"
            responses={responses['Deglutição']['Resíduos após a deglutição']}
            name="degluticaoResiduos"
            initialValues={initialValues}
            isPrint
            obs
          />

          <TitleSection>Sucção</TitleSection>
          <FormRow>
            <div className="fullInput radioGroup">
              <label style={{ height: 25 }} />
              <RadioRow>
                {responses['Sucção']['Sucção'].map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues.succaoSuccao.includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>

            {initialValues.succaoSuccao === '000676' && (
              <div className="fullInput animeLeft">
                <label>Se ausente, descrever</label>
                <input type="text" value={initialValues.succaoSuccaoObs} readOnly />
              </div>
            )}
          </FormRow>
          <TitleSection style={{ paddingTop: 20 }}>Mastigação</TitleSection>
          <FormRow>
            <div className="fullInput radioGroup">
              <label style={{ height: 25 }} />
              <RadioRow>
                {responses['Mastigação']['Mastigação'].map(element => (
                  <label key={element.TAG}>
                    <input
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      checked={initialValues.mastigacaoMastigacao.includes(element.TAG)}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          {initialValues.mastigacaoMastigacao === '000677' && (
            <div className="animeUp">
              <DefaultRadioRow
                label="Lado"
                responses={responses['Mastigação']['Lado']}
                name="mastigacaoLado"
                initialValues={initialValues}
                isPrint
              />
              <DefaultRadioRow
                label="Lábios"
                responses={responses['Mastigação']['Lábios']}
                name="mastigacaoLabios"
                initialValues={initialValues}
                isPrint
              />
              <DefaultRadioRow
                label="Ruído"
                responses={responses['Mastigação']['Ruído']}
                name="mastigacaoRuido"
                initialValues={initialValues}
                isPrint
              />
              <DefaultRadioRow
                label="Dor ou desconforto durante a mastigação"
                responses={responses['Mastigação']['Dor ou desconforto durante a mastigação']}
                name="mastigacaoDor"
                initialValues={initialValues}
                isPrint
              />
              {initialValues.mastigacaoDor === '000685' && (
                <DefaultRadioRow
                  label="Caso às vezes"
                  responses={
                    responses['Mastigação']['Dor ou desconforto durante a mastigação (às vezes)']
                  }
                  name="mastigacaoDorAsVezes"
                  initialValues={initialValues}
                  isPrint
                />
              )}
              {initialValues.mastigacaoDor === '000688' && (
                <DefaultRadioRow
                  label="Caso sim"
                  responses={
                    responses['Mastigação']['Dor ou desconforto durante a mastigação (sim)']
                  }
                  name="mastigacaoDorSim"
                  initialValues={initialValues}
                  isPrint
                />
              )}
              <DefaultRadioRow
                label="Ruído articular"
                responses={responses['Mastigação']['Ruído articular']}
                name="mastigacaoRuidoArticular"
                initialValues={initialValues}
                isPrint
              />
              {initialValues.mastigacaoRuidoArticular === '000692' && (
                <DefaultRadioRow
                  className="animeUp"
                  label="Caso às vezes"
                  responses={responses['Mastigação']['Ruído articular - às vezes']}
                  name="mastigacaoRuidoArticularAsVezes"
                  initialValues={initialValues}
                  isPrint
                />
              )}
              {initialValues.mastigacaoRuidoArticular === '000693' && (
                <DefaultRadioRow
                  className="animeUp"
                  label="Caso às sim"
                  responses={responses['Mastigação']['Ruído articular - sim']}
                  name="mastigacaoRuidoArticularSim"
                  initialValues={initialValues}
                  isPrint
                />
              )}
              <DefaultRadioRow
                label="Dificuldade mastigatória"
                responses={responses['Mastigação']['Dificuldade mastigatória']}
                name="mastigacaoDificuldade"
                obsLabel="Qual"
                initialValues={initialValues}
                isPrint
                obs
              />
              <DefaultRadioRow
                label="Escape de alimentos durante a mastigação"
                responses={responses['Mastigação']['Escape de alimentos durante a mastigação']}
                name="mastigacaoEscape"
                initialValues={initialValues}
                isPrint
              />
            </div>
          )}
          <TitleSection>Comunicação</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea
                value={initialValues.notes}
                readOnly
                placeholder="Descrever aspectos do desenvolvimento da linguagem oral, expressiva e fala"
              />
            </div>
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintFono;
