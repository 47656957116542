import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GlobalStorage } from './GlobalContext';

import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';

export default function App() {
  return (
    <BrowserRouter basename="/">
      <GlobalStorage>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="user/*" element={<Dashboard />} />
        </Routes>
      </GlobalStorage>
    </BrowserRouter>
  );
}
