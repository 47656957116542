import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Container } from './style';
import { Header } from '../../components/Header';

import { MenuEntries } from '../Content/Entries';
import { MainPage } from './MainPage';
import { MenuMovements } from '../Content/Movements';
import { MenuExtras } from '../Content/Extras';
import { MenuReports } from '../Content/Reports';

export function Dashboard() {
  return (
    <Container className="animeShow">
      <Header />
      
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="entries/*" element={<MenuEntries />} />
        <Route path="movements/*" element={<MenuMovements />} />
        <Route path="reports/*" element={<MenuReports />} />
        <Route path="extras/*" element={<MenuExtras />} />
      </Routes>
    </Container>
  );
}
