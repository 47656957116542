import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > label {
    margin-top: 5px;
    font-size: 18px;
    border-bottom: 2px solid var(--red);

    &.left {
      margin-right: 10px;
    }

    &.right {
      margin-left: 10px;
    }
  }
`;
