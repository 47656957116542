import React from "react";
import { GlobalContext } from "../../GlobalContext";


export function MainPage(){

  const { checkUser } = React.useContext(GlobalContext)
  
  !localStorage.getItem('login') && checkUser()

  return <h1>{''}</h1>;
} 