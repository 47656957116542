import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { danger, success } from '../../../../../../components/Alerts';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { GlobalContext } from '../../../../../../GlobalContext';
import { setTriagemPedagogia } from '../../../../../../utils/api';

export default function FormPedagogia({
  initialValues,
  selectedPatient,
  print,
  responses,
  setInitialValues,
  patientInfos,
  pedType
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      const array = selectedPatient.split('-');
      const obj = {
        TRI_PDG_PACIENTE: array[0],
        TRI_PDG_ANO: array[1],
        TRI_PDG_USUARIO: currentUser.id,
        TRI_PDG_ESPECIFICIDADE: pedType,
        TRI_PDG_SENSORIO_MOTOR: values.sensorioMotor,
        TRI_PDG_PRE_OPERATORIO: values.preOperario,
        TRI_PDG_OPERACOES_CONCRETAS: values.operacoesConcretas,
        TRI_PDG_OPERACOES_FORMAIS: values.operacoesFormais,
        TRI_PDG_COMPREENSAO_DO_NOVO: values.compreensaoNovo,
        TRI_PDG_COMPREENSAO_GLOBAL: values.compreensaoGlobal,
        TRI_PDG_CRIATIVIDADE: values.criatividade,
        TRI_PDG_MANUTENCAO_APRENDIZAGEM: values.manutencaoAprendizagem,
        TRI_PDG_PERCEPCAO_VISUAL: values.percepcaoVisual,
        TRI_PDG_PERCEPCAO_AUDITIVA: values.percepcaoAuditiva,
        TRI_PDG_PERCEPCAO_OLFATIVA: values.percepcaoOlfativa,
        TRI_PDG_PERCEPCAO_GUSTATIVA: values.percepcaoGustativa,
        TRI_PDG_ASPCT_TATIL_CINESTESICO: values.aspectosReconhecimento,
        TRI_PDG_COORDENACAO_MOTORA_GRSS: values.motoraGrossa,
        TRI_PDG_COORDENACAO_MOTORA_FINA: values.motoraFina,
        TRI_PDG_LATERALIDADE: values.lateralidade,
        TRI_PDG_AUTOPRESERVACAO: values.autopreservacao,
        TRI_PDG_AUTOESTIMA: values.autoestima,
        TRI_PDG_AUTOCRITICA: values.autocritica,
        TRI_PDG_AUTONOMIA: values.autonomia,
        TRI_PDG_INTRCAO_SOCIAL_COMUNICA: values.interacaoSocial,
        TRI_PDG_TOMADA_DECISAO_EXECUCAO: values.decisaoExecucao,
        TRI_PDG_OBS: values.notes
      };
      setIsLoading(true);
      setTriagemPedagogia(array[0], array[1], obj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Ocorreu um erro ao enviar os dados: ${json.message}`);
        } else {
          success('Dados enviados com sucesso!');
          print();
          navigate('/user');
        }
      });
    }
  }
  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form disabled={!patientInfos.active}>
          <TitleSection style={{ fontSize: 26 }}>
            Apresenta coerência com qual fase do desenvolvimento referida por Piaget?
          </TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sensório-motor</label>
              <RadioRow>
                {responses.sensorioMotor.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Pré-operatório</label>
              <RadioRow>
                {responses.preOperario.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Operações concretas</label>
              <RadioRow>
                {responses.operacoesConcretas.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Operações formais</label>
              <RadioRow>
                {responses.operacoesFormais.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection style={{ fontSize: 24 }}>
            Aponte a situação do(a) beneficiário(a) nos aspectos postos abaixo
          </TitleSection>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Compreensão do novo</label>
              <RadioRow>
                {responses.compreensaoNovo.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Compreensão global dos contextos</label>
              <RadioRow>
                {responses.compreensaoGlobal.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Criatividade</label>
              <RadioRow>
                {responses.criatividade.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Manutenção de determinada aprendizagem</label>
              <RadioRow>
                {responses.manutencaoAprendizagem.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção visual</label>
              <RadioRow>
                {responses.percepcaoVisual.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção auditiva</label>
              <RadioRow>
                {responses.percepcaoAuditiva.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção olfativa</label>
              <RadioRow>
                {responses.percepcaoOlfativa.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Percepção gustativa</label>
              <RadioRow>
                {responses.percepcaoGustativa.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Aspectos tátil-cinestésico de reconhecimento</label>
              <RadioRow>
                {responses.aspectosReconhecimento.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Coordenação motora grossa</label>
              <RadioRow>
                {responses.motoraGrossa.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Coordenação motora fina</label>
              <RadioRow>
                {responses.motoraFina.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Lateralidade</label>
              <RadioRow>
                {responses.lateralidade.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autoestima</label>
              <RadioRow>
                {responses.autoestima.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autonomia</label>
              <RadioRow>
                {responses.autonomia.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Interação social e comunicacional</label>
              <RadioRow>
                {responses.interacaoSocial.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autopreservação</label>
              <RadioRow>
                {responses.autopreservacao.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Autocrítica</label>
              <RadioRow>
                {responses.autocritica.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Tomada de decisão e execução</label>
              <RadioRow>
                {responses.decisaoExecucao.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <FormField
                as="textarea"
                name="notes"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>

          <FormRow>
            <SubmitButton border type="button">
              Imprimir
            </SubmitButton>
            {patientInfos.active &&
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : (
                  'Enviar Dados'
                )}
              </SubmitButton>
            }
          </FormRow>
        </Form>
      )}
    </Formik>
  );
}
