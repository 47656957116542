import React, { useContext, useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Search from '../../../../../../components/Search';
import { danger, info } from '../../../../../../components/Alerts';
import { getPatientsWithSector } from '../../../../../../utils/api';
import { GlobalContext } from '../../../../../../GlobalContext';
import handleGetRespostas from '../../../../../../utils/handleGetRespostas';
import PrintAudiologiaAdulto from './PrintAudiologiaAdulto';
import FormAudiologiaAdulto from './FormAudiologiaAdulto';
import { PatientName } from '../../../../../../components/PatientName';

export default function AudiologiaAdulto() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [selectedPatient, setSelectedPatient] = useState('');
  const [initialValues, setInitialValues] = useState({
    apresentaOtites: '',
    apresentaOtitesNote: '',
    apresentaOtitesSim: [],
    apresentaTonturas: '',
    apresentaZumbido: '',
    apresentaZumbidoSim: [],
    atividadesProfissionaisRuido: '',
    atividadesProfissionaisRuidosNote: '',
    dificuldadeOuvir: '',
    dificuldadeOuvirSim: [],
    dificuldadesEntenderFalaAmbientesRuidosos: '',
    familiarPerdaAuditiva: '',
    meatoscopia: '',
    medicacoesOtotoxicas: '',
    medicacoesOtotoxicasNote: '',
    notes: '',
    problemaSaude: '',
    problemaSaudeNote: '',
    queixas: '',
    usoRegularMedicacoes: '',
    usoRegularMedicacoesNote: ''
  });

  const [responses, setResponses] = useState({});

  const { currentUser } = useContext(GlobalContext);
  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    const array = patient.split('-');
    setPatientInfos(patients.find(patient => patient.id == array[0] && patient.year == array[1]));

    if (Object.keys(responses).length < 14) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      setSelectedPatient(patient);
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    handleGetRespostas([
      {
        label: 'Triagem Audio Nao Crianca - Meatoscopia',
        name: 'meatoscopia'
      },
      {
        label: 'Triagem Audio Nao Crianca - Dificuldades em ouvir',
        name: 'dificuldadeOuvir'
      },
      {
        label: 'Triagem Audio Nao Crianca - Dificuldades em ouvir SIM',
        name: 'dificuldadeOuvirSim'
      },
      {
        label: 'Triagem Audio Nao Crianca - Apresenta zumbido SIM',
        name: 'apresentaZumbidoSim'
      },
      {
        label: 'Triagem Audio Nao Crianca - Apresenta zumbido?',
        name: 'apresentaZumbido'
      },
      {
        label: 'Triagem Audio Nao Crianca - Apresenta tonturas?',
        name: 'apresentaTonturas'
      },
      {
        label:
          'Triagem Audio Nao Crianca - Apresenta dificuldades para entender a fala em ambientes ruidosos?',
        name: 'dificuldadesEntenderFalaAmbientesRuidosos'
      },
      {
        label: 'Triagem Audio Nao Crianca - Apresentou ou apresenta episódios de otites?',
        name: 'apresentaOtites'
      },
      {
        label: 'Triagem Audio Nao Crianca - Apresentou ou apresenta episódios de otites SIM',
        name: 'apresentaOtitesSim'
      },
      {
        label: 'Triagem Audio Nao Crianca - Já fez uso de medicações ototóxicas?',
        name: 'medicacoesOtotoxicas'
      },
      {
        label:
          'Triagem Audio Nao Crianca - Já exerceu ou exerce atividades profissionais em ambientes com ruído intenso?',
        name: 'atividadesProfissionaisRuido'
      },
      {
        label: 'Triagem Audio Nao Crianca - Há historia familiar de perda auditiva?',
        name: 'familiarPerdaAuditiva'
      },
      {
        label: 'Triagem Audio Nao Crianca - Apresenta algum problema de saúde?',
        name: 'problemaSaude'
      },
      {
        label: 'Triagem Audio Nao Crianca - Faz uso regular de medicações?',
        name: 'usoRegularMedicacoes'
      }
    ]).then(result => setResponses(result));
    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Avaliação Audiológica - Adolescente/Adulto/Idoso</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <FormAudiologiaAdulto
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            currentUser={currentUser}
            print={print}
            patientInfos={patientInfos}
            responses={responses}
            setInitialValues={setInitialValues}
          />
          <div style={{ display: 'none' }}>
            <PrintAudiologiaAdulto
              ref={printRef}
              initialValues={initialValues}
              selectedPatient={selectedPatient}
              currentUser={currentUser}
              responses={responses}
              patients={patients}
            />
          </div>
        </div>
      )}
    </div>
  );
}
