import { Route, Routes } from 'react-router-dom';

import { AccessProfiles } from './AccessProfiles';
import { AccessProfileRegister } from './AccessProfileRegister';

export function MenuAccessProfiles() {
  return (
    <Routes>
      <Route path="/" element={<AccessProfiles />} />
      <Route path="register" element={<AccessProfileRegister />} />
    </Routes>
  );
}
