import React, { useContext } from 'react';

import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import PrintHeader from '../../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import { GlobalContext } from '../../../../../../GlobalContext';

const PrintEnfermagem = React.forwardRef(
  ({ initialValues, selectedPatient, responses, patients, types, pedType }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    const { currentUser } = useContext(GlobalContext);
    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <h2 className="section" style={{ marginTop: 3 }}>
            Avaliação - Enfermagem
          </h2>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sistema respiratório</label>
              <RadioRow>
                {responses.sistemaRespiratorio.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sistema gastrointestinal</label>
              <RadioRow>
                {responses.sistemaGastrointestinal.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sistema vesical/intestinal</label>
              <RadioRow>
                {responses.vesicalIntestinal.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Estomias</label>
              <RadioRow>
                {responses.estomias.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Dieta</label>
              <RadioRow>
                {responses.dieta.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Resiste em ser alimentado</label>
              <RadioRow>
                {responses.resisteAlimentado.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Necessidades domiciliares</label>
              <RadioRow>
                {responses.necessidades.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label className="highlight">Presença de escaras</label>
              <RadioRow>
                {responses.escaras.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            {initialValues.escaras === '9901' && (
              <div className="animeLeft mediumInput">
                <label>Local/Região corporal</label>
                <input readOnly value={initialValues.escarasNotes} />
              </div>
            )}
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Controla urina</label>
              <RadioRow>
                {responses.controlaUrina.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Controla fezes</label>
              <RadioRow>
                {responses.controlaFezes.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Tipo de alimentação (consistência)</label>
              <RadioRow>
                {responses.tipoAlimentacao.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Alimenta-se sozinho</label>
              <RadioRow>
                {responses.alimentaSozinho.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Intercorrências</label>
              <RadioRow>
                {responses.intercorrencias.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label className="highlight">Alergia/intolerância alimentar e ou medicamentosa</label>
              <RadioRow>
                {responses.alergia.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            {initialValues.alergia === 'F001' && (
              <div className="animeLeft mediumInput">
                <label>Quais?</label>
                <input type="text" readOnly value={initialValues.alergiaNotes} />
              </div>
            )}
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label className="highlight">Faz ingestão de medicamentos</label>
              <RadioRow>
                {responses.medicamentos.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            {initialValues.medicamentos === 'G001' && (
              <div className="animeLeft mediumInput">
                <label>Quais?</label>
                <input type="text" readOnly value={initialValues.medicamentosNote} />
              </div>
            )}
          </FormRow>

          <TitleSection>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea value={initialValues.notes} readOnly />
            </div>
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintEnfermagem;
