import React from 'react';
import { Container, LoginBox } from './style';
import logo from '../../assets/logo-without-bg.png';
import { GlobalContext } from '../../GlobalContext';
import { Button } from '../../styles/Button';

const objDataLogin = {
  email: '',
  password: ''
};

export function Login() {
  const [dataLogin, setDataLogin] = React.useState(objDataLogin);
  const { userLogin, error, load } = React.useContext(GlobalContext);

  function handleChange({ target }) {
    return setDataLogin({ ...dataLogin, [target.name]: target.value });
  }

  async function handleSubmit() {
    await userLogin(dataLogin);
  }

  return (
    <Container className="animeShow">
      <LoginBox className="animeDown">
        <img src={logo} alt="PODE" />
        <div className="form">
          <h1>Login</h1>
          <input
            autocomplete="off"
            autoComplete="off"
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
            value={dataLogin.email}
            onChange={handleChange}
            onKeyDown={e => {
              e.key === 'Enter' && handleSubmit();
            }}
          />

          <input
            autocomplete="off"
            autoComplete="off"
            type="password"
            name="password"
            id="password"
            placeholder="Senha"
            value={dataLogin.password}
            onChange={handleChange}
            onKeyDown={e => {
              e.key === 'Enter' && handleSubmit();
            }}
          />
          <span>{error}</span>
          <Button disabled={load} primary onClick={handleSubmit}>
            Entrar
          </Button>
        </div>
      </LoginBox>
    </Container>
  );
}
