import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { messageTranslate } from '../../../../utils/validators';

import { Button } from '../../../../styles/Button';
import { Error } from '../../../../styles/GlobalComponents';
import { Title } from '../style';

const schema = Yup.object().shape({
  description: Yup.string()
    .max(64, messageTranslate.maxChar(64))
    .required(messageTranslate.otherRequired)
});

export function AccessProfileRegister() {
  return (
    <div className="animeUp">
      <Title>Cadastro de Perfis de Acesso</Title>
      <Formik
        validationSchema={schema}
        initialValues={{
          cod: '',
          description: ''
        }}
        onSubmit={e => console.log(e)}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <label htmlFor="cod">Código</label>
              <Field disabled id="cod" name="cod" type="text" />
            </div>
            <div>
              <label htmlFor="description">Descrição</label>
              <Field id="description" name="description" type="text" />
              {errors.description && touched.description && <Error>{errors.description}</Error>}
            </div>
            <Button primary type="submit">
              Enviar
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
