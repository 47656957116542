/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState, useContext, useRef } from 'react';

import Search from '../../../../components/Search';
import FormTP from './Components/FormTP';
import handleGetRespostas from '../../../../utils/handleGetRespostas';
import PrintTP from './Components/PrintTP';
import { danger, info, warning } from '../../../../components/Alerts';
import { getFrequency, getPatientsWithSector, getRelationship, getTherapeuticProject, getUsers } from '../../../../utils/api';
import { GlobalContext } from '../../../../GlobalContext';
import { useReactToPrint } from 'react-to-print';
import { PatientName } from '../../../../components/PatientName';

export default function TherapeuticProject() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [frequencies, setFrequencies] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [selectedFamiliars, setSelectedFamiliars] = useState([]);
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [initialValues, setInitialValues] = useState({
    aspectosIndividuais: [],
    atividadesDiarias: [],
    desenvolverAspectosIndividuais: [],
    desenvolverEmocional: [],
    desenvolverOrganizacaoFamiliar: [],
    desenvolverSaude: [],
    desenvolverSocial: [],
    desenvolverVidaDiaria: [],
    fatoresEmocionais: [],
    fatoresSaude: [],
    fatoresSociais: [],
    notes: '',
    previsao: '',
    vidaFamiliar: []
  });
  const [responses, setResponses] = useState({});
  const [selectedAttendances, setSelectedAttendances] = useState([]);

  const { specialtiesData, loadSpecialties, currentUser } = useContext(GlobalContext);
  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    const data = patient.split('-');
    setPatientInfos(patients.find(patient => patient.id == data[0] && patient.year == data[1]));
    if (Object.keys(responses).length < 12 && specialtiesData.length > 0) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      setSelectedPatient(patient);
      const array = patient.split('-');
      const patientData = patients.find(patient => patient.id == array[0] && patient.year == array[1]);
      setPatientInfos(patientData);
      !patientData.active && warning('Paciente inativo!');
      getTherapeuticProject(data[0], data[1]).then(json => {
        if (json.error) {
          danger(`${json.message}`);
        } else {
          if(json.data.objPacienteProjetoTerapeutico) {
            const previsao = json.data.objPacienteProjetoTerapeutico.PPT_PREVISAO_REAVALIACAO.split('/');
            setInitialValues({
              aspectosIndividuais: json.data.objPacienteProjetoTerapeutico.PPT_DIAG_ASPECTOS_INDIVIDUAIS.split('|'),
              atividadesDiarias: json.data.objPacienteProjetoTerapeutico.PPT_DIAG_ATIVID_DE_VIDA_DIARIA.split('|'),
              vidaFamiliar: json.data.objPacienteProjetoTerapeutico.PPT_DIAG_VIDA_FAMILIAR.split('|'),
              fatoresSaude: json.data.objPacienteProjetoTerapeutico.PPT_DIAG_FATORES_DE_SAUDE.split('|'),
              fatoresEmocionais: json.data.objPacienteProjetoTerapeutico.PPT_DIAG_FATORES_EMOCIONAIS.split('|'),
              fatoresSociais: json.data.objPacienteProjetoTerapeutico.PPT_DIAG_FATORES_SOCIAIS.split('|'),
              desenvolverAspectosIndividuais: json.data.objPacienteProjetoTerapeutico.PPT_OBJT_DESENV_ASPCT_INDIVID.split('|'),
              desenvolverVidaDiaria: json.data.objPacienteProjetoTerapeutico.PPT_OBJT_ATIVID_DE_VIDA_DIARIA.split('|'),
              desenvolverOrganizacaoFamiliar: json.data.objPacienteProjetoTerapeutico.PPT_OBJT_ORGANIZACAO_FAMILIAR.split('|'),
              desenvolverSaude: json.data.objPacienteProjetoTerapeutico.PPT_OBJT_SAUDE.split('|'),
              desenvolverEmocional: json.data.objPacienteProjetoTerapeutico.PPT_OBJT_SITUACAO_EMOCIONAL.split('|'),
              desenvolverSocial: json.data.objPacienteProjetoTerapeutico.PPT_OBJT_FATORES_SOCIAIS.split('|'),
              notes: json.data.objPacienteProjetoTerapeutico.PPT_OBS,
              previsao: `${previsao[2]}-${previsao[1]}-${previsao[0]}`,
            })
            setSelectedFamiliars(() => {
              return json.data.objPacienteProjetoTerapeutico.PPT_FAMILIARES.map(familiar => (
                { ...relationships.find(el => el.id == familiar.P_PT_F_GRAU_PARENTESCO), name: familiar.P_PT_F_FAMILIAR }
              ))
            });
            setSelectedProfessionals(() => {
              return json.data.objPacienteProjetoTerapeutico.PPT_PROFISSIONAIS.map(professional => (
                {...professionals.find(el => el.id == professional.P_PT_P_PROFISSIONAL)}
              ))})
            setSelectedAttendances(() => {
              return json.data.objPacienteProjetoTerapeutico.PPT_AGENDAMENTOS.map(attendance => {
                const iniHour = attendance.PAF_HORINI.split(':');
                const finalHour = attendance.PAF_HORFIM.split(':');
                const user = professionals.find(el => el.id == attendance.PAF_AREA);
                return {
                    area: user.area,
                    label: attendance.USR_NOME,
                    id: attendance.PAF_AREA,
                    frequency: attendance.PAF_FREQUENCIA,
                    day: attendance.PAF_DOW,
                    initialHour: iniHour[0],
                    initialMinute: iniHour[1],
                    finalHour: finalHour[0],
                    finalMinute: finalHour[1],
                  }
              });
            });
          } else {
            setInitialValues({
              aspectosIndividuais: [],
              atividadesDiarias: [],
              desenvolverAspectosIndividuais: [],
              desenvolverEmocional: [],
              desenvolverOrganizacaoFamiliar: [],
              desenvolverSaude: [],
              desenvolverSocial: [],
              desenvolverVidaDiaria: [],
              fatoresEmocionais: [],
              fatoresSaude: [],
              fatoresSociais: [],
              notes: '',
              previsao: '',
              vidaFamiliar: []
            })
            setSelectedAttendances([]);
            setSelectedProfessionals([]);
            setSelectedFamiliars([]);
          }
        }
      });
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getFrequency().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar frequências: ${json.message}`);
      } else {
        let arr = [];
        json.data.arrFrequencia.forEach(frequency => {
          const frequencyObj = {
            id: frequency[0],
            label: frequency[1]
          };
          arr.push(frequencyObj);
        });
        arr.unshift({
          id: null,
          label: 'Frequência'
        });
        setFrequencies(arr);
      }
    });

    getRelationship().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrGrauParentesco.forEach(grau => {
          const grauObj = {
            id: grau[0],
            label: grau[1]
          };
          arr.push(grauObj);
        });
        setRelationships(arr);
      }
    });

    getUsers().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar usuários: ${json.message}`);
      } else {
        const array = json.data.arrUsuarios.map(user => ({
          id: user.USR_CODIGO,
          label: user.USR_NOME,
          sector: {
            id: user.USR_SETOR,
            label: user.STR_DESCRITIVO
          },
          area: {
            id: user.USR_AREA,
            label: user.ARE_DESCRITIVO
          },
          class: user.USR_CONSELHO_DE_CLASSE
        }));
        setProfessionals(array);
      }
    });

    handleGetRespostas([
      {
        label: 'Projeto Terapeutico - Aspectos Individuais',
        name: 'aspectosIndividuais'
      },
      {
        label: 'Projeto Terapeutico - Atividades de Vida Diária',
        name: 'atividadesDiarias'
      },
      {
        label: 'Projeto Terapeutico - Vida Familiar',
        name: 'vidaFamiliar'
      },
      {
        label: 'Projeto Terapeutico - Fatores de Saúde',
        name: 'fatoresSaude'
      },
      {
        label: 'Projeto Terapeutico - Fatores Emocionais',
        name: 'fatoresEmocionais'
      },
      {
        label: 'Projeto Terapeutico - Fatores Sociais',
        name: 'fatoresSociais'
      },
      {
        label: 'Projeto Terapeutico - Desenvolver/aprimorar os aspectos individuais',
        name: 'desenvolverAspectosIndividuais'
      },
      {
        label:
          'Projeto Terapeutico - Desenvolver/aprimorar a execução das atividades de vida diária',
        name: 'desenvolverVidaDiaria'
      },
      {
        label: 'Projeto Terapeutico - Desenvolver/aprimorar a organização familiar',
        name: 'desenvolverOrganizacaoFamiliar'
      },
      {
        label: 'Projeto Terapeutico - Desenvolver/aprimorar a saúde',
        name: 'desenvolverSaude'
      },
      {
        label: 'Projeto Terapeutico - Desenvolver/aprimorar a situação emocional',
        name: 'desenvolverEmocional'
      },
      {
        label: 'Projeto Terapeutico - Desenvolver/aprimorar fatores sociais',
        name: 'desenvolverSocial'
      }
    ]).then(result => setResponses(result));

    loadSpecialties();

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Projeto Terapêutico</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <FormTP
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            print={print}
            responses={responses}
            setInitialValues={setInitialValues}
            frequencies={frequencies}
            specialtiesData={specialtiesData}
            selectedAttendances={selectedAttendances}
            setSelectedAttendances={setSelectedAttendances}
            selectedFamiliars={selectedFamiliars}
            setSelectedFamiliars={setSelectedFamiliars}
            selectedProfessionals={selectedProfessionals}
            setSelectedProfessionals={setSelectedProfessionals}
            relationships={relationships}
            professionals={professionals}
          />
          <div style={{ display: 'none' }}>
            <PrintTP
              initialValues={initialValues}
              patients={patients}
              selectedPatient={selectedPatient}
              responses={responses}
              ref={printRef}
              selectedAttendances={selectedAttendances}
              frequencies={frequencies}
              selectedFamiliars={selectedFamiliars}
              selectedProfessionals={selectedProfessionals}
            />
          </div>
        </div>
      )}
    </div>
  );
}
