import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { danger, success } from '../../../../../../components/Alerts';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import { setTriagemFono } from '../../../../../../utils/api';
import { GlobalContext } from '../../../../../../GlobalContext';
import DefaultRadioRow from './DefaultRadioRow';

export default function FormFonoaudiologia({
  initialValues,
  selectedPatient,
  print,
  responses,
  patientInfos,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);
  function handleSubmit(values) {
    if (!isLoading) {
      const array = selectedPatient.split('-');
      const fonoObj = {
        TRI_FONO_PACIENTE: array[0],
        TRI_FONO_ANO: array[1],
        TRI_FONO_USUARIO: currentUser.id,
        TRI_FONO_FACIAL_TIPO: values.tipoFacialTipo,
        TRI_FONO_FACIAL_PERFIL: values.tipoFacialPerfil,
        TRI_FONO_FACIAL_ANGL_NASOLABIAL: values.tipoFacialAngulo,
        TRI_FONO_BOCHECHA_MUCOSA: values.bochechaMucosa.join('|'),
        TRI_FONO_BOCHECHA_TONUS: values.bochechaTonus.join('|'),
        TRI_FONO_BOCHECHA_MOBILIDADE: values.bochechasMobilidade,
        TRI_FONO_BOCHECHA_OBS: values.bochechasObs,
        TRI_FONO_LABIOS_POSICAO_HABTUAL: values.labiosPosicao,
        TRI_FONO_LABIOS_MUCOSA: values.labiosMucosa,
        TRI_FONO_LABIOS_MOBILIDADE: values.labiosMobilidade,
        TRI_FONO_LABIOS_OBS: values.labiosObs,
        TRI_FONO_LINGUA_SULCO_LONGTDNAL: values.linguaSulco,
        TRI_FONO_LINGUA_POSICAO_HABTUAL: values.linguaPosicao,
        TRI_FONO_LINGUA_SIMETRIA: values.linguaSimetria,
        TRI_FONO_LINGUA_SIMETRIA_SE_NAO: values.linguaSimetriaObs,
        TRI_FONO_LINGUA_LARGURA: values.linguaLargura,
        TRI_FONO_LINGUA_TREMOR: values.linguaTremor,
        TRI_FONO_LINGUA_MUCOSA: values.linguaMucosa,
        TRI_FONO_LINGUA_MUCOSA_MARC_DNT: values.linguaMucosaMarcadaLocal,
        TRI_FONO_LINGUA_MUCOSA_FERIDA: values.linguaMucosaFeridaLocal,
        TRI_FONO_LINGUA_FRENULO: values.linguaFrenulo,
        TRI_FONO_LINGUA_FRENULO_OBS: values.linguaObs,
        TRI_FONO_MANDIBULA_POSTURA: values.mandibulaPostura,
        TRI_FONO_MANDIBULA_ABRTURA_BOCA: values.mandibulaAbertura,
        TRI_FONO_MANDIBULA_ABRTR_BC_CD: values.mandibulaAberturaDesvio.join('|'),
        TRI_FONO_MANDIBULA_FCHMNTO_BOCA: values.mandibulaFechamento,
        TRI_FONO_MANDIBULA_FCHMNT_BC_CD: values.mandibulaFechamentoDesvio.join('|'),
        TRI_FONO_MANDIBULA_LATERLDADE_D: values.mandibulaLateralidadeDireita,
        TRI_FONO_MANDIBULA_LATERLDADE_E: values.mandibulaLateralidadeEsquerda,
        TRI_FONO_DENTES_DENTADURA: values.dentesDentadura,
        TRI_FONO_DENTES_NUMRO_DENTES_SD: values.dentesSuperiorDireito,
        TRI_FONO_DENTES_NUMRO_DENTES_SE: values.dentesSuperiorEsquerdo,
        TRI_FONO_DENTES_NUMRO_DENTES_ID: values.dentesSuperiorDireito,
        TRI_FONO_DENTES_NUMRO_DENTES_IE: values.dentesInferiorEsquerdo,
        TRI_FONO_DENTES_FALHA_DENTARIA: values.dentesFalha,
        TRI_FONO_DENTES_F_D_SE_PRESENTE: values.dentesFalhaObs,
        TRI_FONO_DENTES_CONSRV_DENTARIA: values.dentesConservacaoDentaria,
        TRI_FONO_DENTES_CONSRV_GENGIVAL: values.dentesConservacaoGengival,
        TRI_FONO_DENTES_LINHA_MEDIA: values.dentesLinha,
        TRI_FONO_DENTES_CLS_ANGLE_LDO_D: values.dentesClassificacaoDireito,
        TRI_FONO_DENTES_CLS_ANGLE_LDO_E: values.dentesClassificacaoEsquerdo,
        TRI_FONO_PALATO_DURO_PROFUNDID: values.palatoDuroProfundidade,
        TRI_FONO_PALATO_DURO_LARGURA: values.palatoDuroLargura,
        TRI_FONO_PALATO_DURO_FISSURA: values.palatoDuroFissura,
        TRI_FONO_PALATO_DURO_F_SE_SIM_T: values.palatoDuroFissuraTipo,
        TRI_FONO_PALATO_DURO_F_SE_SIM_L: values.palatoDuroFissuraLocalizacao,
        TRI_FONO_PALATO_MOLE_SIMETRIA: values.palatoMoleSimetria,
        TRI_FONO_PALATO_MOLE_EXTENSAO: values.palatoMoleExtensao,
        TRI_FONO_PALATO_MOLE_UVULA: values.palatoMoleUvula,
        TRI_FONO_PALATO_MOLE_FISSURA: values.palatoMoleFissura,
        TRI_FONO_PALATO_MOLE_F_SE_SIM_T: values.palatoMoleFissuraTipo,
        TRI_FONO_PALATO_MOLE_F_SE_SIM_L: values.palatoMoleFissuraLocalizacao,
        TRI_FONO_PALATO_VP_FALAR_REPETD: values.veuA,
        TRI_FONO_PALATO_VP_FALAR_RP_OBS: values.veuAObs,
        TRI_FONO_PALATO_VP_ELICIAR_R_N: values.veuEliciar,
        TRI_FONO_PALATO_VP_ELI_R_N_OBS: values.veuEliciarObs,
        TRI_FONO_RESPIRACAO_TIPO: values.respiracaoTipo,
        TRI_FONO_RESPIRACAO_TIPO_OBS: values.respiracaoTipoObs,
        TRI_FONO_RESPIRACAO_MODO: values.respiracaoModo,
        TRI_FONO_RESPIRACAO_MODO_OBS: values.respiracaoModoObs,
        TRI_FONO_DEGLUTICAO_TIPO_VIA_AL: values.degluticaoVia,
        TRI_FONO_DEGLUTICAO_DIFICULDADE: values.degluticaoDificuldade,
        TRI_FONO_DEGLUTICAO_DIFICLD_OBS: values.degluticaoDificuldadeObs,
        TRI_FONO_DEGLUTICAO_RUIDO: values.degluticaoRuido,
        TRI_FONO_DEGLUTICAO_RUIDO_OBS: values.degluticaoRuidoObs,
        TRI_FONO_DEGLUTICAO_ENGASGOS: values.degluticaoEngasgos,
        TRI_FONO_DEGLUTICAO_ENG_SE_PRST: values.degluticaoEngasgosSeSim.join('|'),
        TRI_FONO_DEGLUTICAO_ODINOFAGIA: values.degluticaoOdinofagia,
        TRI_FONO_DEGLUTICAO_ODINOFG_OBS: values.degluticaoOdinofagiaObs,
        TRI_FONO_DEGLUTICAO_REFLUXO_NSL: values.degluticaoRefluxo,
        TRI_FONO_DEGLUTICAO_R_NASAL_OBS: values.degluticaoRefluxoObs,
        TRI_FONO_DEGLUTICAO_ESCAPE_ANTR: values.degluticaoEscape,
        TRI_FONO_DEGLUTICAO_E_ANTER_OBS: values.degluticaoEscapeObs,
        TRI_FONO_DEGLUTICAO_PIGARRO: values.degluticaoPigarro,
        TRI_FONO_DEGLUTICAO_PIGARRO_SIM: values.degluticaoPigarroSeSim,
        TRI_FONO_DEGLUTICAO_PIGARRO_OBS: values.degluticaoPigarroObs,
        TRI_FONO_DEGLUTICAO_TOSSE: values.degluticaoTosse,
        TRI_FONO_DEGLUTICAO_TOSSE_OBS: values.degluticaoTosseObs,
        TRI_FONO_DEGLUTICAO_RSD_APOS_DG: values.degluticaoResiduos,
        TRI_FONO_DEGLUTICAO_R_AP_DG_OBS: values.degluticaoResiduosObs,
        TRI_FONO_SUCCAO: values.succaoSuccao,
        TRI_FONO_SUCCAO_SE_AUSENTE: values.succaoSuccaoObs,
        TRI_FONO_MASTIGACAO: values.mastigacaoMastigacao,
        TRI_FONO_MASTIGACAO_SE_P_LADO: values.mastigacaoLado.join('|'),
        TRI_FONO_MASTIGACAO_SE_P_LABIOS: values.mastigacaoLabios,
        TRI_FONO_MASTIGACAO_SE_P_RUIDO: values.mastigacaoRuido,
        TRI_FONO_MASTIGACAO_SE_P_DOR: values.mastigacaoDor,
        TRI_FONO_MASTIGACAO_SE_P_DOR_AV: values.mastigacaoDorAsVezes.join('|'),
        TRI_FONO_MASTIGACAO_SE_P_DOR_SM: values.mastigacaoDorSim.join('|'),
        TRI_FONO_MASTIGACAO_SE_P_RU_ART: values.mastigacaoRuidoArticular,
        TRI_FONO_MASTIGACAO_SE_P_R_A_AV: values.mastigacaoRuidoArticularAsVezes.join('|'),
        TRI_FONO_MASTIGACAO_SE_P_R_A_SM: values.mastigacaoRuidoArticularSim.join('|'),
        TRI_FONO_MASTIGACAO_SE_P_ESCAPE: values.mastigacaoEscape,
        TRI_FONO_MASTIGACAO_SE_P_DIF_MA: values.mastigacaoDificuldade,
        TRI_FONO_OBS: values.notes
      };
      setIsLoading(true);
      setInitialValues(values);
      setTriagemFono(array[0], array[1], fonoObj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Ocorreu um erro ao enviar os dados: ${json.message}`);
        } else {
          success('Dados enviados com sucesso!');
          print();
          navigate('/user');
        }
      });
    }
  }
  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form disabled={!patientInfos.active}>
          <TitleSection>Tipo Facial</TitleSection>
          <DefaultRadioRow
            label="Tipo"
            responses={responses['Tipo Facial']['Tipo']}
            name="tipoFacialTipo"
          />
          <DefaultRadioRow
            label="Perfil"
            responses={responses['Tipo Facial']['Perfil']}
            name="tipoFacialPerfil"
          />
          <DefaultRadioRow
            label="Ângulo Nasolabial"
            responses={responses['Tipo Facial']['Ângulo nasolabial']}
            name="tipoFacialAngulo"
          />

          <TitleSection>Bochechas</TitleSection>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Mucosa</label>
              <RadioRow columns={2}>
                {responses.Bochechas.Mucosa.map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="bochechaMucosa"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Tônus</label>
              <RadioRow columns={2}>
                {responses.Bochechas.Tonus.map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="bochechaTonus"
                      value={element.TAG}
                    />
                    <span className={`checkMark`}></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label>Mobilidade</label>
              <FormField type="text" name="bochechasMobilidade" />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label>Observação</label>
              <FormField type="text" name="bochechasObs" />
            </div>
          </FormRow>

          <TitleSection>Lábios</TitleSection>
          <DefaultRadioRow
            label="Posição habitual"
            responses={responses['Labios']['Posicao Habitual']}
            name="labiosPosicao"
          />
          <DefaultRadioRow
            label="Mucosa"
            responses={responses['Labios']['Mucosa']}
            name="labiosMucosa"
          />
          <FormRow>
            <div className="fullInput">
              <label>Mobilidade</label>
              <FormField type="text" name="labiosMobilidade" />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label>Observação</label>
              <FormField type="text" name="labiosObs" />
            </div>
          </FormRow>

          <TitleSection>Língua</TitleSection>
          <DefaultRadioRow
            label="Sulco Longitudinal"
            responses={responses['Lingua']['Sulco longitudinal']}
            name="linguaSulco"
          />
          <DefaultRadioRow
            label="Posição habitual"
            responses={responses['Lingua']['Posicao habitual']}
            name="linguaPosicao"
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Simetria</label>
              <RadioRow>
                {responses.Lingua.Simetria.map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="linguaSimetria"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {values.linguaSimetria === '000546' && (
              <div className="fullInput animeLeft">
                <label>Se não, descrever</label>
                <FormField type="text" name="linguaSimetriaObs" />
              </div>
            )}
          </FormRow>
          <DefaultRadioRow
            label="Largura"
            responses={responses['Lingua']['Largura']}
            name="linguaLargura"
          />
          <DefaultRadioRow
            label="Tremor"
            responses={responses['Lingua']['Tremor']}
            name="linguaTremor"
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Mucosa</label>
              <RadioRow>
                {responses.Lingua.Mucosa.map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="linguaMucosa"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {values.linguaMucosa === '000557' && (
              <div className="fullInput animeLeft">
                <label>Local</label>
                <FormField type="text" name="linguaMucosaMarcadaLocal" />
              </div>
            )}
            {values.linguaMucosa === '000558' && (
              <div className="fullInput animeLeft">
                <label>Local</label>
                <FormField type="text" name="linguaMucosaFeridaLocal" />
              </div>
            )}
          </FormRow>
          <DefaultRadioRow
            label="Frênulo"
            responses={responses['Lingua']['Frenulo']}
            name="linguaFrenulo"
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label>Outras características</label>
              <FormField type="text" name="linguaObs" />
            </div>
          </FormRow>

          <TitleSection>Mandíbula</TitleSection>
          <DefaultRadioRow
            label="Postura"
            responses={responses['Mandibula']['Postura']}
            name="mandibulaPostura"
          />
          <DefaultRadioRow
            label="Abertura da boca"
            responses={responses['Mandibula']['Abertura da boca']}
            name="mandibulaAbertura"
          />

          {values.mandibulaAbertura === '000575' && (
            <DefaultRadioRow
              className="animeUp"
              label="Caso desvio na abertura"
              responses={responses['Mandibula']['Abertura da boca (com desvio)']}
              name="mandibulaAberturaDesvio"
            />
          )}
          <DefaultRadioRow
            label="Fechamento da boca"
            responses={responses['Mandibula']['Fechamento da boca']}
            name="mandibulaFechamento"
          />
          {values.mandibulaFechamento === '000578' && (
            <DefaultRadioRow
              className="animeUp"
              label="Caso desvio no fechamento da boca"
              responses={responses['Mandibula']['Fechamento da boca (com desvio)']}
              name="mandibulaFechamentoDesvio"
            />
          )}
          <DefaultRadioRow
            label="Lateralidade à direita"
            responses={responses['Mandibula']['Lateralidade a direita']}
            name="mandibulaLateralidadeDireita"
          />
          <DefaultRadioRow
            label="Lateralidade à esquerda"
            responses={responses['Mandibula']['Lateralidade a esquerda']}
            name="mandibulaLateralidadeEsquerda"
          />

          <TitleSection>Dentes</TitleSection>
          <DefaultRadioRow
            label="Dentadura"
            responses={responses['Dentes']['Dentadura']}
            name="dentesDentadura"
          />
          <FormRow>
            <div className="fullInput">
              <label className="highlight">Nº de dentes superior direito</label>
              <FormField
                type="number"
                min="0"
                max="8"
                name="dentesSuperiorDireito"
                defaultValue="0"
              />
            </div>
            <div className="fullInput">
              <label className="highlight">Nº de dentes superior esquerdo</label>
              <FormField
                type="number"
                min="0"
                max="8"
                name="dentesSuperiorEsquerdo"
                defaultValue="0"
              />
            </div>
            <div className="fullInput">
              <label className="highlight">Nº de dentes inferior direito</label>
              <FormField
                type="number"
                min="0"
                max="8"
                name="dentesInferiorDireito"
                defaultValue="0"
              />
            </div>
            <div className="fullInput">
              <label className="highlight">Nº de dentes inferior esquerdo</label>
              <FormField
                type="number"
                min="0"
                max="8"
                name="dentesInferiorEsquerdo"
                defaultValue="0"
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Falha dentária</label>
              <RadioRow>
                {responses.Dentes['Falha dentária'].map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="dentesFalha"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {values.dentesFalha === '000595' && (
              <div className="fullInput animeLeft">
                <label>Se presente, especificar elementos</label>
                <FormField type="text" name="dentesFalhaObs" />
              </div>
            )}
          </FormRow>
          <DefaultRadioRow
            label="Conservação dentária"
            responses={responses['Dentes']['Conservação dentária']}
            name="dentesConservacaoDentaria"
          />
          <DefaultRadioRow
            label="Conservação gengival"
            responses={responses['Dentes']['Conservação gengival']}
            name="dentesConservacaoGengival"
          />
          <DefaultRadioRow
            label="Linha média"
            responses={responses['Dentes']['Linha média']}
            name="dentesLinha"
          />
          <DefaultRadioRow
            label="Classificação de Angle Direito"
            responses={responses['Dentes']['Classificação de Angle D']}
            name="dentesClassificacaoDireito"
          />

          <DefaultRadioRow
            label="Classificação de Angle Esquerdo"
            responses={responses['Dentes']['Classificação de Angle E']}
            name="dentesClassificacaoEsquerdo"
          />

          <TitleSection>Palato Duro</TitleSection>
          <DefaultRadioRow
            label="Profundidade"
            responses={responses['Palato']['Duro - Profundidade']}
            name="palatoDuroProfundidade"
          />
          <DefaultRadioRow
            label="Largura"
            responses={responses['Palato']['Duro - Largura']}
            name="palatoDuroLargura"
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Fissura</label>
              <RadioRow>
                {responses.Palato['Duro - Fissura'].map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="palatoDuroFissura"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {values.palatoDuroFissura === '000618' && (
              <>
                <div className="fullInput animeLeft">
                  <label>Tipo</label>
                  <FormField type="text" name="palatoDuroFissuraTipo" />
                </div>
                <div className="fullInput animeLeft">
                  <label>Localização</label>
                  <FormField type="text" name="palatoDuroFissuraLocalizacao" />
                </div>
              </>
            )}
          </FormRow>

          <TitleSection>Palato Mole</TitleSection>
          <DefaultRadioRow
            label="Simetria"
            responses={responses['Palato']['Mole - Simetria']}
            name="palatoMoleSimetria"
          />
          <DefaultRadioRow
            label="Extensão"
            responses={responses['Palato']['Mole - Extensão']}
            name="palatoMoleExtensao"
          />
          <DefaultRadioRow
            label="Úvula"
            responses={responses['Palato']['Mole - Úvula']}
            name="palatoMoleUvula"
          />
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Fissura</label>
              <RadioRow>
                {responses.Palato['Mole - Fissura'].map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="palatoMoleFissura"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {values.palatoMoleFissura === '000629' && (
              <>
                <div className="fullInput animeLeft">
                  <label>Tipo</label>
                  <FormField type="text" name="palatoMoleFissuraTipo" />
                </div>
                <div className="fullInput animeLeft">
                  <label>Localização</label>
                  <FormField type="text" name="palatoMoleFissuraLocalizacao" />
                </div>
              </>
            )}
          </FormRow>

          <TitleSection>Véu palatino</TitleSection>
          <DefaultRadioRow
            label='Falar "a" repetidamente'
            responses={responses['Véu Palatino']['falar {a} repetidamente']}
            name="veuA"
            obs
          />
          <DefaultRadioRow
            label="Eliciar o reflexo nauseante"
            responses={responses['Véu Palatino']['Eliciar o reflexo nauseante']}
            name="veuEliciar"
            obs
          />

          <TitleSection>Respiração</TitleSection>
          <DefaultRadioRow
            label="Tipo"
            responses={responses['Respiração']['Tipo']}
            name="respiracaoTipo"
            obs
          />
          <DefaultRadioRow
            label="Modo"
            responses={responses['Respiração']['Modo']}
            name="respiracaoModo"
            obs
          />

          <TitleSection>Deglutição</TitleSection>
          <FormRow>
            <div className="fullInput">
              <label className="highlight">Tipo de via alimentar</label>
              <FormField type="text" name="degluticaoVia" />
            </div>
          </FormRow>
          <DefaultRadioRow
            label="Difuculdade"
            responses={responses['Deglutição']['Dificuldade']}
            name="degluticaoDificuldade"
            obs
          />
          <DefaultRadioRow
            label="Ruído"
            responses={responses['Deglutição']['Ruído']}
            name="degluticaoRuido"
            obs
          />
          <DefaultRadioRow
            label="Engasgos"
            responses={responses['Deglutição']['Engasgos']}
            name="degluticaoEngasgos"
            // obs
            ifYesLabel="Se presente"
            ifYes={
              values.degluticaoEngasgos === '000648'
                ? responses['Deglutição']['Engasgos (se presente)']
                : false
            }
          />
          <DefaultRadioRow
            label="Odinofagia"
            responses={responses['Deglutição']['Odinofagia (dor ao deglutir)']}
            name="degluticaoOdinofagia"
            obs
          />
          <DefaultRadioRow
            label="Refluxo nasal"
            responses={responses['Deglutição']['Refluxo nasal']}
            name="degluticaoRefluxo"
            obs
          />
          <DefaultRadioRow
            label="Escape anterior"
            responses={responses['Deglutição']['Escape anterior']}
            name="degluticaoEscape"
            obs
          />
          <DefaultRadioRow
            label="Pigarro"
            responses={responses['Deglutição']['Pigarro']}
            name="degluticaoPigarro"
            obs
            ifYesLabel="Se sim"
            ifYes={
              values.degluticaoPigarro === '000664'
                ? responses['Deglutição']['Pigarro (se sim)']
                : false
            }
          />
          <DefaultRadioRow
            label="Tosse"
            responses={responses['Deglutição']['Tosse']}
            name="degluticaoTosse"
            obs
            ifYesLabel="Se sim"
            ifYes={
              values.degluticaoTosse === '000669'
                ? responses['Deglutição']['Tosse (se sim)']
                : false
            }
          />
          <DefaultRadioRow
            label="Resíduos após a deglutição"
            responses={responses['Deglutição']['Resíduos após a deglutição']}
            name="degluticaoResiduos"
            obs
          />

          <TitleSection>Sucção</TitleSection>
          <FormRow>
            <div className="fullInput radioGroup">
              <label style={{ height: 25 }} />
              <RadioRow>
                {responses['Sucção']['Sucção'].map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="succaoSuccao"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
            {values.succaoSuccao === '000676' && (
              <div className="fullInput animeLeft">
                <label>Se ausente, descrever</label>
                <FormField type="text" name="succaoSuccaoObs" />
              </div>
            )}
          </FormRow>

          <TitleSection>Mastigação</TitleSection>
          <FormRow>
            <div className="fullInput radioGroup">
              <label style={{ height: 25 }} />
              <RadioRow>
                {responses['Mastigação']['Mastigação'].map(element => (
                  <label key={element.TAG}>
                    <FormField
                      type={element.SYS_P_E_R_CHECKBOX === 'S' ? 'checkbox' : 'radio'}
                      name="mastigacaoMastigacao"
                      value={element.TAG}
                    />
                    <span
                      className={`checkMark ${element.SYS_P_E_R_CHECKBOX === 'S' ? '' : 'radio'}`}
                    ></span>
                    {element.SYS_P_E_R_RESPOSTA}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          {values.mastigacaoMastigacao === '000677' && (
            <div className="animeUp">
              <DefaultRadioRow
                label="Lado"
                responses={responses['Mastigação']['Lado']}
                name="mastigacaoLado"
              />
              <DefaultRadioRow
                label="Lábios"
                responses={responses['Mastigação']['Lábios']}
                name="mastigacaoLabios"
              />
              <DefaultRadioRow
                label="Ruído"
                responses={responses['Mastigação']['Ruído']}
                name="mastigacaoRuido"
              />
              <DefaultRadioRow
                label="Dor ou desconforto durante a mastigação"
                responses={responses['Mastigação']['Dor ou desconforto durante a mastigação']}
                name="mastigacaoDor"
              />
              {values.mastigacaoDor === '000685' && (
                <DefaultRadioRow
                  className="animeUp"
                  label="Caso às vezes"
                  responses={
                    responses['Mastigação']['Dor ou desconforto durante a mastigação (às vezes)']
                  }
                  name="mastigacaoDorAsVezes"
                />
              )}
              {values.mastigacaoDor === '000688' && (
                <DefaultRadioRow
                  className="animeUp"
                  label="Caso sim"
                  responses={
                    responses['Mastigação']['Dor ou desconforto durante a mastigação (sim)']
                  }
                  name="mastigacaoDorSim"
                />
              )}
              <DefaultRadioRow
                label="Ruído articular"
                responses={responses['Mastigação']['Ruído articular']}
                name="mastigacaoRuidoArticular"
              />
              {values.mastigacaoRuidoArticular === '000692' && (
                <DefaultRadioRow
                  className="animeUp"
                  label="Caso às vezes"
                  responses={responses['Mastigação']['Ruído articular - às vezes']}
                  name="mastigacaoRuidoArticularAsVezes"
                />
              )}
              {values.mastigacaoRuidoArticular === '000693' && (
                <DefaultRadioRow
                  className="animeUp"
                  label="Caso às sim"
                  responses={responses['Mastigação']['Ruído articular - sim']}
                  name="mastigacaoRuidoArticularSim"
                />
              )}
              <DefaultRadioRow
                label="Dificuldade mastigatória"
                responses={responses['Mastigação']['Dificuldade mastigatória']}
                name="mastigacaoDificuldade"
                obsLabel="Qual"
                obs
              />
              <DefaultRadioRow
                label="Escape de alimentos durante a mastigação"
                responses={responses['Mastigação']['Escape de alimentos durante a mastigação']}
                name="mastigacaoEscape"
              />
            </div>
          )}

          <TitleSection>Comunicação</TitleSection>
          <FormRow>
            <div className="fullInput">
              <legend style={{ top: -20 }}>{values.notes.length}/1000 caracteres</legend>
              <FormField
                as="textarea"
                name="notes"
                type="text"
                onChange={handleChange}
                maxLength={1000}
                placeholder="Descrever aspectos do desenvolvimento da linguagem oral, expressiva e fala"
              />
            </div>
          </FormRow>

          <FormRow>
            {patientInfos.active &&
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                  ) : (
                    'Enviar Dados'
                    )}
              </SubmitButton>
            }
          </FormRow>
        </Form>
      )}
    </Formik>
  );
}
