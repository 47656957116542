  import styled from 'styled-components';
import { Button } from '../../../../styles/Button';

export const Select = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;

  &.time {
    background-color: var(--red);
    padding: 5px 0;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    color: #fff;
    height: 50px;
  }

  > select {
    height: 50px;
    padding: 0 10px;
    margin: 0;
    margin-right: 15px;
    min-width: 10rem;
  }

  > input.time {
    text-align: center;
    padding: 0;
    margin-bottom: 0;
    height: 35px;
    width: 40px;
  }

  > select.number {
    text-align: center;
    padding: 0;
    margin: 0 10px;
    height: 35px;
    min-width: 3.5rem;
    width: 40px;
  }
`;

export const AttendanceRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  > label.procedureLabel {
    border-bottom: 3px solid var(--red);
    padding: 5px;
    height: 100%;
  }
`;

export const AddButton = styled(Button)`
  margin-right: auto;
  margin-left: 20px;
  min-height: 50px;
  height: 50px;
  padding: 0 20px;
`;

export const RadioRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => (props.mt ? '10px' : '0')};

  > label {
    min-width: ${props => (props.columns === 2 ? '48%' : '32%')};
    max-width: ${props => (props.columns === 2 ? '48%' : '32%')};
  }

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: var(--input-border);
  }
`;

export const EmptyContent = styled.span`
  margin-left: 20px;
  margin-top: 20px;
  color: #aaa;
  font-size: 1.4rem;
`;
