import styled from 'styled-components';

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: var(--yellow);
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;

  a,
  span {
    color: black;
    padding: 0.75rem;
    font-size: 0.9rem;

    text-decoration: none;
    display: block;

    text-align: center;
    transition-duration: 100ms;

    &:hover {
      transition-duration: 550ms;
      background-color: var(--red);
      color: #fff;
    }
  }

  div.subMenu {
    position: relative;
    display: flex;

    > a,
    span {
      width: 100%;
    }

    &:hover .arrow {
      color: var(--red);
      transition-duration: 100ms;
    }

    > .arrow {
      transition-duration: 550ms;
      position: absolute;
      right: 10px;
      top: 32%;
    }

    div.wrapper {
      display: none;
      left: 100%;
      top: 0;
      padding: 16px 0;
      padding-right: 50px;
      position: absolute;
      z-index: 1;

      > div.content {
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        margin-left: 10px;
        min-width: 150px;
        background-color: var(--yellow);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
      }
    }

    &:hover div.wrapper {
      display: block;
    }
  }
`;

export const DropDown = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${DropDownContent} {
    display: block;
  }

  width: ${props => (props.width < 800 ? '100%' : 'auto')};
  margin-right: ${props => (props.width < 800 ? 0 : '1rem')};
`;
