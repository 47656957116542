import React from 'react';

import Logo from '../../assets/logo-without-bg.png';
import addZero from '../../utils/addZero';
import { PrintHeaderContainer, PrintLogo } from './styles';

export default function PrintHeader({ currentUser, patientName }) {
  const date = new Date();

  return (
    <PrintHeaderContainer>
      <PrintLogo src={Logo} />
      <div>
        <h1>Associação PODE – Portadores de Direitos Especiais</h1>
        <p>Rua da Cachoeira, s/n, Centro</p>
        <p>CEP: 55.200.000 – Pesqueira – Pernambuco – Brasil</p>
        <p>Fone: 87 – 3835.1688</p>
        <p>Email: associacaopode2@yahoo.com.br</p>
        CNPJ: 06698790/0001-07
        {/* <p>Usuário: {currentUser.name}</p>
        <p>Paciente: {patientName && patientName.name}</p>
        <p>
          Data:{' '}
          {`${addZero(date.getDate())}/${addZero(
            date.getMonth() + 1
          )}/${date.getFullYear()}   ${addZero(date.getHours())}:${addZero(
            date.getMinutes()
          )}:${addZero(date.getSeconds())}`}
        </p> */}
        <br />
        <br />
        <p>Paciente: {patientName && patientName.name}</p>
        <p>
          Data:{' '}
          {`${addZero(date.getDate())}/${addZero(
            date.getMonth() + 1
          )}/${date.getFullYear()}   ${addZero(date.getHours())}:${addZero(
            date.getMinutes()
          )}:${addZero(date.getSeconds())}`}
        </p>
      </div>
    </PrintHeaderContainer>
  );
}
