import { Route, Routes } from 'react-router-dom';
import { Container } from '../Entries/style';
import AttendancesReport from './AttendancesReport';
import SocialData from './SocialData';
import Procedures from './Procedures';
import GeralProfiles from './GeralProfiles';
import GeralBeneficiaries from './GeralBeneficiaries';
import GeralDataBeneficiaries from './GeralDataBeneficiaries';
import QuantitativeStatement from './QuantitativeStatement';
import GeralFrequencyAmbu from './GeralFrequencyAmbu';
import QuantitativeStatementBeneficiaries from './QuantitativeStatementBeneficiaries';
import QuantitativeStatementDeficiency from './QuantitativeStatementDeficiency';
import GeneralHoursAmbu from './GeneralHoursAmbu';
import QuantitativeStatementDetailedAmbu from './QuantitativeStatementDetailedAmbu';
import HourDetailedAmbu from './HourDetailedAmbu';
import GeralBeneficiariesFrequency from './GeralBeneficieriesFrequency';

export function MenuReports() {
  return (
    <Container>
      <Routes>
        <Route path="/frequencia-atendimentos" element={<AttendancesReport />} />
        <Route path="/dados-sociais" element={<SocialData />} />
        <Route path="/resumo-procedimentos" element={<Procedures />} />
        <Route path="/geral/profile" element={<GeralProfiles />} />
        <Route path="/demonstrativo-quantitativo-deficiencia" element={<QuantitativeStatementDeficiency />} />
        <Route path="/pormenorizado-atendimento-profissional" element={<GeralBeneficiaries />} />
        <Route path="/pormenorizado-frequencia-profissional" element={<GeralBeneficiariesFrequency />} />
        <Route path="/demonstrativo-quantitativo-municipio-genero-idade-deficiencia" element={<QuantitativeStatement />} />
        <Route path="/demonstrativo-geral-frequencia-ambulatorial" element={<GeralFrequencyAmbu />} />
        <Route path="/demonstrativo-quantitativo-beneficiarios" element={<QuantitativeStatementBeneficiaries />} />
        <Route path="/dados-gerais-beneficiarios" element={<GeralDataBeneficiaries />} />
        <Route path="/horario-geral-atendimento-ambulatorial" element={<GeneralHoursAmbu />} />
        <Route path="/demonstrativo-pormenorizado-frequencia-ambulatorial" element={<QuantitativeStatementDetailedAmbu />} />
        <Route path="/horario-pormenorizado-atendimento-ambulatorial" element={<HourDetailedAmbu />} />
      </Routes>
    </Container>
  );
}
