import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { danger, success, warning } from '../../../../../../components/Alerts';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { GlobalContext } from '../../../../../../GlobalContext';
import { setTriagemEnfermagem } from '../../../../../../utils/api';

export default function FormEnfermagem({
  initialValues,
  selectedPatient,
  print,
  responses,
  setInitialValues,
  patientInfos
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      const array = selectedPatient.split('-');
      const obj = {
        TRI_ENF_PACIENTE: array[0],
        TRI_ENF_ANO: array[1],
        TRI_ENF_USUARIO: currentUser.id,
        TRI_ENF_SISTEMA_RESPIRATORIO: values.sistemaRespiratorio,
        TRI_ENF_SISTEMA_GASTRO_INTESTNL: values.sistemaGastrointestinal,
        TRI_ENF_SISTEMA_VESICL_INTESTNL: values.vesicalIntestinal,
        TRI_ENF_ESTOMIAS: values.estomias,
        TRI_ENF_DIETA: values.dieta,
        TRI_ENF_RESISTE_SER_ALIMENTADO: values.resisteAlimentado,
        TRI_ENF_NECESSIDADES_DOMICILIAR: values.necessidades.join('|'),
        TRI_ENF_PRESENCA_DE_ESCARAS: values.escaras,
        TRI_ENF_LOCAL_DAS_ESCARAS: values.escarasNotes,
        TRI_ENF_CONTROLA_URINA: values.controlaUrina,
        TRI_ENF_CONTROLA_FEZES: values.controlaFezes,
        TRI_ENF_TIPO_ALIMENTACAO_CONSIS: values.tipoAlimentacao.join('|'),
        TRI_ENF_SE_ALIMENTA_SOZINHO: values.alimentaSozinho,
        TRI_ENF_INTERCORRENCIAS: values.intercorrencias.join('|'),
        TRI_ENF_ALRGIA_INTLRANCIA_ALIME: values.alergia,
        TRI_ENF_ALRGIA_INTLRANCIA_QUAIS: values.alergiaNotes,
        TRI_ENF_FAZ_INGESTAO_MEDICAMENT: values.medicamentos,
        TRI_ENF_INGESTAO_MEDICAMNT_QUAL: values.medicamentosNotes,
        TRI_ENF_OBS: values.notes
      };
      setIsLoading(true);
      setTriagemEnfermagem(array[0], array[1], obj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Falha ao enviar dados: ${json.message}`);
        } else {
          success('Dados enviados com sucesso!');
          print();
          navigate('/user');
        }
      });
    }
  
  }

  useEffect(() => {
    !patientInfos.active && warning('Paciente inativo!')
  }, [patientInfos]);

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form disabled={!patientInfos.active}>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sistema respiratório</label>
              <RadioRow>
                  {/* <RadioOrCheck
                    response={{type: 'radio', value: "", name:"sistemaRespiratorio", label: 'Não definido'}}
                    setFieldValue={setFieldValue}
                  /> */}
                {responses.sistemaRespiratorio.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sistema gastrointestinal</label>
              <RadioRow>
                {responses.sistemaGastrointestinal.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Sistema vesical/intestinal</label>
              <RadioRow>
                {responses.vesicalIntestinal.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Estomias</label>
              <RadioRow>
                {responses.estomias.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Dieta</label>
              <RadioRow>
                {responses.dieta.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Resiste em ser alimentado</label>
              <RadioRow>
                {responses.resisteAlimentado.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Necessidades domiciliares</label>
              <RadioRow>
                {responses.necessidades.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label className="highlight">Presença de escaras</label>
              <RadioRow>
                {responses.escaras.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
            {values.escaras === '9901' && (
              <div className="animeLeft mediumInput">
                <label>Local/Região corporal</label>
                <FormField type="text" name="escarasNotes" />
              </div>
            )}
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Controla urina</label>
              <RadioRow>
                {responses.controlaUrina.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Controla fezes</label>
              <RadioRow>
                {responses.controlaFezes.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Tipo de alimentação (consistência)</label>
              <RadioRow>
                {responses.tipoAlimentacao.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Alimenta-se sozinho</label>
              <RadioRow>
                {responses.alimentaSozinho.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Intercorrências</label>
              <RadioRow>
                {responses.intercorrencias.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label className="highlight">Alergia/intolerância alimentar e ou medicamentosa</label>
              <RadioRow>
                {responses.alergia.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
            {values.alergia === '000110' && (
              <div className="animeLeft mediumInput">
                <label>Quais?</label>
                <FormField type="text" name="alergiaNotes" />
              </div>
            )}
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label className="highlight">Faz ingestão de medicamentos</label>
              <RadioRow>
                {responses.medicamentos.map(response => (
                  <RadioOrCheck
                    key={response.value}
                    response={response}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </RadioRow>
            </div>
            {values.medicamentos === '000124' && (
              <div className="animeLeft mediumInput">
                <label>Quais?</label>
                <FormField type="text" name="medicamentosNotes" />
              </div>
            )}
          </FormRow>

          <TitleSection>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <FormField
                as="textarea"
                name="notes"
                type="text"
                onChange={handleChange}
                maxLength={256}
              />
            </div>
          </FormRow>

          <FormRow>
            {patientInfos.active &&
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                  ) : (
                    'Enviar Dados'
                    )}
              </SubmitButton>
            }
          </FormRow>
        </Form>
      )}
    </Formik>
  );
}
