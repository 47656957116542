import React, { useContext, useEffect, useRef, useState } from 'react';
import Search from '../../../../../components/Search';
import { danger, info, warning } from '../../../../../components/Alerts';
import { getPatientsWithSector, getRespostas2, getTriagemFono } from '../../../../../utils/api';
import FormFonoaudiologia from './Components/FormFonoaudiologia';
import PrintFono from './Components/PrintFono';
import { useReactToPrint } from 'react-to-print';
import { PatientName } from '../../../../../components/PatientName';
import { GlobalContext } from '../../../../../GlobalContext';

export default function Fonoaudiologia() {
  const { currentUser } = useContext(GlobalContext);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [initialValues, setInitialValues] = useState({
    bochechaMucosa: [],
    bochechaTonus: [],
    bochechasMobilidade: '',
    bochechasObs: '',
    degluticaoDificuldade: '',
    degluticaoDificuldadeObs: '',
    degluticaoEngasgos: '',
    degluticaoEngasgosSeSim: [],
    degluticaoEscape: '',
    degluticaoEscapeObs: '',
    degluticaoOdinofagia: '',
    degluticaoOdinofagiaObs: '',
    degluticaoPigarro: '',
    degluticaoPigarroSeSim: '',
    degluticaoPigarroObs: '',
    degluticaoRefluxo: '',
    degluticaoRefluxoObs: '',
    degluticaoResiduos: '',
    degluticaoResiduosObs: '',
    degluticaoRuido: '',
    degluticaoRuidoObs: '',
    degluticaoTosse: '',
    degluticaoTosseObs: '',
    degluticaoVia: '',
    dentesClassificacaoDireito: '',
    dentesClassificacaoEsquerdo: '',
    dentesConservacaoDentaria: '',
    dentesConservacaoGengival: '',
    dentesDentadura: '',
    dentesFalha: '',
    dentesFalhaObs: '',
    dentesInferiorDireito: 0,
    dentesInferiorEsquerdo: 0,
    dentesLinha: '',
    dentesSuperiorDireito: 0,
    dentesSuperiorEsquerdo: 0,
    labiosMobilidade: '',
    labiosMucosa: '',
    labiosObs: '',
    labiosPosicao: '',
    linguaFrenulo: '',
    linguaLargura: '',
    mandibulaLateralidadeDireita: '',
    mandibulaLateralidadeEsquerda: '',
    linguaMucosa: '',
    linguaMucosaMarcadaLocal: '',
    linguaMucosaFeridaLocal: '',
    linguaObs: '',
    linguaPosicao: '',
    linguaSimetria: '',
    linguaSimetriaObs: '',
    linguaSulco: '',
    linguaTremor: '',
    mandibulaAbertura: '',
    mandibulaAberturaDesvio: [],
    mandibulaFechamento: '',
    mandibulaFechamentoDesvio: [],
    mandibulaPostura: '',
    mastigacaoDificuldade: '',
    mastigacaoLabios: '',
    mastigacaoDificuldadeObs: '',
    mastigacaoDor: '',
    mastigacaoDorAsVezes: [],
    mastigacaoDorSim: [],
    mastigacaoEscape: '',
    mastigacaoLado: [],
    mastigacaoMastigacao: '',
    mastigacaoRuido: '',
    mastigacaoRuidoArticular: '',
    mastigacaoRuidoArticularAsVezes: [],
    mastigacaoRuidoArticularSim: [],
    notes: '',
    palatoDuroFissura: '',
    palatoDuroFissuraTipo: '',
    palatoDuroFissuraLocalizacao: '',
    palatoDuroLargura: '',
    palatoDuroProfundidade: '',
    palatoMoleExtensao: '',
    palatoMoleFissura: '',
    palatoMoleFissuraTipo: '',
    palatoMoleFissuraLocalizacao: '',
    palatoMoleSimetria: '',
    palatoMoleUvula: '',
    respiracaoModo: '',
    respiracaoModoObs: '',
    respiracaoTipo: '',
    respiracaoTipoObs: '',
    succaoSuccao: '',
    succaoSuccaoObs: '',
    tipoFacialAngulo: '',
    tipoFacialPerfil: '',
    tipoFacialTipo: '',
    veuA: '',
    veuAObs: '',
    veuEliciar: '',
    veuEliciarObs: ''
  });
  const [responses, setResponses] = useState({});

  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    const data = patient.split('-');
    const patientData = patients.find(patient => patient.id == data[0] && patient.year == data[1]);
    setPatientInfos(patientData);
    !patientData.active && warning('Paciente inativo!');

    // if (Object.keys(responses).length < 17) {
    if (false) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      getTriagemFono(data[0], data[1]).then(res => console.log(res));
      setSelectedPatient(patient);
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getRespostas2('Triagem - Fono', '').then(json => {
      if (json.error) {
        danger('Erro ao resgatar respostas');
      } else {
        const categoriesNames = [];
        json.data.arrP_e_R.forEach(response => {
          if (!categoriesNames.includes(response.SYS_P_E_R_CATEG)) {
            categoriesNames.push(response.SYS_P_E_R_CATEG);
          }
        });
        const categories = {};
        categoriesNames.forEach(category => {
          categories[category] = {};
          const fieldNames = [];
          const filtered = json.data.arrP_e_R.filter(
            element => element.SYS_P_E_R_CATEG === category
          );
          filtered.forEach(element => {
            if (!fieldNames.includes(element.SYS_P_E_R_PERGUNTA)) {
              fieldNames.push(element.SYS_P_E_R_PERGUNTA);
            }
          });
          fieldNames.map(name => {
            const filteredFields = filtered.filter(
              response => response.SYS_P_E_R_PERGUNTA === name
            );
            categories[category][name] = filteredFields;
          });
        });
        setResponses(categories);
      }
    });

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Triagem - Fonoaudiologia</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <div style={{ display: 'none' }}>
            <PrintFono
              initialValues={initialValues}
              patients={patients}
              selectedPatient={selectedPatient}
              responses={responses}
              ref={printRef}
            />
          </div>
          <PatientName patientInfos={patientInfos} />

          <FormFonoaudiologia
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            responses={responses}
            print={print}
            setInitialValues={setInitialValues}
            patientInfos={patientInfos}
          />
        </div>
      )}
    </div>
  );
}
