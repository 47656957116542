import { Route, Routes } from 'react-router-dom';

import { Container } from './style';

import { MenuUsers } from './Users';
// import { UserRegister } from './Users/UserRegister';
// <Route path="register" element={<UserRegister />} />

import { MenuHealthProfessionals } from './HealthProfessionals';
import { MenuPatients } from './Patients';
import { MenuAccessProfiles } from './AccessProfiles';
import { MenuAreas } from './Areas';

const MainPage = () => <h1>Pagina Principal</h1>;

export function MenuEntries() {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="users/*" element={<MenuUsers />} />
        <Route path="patients/*" element={<MenuPatients />} />
        <Route path="health-professionals/*" element={<MenuHealthProfessionals />} />
        <Route path="access-profiles/*" element={<MenuAccessProfiles />} />
        <Route path="areas/*" element={<MenuAreas />} />
      </Routes>
    </Container>
  );
}
