/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactLoading from 'react-loading';

import { PatientName } from '../../../../components/PatientName';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { danger, info, success } from '../../../../components/Alerts';
import { FormRow, SubmitButton, TitleSection } from '../../Entries/Patients/styles';
import { AddButton, AttendanceRow, RadioRow, Select } from '../Therapeutic Project/styles';
import { getEvolution, setEvolution, getPatients, getProcedimentos, getPatientsWithSector } from '../../../../utils/api';
import { GlobalContext } from '../../../../GlobalContext';
import moment from 'moment';

export default function NewEvolution() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [current, setCurrent] = useState('');
  const [status, setStatus] = useState('0');
  const [evolution, setCurrentEvolution] = useState('');
  const [procedures, setProcedures] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [patientInfos, setPatientInfos] = useState({});
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, setCurrentUser, evolutionData, storeEvolution } = React.useContext(GlobalContext);
  const { id } = useParams();
  const [date, setDate] = useState(() => {
    if(evolutionData?.date?.includes('-')) {
      const array = evolutionData.date.split('-');
      return new Date(array[0], array[1] - 1, array[2]);
    } else {
      return new Date()
    }
  });
  const [hour, setHour] = useState('08:00');
  const [sectorOk, setSectorOk] = useState(evolutionData.sectorOk ? evolutionData.sectorOk : '0');
  const [procedureType, setProcedureType] = useState(null);
  const [procedureType2, setProcedureType2] = useState(null);
  const [AASI, setAASI] = useState(null);
  const navigate = useNavigate();

  function handleGetPatientInfos() {
    const data = patient.split('-');
    getEvolution(data[0], data[1]).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        setCurrent(patient.replace('-', '/'));
      }
    });
  }

  function handleSubmit() {
    if (!isLoading) {
      if (evolution.length < 1) {
        danger('É necessário preencher o campo de evolução!');
      } else if (status == '0' && selectedProcedures.length === 0) {
        danger('Adicione um procedimento para continuar!');
      }
       else {
        setIsLoading(true);
        let data;
        if (id) {
          data = id.split('-');
        } else {
          current ? current.split('/') : evolutionData.id.split('-');
        }
        const procedures = selectedProcedures.map(procedure => procedure.id);
        setEvolution(
          evolution,
          procedures,
          data[0],
          data[1],
          sectorOk,
          currentUser.id,
          evolutionData.date,
          evolutionData.hour,
          date,
          status
        ).then(json => {
          setIsLoading(false);
          if (json.error) {
            danger(`${json.message}`);
          } else {
            success('Dados enviados com sucesso!');
            navigate('/user/movements/evolution');
          }
        });
      }
    }
  }

  function handleAddProcedure() {
    const have = selectedProcedures.some(el => el.id == selectedProcedure);
    if (selectedProcedure && !have) {
      let array = [...selectedProcedures];
      const newAttendance = procedures.find(el => el.id == selectedProcedure);
      array.push(newAttendance);
      setSelectedProcedures(array);
    }
  }

  function handleRemoveProcedure(procedure) {
    let array = [...selectedProcedures];
    array.splice(array.indexOf(procedure), 1);
    setSelectedProcedures(array);
  }

  useEffect(() => {
    id && setCurrent(id);

    const current = localStorage.getItem('currentUser');
    current && setCurrentUser(JSON.parse(current));

    getPatientsWithSector(current.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });

        setPatients(arr);
        if (id) {
          setCurrent(id);
          setPatient(id);
          const array = id.split('-');
          setPatientInfos(arr.find(patient => patient.id == array[0] && patient.year == array[1]));
        } else {
          arr[0] ? setPatient(`${arr[0].id}-${arr[0].year}`) : info('Ainda não foi cadastrado nenhum paciente!');
        }
      }
    });

    getProcedimentos().then(json => {
      if (json.error) {
        danger(`Erro ao resgatar procedimentos: ${json.message}`);
      } else {
        const array = json.data.arrProcedimento.map(procedure => ({
          id: procedure[0],
          label: procedure[1]
        }));
        setProcedures(array);
      }
    });

    if (evolutionData.date) {
      setCurrentEvolution(evolutionData.label);
      setSectorOk(evolutionData.sectorOk);
      setStatus(`${evolutionData.status}`);
    }

    setDate(date?.toISOString().substring(0, 10));

    return () => storeEvolution({});
  }, []);

  useEffect(() => {
    if(status != 0) {
      setSelectedProcedures([]);
    }
  }, [status]);

  useEffect(() => {
    if (procedures.length > 0) {
      if (evolutionData.procedures) {
        let array = [];
        evolutionData.procedures.forEach(procedure => {
          array.push(procedures.find(item => item.id === procedure));
        });
        setSelectedProcedures(array);
      }
    }
  }, [procedures]);

  return (
    <div className="animeUp">
      {evolutionData.date ? (
        <h2 className="section">Editar Evolução</h2>
      ) : (
        <h2 className="section">Nova Evolução</h2>          
      )}

      {current || evolutionData.date ? (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />
          {current && (
            <TitleSection>
              {evolutionData.date ? 'Editar Evolução' : 'Nova Evolução'}: {current.padStart(8, '0')}
            </TitleSection>
        )}
          <FormRow>
            <div className="fullInput radioGroup">
            <RadioRow>
              <label onClick={() => setStatus('0')}>
                <input type="radio" checked={status == '0'} />
                <span className="checkMark radio"></span>
                Presente
              </label>
              <label onClick={() => setStatus('1')}>
                <input type="radio" checked={status == '1'} />
                <span className="checkMark radio"></span>
                Falta justificada
              </label>
              <label onClick={() => setStatus('2')}>
                <input type="radio" checked={status == '2'} />
                <span className="checkMark radio"></span>
                Falta não justificada
              </label>
            </RadioRow>
            </div>
          </FormRow>
      
          <FormRow>
            <div className="fullInput">
              <label htmlFor="evolution">Descrição</label>
              <legend>{evolution.length}/1000 caracteres</legend>
              <textarea
                maxLength={1000}
                type="text"
                id="evolution"
                autoFocus
                value={evolution}
                onChange={e => setCurrentEvolution(e.target.value)}
              />
            </div>
            <div className="smallInput">
              <label htmlFor="date">Data</label>
              <input type="date" id="date" autoFocus value={date} onChange={e => setDate(e.target.value)}
                max={moment().format('YYYY-MM-DD')} 
                min={moment().subtract(30, 'days').format('YYYY-MM-DD')} 
                onBlur={() => {
                  const array = date.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setDate(newDate);
                    }
                }}
              />
            </div>
            {/* <div className="numberInput">
              <label htmlFor="hour">Hora</label>
              <input type="time" id="hour" value={hour} onChange={e => setHour(e.target.value)} />
            </div> */}
            </FormRow>
            {status == '0' && 
              <>
                <TitleSection>Procedimentos</TitleSection>
                <FormRow style={{ marginBottom: 20 }}>
                  <Select>
                    <select onChange={e => setSelectedProcedure(e.target.value)} value={selectedProcedure}>
                      {procedures.map(procedure => (
                        <option key={procedure.id} value={procedure.id}>
                          {procedure.id}
                        </option>
                      ))}
                    </select>
                    <AddButton type="button" primary onClick={handleAddProcedure}>
                      <BsPlus size={30} color="#fff" />
                    </AddButton>
                  </Select>
                </FormRow>
                
                <FormRow>
                  <div className="fullInput animeDown radioGroup">
                    <div>
                      {selectedProcedures.map(procedure => {
                        return (
                          <AttendanceRow className="animeUp" key={procedure.id}>
                            <label className="procedureLabel">{procedure.label}</label>
                            <AddButton
                              type="button"
                              className="animeUp"
                              primary
                              onClick={() => handleRemoveProcedure(procedure)}
                            >
                              <BsTrash size={20} color="#fff" />
                            </AddButton>
                          </AttendanceRow>
                        );
                      })}
                    </div>
                  </div>
                </FormRow>

                {/* Validar se é setor audiologia */}
                {true &&
                  <>
                    {selectedProcedures.some(el => el.id === '02.11.07.015-7') &&
                      <>
                        <h2>02.11.07.015-7</h2>
                        <FormRow>
                          <div className="smallInput">
                            <label className="center">OD</label>
                          </div>
                          <div className="fullInput">
                            <select style={{ marginTop: 22 }}>
                              <option value="0">AUSENTE</option>
                              <option value="1">PRESENTE</option>
                              <option value="2">NÃO REALIZADO</option>
                            </select>
                          </div>
                        </FormRow>
                        <FormRow>
                          <div className="smallInput">
                            <label className="center">OE</label>
                          </div>
                          <div className="fullInput">
                            <select style={{ marginTop: 22 }}>
                              <option value="0">AUSENTE</option>
                              <option value="1">PRESENTE</option>
                              <option value="2">NÃO REALIZADO</option>
                            </select>
                          </div>
                        </FormRow>
                        <FormRow>
                          <div className="fullInput radioGroup">
                          <RadioRow>
                            <label onClick={() => setProcedureType('0')}>
                              <input type="radio" checked={procedureType == '0'} />
                              <span className="checkMark radio"></span>
                              Alterado
                            </label>
                            <label onClick={() => setProcedureType('1')}>
                              <input type="radio" checked={procedureType == '1'} />
                              <span className="checkMark radio"></span>
                              Normal
                            </label>
                          </RadioRow>
                          </div>
                        </FormRow>
                      </>
                    }
                    {selectedProcedures.some(el => el.id === '02.11.07.043-2') &&
                      <>
                        <h2>02.11.07.043-2</h2>
                        <FormRow>
                          <div className="fullInput radioGroup">
                          <RadioRow>
                            <label onClick={() => setProcedureType2('0')}>
                              <input type="radio" checked={procedureType2 == '0'} />
                              <span className="checkMark radio"></span>
                              Alterado
                            </label>
                            <label onClick={() => setProcedureType2('1')}>
                              <input type="radio" checked={procedureType2 == '1'} />
                              <span className="checkMark radio"></span>
                              Normal
                            </label>
                          </RadioRow>
                          </div>
                        </FormRow>
                      </>
                    }
                    {selectedProcedures.some(el => el.id === '02.11.07.004-1') &&
                      <>
                        <h2>02.11.07.004-1</h2>
                        <FormRow>
                          <div className="smallInput">
                            <label className="center">OD</label>
                          </div>
                          <div className="fullInput">
                            <select style={{ marginTop: 22 }}>
                              <option value="0">NORMAL</option>
                              <option value="1">PAC</option>
                              <option value="2">PAM</option>
                              <option value="3">PASN</option>
                            </select>
                          </div>
                        </FormRow>
                        <FormRow>
                          <div className="smallInput">
                            <label className="center">OE</label>
                          </div>
                          <div className="fullInput">
                            <select style={{ marginTop: 22 }}>
                              <option value="0">NORMAL</option>
                              <option value="1">PAC</option>
                              <option value="2">PAM</option>
                              <option value="3">PASN</option>
                            </select>
                          </div>
                        </FormRow>
                        <FormRow>
                          <div className="fullInput radioGroup">
                            <label>Indicação AASI</label>
                          <RadioRow>
                            <label onClick={() => setAASI('0')}>
                              <input type="radio" checked={AASI == '0'} />
                              <span className="checkMark radio"></span>
                              Sim
                            </label>
                            <label onClick={() => setAASI('1')}>
                              <input type="radio" checked={AASI == '1'} />
                              <span className="checkMark radio"></span>
                              Não
                            </label>
                          </RadioRow>
                          </div>
                        </FormRow>
                      </>
                    }
                  </>
                }
              </>
            }
          <SubmitButton style={{ height: 50, marginTop: 'auto', marginBottom: 'auto' }} onClick={handleSubmit} primary>
            {isLoading ? <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} /> : 'Enviar Dados'}
          </SubmitButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
