import React from 'react';
import { FormRow, TitleSection } from '../../../../Entries/Patients/styles';
import PrintHeader from '../../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { RadioRow } from '../../../Therapeutic Project/styles';

const PrintAudiologiaCrianca = React.forwardRef(
  ({ initialValues, selectedPatient, currentUser, responses, patients }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])

    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <h2 className="section" style={{ marginTop: 3 }}>
            Avaliação Audiológica - Criança
          </h2>
          <TitleSection>Histórico</TitleSection>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Antecedentes familiares e hereditários</label>
              <RadioRow>
                {responses.antecedentes.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput">
              {initialValues.antecedentes === '000005' && (
                <>
                  <label className="animeLeft">Se sim, especificar:</label>
                  <input className="animeLeft" value={initialValues.antecedentesNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Abortos da genitora</label>
              <RadioRow>
                {responses.abortosGenitora.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.abortosGenitora === '000001' && (
                <>
                  <label className="animeLeft">Se sim, quantos:</label>
                  <input
                    style={{ width: 80, textAlign: 'center' }}
                    value={initialValues.abortosQuantidade}
                  />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Filhos vivos</label>
              <RadioRow>
                {responses.filhosVivos.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.filhosVivos === '000028' && (
                <>
                  <label className="animeLeft">Se sim, quantos:</label>
                  <input
                    style={{ width: 80, textAlign: 'center' }}
                    value={initialValues.filhosQuantidade}
                  />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Natimorto</label>
              <RadioRow>
                {responses.natimorto.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.natimorto === '000048' && (
                <>
                  <label className="animeLeft">Se sim, quantos:</label>
                  <input
                    style={{ width: 80, textAlign: 'center' }}
                    value={initialValues.natimortoQuantidade}
                  />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Consanguinidade</label>
              <RadioRow>
                {responses.consanguinidade.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput" />
          </FormRow>
          <TitleSection>Fatores pré-natal</TitleSection>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Alergias</label>
              <RadioRow>
                {responses.alergias.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.alergias === '000003' && (
                <>
                  <label className="animeLeft">Se sim, qual(is)?</label>
                  <input value={initialValues.alergiasQuais} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Gripes</label>
              <RadioRow>
                {responses.gripes.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.gripes === '000032' && (
                <>
                  <label className="animeLeft">Se sim, em qual período da gestação?</label>
                  <input value={initialValues.gripesNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Hemorragias</label>
              <RadioRow>
                {responses.hemorragias.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.hemorragias === '000036' && (
                <>
                  <label className="animeLeft">Se sim, em qual período da gestação?</label>
                  <input value={initialValues.hemorragiasNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Realizou Raio X</label>
              <RadioRow>
                {responses.raioX.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.raioX === '000056' && (
                <>
                  <label className="animeLeft">Se sim, em qual período da gestação?</label>
                  <input value={initialValues.raioXNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow style={{ marginTop: 50, paddingTop: 10 }}>
            <div className="mediumInput radioGroup">
              <label className="highlight">Diabetes gestacional</label>
              <RadioRow>
                {responses.diabetesGestacional.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.diabetesGestacional === '000022' && (
                <>
                  <label className="animeLeft">Se sim, em qual período da gestação?</label>
                  <input value={initialValues.diabetesGestacionalNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">COVID-19</label>
              <RadioRow>
                {responses.covid19.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.covid19 === '000011' && (
                <>
                  <label className="animeLeft">Se sim, em qual período da gestação?</label>
                  <input value={initialValues.covid19Note} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Hipertensão arterial:</label>
              <RadioRow>
                {responses.hipertensao.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.hipertensao === '000038' && (
                <>
                  <label className="animeLeft">Se sim, em qual período da gestação?</label>
                  <input value={initialValues.hipertensaoNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow style={{ marginBottom: 20 }}>
            <div className="mediumInput radioGroup">
              <label className="highlight">Tentativa de aborto</label>
              <RadioRow>
                {responses.aborto.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.aborto === '000068' && (
                <>
                  <label className="animeLeft">Se sim, tipo:</label>
                  <input value={initialValues.tentativaAbortoNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Rubéola</label>
              <RadioRow>
                {responses.rubeola.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Infecção urinaria</label>
              <RadioRow>
                {responses.infeccaoUrinaria.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Sífilis</label>
              <RadioRow>
                {responses.sifilis.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Toxoplasmose</label>
              <RadioRow>
                {responses.toxoplasmose.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">HIV</label>
              <RadioRow>
                {responses.hiv.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow style={{ marginTop: 20 }}>
            <div className="fullInput">
              <label className="highlight">Medicação utilizada durante a gestação</label>
              <input type="text" value={initialValues.medicacao} />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Outras doenças</label>
              <RadioRow>
                {responses.outrasDoencasPreNatal.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput">
              {initialValues.outrasDoencasPreNatal === '000052' && (
                <>
                  <label className="animeLeft">Se sim, qual(is):</label>
                  <input value={initialValues.outrasDoencasNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <TitleSection>Fatores Perinatais</TitleSection>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Tipo de parto</label>
              <RadioRow>
                {responses.tipoParto.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              <label>Tempo de gestação em semanas</label>
              <input style={{ textAlign: 'center' }} value={initialValues.tempoGestacao} />
            </div>
            <div className="mediumInput">
              <label>Peso ao nascer</label>
              <input style={{ textAlign: 'center' }} value={initialValues.pesoNascer} />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label className="highlight">APGAR</label>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ width: '31%' }}>
                  <label>1m:</label>
                  <input style={{ textAlign: 'center' }} value={initialValues.APGAR1} />
                </div>
                <div style={{ width: '31%' }}>
                  <label>5m:</label>
                  <input style={{ textAlign: 'center' }} value={initialValues.APGAR5} />
                </div>
                <div style={{ width: '31%' }}>
                  <label>PC:</label>
                  <input style={{ textAlign: 'center' }} value={initialValues.APGARPC} />
                </div>
              </div>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Demorou pra chorar</label>
              <RadioRow>
                {responses.demorouChorar.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow style={{ paddingTop: 10 }}>
            <div className="fullInput radioGroup">
              <label className="highlight">Outros fatores ocorridos</label>
              <RadioRow>
                {responses.outrosFatores.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Apresentou malformação congênita</label>
              <RadioRow>
                {responses.malformacao.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput">
              {initialValues.malformacao === '000007' && (
                <>
                  <label className="animeLeft">Se sim, qual(is)?</label>
                  <input value={initialValues.malformacaoNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Foto terapia</label>
              <RadioRow>
                {responses.fotoTerapia.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput">
              {initialValues.fotoTerapia === '000030' && (
                <>
                  <label className="animeLeft">Se sim, quanto tempo?</label>
                  <input value={initialValues.fotoTerapiaraNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Incubadora</label>
              <RadioRow>
                {responses.incubadora.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput">
              {initialValues.incubadora === '000042' && (
                <>
                  <label className="animeLeft">Se sim, quanto tempo?</label>
                  <input value={initialValues.incubadoraNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Ventilação mecânica</label>
              <RadioRow>
                {responses.ventilacaoMecanica.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            {initialValues.ventilacaoMecanica === '000080' ? (
              <>
                <div className="mediumInput animeLeft">
                  <label>Se sim, qual o tipo?</label>
                  <input value={initialValues.ventilacaoMecanicaTipo} type="text" />
                </div>
                <div className="mediumInput animeLeft">
                  <label>Duração</label>
                  <input value={initialValues.ventilacaoMecanicaDuracao} type="text" />
                </div>
              </>
            ) : (
              <div className="largeInput" />
            )}
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Sonda alimentar</label>
              <RadioRow>
                {responses.sondaAlimentar.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput">
              {initialValues.sondaAlimentar === '000062' && (
                <>
                  <label className="animeLeft">Se sim, qual o tempo de permanência?</label>
                  <input value={initialValues.sondaAlimentarNote} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Icterícia</label>
              <RadioRow>
                {responses.ictericia.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput" />
          </FormRow>
          <TitleSection>Fatores pós-natal</TitleSection>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Bebê molinho</label>
              <RadioRow>
                {responses.bebeMolinho.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Dificuldade para sugar</label>
              <RadioRow>
                {responses.dificuldadeSugar.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Engasgo ao engolir</label>
              <RadioRow>
                {responses.engasgo.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Meningite</label>
              <RadioRow>
                {responses.meningite.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Caxumba</label>
              <RadioRow>
                {responses.caxumba.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Sarampo</label>
              <RadioRow>
                {responses.sarampo.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput radioGroup">
              <label className="highlight">Otites</label>
              <RadioRow>
                {responses.otites.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Trauma físico</label>
              <RadioRow>
                {responses.traumaFisico.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput radioGroup">
              <label className="highlight">Trauma acústico</label>
              <RadioRow>
                {responses.traumaAcustico.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow style={{ marginTop: 20 }}>
            <div className="mediumInput radioGroup">
              <label className="highlight">Síndrome</label>
              <RadioRow>
                {responses.sindrome.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.sindrome === '000066' && (
                <>
                  <label className="animeLeft">Se sim, qual(is)?</label>
                  <input value={initialValues.sindromeQuais} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <br/>
          <br/>
          <br/>
          <FormRow style={{ marginBottom: 20 }}>
            <div className="mediumInput radioGroup">
              <label className="highlight">Outras doenças</label>
              <RadioRow>
                {responses.outrasDoencasPosNatal.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="largeInput animeLeft">
              {initialValues.outrasDoencasPosNatal === '000054' && (
                <>
                  <label className="animeLeft">Se sim, qual(is)?</label>
                  <input value={initialValues.outrasDoencasPosNatalQuais} type="text" />
                </>
              )}
            </div>
          </FormRow>
          <TitleSection style={{ paddingTop: 10 }}>Informações Complementares</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea value={initialValues.notes} readOnly />
            </div>
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintAudiologiaCrianca;
