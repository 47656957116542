import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  /* align-items: center; */

  select {
    width: 55%;
    margin-bottom: 0;
    margin-left: 20px;
    border: 2px solid var(--red);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:hover,
    &:focus {
      /* border-color: none; */
      box-shadow: none;
    }
  }

  button {
    border-radius: 0px 10px 10px 0;
    /* border-radius: 10px; */
  }

  img {
    width: 35px;
    height: 35px;
  }
`;

export const SearchWrapper = styled.div`
  background-color: var(--red);
  display: flex;
  padding: 2px;
  border-radius: 10px;
  width: 100%;

  > div.alta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    > label {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      margin-bottom: 0;

      > span {
        /* background-color: var(--red); */
        border-color: var(--input-border);
      }
    }
  }
`;

export const SearchInput = styled.input`
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-radius: 7px;
  flex: 1;
`;

export const SearchButton = styled.a`
  background-color: var(--red);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;
  /* border-top-right-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
  transition: filter 0.25s;

  &:hover {
    transition: filter 0.25s;
    filter: brightness(1.2);
  }
`;

export const OptionsFilter = styled.div`
  display: flex;
  margin-right: 20px;
  gap: 1rem;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    /* span {
      min-width: 20px;
      min-height: 20px;
    } */
  }
`;