import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { success, danger } from './components/Alerts';

import { capitalize, validateEmail } from './utils/validators';
import { login, getSpecialties } from './utils/api';

export const GlobalContext = React.createContext();

export const GlobalStorage = ({ children }) => {
  const [error, setError] = React.useState('');
  const [register, setRegister] = React.useState('');
  const [specialtiesData, setSpecialtiesData] = React.useState([]);
  const [specialityData, storeSpeciality] = React.useState({ cod: '', description: '' });
  const [areaData, storeArea] = React.useState({});
  const [patientData, storePatient] = useState({});
  const [userData, storeUser] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [evolutionData, storeEvolution] = useState({});
  const [actions, setActions] = useState([]);
  const [load, setLoad] = React.useState(false);
  const navigate = useNavigate();

  async function userLogin(inputValue) {
    try {
      setError('');
      setLoad(true);
      if (inputValue.email.length <= 0 || !validateEmail(inputValue.email)) {
        throw new Error('O campo e-mail deve ser preenchido com um e-mail valido.');
      }
      if (inputValue.password.length <= 0) {
        throw new Error('O campo senha deve ser preenchido.');
      }

      const result = await login(inputValue);
      if (result.error) {
        throw new Error(`${result.message}`);
      } else {
        localStorage.setItem('login', JSON.stringify(inputValue.email))
        const array = result.data.setr.split('-');
        setCurrentUser({
          id: result.data.codg,
          name: result.data.nome,
          sector: {
            value: array[0],
            label: array[1]
          } 
        });
        localStorage.setItem('currentUser',
          JSON.stringify({
            id: result.data.codg,
            name: result.data.nome,
            sector: {
              value: array[0],
              label: array[1]
            } 
          })
        );
        // PERMISSIONS
        setActions(
          result.data.perm[0].ACOES.map(action => ({
            id: action.PER_ACAO,
            label: action.ACA_DESCRITIVO
          }))
        );
        navigate('/user');
        success(`Seja bem vindo ${capitalize(result.data.nome)}`);
      }
    } catch (err) {
      return setError(err.message);
    } finally {
      return setLoad(false);
    }
  }

  async function checkUser() {
    try {
      setError('');
      setLoad(true);
      const loginData = await localStorage.getItem('login');
      if (!loginData || loginData.length <= 0) {
        danger('É necessário fazer o login');
        navigate('/');
      }
    } catch (err) {
      return setError(err.message);
    } finally {
      return setLoad(false);
    }
  }

  async function registerType(type) {
    try {
      setError('');
      setRegister(type);
    } catch (err) {
      return setError(err.message);
    } finally {
      return setLoad(false);
    }
  }

  async function loadSpecialties() {
    try {
      setLoad(true);
      getSpecialties().then(json => {
        if (json.error) {
          danger(`${json.message}`);
        } else {
          const newArr = [];
          json.data.setores.forEach(setor => {
            const specialtyObj = {
              id: setor[0],
              description: setor[1]
            };
            console.log(specialityData);
            newArr.push(specialtyObj);
          });
          setSpecialtiesData(newArr);
        }
      });
    } catch (err) {
      console.warn(err.message);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    !currentUser.id && navigate('/');
  }, [currentUser]);

  return (
    <GlobalContext.Provider
      value={{
        error,
        load,
        userLogin,
        checkUser,
        register,
        registerType,
        specialtiesData,
        loadSpecialties,
        specialityData,
        storeSpeciality,
        patientData,
        storePatient,
        userData,
        storeUser,
        modalIsOpen,
        setModalIsOpen,
        currentUser,
        setCurrentUser,
        evolutionData,
        storeEvolution,
        areaData,
        storeArea,
        actions
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
