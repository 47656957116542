import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto; //centralizado
  padding: 2rem;
  margin: 1rem 0;
  border-radius: 1rem;
  /* border: 3px solid var(--red); */
  min-height: 35vh;
  background-color: var(--white);

  h1 {
    margin-bottom: 1rem;
  }

  h1::after {
    content: '';
    content: '';
    display: block;
    position: absolute;
    margin: -2.6rem -2rem;
    background: var(--red);
    height: 3rem;
    width: 2rem;
    border-radius: 0 5px 5px 0;
  }
`;

export const HeaderEntries = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  color: var(--text-body);
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  margin-top: 20px;

  img {
    width: 30px;
  }
`