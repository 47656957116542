import React, { useContext } from 'react';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { HiUserRemove } from 'react-icons/hi';

import RadioOrCheck from '../../../../../components/RadioOrCheck';
import { RemoveButton, FamiliarInput, FamiliarType } from '../styles';
import { FormRow, TitleSection } from '../../../Entries/Patients/styles';
import {
  AddButton,
  AttendanceRow,
  EmptyContent,
  RadioRow,
  Select
} from '../../Therapeutic Project/styles';
import addZero from '../../../../../utils/addZero';
import { PrintContainer } from '../../../../../components/PrintHeader/styles';
import { GlobalContext } from '../../../../../GlobalContext';
import PrintHeader from '../../../../../components/PrintHeader';

const PrintRevaluation = React.forwardRef(
  (
    {
      initialValues,
      selectedProfessionals2,
      selectedProfessionals,
      selectedAttendances,
      selectedFamiliars,
      selectedProfessional,
      selectedFamiliar,
      selectedAttendance,
      responses,
      selectedPatient,
      patients,
      frequencies
    },
    ref
  ) => {
    const { currentUser } = useContext(GlobalContext);
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    const currentDate = new Date();
    const hours = [
      { value: '08', label: '8' },
      { value: '09', label: '9' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
      { value: '13', label: '13' },
      { value: '14', label: '14' },
      { value: '15', label: '15' },
      { value: '16', label: '16' },
      { value: '17', label: '17' },
      { value: '18', label: '18' }
    ];
    const minutes = [
      { value: '00', label: '00' },
      { value: '05', label: '05' },
      { value: '10', label: '10' },
      { value: '15', label: '15' },
      { value: '20', label: '20' },
      { value: '25', label: '25' },
      { value: '30', label: '30' },
      { value: '35', label: '35' },
      { value: '40', label: '40' },
      { value: '45', label: '45' },
      { value: '50', label: '50' },
      { value: '55', label: '55' }
    ];
    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolveu/aprimorou aspectos individuais</label>
              <RadioRow>
                {responses.aspectosIndividuais.map(response => (
                  <RadioOrCheck initialValues={initialValues} response={response} isPrint />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <div className="bottonLine" />
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">
                Desenvolveu/aprimorou a execução das atividades de vida diária
              </label>
              <RadioRow>
                {responses.atividadesDiarias.map(response => (
                  <RadioOrCheck initialValues={initialValues} response={response} isPrint />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolveu/aprimorou a organização familiar</label>
              <RadioRow>
                {responses.organizacaoFamiliar.map(response => (
                  <RadioOrCheck initialValues={initialValues} response={response} isPrint />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolveu/aprimorou a saúde</label>
              <RadioRow>
                {responses.saude.map(response => (
                  <RadioOrCheck initialValues={initialValues} response={response} isPrint />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolveu/aprimorou a condição emocional</label>
              <RadioRow>
                {responses.situacaoEmocional.map(response => (
                  <RadioOrCheck initialValues={initialValues} response={response} isPrint />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label className="highlight">Desenvolveu/aprimorou fatores sociais</label>
              <RadioRow>
                {responses.fatoresSociais.map(response => (
                  <RadioOrCheck initialValues={initialValues} response={response} isPrint />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Observações</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea value={initialValues.notes} readOnly />
            </div>
          </FormRow>

          <TitleSection>Situação</TitleSection>
          <FormRow style={{ marginBottom: 20, flexDirection: 'column' }}>
            <label className="highlight">Responsáveis pela alta</label>
            {selectedProfessionals.length > 0 ? (
              selectedProfessionals.map(professional => {
                return (
                  <AttendanceRow key={professional.id} className="animeUp">
                    <label style={{ minWidth: 280 }}>- {professional.name}</label>
                  </AttendanceRow>
                );
              })
            ) : (
              <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
            )}
          </FormRow>
          <FormRow style={{ marginBottom: 20, flexDirection: 'column' }}>
            <label style={{ width: 130 }}>Será atendido por</label>
            {selectedAttendances.length > 0 ? (
              selectedAttendances.map(attendance => {
                const frequency = frequencies.findIndex(freq => freq.id == attendance.frequency);
                return (
                  <AttendanceRow key={attendance.id} className="animeUp">
                    <label style={{ minWidth: 200 }}>{attendance.label}</label>
                    <Select className="animeLeft">
                      <select>
                        <option>{frequencies[frequency]?.label || 'Frequência'}</option>
                      </select>
                    </Select>
                    <Select>
                      <select value={attendance.day}>
                        <option value={2}>Segunda</option>
                        <option value={3}>Terça</option>
                        <option value={4}>Quarta</option>
                        <option value={5}>Quinta</option>
                        <option value={6}>Sexta</option>
                        <option value={7}>Sábado</option>
                        <option value={1}>Domingo</option>
                      </select>
                    </Select>
                    <Select className="time animeLeft">
                      <select className="number">
                        <option>{attendance.initialHour}</option>
                      </select>
                      :
                      <select className="number">
                        <option>{attendance.initialMinute}</option>
                      </select>
                    </Select>
                    até
                    <Select className="time animeLeft">
                      <select className="number">
                        <option>{attendance.finalHour}</option>
                      </select>
                      :
                      <select className="number">
                        <option>{attendance.finalMinute}</option>
                      </select>
                    </Select>
                  </AttendanceRow>
                );
              })
            ) : (
              <EmptyContent className="animeUp">Nenhum Atendimento Adicionado</EmptyContent>
            )}
          </FormRow>

          <TitleSection>Previsão de reavaliação do projeto terapêutico</TitleSection>
          <FormRow>
            <div className="smallInput">
              <input value={initialValues.previsao} type="text" readOnly />
            </div>
            <div className="mediumInput" />
            <div className="mediumInput" />
          </FormRow>

          <TitleSection>Componentes Familiares que Participaram</TitleSection>
          <FormRow>
            {selectedFamiliars.length > 0 ? (
              selectedFamiliars.map(familiar => (
                <AttendanceRow className="animeUp" style={{ maxHeight: 50 }}>
                  <div className="smallInput">
                    <FamiliarType>{familiar.label}</FamiliarType>
                  </div>
                  <div className="mediumInput" style={{ marginRight: 20 }}>
                    <FamiliarInput type="text" value={familiar.name} readyOnly />
                  </div>
                  <div className="mediumInput">
                    <span>______________________________________________</span>
                  </div>
                </AttendanceRow>
              ))
            ) : (
              <EmptyContent>Sem Familiares adicionados</EmptyContent>
            )}
          </FormRow>
          <FormRow style={{ marginTop: 30 }}>
            <div className="smallInput animeDown radioGroup">
              <label htmlFor="">Alta de setor</label>
              <RadioRow>
                <label>
                  <input type="radio" name="alta" value={`0`} checked={initialValues.alta == '0'} />
                  <span className="checkMark"></span>Não
                </label>
                <label>
                  <input type="radio" name="alta" value={`1`} checked={initialValues.alta == '1'} />
                  <span className="checkMark"></span>Sim
                </label>
              </RadioRow>
            </div>
          </FormRow>
          <TitleSection>Profissionais que participaram</TitleSection>
          <FormRow style={{ flexDirection: 'column' }}>
            {selectedProfessionals2.length > 0 ? (
              selectedProfessionals2.map(professional => (
                <AttendanceRow style={{ maxHeight: 50 }}>
                  <div className="mediumInput">
                    <FamiliarInput
                      style={{ marginLeft: -5 }}
                      type="text"
                      value={professional.name}
                      readyOnly
                    />
                  </div>
                  <div className="mediumInput">
                    <span>______________________________________________</span>
                  </div>
                  <div className="mediumInput" />
                </AttendanceRow>
              ))
            ) : (
              <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
            )}
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default PrintRevaluation;
