import styled from 'styled-components';
import { Button } from '../../../../styles/Button';

export const ModalContent = styled.div`
  max-height: 50vh;

  > h1 {
    text-align: center;
    margin-bottom: 20px;
    background-color: var(--red);
    color: #fff;
    border-radius: 5px;
    padding: 5px 0;
  }

  > label {
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
  } 
`

export const ThinButton = styled(Button)`
  padding: 10px;
  min-height: 0;
  margin-bottom: 10px;
`