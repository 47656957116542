import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { danger, success } from '../../../../../../components/Alerts';
import { useNavigate } from 'react-router';
import ReactLoading from 'react-loading';
import {
  FormField,
  FormRow,
  SubmitButton,
  TitleSection
} from '../../../../Entries/Patients/styles';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import { RadioRow, Select } from '../../../Therapeutic Project/styles';
import { setTriagemPsicologia } from '../../../../../../utils/api';

export default function FormPsicologia({
  initialValues,
  selectedPatient,
  currentUser,
  print,
  responses,
  patientInfos,
  frequencies,
  isPrint,
  setInitialValues
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      if (
        false
        // !values.alteracaoSono ||
        // (values.pesadelosRecorrentes === '000411' &&
        //   values.pesadelosPeriodicidade === 'Frequência') ||
        // (values.pesadelosRecorrentes === '000411' && !values.pesadelosPeriodicidade)
      ) {
        danger('Preencha os campos corretamente!');
      } else {
        setIsLoading(true);
        const data = selectedPatient.split('-');
        const objPsi = {
          TRI_PSI_ALTERACAO_DE_CONDUTA: values.alteracaoConduta || '',
          TRI_PSI_ALTERACAO_DO_SONO: values.alteracaoSono,
          TRI_PSI_ANO: data[1],
          TRI_PSI_COMPORTAMENTOS: values.comportamentos.join('|'),
          TRI_PSI_HUMOR_FREQUENTE: values.humorFrequente.join('|'),
          TRI_PSI_INTERACAO_SOCIAL: values.interacaoSocial.join('|'),
          TRI_PSI_OBS: values.notes || '',
          TRI_PSI_PACIENTE: data[0],
          TRI_PSI_PESADELOS_FREQUENCIA: values.pesadelosPeriodicidade
            ? values.pesadelosPeriodicidade
            : null,
          TRI_PSI_PESADELOS_RECORRENTES: values.pesadelosRecorrentes,
          TRI_PSI_PROBLEMAS_REL_FAMILIAR: values.problemasFamiliar || '',
          TRI_PSI_USUARIO: currentUser.id
        };
        setTriagemPsicologia(data[0], data[1], objPsi).then(json => {
          setIsLoading(false);
          if (json.error) {
            danger(`${json.message}`);
          } else {
            success('Dados enviados com sucesso!');
            print();
            navigate('/user');
          }
        });
      }
    }
  }

  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!patientInfos.active}>
            <TitleSection>Avaliação Psicológica</TitleSection>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Humor frequente</label>
                <RadioRow>
                  {responses.humorFrequente.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Interação social</label>
                <RadioRow>
                  {responses.interacaoSocial.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Comportamentos</label>
                <RadioRow>
                  {responses.comportamentos.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <FormRow>
              <div className="smallInput animeDown radioGroup">
                <label className="highlight">Alteração do sono</label>
                <RadioRow>
                  {responses.alteracaoSono.map((response, index) => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              {/* <div className="mediumInput" /> */}
            </FormRow>

            <FormRow>
              <div className="smallInput radioGroup">
                <label className="highlight">Pesadelos recorrentes</label>
                <RadioRow>
                  {responses.pesadelosRecorrentes.map((response, index) => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
              {values.pesadelosRecorrentes === '000411' && (
                <Select className="animeLeft" style={{ marginRight: 'auto' }}>
                  <FormField
                    as="select"
                    id="pesadelosPeriodicidade"
                    name="pesadelosPeriodicidade"
                    type="select"
                    onChange={handleChange}
                    value={values.pesadelosPeriodicidade}
                  >
                    {frequencies.map(frequency => (
                      <option key={frequency.id} value={frequency.id}>
                        {frequency.label}
                      </option>
                    ))}
                  </FormField>
                </Select>
              )}
            </FormRow>

            <FormRow>
              <div className="fullInput">
                <label htmlFor="">Alteração de conduta</label>
                {!isPrint ? (
                  <FormField
                    as="textarea"
                    name="alteracaoConduta"
                    type="text"
                    onChange={handleChange}
                    maxLength={256}
                  />
                ) : (
                  <textarea value={values.alteracaoConduta} />
                )}
              </div>
              <div className="fullInput">
                <label htmlFor="">Problemas na relação familiar/interpessoal</label>
                {!isPrint ? (
                  <FormField
                    as="textarea"
                    name="problemasFamiliar"
                    type="text"
                    onChange={handleChange}
                    maxLength={256}
                  />
                ) : (
                  <textarea value={values.problemasFamiliar} />
                )}
              </div>
            </FormRow>

            <TitleSection>Informações Complementares</TitleSection>
            <FormRow>
              <div className="fullInput">
                {!isPrint ? (
                  <FormField
                    as="textarea"
                    id="notes"
                    name="notes"
                    type="text"
                    onChange={handleChange}
                    maxLength={256}
                  />
                ) : (
                  <textarea value={values.notes} />
                )}
              </div>
            </FormRow>

            {patientInfos.active &&
              <FormRow>
                {/* <SubmitButton border type="button" onClick={() => print()}>
                      Imprimir
                    </SubmitButton> */}
                {!isPrint && (
                  <SubmitButton primary type="submit">
                    {isLoading ? (
                      <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                    ) : (
                      'Enviar Dados'
                    )}
                  </SubmitButton>
                )}
              </FormRow>
            }
          </Form>
        );
      }}
    </Formik>
  );
}
