import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { Option } from './styles';

export default function SubCheck({motivation, value, setValue, label}) {
  return (
    <Option
      className="animeLeft"
      onClick={() => setValue(value)}
    >
      <IoIosArrowForward color="#C61E24"/>
      <input name="motivation2" type="radio" checked={motivation == value}/>
      <span className="checkMark" />
      {label}
    </Option>
  )
}