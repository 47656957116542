import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Formik, Form, Field } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import { messageTranslate } from '../../../../utils/validators';

import { Error } from '../../../../styles/GlobalComponents';
import { Title } from '../style';
import { GlobalContext } from '../../../../GlobalContext';
import { getAreas, getProfiles, setUser } from '../../../../utils/api';
import { danger, success } from '../../../../components/Alerts';
import { FormRow, SubmitButton } from '../Patients/styles';
import { useNavigate } from 'react-router';
import { Row } from '../../Movements/Closing/styles';
import Switch from 'rc-switch';

const schema = Yup.object().shape({
  name: Yup.string().max(64, messageTranslate.maxChar(64)).required(messageTranslate.otherRequired),
  login: Yup.string()
    .max(64, messageTranslate.maxChar(64))
    .required(messageTranslate.otherRequired),
  email: Yup.string().email(),
  phone: Yup.string(),
  password: Yup.string()
    .required(messageTranslate.otherRequired)
    .max(16, messageTranslate.maxChar(16))
});

export function UserRegister() {
  const { userData, storeUser, specialtiesData } = useContext(GlobalContext);
  const [profiles, setProfiles] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [isProfessional, setIsProfessional] = useState(false);
  const [isVoluntary, setIsVoluntary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(values, resetForm) {
    if (!isLoading) {
      const obj = {
        ...values,
        isProfessional: isProfessional ? 1 : 0,
        isVoluntary: isVoluntary ? 1 : 0,
      };
      setIsLoading(true);
      setUser(obj).then(json => {
        if (json.error) {
          danger(
            `Ocorreu um erro ao ${userData.id ? 'editar' : 'cadastrar'} usuário! ${json.message}`
          );
          setIsLoading(false);
        } else {
          setInitialValues({
            code: '',
            name: '',
            login: '',
            email: '',
            phone: '',
            password: '',
            profile: '',
            area: '',
            class: ''
          });
          success(`Usuário ${userData.USR_CODIGO ? 'editado' : 'cadastrado'} com sucesso!`);
          resetForm();
          setIsLoading(false);
          storeUser({});
          navigate('/user/entries/users');
        }
      });
    }
  }

  useEffect(() => {
    getProfiles().then(json => {
      if (json.error) {
        danger('Ocorreu um erro ao resgatar os perfis de acesso!');
      } else {
        let arr = [];
        json.data.arrPerfil.forEach(profile => {
          arr.push({
            id: profile[0],
            label: profile[1]
          });
        });
        setProfiles(arr);
        setInitialValues(old => ({...old, profile: arr[0].id}));
        getAreas().then(json => {
          if (json.error) {
            danger('Ocorreu um erro ao resgatar as profissões');
          } else {
            let arr = [];
            json.data.arrAreas.forEach(area => {
              arr.push({
                id: area.ARE_CODIGO,
                label: area.ARE_DESCRITIVO
              });
              setSpecialities(arr);
              setInitialValues(old => ({...old, area: arr[0].id}));

              if (Object.keys(userData).length > 0) {
                setInitialValues({
                  code: userData.USR_CODIGO,
                  name: userData.USR_NOME,
                  shortname: userData.USR_NOME_ABREVIADO,
                  login: userData.USR_LOGIN,
                  email: userData.USR_EMAIL,
                  phone: userData.USR_FONE,
                  password: userData.USR_SENHA,
                  profile: userData.USR_PERFIL,
                  area: userData.USR_AREA,
                  class: userData.USR_CONSELHO_DE_CLASSE
                });
                setIsProfessional(userData.USR_EH_PROFISSIONAL_DA_SAUDE == '1');
                setIsVoluntary(userData.USR_EH_VOLUNTARIO == '1');
              }
            });
          }
        });
      }
    });

    return () => {
      storeUser({});
    };
  }, []);

  return (
    <div className="animeUp">
      <Title>
        {Object.keys(userData).length === 0
          ? 'Cadastro de Usuários'
          : `Edição do usuário ${userData.USR_NOME}`}
      </Title>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form autoComplete="off" autocomplete="off">
            <FormRow aria-autocomplete="none">
              <div className="mediumInput">
                <label htmlFor="name">Nome</label>
                <Field id="name" name="name" type="text" autocomplete="off" />
                {errors.name && touched.name && <Error>{errors.name}</Error>}
              </div>
              <div className="mediumInput">
                <label htmlFor="name">Nome Abreviado</label>
                <Field id="name" name="shortname" type="text" autocomplete="off" maxLength={15} />
              </div>
              <div className="mediumInput">
                <label htmlFor="login">Login</label>
                <Field id="login" name="login" type="text" />
                {errors.login && touched.login && <Error>{errors.login}</Error>}
              </div>
            </FormRow>
            <FormRow>
              <div className="mediumInput">
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" type="email" maxLength={320} />
              </div>
              <div className="mediumInput">
                <label htmlFor="phone">Telefone</label>
                <Field
                  id="phone"
                  name="phone"
                  type="text"
                  maxLength={11}
                  render={({ field }) => (
                    <InputMask
                      {...field}
                      onChange={handleChange}
                      value={values.phone}
                      mask="(99) 99999-9999"
                    />
                  )}
                />
                {errors.phone && touched.phone && <Error>{errors.password}</Error>}
              </div>
              <div className="mediumInput">
                <label htmlFor="password">Senha</label>
                <Field id="password" name="password" type="password" />
                {errors.password && touched.password && <Error>{errors.password}</Error>}
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput">
                <label htmlFor="class">Conselho de Classe</label>
                <Field id="class" name="class" type="text" maxLength={64} />
                {errors.class && touched.class && <Error>{errors.password}</Error>}
              </div>
            </FormRow>
            <FormRow>
              <div>
                <label htmlFor="profile">Perfil</label>
                <Field id="profile" name="profile" type="text" as="select">
                  {profiles.map(profile => (
                    <option key={profile.id} value={profile.id}>
                      {profile.label}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <label htmlFor="area">Area</label>
                <Field id="area" name="area" type="text" as="select">
                  {specialities.map(area => (
                    <option key={area.id} value={area.id}>
                      {area.label}
                    </option>
                  ))}
                </Field>
                {errors.area && touched.area && <Error>{errors.area}</Error>}
              </div>
              <div className="mediumInput radioGroup">
                <label htmlFor="triagem"></label>
                <div style={{ width: 260, display: 'none' }}>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <label onClick={() => setIsProfessional(!isProfessional)} className="left">
                      Profissional de saúde:
                    </label>
                    <Switch
                      onChange={() => setIsProfessional(!isProfessional)}
                      onClick={() => setIsProfessional(!isProfessional)}
                      checked={isProfessional}
                    />
                  </Row>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <label onClick={() => setIsVoluntary(!isVoluntary)} className="left">
                      Funcionário-voluntário:
                    </label>
                    <Switch
                      onChange={() => setIsVoluntary(!isVoluntary)}
                      onClick={() => setIsVoluntary(!isVoluntary)}
                      checked={isVoluntary}
                    />
                  </Row>
                </div>
              </div>
            </FormRow>

            <SubmitButton style={{ marginTop: 20 }} primary type="submit">
              {isLoading 
                ? <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                : 'Enviar Dados'
              }
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
}
