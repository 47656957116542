import React, { useContext } from 'react';

import { TitleSection, FormRow } from '../../../../Entries/Patients/styles';
import { RadioRow } from '../../../Therapeutic Project/styles';
import PrintHeader from '../../../../../../components/PrintHeader';
import { GlobalContext } from '../../../../../../GlobalContext';
import RadioOrCheck from '../../../../../../components/RadioOrCheck';
import Select from '../../../../../../components/Select';
import { PrintContainer } from '../../../../../../components/PrintHeader/styles';

const PrintFisio = React.forwardRef(
  ({ initialValues, selectedPatient, responses, patients }, ref) => {
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    const { currentUser } = useContext(GlobalContext);

    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <h2 className="section" style={{ marginTop: 3 }}>
            Avaliação Terapêutica
          </h2>
          <TitleSection>História da Gravidez</TitleSection>
          <FormRow>
            <div className="animeDown">
              <label htmlFor="">Semanas de gravidez</label>
              <input
                style={{ textAlign: 'center' }}
                type="number"
                readOnly
                value={initialValues.semanasGravidez}
              />
            </div>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tipo de parto</label>
              <RadioRow>
                {responses.tipoParto.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput">
              <label>Complicações da gravidez</label>
              <textarea value={initialValues.complicacoesGravidez} readOnly />
            </div>
          </FormRow>

          <TitleSection>Neonatal</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tipo de neonato</label>
              <RadioRow>
                {responses.tipoNeonato.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Chorou imediatamente ao nascer</label>
              <RadioRow>
                {responses.chorouNascer.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput animeDown radioGroup">
              <label>Ventilação mecânica</label>
              <RadioRow>
                {responses.ventilacaoMecanica.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="mediumInput">
              <label>Tempo</label>
              <input value={initialValues.ventilacaoMecanicaTempo} type="text" readOnly />
            </div>
            <div className="mediumInput" />
          </FormRow>

          <FormRow>
            <div className="mediumInput">
              <label htmlFor="">APGAR</label>
              <input value={initialValues.apgar} type="text" readOnly />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Convulsão</label>
              <input value={initialValues.convulsao} type="text" readOnly />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Tempo na incubadora (minutos)</label>
              <input value={initialValues.tempoIncubadora} type="number" readOnly />
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Observações</label>
              <input value={initialValues.neonatalNotes} type="text" readOnly />
            </div>
          </FormRow>

          <TitleSection>Pessoa</TitleSection>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label>Breve relato do início da alteração motora</label>
              <textarea value={initialValues.relato} style={{ minHeight: 70 }} readOnly />
            </div>
          </FormRow>

          <FormRow>
            <div className="mediumInput radioGroup">
              <label htmlFor="">Alteração visual</label>
              <div className="radioGroupRow">
                {responses.alteracaoVisual.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </div>
            </div>
            <div className="mediumInput radioGroup">
              <label htmlFor="">Alteração auditiva</label>
              <div className="radioGroupRow">
                {responses.alteracaoAuditiva.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </div>
            </div>
            <div className="mediumInput radioGroup">
              <label htmlFor="">Alteração respiratória</label>
              <div className="radioGroupRow">
                {responses.alteracaoRespiratoria.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </div>
            </div>
          </FormRow>

          <TitleSection>Função Motora</TitleSection>

          <FormRow>
            <div className="mediumInput">
              <label>Idade motora atual (anos)</label>
              <input value={initialValues.idadeMotoraAtual} type="text" readOnly />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Idade motora atual (meses)</label>
              <input value={initialValues.idadeMotoraAtualMes} type="text" readOnly />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label htmlFor="">Idade cronológica (anos)</label>
              <input value={initialValues.idadeCronologica} type="text" readOnly />
            </div>
            <div className="mediumInput">
              <label htmlFor="">Idade cronológica (meses)</label>
              <input value={initialValues.idadeCronologicaMes} type="text" readOnly />
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tipo de PC</label>
              <RadioRow>
                {responses.tipoPC.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label>Trofismo</label>
              <RadioRow>
                {responses.trofismo.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Tônus</label>
              <RadioRow>
                {responses.tonus.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
            <div className="fullInput">
              <Select
                label="Avaliação do tônus"
                name="avaliacaoTonus"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Postura supino MMSS</label>
              <textarea value={initialValues.supinoMMSS} readOnly />
            </div>
            <div className="fullInput">
              <label htmlFor="">Postura supino MMII</label>
              <textarea value={initialValues.supinoMMII} readOnly />
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Postura em prono MMSS</label>
              <textarea value={initialValues.pronoMMSS} readOnly />
            </div>
            <div className="fullInput">
              <label htmlFor="">Postura em prono MMII</label>
              <textarea value={initialValues.pronoMMII} readOnly />
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Dissociação pélvica</label>
              <RadioRow>
                {responses.dissociacaoPelvica.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Dissociação escapular</label>
              <RadioRow>
                {responses.dissociacaoEscapular.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Padrões Motores</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Controle de cabeça</label>
              <RadioRow>
                {responses.controleCabeca.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow style={{ marginTop: 40, paddingTop: 10 }}>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Rolar</label>
              <RadioRow>
                {responses.rolar.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Supino-prono</label>
              <RadioRow>
                {responses.supinoProno.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Prono-supino</label>
              <RadioRow>
                {responses.pronoSupino.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Puppy</label>
              <RadioRow>
                {responses.puppy.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Supino/sentado</label>
              <RadioRow>
                {responses.supinoSentado.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Prono/sentado</label>
              <RadioRow>
                {responses.pronoSentado.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Sentado</label>
              <RadioRow>
                {responses.sentado.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Ponte</label>
              <RadioRow>
                {responses.ponte.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Sentado/em pé</label>
              <RadioRow>
                {responses.sentadoEmPe.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Ortostatismo</label>
              <RadioRow>
                {responses.ortostatimo.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <TitleSection>Marcha</TitleSection>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <RadioRow>
                {responses.marcha.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>

          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Fase da marcha alterada</label>
              <RadioRow>
                {responses.faseMarcha.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput animeDown radioGroup">
              <label htmlFor="">Equilíbrio</label>
              <RadioRow>
                {responses.equilibrio.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label htmlFor="">Observações</label>
              <input value={initialValues.marchaNote} type="text" readOnly />
            </div>
          </FormRow>

          <TitleSection>Órteses</TitleSection>
          <FormRow>
            <div className="smallInput radioGroup">
              <label>Órteses</label>
              <div className="radioGroupRow">
                {responses.orteses.map(response => (
                  <RadioOrCheck isPrint initialValues={initialValues} response={response} />
                ))}
              </div>
            </div>
            <div className="fullInput">
              <label htmlFor="">Observações</label>
              <input value={initialValues.ortesesNote} type="text" readOnly />
            </div>
          </FormRow>

          <TitleSection>Avaliação dos reflexos e reações</TitleSection>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo de preensão dos dedos das mãos</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="reflexoMaoEsquerdo"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="reflexoMaoDireito"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo de preensão dos dedos dos pés</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="reflexoPeEsquerdo"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="reflexoPeDireito"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Marcha reflexa</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="marchaReflexa"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo de moro</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="reflexoMoro"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reflexo cutâneo plantar (babinsk)</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="babinskEsquerdo"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="babinskDireito"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">Reação de proteção das VAS</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="VAS"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">RTCA</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Esquerdo"
                name="RTCAEsquerdo"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput">
              <Select
                label="Direito"
                name="RTCADireito"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="mediumInput">
              <label className="center">RTCS</label>
            </div>
            <div className="mediumInput">
              <Select
                label="Status"
                name="RTCS"
                values={initialValues}
                isPrint
                responses={responses}
              />
            </div>
            <div className="mediumInput" />
          </FormRow>

          <TitleSection>Testes Especiais</TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea value={initialValues.testes} readOnly />
            </div>
          </FormRow>

          <TitleSection style={{ marginTop: 30, paddingTop: 10 }}>
            Informações Complementares
          </TitleSection>
          <FormRow>
            <div className="fullInput">
              <textarea value={initialValues.notes} />
            </div>
          </FormRow>
        </PrintContainer>
        <TitleSection></TitleSection>
      </div>
    );
  }
);

export default PrintFisio;
