import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';

import { messageTranslate } from '../../../../utils/validators';
import { GlobalContext } from '../../../../GlobalContext';

import { Button } from '../../../../styles/Button';
import { Error } from '../../../../styles/GlobalComponents';
import { Title } from '../style';
import { FormRow } from '../Patients/styles';
import { getSpecialties, setSpeciality, setArea } from '../../../../utils/api';
import { danger, success } from '../../../../components/Alerts';
import { RadioRow } from '../../Movements/Therapeutic Project/styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .max(64, messageTranslate.maxChar(64))
    .required(messageTranslate.otherRequired)
});

export function AreasRegister() {
  const [sectors, setSectors] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const { load, areaData, storeArea, checkUser } = React.useContext(GlobalContext);
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      setIsLoading(true);
      const obj = {
        ARE_CODIGO: values.cod,
        ARE_DESCRITIVO: values.description,
        ARE_SETOR: values.sector,
        ARE_REALIZA_TRIAGEM: values.triagem
      };
      setArea(obj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Erro ao ${areaData.label ? 'editar' : 'cadastrar'} área`);
        } else {
          success(`Área ${areaData.id ? 'editado' : 'cadastrado'} com sucesso!`);
          navigate('/user/entries/areas/');
        }
      });
    }
  }

  useEffect(() => {
    return () => {
      storeArea({});
    };
  }, [storeArea]);

  useEffect(() => {
    getSpecialties().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar os setores: ${json.message}`);
      } else {
        const array = json.data.setores.map(sector => ({
          id: sector[0],
          label: sector[1]
        }));
        setSectors(array);
      }
    });
  }, []);

  !localStorage.getItem('login') && checkUser();
  return (
    <div className="animeUp">
      <Title>{areaData.label ? 'Edição de área' : 'Cadastro de área'}</Title>

      <Formik
        validationSchema={schema}
        initialValues={{
          cod: areaData.id ? areaData.id : '',
          description: areaData.label ? areaData.label : '',
          sector: areaData.sector
            ? `${areaData.sector.id}`
            : sectors.length > 0
            ? sectors[0].id
            : '0',
          triagem: areaData.triagem ? areaData.triagem : 'N'
        }}
        onSubmit={values => handleSubmit(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div style={{ display: 'none' }}>
              <label htmlFor="cod">Código</label>
              <Field disabled id="cod" name="cod" type="text" />
            </div>
            <div>
              <label htmlFor="description">Descrição</label>
              <Field id="description" name="description" type="text" />
              {errors.description && touched.description && <Error>{errors.description}</Error>}
            </div>
            <div>
              <label htmlFor="sector">Setor</label>
              <Field as="select" id="sector" name="sector">
                {sectors.map(sector => (
                  <option key={sector.id} value={sector.id}>
                    {sector.label}
                  </option>
                ))}
              </Field>
            </div>
            <FormRow>
              <div className="smallInput radioGroup">
                <label htmlFor="triagem">Faz triagem?</label>
                <RadioRow>
                  <label>
                    <Field type="radio" name="triagem" value="N" />
                    <span className="checkMark"></span>
                    Não
                  </label>

                  <label>
                    <Field type="radio" name="triagem" value="S" />
                    <span className="checkMark"></span>
                    Sim
                  </label>
                </RadioRow>
              </div>
            </FormRow>
            <Button disabled={load} primary type="submit">
              {isLoading ? (
                <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
              ) : (
                'Enviar Dados'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
