import styled from 'styled-components';
import { EmptyContent } from '../../../Therapeutic Project/styles';

export const Empty = styled(EmptyContent)`
  margin-left: 0;
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Label = styled.label`
  display: flex;
  border-bottom: 2px solid var(--red);
  padding: 0;
  margin: 0;
`;
