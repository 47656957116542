import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form, Formik } from 'formik';
import ReactLoading from 'react-loading';

import { danger, info, success, warning } from '../../../../components/Alerts';
import Search from '../../../../components/Search';
import {
  getAcompanhamentoFamiliar,
  getAreasSaude,
  getPatientsWithSector,
  getRelationship,
  setAcompanhamentoFamiliar
} from '../../../../utils/api';
import { FormField, FormRow, SubmitButton, TitleSection } from '../../Entries/Patients/styles';
import {
  AddButton,
  AttendanceRow,
  EmptyContent,
  RadioRow,
  Select
} from '../Therapeutic Project/styles';
import { RemoveButton, FamiliarType } from '../Revaluation/styles';
import { BsPlus } from 'react-icons/bs';
import { HiUserRemove } from 'react-icons/hi';
import FamilyAccopanimentPrint from './Components/FamilyAccopanimentPrint';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../GlobalContext';
import { PatientName } from '../../../../components/PatientName';

export default function FamilyAccopaniment() {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState('');
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const [patientInfos, setPatientInfos] = useState({});
  const [relationships, setRelationships] = useState([]);
  const [initialValues, setInitialValues] = useState({
    nome: '',
    local: '',
    parentesco: '0',
    demanda: '',
    encaminhamentos: []
  });
  const referrals = [
    { id: '0', label: 'CRAS' },
    { id: '1', label: 'CREAS' },
    { id: '2', label: 'Secretaria de Assistência Social' },
    { id: '3', label: 'UBS' },
    { id: '4', label: 'Secretaria de Saúde' },
    { id: '5', label: 'Escola' },
    { id: '6', label: 'Secretaria de Educação' },
    { id: '7', label: 'INSS' }
  ];
  const [professionalName, setProfessionalName] = useState('');
  const [professionalFunction, setProfessionalFunction] = useState('');
  const [selectedProfessionals2, setSelectedProfessionals2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });
  const { actions, currentUser } = useContext(GlobalContext);

  function handleGetPatientInfos() {
    const array = patient.split('-');
    // getAcompanhamentoFamiliar(array[0], array[1]).then(json => {
    //   if (json.error) {
    //     danger(`Ocorreu um erro ao resgatar os dados: ${json.message}`);
    //   } else {
    setSelectedPatient(patient);
    const patientData = patients.find(patient => patient.id == array[0] && patient.year == array[1]);
    setPatientInfos(patientData);
    !patientData.active && warning('Paciente inativo!')
    //   }
    // });
  }

  function handleAddProfessional(isLast) {
    if (!isLast) {
      const have = selectedProfessionals.some(el => el.id == selectedProfessional);
      if (selectedProfessional && !have) {
        let array = [...selectedProfessionals];
        const newProfessional = professionals.find(el => el.id == selectedProfessional);
        array.push(newProfessional);
        setSelectedProfessionals(array);
      }
    } else {
      if (professionalName && professionalFunction) {
        setProfessionalName('');
        setProfessionalFunction('');
        let array = [...selectedProfessionals2];
        const newProfessional = {
          name: professionalName,
          area: professionalFunction
        };
        array.push(newProfessional);
        setSelectedProfessionals2(array);
      }
    }
  }

  function handleRemoveProfessional(professional, isLast) {
    let array;
    !isLast ? (array = [...selectedProfessionals]) : (array = [...selectedProfessionals2]);
    array.splice(array.indexOf(professional), 1);
    !isLast ? setSelectedProfessionals(array) : setSelectedProfessionals2(array);
  }

  function handleSubmit(values) {
    if (!isLoading) {
      setIsLoading(true);
      setInitialValues(values);
      const professionals = selectedProfessionals.map(professional => professional.id);
      const professionals2 = selectedProfessionals2.map(
        professional => `${professional.name}|${professional.area}`
      );
      const data = patient.split('-');
      const obj = {
        PAF_PACIENTE: data[0],
        PAF_ANO: data[1],
        PAF_GRAU_PARENTESCO: values.parentesco,
        PAF_FAMILIAR_CONTATADO: values.nome,
        PAF_LOCAL_ATENDIMENTO: values.local,
        PAF_DEMANDA: values.demanda,
        PAF_ENCAMINHAMENTO_EFETUADO: values.encaminhamentos.join('|'),
        PAF_P_P_PROFISSIONAIS_PODE: professionals,
        PAF_P_C_PROFISSIONAIS_CONTATADOS: professionals2
      };

      setAcompanhamentoFamiliar(data[0], data[1], obj).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Ocorreu um erro ao enviar os dados: ${json.message}`);
        } else {
          print();
          success('Dados enviados com sucesso!');
          navigate('/user');
        }
      });
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getAreasSaude().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as áreas: ${json.message}`);
      } else {
        const array = json.data.arrProfissionaisDeSaude.map(area => ({
          id: area.USR_CODIGO,
          label: area.USR_NOME,
          area: {
            id: area.USR_AREA,
            label: area.ARE_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setProfessionals(array);
        array.length > 0 && setSelectedProfessional(array[0].id);
      }
    });

    getRelationship().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrGrauParentesco.forEach(grau => {
          const grauObj = {
            id: grau[0],
            name: grau[1]
          };
          arr.push(grauObj);
        });
        setRelationships(arr);
      }
    });
  }, []);
  return (
    <div className="animeUp">
      <h2 className="section">Acompanhamento Familiar</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <div style={{ display: 'none' }}>
            <FamilyAccopanimentPrint
              ref={printRef}
              initialValues={initialValues}
              selectedPatient={selectedPatient}
              patients={patients}
              selectedProfessionals2={selectedProfessionals2}
              selectedProfessionals={selectedProfessionals}
              referrals={referrals}
              relationships={relationships}
            />
          </div>

          <PatientName patientInfos={patientInfos} />

          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={(values, { resetForm, setFieldValue }) => handleSubmit(values)}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form disabled={!actions.some(action => action.id === 601) || !patientInfos.active}>
                  <TitleSection>Acompanhamento Familiar</TitleSection>
                  <FormRow>
                    <div className="fullInput">
                      <label htmlFor="nome">Nome da pessoa contatada na família</label>
                      <FormField id="nome" name="nome" type="text" />
                    </div>
                    <div className="smallInput">
                      <label htmlFor="parentesco">Grau de parentesco</label>
                      <FormField
                        as="select"
                        id="parentesco"
                        name="parentesco"
                        onChange={handleChange}
                      >
                        {relationships.map(relationship => {
                          return (
                            <option key={relationship.id} value={relationship.id}>
                              {relationship.name}
                            </option>
                          );
                        })}
                      </FormField>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="fullInput radioGroup">
                      <label className="highlight">Local do atendimento</label>
                      <RadioRow>
                        <label>
                          <FormField type="radio" name="local" value="0" />
                          <span className="checkMark radio"></span>
                          Residência da família
                        </label>

                        <label>
                          <FormField type="radio" name="local" value="1" />
                          <span className="checkMark radio"></span>
                          Associação PODE
                        </label>
                      </RadioRow>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="fullInput">
                      <label htmlFor="demanda">Descrição da demanda</label>
                      <FormField id="demanda" name="demanda" type="text" />
                    </div>
                  </FormRow>
                  <FormRow>
                    <div className="fullInput radioGroup">
                      <label className="highlight">Encaminhamento(s) efetuados</label>
                      <RadioRow>
                        {referrals.map(referral => (
                          <label key={referral.id}>
                            <FormField type="checkbox" name="encaminhamentos" value={referral.id} />
                            <span className="checkMark"></span>
                            {referral.label}
                          </label>
                        ))}
                      </RadioRow>
                    </div>
                  </FormRow>
                  <TitleSection style={{ fontSize: 22 }}>
                    Profissional responsável da Associação PODE pelo atendimento
                  </TitleSection>
                  <FormRow style={{ marginBottom: 10 }}>
                    <Select>
                      <select
                        onChange={e => setSelectedProfessional(e.target.value)}
                        value={selectedProfessional}
                      >
                        {professionals.map(professional => {
                          return (
                            <option key={professional.id} value={professional.id}>
                              {professional.label}
                            </option>
                          );
                        })}
                      </select>
                      <AddButton type="button" primary onClick={() => handleAddProfessional(false)}>
                        <BsPlus size={30} color="#fff" />
                      </AddButton>
                    </Select>
                  </FormRow>
                  <FormRow style={{ flexDirection: 'column' }}>
                    {selectedProfessionals.length > 0 ? (
                      selectedProfessionals.map(professional => (
                        <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                          <RemoveButton
                            type="button"
                            className="animeUp"
                            primary
                            onClick={() => handleRemoveProfessional(professional)}
                          >
                            <HiUserRemove size={30} color="#C61E24" />
                          </RemoveButton>
                          <FamiliarType key={professional.id}>
                            Área {professional.area.label}: {professional.label}{' '}
                            {professional.class}
                          </FamiliarType>
                        </AttendanceRow>
                      ))
                    ) : (
                      <EmptyContent className="animeUp">
                        Nenhum Profissional Adicionado
                      </EmptyContent>
                    )}
                  </FormRow>

                  <TitleSection style={{ fontSize: 21 }}>
                    Profissional contatado no órgão/instituição/setor ao qual os encaminhamentos
                    foram efetuados
                  </TitleSection>
                  <FormRow>
                    <div className="mediumInput">
                      <label className="highlight">Nome do profissional</label>
                      <input
                        type="text"
                        value={professionalName}
                        onChange={e => setProfessionalName(e.target.value)}
                        placeholder="Nome"
                      />
                    </div>
                    <div className="smallInput">
                      <label className="highlight">Função do profissional</label>
                      <input
                        type="text"
                        value={professionalFunction}
                        onChange={e => setProfessionalFunction(e.target.value)}
                        placeholder="Função"
                      />
                    </div>
                    <div className="smallInput" style={{ marginTop: 30 }}>
                      <AddButton type="button" primary onClick={() => handleAddProfessional(true)}>
                        <BsPlus size={30} color="#fff" />
                      </AddButton>
                    </div>
                  </FormRow>
                  <FormRow style={{ flexDirection: 'column' }}>
                    {selectedProfessionals2.length > 0 ? (
                      selectedProfessionals2.map(professional => (
                        <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                          <RemoveButton
                            type="button"
                            className="animeUp"
                            primary
                            onClick={() => handleRemoveProfessional(professional, true)}
                          >
                            <HiUserRemove size={30} color="#C61E24" />
                          </RemoveButton>
                          <FamiliarType key={professional.name}>
                            {professional.name} - {professional.area}
                          </FamiliarType>
                        </AttendanceRow>
                      ))
                    ) : (
                      <EmptyContent className="animeUp">
                        Nenhum Profissional Adicionado
                      </EmptyContent>
                    )}
                  </FormRow>
                  {patientInfos.active &&
                    <SubmitButton primary type="submit">
                      {isLoading ? (
                        <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                      ) : (
                        'Enviar Dados'
                      )}
                    </SubmitButton>
                  }
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}
