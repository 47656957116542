import { Route, Routes } from 'react-router-dom';

import { Users } from './Users';
import { UserRegister } from './UserRegister';

export function MenuUsers() {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="register" element={<UserRegister />} />
    </Routes>
  );
}