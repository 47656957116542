import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { triggerBase64Download } from 'common-base64-downloader-react';

import { SearchBox, SearchRow } from '../styles';
import { FormRow, SubmitButton } from '../../Entries/Patients/styles';
import { getAreas, getAreasSaude, getPermenorizadoProfissinal, getProcedimentos } from '../../../../utils/api';
import { danger, info } from '../../../../components/Alerts';
import { GlobalContext } from '../../../../GlobalContext';

export default function GeralBeneficiaries() {
  const [entryDate, setEntryDate] = useState('');
  const [exitDate, setExitDate] = useState('');
  const [professionals, setProfessionals] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [procedure, setProcedure] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [professional, setProfessional] = useState('0');
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState('0');
  const { actions } = useContext(GlobalContext);
  const territories = [
    'Todas', 'Alagoinha', 'Belo Jardim', 'Cachoeirinha', 'Pesqueira', 'Poção', 'Sanharó', 'São Bento do Una', 'Tacaimbó'
  ]
  const [territory, setTerritory] = useState('Todas');
  const [allCities, setAllCities] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    if(!isLoading) {
      if (!professional || !entryDate || !exitDate || !area) {
        info('Verifique o formato');
      } else {
        setIsLoading(true);
        const array1 = entryDate.split('-');
        const array2 = exitDate.split('-');
        const date1 = `${array1[2]}.${array1[1]}.${array1[0]}`;
        const date2 = `${array2[2]}.${array2[1]}.${array2[0]}`;
        getPermenorizadoProfissinal(professional, area, date1, date2, territory, allCities, procedure).then(json => {
          if (json.error) {
            danger(`${json.message}`);
          } else {
            triggerBase64Download(json.data.base64_string, json.data.file_name.replace('.pdf', ''));
          }
          setIsLoading(false);
        });
      }
    }
  }

  useEffect(() => {
    territory !== 'Todas' && setAllCities(false);
  }, [territory]);

  useEffect(() => {
    getAreas().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as áreas: ${json.message}`);
      } else {
        const array = json.data.arrAreas.map(area => ({
          id: area.ARE_CODIGO,
          label: area.ARE_DESCRITIVO,
          sector: {
            id: area.ARE_SETOR,
            label: area.STR_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setAreas([{ id: '0', label: 'Todos' }, ...array]);
      }
    });

    
    getProcedimentos().then(json => {
      if (json.error) {
        danger(`Erro ao resgatar procedimentos: ${json.message}`);
      } else {
        const array = json.data.arrProcedimento.map(procedure => ({
          id: procedure[0],
          label: procedure[1]
        }));
        setProcedures([{id: '0', label: 'Todos'}, ...array]);
        setProcedure('0');
      }
    });

    getAreasSaude().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar áreas de saúde: ${json.message}`);
      } else {
        const array = json.data.arrProfissionaisDeSaude.map(area => ({
          id: area.USR_CODIGO,
          label: area.USR_NOME,
          area: {
            id: area.USR_AREA,
            label: area.ARE_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setProfessionals([...array]);
        setProfessional(array[0].id);
      }
    });
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">DEMONSTRATIVO PORMENORIZADO DE ATENDIMENTO POR PROFISSIONAL</h2>
      <form disabled={!actions.some(action => action.id === 1022)}>
        <SearchBox width="50%">
          <SearchRow>
            <div className="mediumInput">
              <label className="main">Período: </label>
              <input type="date" value={entryDate} onChange={e => setEntryDate(e.target.value)} max="2999-12-31"
                onBlur={() => {
                  const array = entryDate.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setEntryDate(newDate);
                    }
                }}
              />
              <label>até</label>
              <input type="date" value={exitDate} onChange={e => setExitDate(e.target.value)} max="2999-12-31"
                onBlur={() => {
                  const array = exitDate.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setExitDate(newDate);
                    }
                }}
              />
            </div>
          </SearchRow>

          <SearchRow>
            <div className="mediumInput">
              <label className="main">Área: </label>
              <select value={area} onChange={e => setArea(e.target.value)}>
                {areas.map(area => (
                  <option key={area.id} value={area.id}>
                    {area.label}
                  </option>
                ))}
              </select>
            </div>
          </SearchRow>

          <SearchRow>
            <div className="fullInput">
              <label className="main">Profissional: </label>
              <select value={professional} onChange={e => setProfessional(e.target.value)}>
                {professionals.map(professional => (
                  <option key={professional.id} value={professional.id}>
                    {professional.label}
                  </option>
                ))}
              </select>
            </div>
          </SearchRow>
          <SearchRow>
            <div className="mediumInput">
              <label className="main">Procedimento: </label>
              <select style={{ width: 360 }} value={procedure} onChange={e => setProcedure(e.target.value)}>
                {procedures.map(el => (
                  <option key={el.id} value={el.id}>
                    {el.id == '0' ? 'Todos' : el.id}
                  </option>
                ))}
              </select>
            </div>
          </SearchRow>
          <SearchRow>
            <div className="mediumInput">
              <label className="main">Cidade: </label>
              <select value={territory} onChange={e => setTerritory(e.target.value)}>
                {territories.map(el => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </select>
            </div>
          </SearchRow>
          {territory === 'Todas' && 
            <FormRow className='animeUp'>
              <div className="fullInput radioGroup" style={{ marginLeft: -5 }}>
                <div className="radioGroupRow">
                  <label>
                    <input type="checkbox" checked={allCities} onChange={() => setAllCities(!allCities)} />
                    <span className="checkMark" style={{ marginLeft: 5 }}></span>Incluir cidades fora da 8º microregião de saúde
                  </label>
                </div>
              </div>
            </FormRow>
          }

          <SubmitButton primary onClick={e => handleSubmit(e)}>
            {isLoading ? (
                <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
              ) : 'Gerar relatório'
            }
          </SubmitButton>
        </SearchBox>
      </form>
    </div>
  );
}
