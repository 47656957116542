import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImExit } from 'react-icons/im';

import useWindowDimensions from '../../utils/DimensionsHook';
import Burger from '../Burger';
import { Container, Content, ExitButton, Menu, Version } from './style';
import { DropDownMenu } from '../DropDownMenu';
import { version } from '../../../package.json';
import { getAPIVersion, getBDVersion } from '../../utils/api';
import { danger } from '../Alerts';

export function Header() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const [bdVersion, setBdVersion] = useState('---');
  const [apiVersion, setApiVersion] = useState('---');

  useEffect(() => {
    getBDVersion().then(json => {
      if(json.error) {
        // danger('Erro ao resgatar versão do banco!');
      } else {
        setBdVersion(json.data.versao);
      }
    });

    getAPIVersion().then(json => {
      if(json.error) {
        // danger('Erro ao resgatar versão da API!');
      } else {
        setApiVersion(json.data.versao);
      }
    });
  }, []);

  return (
    <Container>
      <Content>
        <div>
          <Link to="/user">
            <h1>ASSOCIAÇÃO PODE</h1>
            <h5>Portadores de Direitos Especiais</h5>
          </Link>
        </div>
        {width > 800 ? (
          <Menu>
            <DropDownMenu name="cadastros" />
            <DropDownMenu name="movimentações" />
            <DropDownMenu name="relatórios" />
            <DropDownMenu name="extras" />
            <ExitButton onClick={() => navigate('/')}>
              Sair
              <ImExit style={{ marginLeft: 10, marginBottom: -2 }} size={15} />
            </ExitButton>
            <span className="name">{user.name}</span>
          </Menu>
        ) : (
          <Burger />
        )}
      </Content>
      <Version>
        Web: {version} <span>API: {apiVersion}</span> <span>Banco: {bdVersion}</span>
      </Version>
    </Container>
  );
}
