import React, { useContext, useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Search from '../../../../../components/Search';
import { danger, info, warning } from '../../../../../components/Alerts';
import { PatientName } from '../../../../../components/PatientName';
import { getFrequency, getPatientsWithSector, getTriagemPsicologia } from '../../../../../utils/api';
import handleGetRespostas from '../../../../../utils/handleGetRespostas';
import { GlobalContext } from '../../../../../GlobalContext';
import PrintPsicologia from './Components/PrintPsicologia';
import FormPsicologia from './Components/FormPsicologia';

export default function Psicologia() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [frequencies, setFrequencies] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [initialValues, setInitialValues] = useState({
    pesadelosRecorrentes: '',
    pesadelosPeriodicidade: '',
    alteracaoSono: '',
    humorFrequente: [],
    interacaoSocial: [],
    comportamentos: [],
    alteracaoConduta: '',
    problemasFamiliar: '',
    notes: ''
  });
  const [responses, setResponses] = useState({});

  const { currentUser } = useContext(GlobalContext);
  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    if (Object.keys(responses).length < 5) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      setSelectedPatient(patient);
      const array = patient.split('-');
      const patientData = patients.find(patient => patient.id == array[0] && patient.year == array[1]);
      setPatientInfos(patientData);
      !patientData.active && warning('Paciente inativo!');
      getTriagemPsicologia(array[0], array[1]).then(res => {
        setInitialValues({
          pesadelosRecorrentes: '',
          pesadelosPeriodicidade: '',
          alteracaoSono: '',
          humorFrequente: [],
          interacaoSocial: [],
          comportamentos: [],
          alteracaoConduta: '',
          problemasFamiliar: '',
          notes: ''
        });
      });
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getFrequency().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar frequências: ${json.message}`);
      } else {
        let arr = [];
        json.data.arrFrequencia.forEach(frequency => {
          const frequencyObj = {
            id: frequency[0],
            label: frequency[1]
          };
          arr.push(frequencyObj);
        });
        arr.unshift({
          id: false,
          label: 'Frequência'
        });
        setFrequencies(arr);
      }
    });

    handleGetRespostas([
      {
        label: 'Triagem Psi - Humor Frequente',
        name: 'humorFrequente'
      },
      {
        label: 'Triagem Psi - Interação Social',
        name: 'interacaoSocial'
      },
      {
        label: 'Triagem Psi - Comportamentos',
        name: 'comportamentos'
      },
      {
        label: 'Triagem Psi - Alteração do sono',
        name: 'alteracaoSono'
      },
      {
        label: 'Triagem Psi - Pesadelos recorrentes',
        name: 'pesadelosRecorrentes'
      }
    ]).then(result => setResponses(result));

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Triagem - Psicologia</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <FormPsicologia
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            currentUser={currentUser}
            print={print}
            patientInfos={patientInfos}
            responses={responses}
            frequencies={frequencies}
            setInitialValues={setInitialValues}
          />
          <div style={{ display: 'none' }}>
            <PrintPsicologia
              ref={printRef}
              initialValues={initialValues}
              selectedPatient={selectedPatient}
              currentUser={currentUser}
              responses={responses}
              frequencies={frequencies}
              patients={patients}
            />
          </div>
        </div>
      )}
    </div>
  );
}
