import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { triggerBase64Download } from 'common-base64-downloader-react';

import { getAreas, getAreasSaude, getFrequenciaAtendimentos } from '../../../../utils/api';
import { danger, info } from '../../../../components/Alerts';
import { SearchBox, SearchRow } from '../styles';
import { SubmitButton } from '../../Entries/Patients/styles';
import { GlobalContext } from '../../../../GlobalContext';

export default function AttendancesReport() {
  const [areas, setAreas] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [entryDate, setEntryDate] = useState('');
  const [exitDate, setExitDate] = useState('');
  const [area, setArea] = useState('0');
  const [professional, setProfessional] = useState('0');
  const [format, setFormat] = useState('S');
  const { actions } = useContext(GlobalContext);

  useEffect(() => {
    getAreas().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as áreas: ${json.message}`);
      } else {
        const array = json.data.arrAreas.map(area => ({
          id: area.ARE_CODIGO,
          label: area.ARE_DESCRITIVO,
          sector: {
            id: area.ARE_SETOR,
            label: area.STR_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setAreas([{ id: '0', label: 'Todos' }, ...array]);
      }
    });

    getAreasSaude().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar áreas de saúde: ${json.message}`);
      } else {
        const array = json.data.arrProfissionaisDeSaude.map(area => ({
          id: area.USR_CODIGO,
          label: area.USR_NOME,
          area: {
            id: area.USR_AREA,
            label: area.ARE_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setProfessionals([{ id: '0', label: 'Todos' }, ...array]);
      }
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (!isLoading) {
      if (!entryDate || !exitDate || !format) {
        info(!format ? 'Verifique o formato' : 'Verifique as datas');
      } else {
        setIsLoading(true);
        const array1 = entryDate.split('-');
        const array2 = exitDate.split('-');
        const date1 = `${array1[2]}.${array1[1]}.${array1[0]}`;
        const date2 = `${array2[2]}.${array2[1]}.${array2[0]}`;
        getFrequenciaAtendimentos(date1, date2, area, professional, format).then(json => {
          if (json.error) {
            danger(`Ocorreu um erro: ${json.message}`);
          } else {
            triggerBase64Download(json.data.base64_string, json.data.file_name.replace('.pdf', ''))
          }
          setIsLoading(false);
        });
      }
    }
  }

  return (
    <div className="animeUp">
      <h2 className="section">Relatório de Atendimentos</h2>
      <form disabled={!actions.some(action => action.id === 1011)}>
        <SearchBox width="50%">
          <SearchRow>
            <div className="mediumInput">
              <label className="main">Período: </label>
              <input type="date" value={entryDate} onChange={e => setEntryDate(e.target.value)} max="2999-12-31"
                onBlur={() => {
                  const array = entryDate.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setEntryDate(newDate);
                    }
                }}
              />
              <label>até</label>
              <input type="date" value={exitDate} onChange={e => setExitDate(e.target.value)} max="2999-12-31"
                onBlur={() => {
                  const array = exitDate.split('-');
                    if(array[0].length > 4) {
                      const newDate = `${array[0].slice(0, 4)}-${array[1]}-${array[2]}`; 
                      setExitDate(newDate);
                    }
                }}
              />
            </div>
          </SearchRow>
          <SearchRow>
            <div className="mediumInput">
              <label className="main">Área: </label>
              <select value={area} onChange={e => setArea(e.target.value)}>
                {areas.map(area => (
                  <option key={area.id} value={area.id}>
                    {area.label}
                  </option>
                ))}
              </select>
            </div>
          </SearchRow>
          <SearchRow>
            <div className="fullInput">
              <label className="main">Profissional: </label>
              <select value={professional} onChange={e => setProfessional(e.target.value)}>
                {professionals.map(professional => (
                  <option key={professional.id} value={professional.id}>
                    {professional.label}
                  </option>
                ))}
              </select>
            </div>
          </SearchRow>
          <SearchRow>
            <div style={{ marginTop: 10, marginBottom: 10 }} className="mediumInput radioGroup">
              <label className="main">Formato: </label>
              <div className="radioGroupRow">
                <label>
                  <input type="radio" checked={format === 'S'} onChange={() => setFormat('S')} />
                  <span className="checkMark radio"></span>Sintético
                </label>
                <label>
                  <input type="radio" checked={format === 'A'} onChange={() => setFormat('A')} />
                  <span className="checkMark radio"></span>Analítico
                </label>
              </div>
            </div>
          </SearchRow>
          <SubmitButton primary onClick={e => handleSubmit(e)}>
            {isLoading ? (
                <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
              ) : 'Gerar relatório'
            }
          </SubmitButton>
        </SearchBox>
      </form>
    </div>
  );
}
