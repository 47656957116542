import styled from 'styled-components';
import loginImg from '../../assets/pode2.png';

export const Container = styled.div`
  background: var(--white);
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  gap: 2rem;

  &::before {
    content: '';
    display: block;
    background: url(${loginImg}) no-repeat center;
    background-size: contain;
  }

  @media (max-width: 51rem) {
    & {
      grid-template-columns: 1fr;
    }
    &::before {
      display: none;
    }
  }
`;

export const LoginBox = styled.div`
  display: flex;
  place-items: center;
  flex-direction: column;

  input {
    width: 19rem;
  }

  img {
    width: 35%;
  }

  h1 {
    margin-bottom: 2rem;
    color: var(--text-title);
  }

  button {
    float: right;
  }

  span {
    display: block;
    margin-bottom: 1rem;
    color: red;
    font-size: 0.8rem;
    word-wrap: break-word;
  }

  @media (max-width: 51rem) {
    margin-top: auto;
    margin-bottom: 50vh;
  }
`;
