import styled from 'styled-components';

export const Button = styled.button`
  padding: 0 2rem;
  min-height: 3rem;
  border: ${props => (props.border ? '2px solid var(--red)' : 'none')};
  border-radius: 0.25rem;
  background: ${props => (props.primary ? 'var(--red)' : 'var(--yellow)')};
  color: ${props => (props.primary ? 'var(--white)' : 'var(--red)')};
  transition: filter 0.25s;
  &:hover {
    transition: filter 0.25s;
    filter: brightness(1.5);
  }
  & + & {
    margin-left: 1rem;
  }
`;

export const MenuMobileButton = styled(Button)`
  width: 100%;
`;
