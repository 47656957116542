/* eslint eqeqeq: "off", curly: "error" */
import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { danger, success, warning } from '../../../../components/Alerts';
import { PatientName } from '../../../../components/PatientName';
import Search from '../../../../components/Search';
import { Table } from '../../../../components/Table';
import { GlobalContext } from '../../../../GlobalContext';
import { getDocs, getDocTypes, getPatient, getPatientsWithSector, sendFile } from '../../../../utils/api';
import { FormRow, SubmitButton } from '../../Entries/Patients/styles';
import { Container } from '../../Entries/style';

export default function Upload() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [selectedPatient, setSelectedPatient] = useState({});
  const [docs, setDocs] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [docType, setDocType] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(GlobalContext);

  function handleGetPatient() {
    if (patient) {
      const data = patient.split('-');
      getPatient(data[0], data[1]).then(json => {
        if (json.error) {
          danger(`${json.message}`);
        } else {
          const patientData = json.data.objPaciente;
          const patient = {
            id: patientData.PAC_CODIGO,
            name: patientData.PAC_NOME,
            year: patientData.PAC_ANO,
            active: patientData.PAC_ALTA_INSTITUCIONAL === 0,
          };
          !patient.active && warning('Paciente inativo!');
          setSelectedPatient(patients.find(el => el.id == patient.id && el.year == patient.year));
          getDocs(patient.id, patient.year).then(json => {
            if (json.error) {
              danger(`Erro ao recuperar documentos: ${json.message}`);
            } else {
              const array = json.data.objPacienteDocumento.PD_DOCUMENTOS.map(doc => ({
                date: doc.PD_DATSYS,
                hour: doc.PD_HORSYS,
                type: doc.QRY_TIPO_DOCUMENTO,
                name: doc.PD_DOCUMENTO,
                responsible: doc.QRY_RESPONSAVEL,
                id: json.data.objPacienteDocumento.PD_PACIENTE,
                year: json.data.objPacienteDocumento.PD_ANO,
                seq: doc.PD_SEQ
              }));
              setDocs(array);
            }
          });
        }
      });
    }
  }

  function submit(e) {
    e.preventDefault();
    if (!selectedFile) {
      danger('É necessário selecionar o documento!');
    } else {
      if (!isLoading) {
        setIsLoading(true);
        let base64;
        let fileReader = new FileReader();
        fileReader.onload = loadEvent => {
          base64 = loadEvent.target.result;
          sendFile(
            selectedPatient.id,
            selectedPatient.year,
            docType,
            base64,
            selectedFile.name,
            currentUser.id
          ).then(json => {
            setIsLoading(false);
            if (json.error) {
              danger(json.message);
            } else {
              success('Enviado com sucesso!');
              getDocs(selectedPatient.id, selectedPatient.year).then(json => {
                if (json.error) {
                  danger(`Erro ao recuperar documentos: ${json.message}`);
                } else {
                  const array = json.data.objPacienteDocumento.PD_DOCUMENTOS.map(doc => ({
                    date: doc.PD_DATSYS,
                    hour: doc.PD_HORSYS,
                    type: doc.QRY_TIPO_DOCUMENTO,
                    name: doc.PD_DOCUMENTO,
                    responsible: doc.QRY_RESPONSAVEL,
                    id: json.data.objPacienteDocumento.PD_PACIENTE,
                    year: json.data.objPacienteDocumento.PD_ANO,
                    seq: doc.PD_SEQ
                  }));
                  setDocs(array);
                }
              });
            }
          });
        };
        fileReader.readAsDataURL(selectedFile);
      }
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
      }
    });

    getDocTypes().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        const array = json.data.arrTipoDocumento.map(element => ({
          id: element[0],
          label: element[1]
        }));
        setDocTypes(array);
        setDocType(array[0].id);
      }
    });
  }, []);

  useEffect(() => {
    patients.length > 0 && setPatient(`${patients[0].id}-${patients[0].year}`);
  }, [patients]);

  return (
    <Container className="animeUp">
      <h2 className="section">Anexar Documentos</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatient}
        placeholder="pacientes"
      />
      {selectedPatient.name && (
        <>  {selectedPatient.active &&
              <form className="animeUp" onSubmit={submit}>
                <PatientName patientInfos={selectedPatient} />
                <FormRow style={{ justifyContent: 'flex-start' }}>
                  <div>
                    <label className="highlight">Tipo do documento</label>
                    <select
                      style={{ width: '100%', height: 50 }}
                      onChange={e => setDocType(e.target.value)}
                      value={docType}
                    >
                      {docTypes.map((type, index) => {
                        return (
                          <option key={type.id} value={type.id}>
                            {type.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <label className="highlight">Documento</label>
                    <input
                      type="file"
                      name="file"
                      accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                      onChange={e => {
                        setSelectedFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  <SubmitButton
                    style={{ marginRight: 'auto', marginLeft: 0, height: 50, marginTop: 35 }}
                    primary
                    type="submit"
                  >
                    {isLoading ? (
                      <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                    ) : (
                      'Enviar Dados'
                    )}
                  </SubmitButton>
                </FormRow>
              </form>
            }
          {docs.length > 0 && (
            <Table
              content={docs}
              headers={{
                date: 'Data/Hora',
                type: 'Tipo do documento',
                description: 'Nome do arquivo',
                responsible: 'Responsável'
              }}
              buttons={{ download: true, delete: true, deleteFunc: () => handleGetPatient() }}
              typeTable="docs"
            />
          )}
        </>
      )}
    </Container>
  );
}
