import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiUserRemove } from 'react-icons/hi';
import { BsPlus } from 'react-icons/bs';

import { danger, info, success } from '../../../../components/Alerts';
import { FormRow, SubmitButton, TitleSection } from '../../Entries/Patients/styles';
import { AddButton, AttendanceRow, EmptyContent, Select } from '../Therapeutic Project/styles';
import Search from '../../../../components/Search';
import { FamiliarType, RemoveButton } from '../Revaluation/styles';
import { GlobalContext } from '../../../../GlobalContext';
import { getPatient, getPatientsWithSector, getUsers, setAltaInstitucional } from '../../../../utils/api';
import SubCheck from './SubCheck';
import { PatientName } from '../../../../components/PatientName';

export default function Closing() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [motivation, setMotivation] = useState('');
  const [motivation2, setMotivation2] = useState('');
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState();
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const { actions, currentUser } = useContext(GlobalContext);
  const navigate = useNavigate();

  function handleGetPatientInfos() {
    setSelectedPatient(patient);
    const array = patient.split('-');
    getPatient(array[0], array[1]).then(json => {
      if (json.error) {
        danger(`Erro ao buscar informações de fechamento: ${json.message}`);
      } else {
        const infos = patients.find(patient => patient.id == array[0] && patient.year == array[1]);
        if (infos.status != 0) {
          info('Alta já realizada anteriormente.');
          setPatientInfos({});
          setSelectedPatient('');
        } else {
          setPatientInfos(infos);
          if (json.data.objPaciente.PAC_ALTA_INSTITUCIONAL > 10) {
            setMotivation('1');
            setMotivation2(`${json.data.objPaciente.PAC_ALTA_INSTITUCIONAL}`);
          } else {
            setMotivation(`${json.data.objPaciente.PAC_ALTA_INSTITUCIONAL}`);
          }
        }
      }
    });
  }

  function setAlta() {
    const array = patient.split('-');
    if (motivation === '1' && !motivation2) {
      alert('Selecione o descumprimento de termo');
    }
    const professionals = selectedProfessionals.map(professional => professional.id);
    setAltaInstitucional(
      array[0],
      array[1],
      professionals,
      motivation === '1' ? motivation2 : motivation
    ).then(json => {
      if (json.error) {
        danger(json.message);
      } else {
        success('Dados enviados com sucesso!');
      }
      navigate('/user');
    });
  }

  function handleAddProfessional() {
    const have = selectedProfessionals.some(el => el.id == selectedProfessional);
    if (selectedProfessional && !have) {
      let array = [...selectedProfessionals];
      const newProfessional = professionals.find(el => el.id == selectedProfessional);
      array.push(newProfessional);
      setSelectedProfessionals(array);
    }
  }

  function handleRemoveProfessional(professional, isLast) {
    let array = [...selectedProfessionals];
    array.splice(array.indexOf(professional), 1);
    setSelectedProfessionals(array);
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            status: patient.PAC_ALTA_INSTITUCIONAL,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getUsers().then(json => {
      if (json.error) {
        danger(`Erro ao resgatar usuários: ${json.message}`);
      } else {
        const users = json.data.arrUsuarios.map(user => ({
          id: user.USR_CODIGO,
          name: user.USR_NOME,
          sector: user.ARE_DESCRITIVO,
          class: user.USR_CONSELHO_DE_CLASSE
        }));
        setProfessionals(users);
        setSelectedProfessional(users[0].id);
      }
    });
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Fechamento</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />
          <form disabled={!actions.some(action => action.id === 801)}>
            <FormRow style={{ marginTop: 10 }}>
              <div className="fullInput">
                <h1 className="section">Desligamento Institucional</h1>
              </div>
            </FormRow>
            <FormRow className="animeUp">
              <div className="largeInput radioGroup">
                <div>
                  <label onClick={() => setMotivation('1')}>
                    <input
                      name="motivation"
                      type="radio"
                      checked={motivation == '1' || motivation > 10}
                    />
                    <span className="checkMark radio" />
                    Descumprimento do Termo de Compromisso
                  </label>
                  {motivation == '1' && (
                    <>
                      <SubCheck
                        value="11"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Garantir a assiduidade do(a) beneficiário(a) em tela nos atendimentos, bem
                        como sua presença no cumprimento integral do horário necessário ao
                        atendimento"
                      />
                      <SubCheck
                        value="12"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Está presente na PODE no período de atendimento do(a) beneficiário(a), bem
                        como participar das atividades que o(a) profissional executor(a) do
                        atendimento entender necessária para evolução do(a) beneficiário(a)"
                      />
                      <SubCheck
                        value="13"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Participar das reuniões e eventos"
                      />
                      <SubCheck
                        value="14"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Justificar as ausências do(a) beneficiário(a) nos atendimentos sempre que
                        essas ocorrerem"
                      />
                      <SubCheck
                        value="15"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Não exceder o limite permitido de faltas justificadas e faltas não
                        justificadas estabelecidas pela instituição, o qual é, de forma mensal e na
                        ordem que se segue, a quantidade de dias disposto em atestado médico, bem
                        como duas faltas não justificadas"
                      />
                      <SubCheck
                        value="16"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Participar do processo de avaliação inicial e contínua do(a)
                        beneficiário(a), bem como está ciente e concordar que sua inserção no
                        processo de habilitação/reabilitação se dará apenas após concluída a
                        avaliação inicial, caso seu resultado indique o posto."
                      />
                      <SubCheck
                        value="17"
                        setValue={setMotivation2}
                        motivation={motivation2}
                        label="Requerido pela(o) responsável por motivos pessoais."
                      />
                    </>
                  )}
                  <label onClick={() => setMotivation('2')}>
                    <input name="motivation" type="radio" checked={motivation === '2'} />
                    <span className="checkMark radio" />
                    Mudança de Cidade
                  </label>
                  <label onClick={() => setMotivation('3')}>
                    <input name="motivation" type="radio" checked={motivation === '3'} />
                    <span className="checkMark radio" />
                    Óbito
                  </label>
                  <label onClick={() => setMotivation('4')}>
                    <input name="motivation" type="radio" checked={motivation === '4'} />
                    <span className="checkMark radio" />
                    Não justificada
                  </label>
                </div>
              </div>
            </FormRow>
            <TitleSection>Profissionais que participaram do desligamento</TitleSection>
            <FormRow style={{ marginBottom: 10 }}>
              <Select>
                <select
                  onChange={e => setSelectedProfessional(e.target.value)}
                  value={selectedProfessional}
                >
                  {professionals.map(professional => {
                    return (
                      <option key={professional.id} value={professional.id}>
                        {professional.name}
                      </option>
                    );
                  })}
                </select>
                <AddButton type="button" primary onClick={handleAddProfessional}>
                  <BsPlus size={30} color="#fff" />
                </AddButton>
              </Select>
            </FormRow>
            <FormRow style={{ flexDirection: 'column' }}>
              {selectedProfessionals.length > 0 ? (
                selectedProfessionals.map(professional => (
                  <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                    <RemoveButton
                      type="button"
                      className="animeUp"
                      primary
                      onClick={() => handleRemoveProfessional(professional)}
                    >
                      <HiUserRemove size={30} color="#C61E24" />
                    </RemoveButton>
                    <FamiliarType key={professional.id}>
                      {/* Área {professional.area.label}: {professional.label} {professional.class} */}
                      {professional.name}
                    </FamiliarType>
                  </AttendanceRow>
                ))
              ) : (
                <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
              )}
            </FormRow>
          </form>
          <FormRow>
            <SubmitButton primary type="submit" onClick={setAlta}>
              {/* {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : Object.keys(patientData).length === 0 ? (
                  'Cadastrar'
                ) : (
                  'Salvar'
                )} */}
              Enviar Dados
            </SubmitButton>
          </FormRow>
        </div>
      )}
    </div>
  );
}
