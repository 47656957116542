import React, { useEffect, useRef, useState, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';

import { RadioRow } from '../../Therapeutic Project/styles';
import { FormRow } from '../../../Entries/Patients/styles';
import Search from '../../../../../components/Search';
import { danger, info, warning } from '../../../../../components/Alerts';
import { getPatientsWithSector, getRepostas } from '../../../../../utils/api';
import handleGetRespostas from '../../../../../utils/handleGetRespostas';
import FormPedagogia from './Components/FormPedagogia';
import PrintPedagogia from './Components/PrintPedagogia';
import { GlobalContext } from '../../../../../GlobalContext';
import { PatientName } from '../../../../../components/PatientName';

export default function Pedagogia() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [pedType, setPedType] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [initialValues, setInitialValues] = useState({
    aspectosReconhecimento: '',
    autocritica: '',
    autoestima: '',
    autonomia: '',
    autopreservacao: '',
    compreensaoGlobal: '',
    compreensaoNovo: '',
    criatividade: '',
    decisaoExecucao: '',
    interacaoSocial: '',
    lateralidade: '',
    manutencaoAprendizagem: '',
    motoraFina: '',
    motoraGrossa: '',
    notes: '',
    operacoesConcretas: '',
    operacoesFormais: '',
    percepcaoAuditiva: '',
    percepcaoGustativa: '',
    percepcaoOlfativa: '',
    percepcaoVisual: '',
    preOperario: '',
    sensorioMotor: ''
  });
  const [responses, setResponses] = useState({});
  const [types, setTypes] = useState([]);

  const { currentUser } = useContext(GlobalContext);
  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    const array = patient.split('-');
    const patientData = patients.find(patient => patient.id == array[0] && patient.year == array[1])
    setPatientInfos(patientData);
    !patientData.active && warning('Paciente inativo!');

    if (Object.keys(responses).length < 23) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      setSelectedPatient(patient);
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getRepostas('Triagem Pedagogia - Especificidade').then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar valores: Especificidade`);
      } else {
        const array = json.data.arrRespostas.map(response => {
          const array = response.split('|');
          return {
            value: array[0],
            label: array[1],
            isDefault: array[2] === 'S'
          };
        });
        setTypes(array);
      }
    });
    handleGetRespostas([
      {
        label: '',
        name: 'especificidade'
      },
      {
        label: 'Triagem Pedagogia - Sensório-motor',
        name: 'sensorioMotor'
      },
      {
        label: 'Triagem Pedagogia - Pré-operatório',
        name: 'preOperario'
      },
      {
        label: 'Triagem Pedagogia - Operações concretas',
        name: 'operacoesConcretas'
      },
      {
        label: 'Triagem Pedagogia - Operações formais',
        name: 'operacoesFormais'
      },
      {
        label: 'Triagem Pedagogia - Compreensão do novo',
        name: 'compreensaoNovo'
      },
      {
        label: 'Triagem Pedagogia - Compreensão global dos contextos',
        name: 'compreensaoGlobal'
      },
      {
        label: 'Triagem Pedagogia - Criatividade',
        name: 'criatividade'
      },
      {
        label: 'Triagem Pedagogia - Manutenção de determinada aprendizagem',
        name: 'manutencaoAprendizagem'
      },
      {
        label: 'Triagem Pedagogia - Percepção visual',
        name: 'percepcaoVisual'
      },
      {
        label: 'Triagem Pedagogia - Percepção auditiva',
        name: 'percepcaoAuditiva'
      },
      {
        label: 'Triagem Pedagogia - Percepção olfativa',
        name: 'percepcaoOlfativa'
      },
      {
        label: 'Triagem Pedagogia - Percepção gustativa',
        name: 'percepcaoGustativa'
      },
      {
        label: 'Triagem Pedagogia - Aspectos tátil-cinestésico de reconhecimento',
        name: 'aspectosReconhecimento'
      },
      {
        label: 'Triagem Pedagogia - Coordenação motora grossa',
        name: 'motoraGrossa'
      },
      {
        label: 'Triagem Pedagogia - Coordenação motora fina',
        name: 'motoraFina'
      },
      {
        label: 'Triagem Pedagogia - Lateralidade',
        name: 'lateralidade'
      },
      {
        label: 'Triagem Pedagogia - Autopreservação',
        name: 'autopreservacao'
      },
      {
        label: 'Triagem Pedagogia - Autoestima',
        name: 'autoestima'
      },
      {
        label: 'Triagem Pedagogia - Autocrítica',
        name: 'autocritica'
      },
      {
        label: 'Triagem Pedagogia - Autonomia',
        name: 'autonomia'
      },
      {
        label: 'Triagem Pedagogia - Interação social e comunicacional',
        name: 'interacaoSocial'
      },
      {
        label: 'Triagem Pedagogia - Tomada de decisão e execução',
        name: 'decisaoExecucao'
      }
    ]).then(result => {
      setResponses(result);
    });

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section" style={{ marginTop: 20 }}>
      Triagem - Pedagogia / Psicopedagogia
      </h2>

      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      <FormRow>
        <div className="fullInput animeDown radioGroup">
          <RadioRow style={{ justifyContent: 'space-between' }}>
            {types.map(el => (
              <label style={{ maxWidth: '24%', minWidth: '0%' }} key={el.id}>
                <input
                  type="radio"
                  name="type"
                  id="type"
                  value={el.value}
                  onChange={e => setPedType(e.target.value)}
                />
                <span className="checkMark radio"></span>
                {el.label}
              </label>
            ))}
          </RadioRow>
        </div>
      </FormRow>
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <FormPedagogia
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            print={print}
            responses={responses}
            setInitialValues={setInitialValues}
            pedType={pedType}
            patientInfos={patientInfos}
          />
          <div style={{ display: 'none' }}>
            <PrintPedagogia
              ref={printRef}
              initialValues={initialValues}
              selectedPatient={selectedPatient}
              currentUser={currentUser}
              responses={responses}
              patients={patients}
              types={types}
              pedType={pedType}
            />
          </div>
        </div>
      )}
    </div>
  );
}
