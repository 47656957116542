import styled from 'styled-components';

export const Container = styled.header`
  background: var(--background);
  position: relative;
`;

export const Content = styled.div`
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6rem;

  h5::after {
    content: '';
    content: '';
    display: block;
    background: var(--red);
    height: 0.3rem;
    width: 12.8rem;
    border-radius: 0.2rem;
  }

  h1,
  h5 {
    color: var(--text-body);
  }

  background-color: var(--white);
  border-radius: 0 0 1rem 1rem;
`;

export const Menu = styled.section`
  button {
    margin-right: 0.5rem;
  }

  span.name {
    position: absolute;
    /* width: 100%; */
    top: 1px;
    right: 5px;

    font-weight: bold;
  }
`;

export const ExitButton = styled.span`
  border-bottom: 2px solid var(--red);
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
  padding: 5px 10px;
  transition-duration: 0.5s;

  &:hover {
    border-radius: 5px;
    background-color: var(--red);
    color: #fff;
    cursor: pointer;
  }
`;

export const Version = styled.span`
  position: absolute;
  top: 0;
  left: 16px;
  color: #000;
  font-size: 13px;
  
  span {
    &:not(:last-child) {
      border-left: 1px solid var(--red);
      border-right: 1px solid var(--red);
      padding: 0 5px;
    }
  }
`
