import styled from 'styled-components';
import { Button } from '../../../../styles/Button';
import { Title } from '../style';
import { Field } from 'formik';

export const TitleSection = styled(Title)`
  margin-top: 15px;
  margin-bottom: 5px;

  > span.patient {
    color: var(--red);
  }
`;

export const SubTitleSection = styled.h2`
  margin-top: -15px;
  margin-bottom: 15px;
  margin-left: 3px;
  font-size: 0.9rem;
  color: #777;

  > span {
    color: var(--red);
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const BackButton = styled(Button)`
  background-color: #0000;
  padding: 0;
  font-size: 1.1em;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  min-height: 50px;

  legend {
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
  }

  > div.borderBottom {
    border-bottom: 2px solid var(--input-border);
  }

  &.borderBottom {
    border-bottom: 2px solid var(--input-border);
    margin-bottom: 10px;
  }

  div > label.highlight {
    background-color: var(--red);
    color: #fff;
    padding: 0.3em 0.5em;
    display: inline-block;
    font-weight: bold;
  }

  label.center {
    background-color: var(--red);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0;
    margin-top: 1.6rem;
    border-radius: 5px;
    font-size: 0.9em;
    font-weight: bold;
  }

  input {
    width: 100%;
  }

  > div {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 700px) {
    > div {
      margin-left: 7.5px;
      margin-right: 7.5px;
    }
  }

  div.smallInput {
    width: 20%;
  }

  div.mediumInput {
    width: 50%;
  }

  > div.largeInput {
    width: 70%;
  }

  > div.fullInput {
    width: 100%;
    position: relative;
  }

  > div.radioGroup {
    margin-bottom: 15px;
  }

  > div.radioGroup > div.radioGroupRow {
    display: flex;
    flex-wrap: wrap;
  }

  > div.notes > label {
    margin-top: 3rem;
    font-size: 1.5em;
    font-weight: bold;
    color: var(--red);
  }

  > div.radioGroup > div > label {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 10px;
    input {
      width: 20px;
    }
  }

  h2 {
    text-align: center;
  }
`;

export const FormField = styled(Field)`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
`;

export const AddParentButton = styled(Button)`
  min-height: 2.8rem;
  height: 2.8rem;
  margin-left: 10px;
`;

export const Parent = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;

    &:hover {
      filter: brightness(1.2);
    }
  }
`;
