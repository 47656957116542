import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';

import { messageTranslate } from '../../../../utils/validators';
import { GlobalContext } from '../../../../GlobalContext';

import { Button } from '../../../../styles/Button';
import { Error } from '../../../../styles/GlobalComponents';
import { Title } from '../style';
import { setSpeciality } from '../../../../utils/api';
import { danger, success } from '../../../../components/Alerts';

const schema = Yup.object().shape({
  description: Yup.string()
    .max(64, messageTranslate.maxChar(64))
    .required(messageTranslate.otherRequired)
});

export function HealthProfessionalRegister() {
  const { load, specialityData, storeSpeciality, checkUser } = React.useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();

  function handleSubmit(values) {
    if (!isLoading) {
      setIsLoading(true);
      setSpeciality(values).then(json => {
        if (json.error) {
          danger(`Erro ao ${specialityData.label ? 'editar' : 'cadastrar'} setor`);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          success(`Setor ${specialityData.id ? 'editado' : 'cadastrado'} com sucesso!`);
          navigate('/user/entries/health-professionals/');
        }
      });
    }
  }

  useEffect(() => {
    return () => {
      storeSpeciality({});
    };
  }, [storeSpeciality]);

  !localStorage.getItem('login') && checkUser();
  return (
    <div className="animeUp">
      <Title>{specialityData.label ? 'Edição de setor' : 'Cadastro de setor'}</Title>

      <Formik
        validationSchema={schema}
        initialValues={{
          cod: specialityData.id ? specialityData.id : '',
          description: specialityData.label ? specialityData.label : ''
        }}
        onSubmit={values => handleSubmit(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div style={{ display: 'none' }}>
              <label htmlFor="cod">Código</label>
              <Field disabled id="cod" name="cod" type="text" />
            </div>
            <div>
              <label htmlFor="description">Descrição</label>
              <Field id="description" name="description" type="text" />
              {errors.description && touched.description && <Error>{errors.description}</Error>}
            </div>
            <Button disabled={load} primary type="submit">
              {isLoading ? (
                <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
              ) : (
                'Enviar Dados'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
