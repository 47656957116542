import styled from 'styled-components';

export const PrintContainer = styled.div`
  padding: 0 10px;
`;

export const PrintHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  div {
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    text-align: right;
    padding: 0 10px;
  }

  h1 {
    font-size: 20px;
  }
`;

export const PrintLogo = styled.img`
  width: 150px;
`;
