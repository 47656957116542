/* eslint eqeqeq: "off", curly: "error" */
import axios from 'axios';
import addZero from '../utils/addZero';
import { version } from '../../package.json';

// const baseURL = 'http://127.0.0.1/pode/ws';
const baseURL = 'https://associacaopode.org.br/ws';
export { baseURL };

async function defaultRequest(xmls) {
  const soap = `<?xml version="1.0" encoding="UTF-8"?>
    <SOAP-ENV:Envelope
      xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/"
      SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
    <SOAP-ENV:Body>
      ${xmls}
    </SOAP-ENV:Body>
    </SOAP-ENV:Envelope>`;

  try {
    const response = await axios.post(`${baseURL}/pode_soapserver.php?wsdl`, soap, {
      headers: { 'Content-Type': 'application/xml' }
    });

    if(baseURL === 'http://localhost/pode/ws') {
      console.log('requisição feita localmente!');
    }
    // console.log(soap);
    console.log(response.data);

    const json = JSON.parse(
      response.data.substring(
        response.data.indexOf('<return xsi:type="xsd:string">') + '<return xsi:type="xsd:string">'.length,
        response.data.indexOf('</return>')
      )
    );

    if (json.err_msg) {
      return {
        error: true,
        message: `${json.err_msg}`
      };
    } else {
      return {
        error: false,
        data: json
      };
    }
  } catch (error) {
    baseURL === 'http://localhost/pode/ws' && alert('requisição feita localmente!');
    console.log(`ERRO_______________________`);
    console.log(xmls);
    return {
      error: true,
      message: `${error}`
    };
  }
}

export async function sendFile(id, year, type, file, fileName, responsible) {
  const xmls = `<m:setPacienteDocumentoJSON xmlns:m="${baseURL}/setPacienteDocumentoJSON">
    <tipo_documento>${type}</tipo_documento>
    <responsavel>${responsible}</responsavel>
    <doc_nome>${fileName}</doc_nome>
    <doc_file>${file}</doc_file>
    <pac>${id}</pac>
    <ano>${year}</ano>
  </m:setPacienteDocumentoJSON>`;

  return defaultRequest(xmls);
}

export async function getSpecialties() {
  const xmls = `<m:getSetoresJSON xmlns:m="${baseURL}/getSetoresJSON" />`;
  return defaultRequest(xmls);
}

export async function getAreas() {
  const xmls = `<m:getAreasJSON xmlns:m="${baseURL}/getAreasJSON" />`;
  return defaultRequest(xmls);
}

export async function getAreasSaude() {
  const xmls = `<m:getProfissionaisDeSaudeJSON xmlns:m="${baseURL}/getProfissionaisDeSaudeJSON" />`;
  return defaultRequest(xmls);
}

export async function getBDVersion() {
  const xmls = `<m:getVersaoBDJSON xmlns:m="${baseURL}/getVersaoBDJSON" />`;
  return defaultRequest(xmls);
}

export async function getAPIVersion() {
  const xmls = `<m:getVersaoAPIJSON xmlns:m="${baseURL}/getVersaoAPIJSON" />`;
  return defaultRequest(xmls);
}

export async function setSpeciality(values) {
  const xmls = `<m:setSetorJSON xmlns:m="${baseURL}/setSetorJSON">
      <setor>${values.description}</setor>
      ${values.cod ? `<codigo>${values.cod}</codigo>` : ''}
    </m:setSetorJSON>`;
  return defaultRequest(xmls);
}

export async function setArea(obj) {
  const xmls = `<m:setAreaJSON xmlns:m="${baseURL}/setAreaJSON">
      <obj>${JSON.stringify(obj)}</obj>
      <cod>${obj.ARE_CODIGO ? obj.ARE_CODIGO : 0}</cod>
    </m:setAreaJSON>`;
  return defaultRequest(xmls);
}

export async function login({ email, password }) {
  let xmls = `<m:getUserOk xmlns:m="${baseURL}/getUserOk">
      <usr>${email}</usr>
      <pwd>${password}</pwd>
    </m:getUserOk>`;

  return defaultRequest(xmls);
}

export async function getRelationship() {
  const xmls = `<m:getGrauParentescoJSON xmlns:m="${baseURL}/getGrauParentescoJSON" />`;
  return defaultRequest(xmls);
}

// export async function getPatients() {
//   const xmls = `<m:getPacientesJSON xmlns:m="${baseURL}/getPacientesJSON" />`;
//   return defaultRequest(xmls);
// }

export async function getPatientsWithSector(userId) {
  const xmls = `<m:getPacientesNgJSON xmlns:m="${baseURL}/getPacientesNgJSON">
    <id>${userId || 0}</id>
  </m:getPacientesNgJSON>`;
  return defaultRequest(xmls);
}

export async function getPatient(id, year) {
  const xmls = `<m:getPacienteJSON xmlns:m="${baseURL}/getPacienteJSON">
    <pac_codigo>${id}</pac_codigo>
    <ano>${year}</ano>
  </m:getPacienteJSON>`;
  return defaultRequest(xmls);
}

export async function setPatient(values, selectedParents, userId) {
  const birthday = () => {
    let arr = values.birth.split('-');
    return `${arr[2]}/${arr[1]}/${arr[0]}`;
  };
  const parents = values.haveParent == 1 ? selectedParents.map(parent => `${parent.id}|${parent.year}`) : []

  const patient = {
    PAC_USRCAD_FIRST: values.code === 0 ? userId : null,
    PAC_USRCAD_LAST: values.code !== 0 ? userId : null, 
    PAC_SETCAD: 2,
    PAC_NOME: values.name,
    PAC_DATA_NASCIMENTO: birthday(),
    PAC_COR_RACA: values.race,
    PAC_SEXO: values.sex,
    PAC_GENERO: values.gender,
    PAC_NUMR_REGISTRO: values.n_register,
    PAC_NUMR_RG: values.rg,
    PAC_NUMR_CPF: values.cpf.replace('.', '').replace('.', '').replace('-', ''),
    PAC_NUMR_NIS: values.nis,
    PAC_NUMR_SUS: values.sus,
    PAC_FONE: values.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
    PAC_WHTS: values.isWpp ? 1 : 0,
    PAC_EMAIL: values.email,
    PAC_ENDERECO: '',
    PAC_END_UF: values.uf,
    PAC_END_CIDADE: values.cidade,
    PAC_END_BAIRRO: values.bairro,
    PAC_END_TIPO_LOGRADOURO: values.tipoLogradouro ? values.tipoLogradouro : 0,
    PAC_END_LOGRADOURO: values.logradouro,
    PAC_END_NUMERO: values.numero && values.temNumero.includes('1') ? parseInt(values.numero) : 0,
    PAC_END_COMPLEMENTO: values.complemento,
    PAC_END_CEP: values.cep ? parseInt(values.cep.replace('-', '')) : 0,
    PAC_NOME_RESPONSAVEL: values.responsibleName == '' ? 0 : values.responsibleName,
    PAC_GRAU_RESPONSAVEL: values.responsibleRelationship,
    PAC_FONE_RESPONSAVEL: values.responsiblePhone.replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
    PAC_WHTS_RESPONSAVEL: values.responsibleIsWpp ? 1 : 0,
    PAC_EMAIL_RESPONSAVEL: values.responsibleEmail,
    PAC_FAMILIARES_NO_PODE: parents,
    PAC_OBS: values.notes,
    PAC_ENCAMINHAMENTO: values.forwarded
  };

  const xmls = `<m:setPacienteJSON xmlns:m="${baseURL}/setPacienteJSON">
    <objPaciente>${JSON.stringify(patient)}</objPaciente>
    <pac_codigo>${Number(values.code)}</pac_codigo>
    <ano>${values.year ? values.year : 0}</ano>
  </m:setPacienteJSON>`;

  return defaultRequest(xmls);
}

export async function getSocialPrograms() {
  const xmls = `<m:getProgramaSocialJSON xmlns:m="${baseURL}/getProgramaSocialJSON" />`;
  return defaultRequest(xmls);
}

export async function getCommitments() {
  const xmls = `<m:getComprometimentoJSON xmlns:m="${baseURL}/getComprometimentoJSON" />`;
  return defaultRequest(xmls);
}

export async function getScholarity() {
  const xmls = `<m:getEscolaridadeJSON xmlns:m="${baseURL}/getEscolaridadeJSON" />`;
  return defaultRequest(xmls);
}

export async function getPatientHealth(id, year) {
  const xmls = `<m:getPacienteSaudeJSON xmlns:m="${baseURL}/getPacienteSaudeJSON">
      <ps_paciente>${id}</ps_paciente>
      <ps_ano>${year}</ps_ano>
    </m:getPacienteSaudeJSON>`;
  return defaultRequest(xmls);
}

export async function setPatientHealth(
  patient,
  code,
  year,
  cids
) {
  const patientObj = {
    PS_PACIENTE: Number(code),
    PS_ANO: year,
    PS_UBS_NOME: patient.ubs,
    PS_TIPO_SANGUINEO: patient.bloodType,
    PS_CID_CODIGOS: cids.map(cid => cid.id),
    PS_TRATAMENTOS_ANTERIORES: patient.previousTreatments,
    PS_TRATAMENTOS_CONCOMITANTES: patient.commitTreatments,
    PS_TEM_COMPROMETIMENTO: patient.commitments.length > 0 ? 1 : 0,
    PS_COMPROMETIMENTOS: patient.commitments,
    PS_HIST_CLINICO_PACIENTE: patient.clinicHistoric,
    PS_HIST_CLINICO_FAMILIAR: patient.clinicHistoricFamily,
    PS_SINDROMES: patient.syndromes ? patient.syndromes : []
  };

  const xmls = `<m:setPacienteSaudeJSON xmlns:m="${baseURL}/setPacienteSaudeJSON">
      <paciente_saude>${JSON.stringify(patientObj)}</paciente_saude>
      <cod>${code}</cod>
      <ano>${year}</ano>
    </m:setPacienteSaudeJSON>`;
  return defaultRequest(xmls);
}

export async function getPatientSocial(id, year) {
  const xmls = `<m:getPacienteSocialJSON xmlns:m="${baseURL}/getPacienteSocialJSON">
      <ps_paciente>${id}</ps_paciente>
      <ps_ano>${year}</ps_ano>
    </m:getPacienteSocialJSON>
  `;
  return defaultRequest(xmls);
}

export async function setPatientSocial(patient, code, year) {
  const patientObj = {
    PS_COMPONENTES_FAMILIARES: patient.n_componentsFamily,
    PS_EH_INSCRITO_NO_CADUNICO: Number(patient.haveCadUnico),
    PS_EH_TRABALHADOR: Number(patient.haveWork),
    PS_PACIENTE: Number(code),
    PS_ANO: Number(year),
    PS_PROGRAMAS_SOCIAIS: patient.socialPrograms,
    PS_QTD_FAMILIARES_Q_TRABALHAM: patient.n_worksFamily,
    PS_RENDA_FAMILIAR: Number(patient.familyIncome),
    PS_TRABALHO_RENDA: Number(patient.income),
    PS_TRABALHO_TIPO: patient.workDescription,
    PS_SITUACAO_ECONOMICA_FAMILIA: patient.familySituation
  };

  const xmls = `<m:setPacienteSocialJSON xmlns:m="${baseURL}/setPacienteSocialJSON">
      <paciente_social>${JSON.stringify(patientObj)}</paciente_social>
      <cod>${code}</cod>
      <ano>${year}</ano>
    </m:setPacienteSocialJSON>`;
  return defaultRequest(xmls);
}

export async function getPatientScholarity(id, year) {
  const xmls = `<m:getPacienteEscolarJSON xmlns:m="${baseURL}/getPacienteEscolarJSON">
      <pe_paciente>${id}</pe_paciente>
      <pe_ano>${year}</pe_ano>
    </m:getPacienteEscolarJSON>
  `;
  return defaultRequest(xmls);
}

export async function setPatientScholarity(patient, code, year) {
  const patientObj = {
    PE_EH_ESTUDANTE: Number(patient.haveStudy),
    PE_ESCOLARIDADE_RESPONSAVEL: patient.responsibleScholarity,
    PE_ESCOLARIDADE_GENITOR: patient.responsibleScholarity2,
    PE_TEM_EDUCACAO_INCLUSIVA: patient.studyInclusive.length > 0 ? 1 : 0,
    PE_EDUCACOES_INCLUSIVAS: patient.studyInclusive,
    PE_ESCOLA_NOME: patient.studyDescription,
    PE_ESCOLA_SERIE: patient.studyYear,
    PE_OBS: '',
    PE_ANO: year,
    PE_PACIENTE: code
  };

  const xmls = `<m:setPacienteEscolarJSON xmlns:m="${baseURL}/setPacienteEscolarJSON">
      <paciente_escolar>${JSON.stringify(patientObj)}</paciente_escolar>
      <cod>${code}</cod>
      <ano>${year}</ano>
    </m:setPacienteEscolarJSON>`;
  return defaultRequest(xmls);
}

export async function getAnamnese(id, year) {
  const xmls = `<m:getPacienteAnamneseJSON xmlns:m="${baseURL}/getPacienteAnamneseJSON">
      <pa_paciente>${id}</pa_paciente>
      <pa_ano>${year}</pa_ano>
    </m:getPacienteAnamneseJSON>`;
  return defaultRequest(xmls);
}

export async function setAnamnese(patient, code, year) {
  const patientObj = {
    PA_PACIENTE: Number(code),
    PA_ANO: Number(year),
    PA_OBS: patient.notes
  };

  const xmls = `<m:setPacienteAnamneseJSON xmlns:m="${baseURL}/setPacienteAnamneseJSON">
      <paciente_anamnese>${JSON.stringify(patientObj)}</paciente_anamnese>
      <cod>${code}</cod>
      <ano>${year}</ano>
    </m:setPacienteAnamneseJSON>
  `;
  return defaultRequest(xmls);
}

export async function getGenders() {
  const xmls = `<m:getGeneroJSON xmlns:m="${baseURL}/getGeneroJSON" />`;
  return defaultRequest(xmls);
}

export async function getUsers() {
  const xmls = `<m:getUsuariosJSON xmlns:m="${baseURL}/getUsuariosJSON" />`;
  return defaultRequest(xmls);
}

export async function getProfissionaisSaude() {
  const xmls = `<m:GetProfissionaisDeSaudeJSON xmlns:m="${baseURL}/GetProfissionaisDeSaudeJSON" />`;
  return defaultRequest(xmls);
}

export async function getInclusiveScholarity() {
  const xmls = `<m:getEducacaoInclusivaJSON xmlns:m="${baseURL}/getEducacaoInclusivaJSON" />`;
  return defaultRequest(xmls);
}

export async function getUser(id) {
  const xmls = `<m:getUsuarioJSON xmlns:m="${baseURL}/getUsuarioJSON">
    <usr_codigo>${id}</usr_codigo>
  </m:getUsuarioJSON>`;
  return defaultRequest(xmls);
}

export async function setUser(user) {
  const newUser = {
    USR_CODIGO: user.code ? user.code : 0,
    USR_NOME: user.name,
    USR_NOME_ABREVIADO: user.shortname,    
    USR_LOGIN: user.login,
    USR_EMAIL: user.email,
    USR_FONE: user.phone 
      ? user.phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
      : '0',
    USR_SENHA: user.password,
    USR_PERFIL: parseInt(user.profile),
    USR_CONSELHO_DE_CLASSE: user.class ? user.class : '',
    USR_EH_PROFISSIONAL_DA_SAUDE: user.isProfessional ? user.isProfessional : 0,
    USR_EH_VOLUNTARIO: user.isVoluntary ? user.isVoluntary : 0,
    USR_AREA: parseInt(user.area),
  };

  const xmls = `<m:setUsuarioJSON xmlns:m="${baseURL}/setUsuarioJSON">
      <usuario>${JSON.stringify(newUser)}</usuario>
      <cod>${user.code ? user.code : 0}</cod>
    </m:setUsuarioJSON>`;
  return defaultRequest(xmls);
}

export async function getProfiles() {
  const xmls = `<m:getPerfisJSON xmlns:m="${baseURL}/getPerfisJSON" />`;
  return defaultRequest(xmls);
}

export async function getSpeciality(id) {
  const xmls = `<m:getSetorJSON xmlns:m="${baseURL}/getSetorJSON">
      <esp_codigo>${id}</esp_codigo>
    </m:getSetorJSON>`;
  return defaultRequest(xmls);
}

export async function getProfile(id) {
  const xmls = `<m:getPerfilJSON xmlns:m="${baseURL}/getPerfilJSON">
      <codigo>${id}</codigo>
    </m:getPerfilJSON>`;
  return defaultRequest(xmls);
}

export async function editMedicalRecord(oldCode, oldYear, newCode, newYear) {
  const xmls = `<m:setProntuarioJSON xmlns:m="${baseURL}/setProntuarioJSON">
      <old_cod>${oldCode}</old_cod>
      <old_ano>${oldYear}</old_ano>
      <new_cod>${newCode}</new_cod>
      <new_ano>${newYear}</new_ano>
    </m:setProntuarioJSON>`;
  return defaultRequest(xmls);
}

export async function getForwardings() {
  const xmls = `<m:getEncaminhamentoJSON xmlns:m="${baseURL}/getEncaminhamentoJSON" />`;
  return defaultRequest(xmls);
}

export async function getEvolution(id, year) {
  const xmls = `<m:getPacienteAcompanhamentoJSON xmlns:m="${baseURL}/getPacienteAcompanhamentoJSON">
      <cod>${id}</cod>
      <ano>${year}</ano>
    </m:getPacienteAcompanhamentoJSON>`;
  return defaultRequest(xmls);
}

export async function setEvolution(evolution, procedure, id, year, sectorOk, userId, date, hour, selectedDate, status) {
  const currentDate = new Date();
  const array = selectedDate.split('-');
  let array2;
  if (date) {
    array2 = date.split('-');
  }
  const patient = {
    PA_EVOLUCAO: evolution,
    PA_ALTA: Number(sectorOk),
    PA_RESPONSAVEL: userId,
    PA_DATA: `${array[2]}.${array[1]}.${array[0]}`,
    PAP_PROCEDIMENTO: procedure,
    PA_SITUACAO: Number(status || 0)
  };

  const xmls = `<m:setPacienteAcompanhamentoJSON xmlns:m="${baseURL}/setPacienteAcompanhamentoJSON">
      <paciente_acompanhamento>${JSON.stringify(patient)}</paciente_acompanhamento> 
      <cod>${id}</cod>
      <ano>${year}</ano>
      <dat>${date ? `${array2[2]}.${array2[1]}.${array2[0]}` : `${array[2]}.${array[1]}.${array[0]}`}</dat>
      <hor>${
        hour
          ? hour
          : `${addZero(currentDate.getHours())}:${addZero(currentDate.getMinutes())}:${addZero(
              currentDate.getSeconds()
            )}`
      }</hor>
    </m:setPacienteAcompanhamentoJSON>`;
  return defaultRequest(xmls);
}

export async function delEvolution(evolution, patient) {
  const array = patient.split('-');
  const xmls = `<m:delPacienteAcompanhamentoJSON xmlns:m="${baseURL}/delPacienteAcompanhamentoJSON">
      <cod>${array[0]}</cod>
      <ano>${array[1]}</ano>
      <dat>${evolution.date}</dat>
      <hor>${evolution.hour}</hor>
    </m:delPacienteAcompanhamentoJSON>`;
  return defaultRequest(xmls);
}

export async function getFrequency() {
  const xmls = `<m:getFrequenciaJSON xmlns:m="${baseURL}/getFrequenciaJSON" />`;
  return defaultRequest(xmls);
}

export async function getTherapeuticProject(id, year) {
  const xmls = `<m:getPacienteProjetoTerapeuticoJSON xmlns:m="${baseURL}/getPacienteProjetoTerapeuticoJSON">
      <cod>${id}</cod>
      <ano>${year}</ano>
    </m:getPacienteProjetoTerapeuticoJSON>`;
  return defaultRequest(xmls);
}

export async function getTriagemPsicologia(id, year) {
  const xmls = `<m:getTriagemPsiJSON xmlns:m="${baseURL}/getTriagemPsiJSON">
    <paciente>${id}</paciente>
    <ano>${year}</ano>
    </m:getTriagemPsiJSON>`;
  return defaultRequest(xmls);
}

export async function setTriagemPsicologia(id, year, data) {
  const xmls = `<m:setTriagemPsiJSON xmlns:m="${baseURL}/setTriagemPsiJSON">
      <ver>${version}</ver>
      <triagem_psi>${JSON.stringify(data)}</triagem_psi>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemPsiJSON>`;
  return defaultRequest(xmls);
}

export async function getRepostas(value) {
  const xmls = `<m:getRespostasJSON xmlns:m="${baseURL}/getRespostasJSON">
      <value>${value}</value>
    </m:getRespostasJSON>`;
  return defaultRequest(xmls);
}

export async function getActions() {
  const xmls = `<m:getAcoesJSON xmlns:m="${baseURL}/getAcoesJSON" />`;
  return defaultRequest(xmls);
}

export async function getPermissions() {
  const xmls = `<m:getPermissoesJSON xmlns:m="${baseURL}/getPermissoesJSON" />`;
  return defaultRequest(xmls);
}

export async function sendActions(permissions) {
  const xmls = `<m:setPermissoesJSON xmlns:m="${baseURL}/setPermissoesJSON">
      <arrPermissoes>${JSON.stringify(permissions)}</arrPermissoes>
    </m:setPermissoesJSON>
  `;
  return defaultRequest(xmls);
}

export async function getTriagemFono(id, year) {
  const xmls = `<m:getTriagemFonoJSON xmlns:m="${baseURL}/getTriagemFonoJSON">
    <paciente>${id}</paciente>
    <ano>${year}</ano>
    </m:getTriagemFonoJSON>`;
  return defaultRequest(xmls);
}

export async function setTriagemFono(id, year, data) {
  const xmls = `<m:setTriagemFonoJSON xmlns:m="${baseURL}/setTriagemFonoJSON">
      <triagem_fono>${JSON.stringify(data)}</triagem_fono>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemFonoJSON>`;
  return defaultRequest(xmls);
}

export async function getTriagemFisiologia(id, year) {
  const xmls = `<m:getTriagemFisioJSON xmlns:m="${baseURL}/getTriagemFisioJSON">
    <paciente>${id}</paciente>
    <ano>${year}</ano>
    </m:getTriagemFisioJSON>`;
  return defaultRequest(xmls);
}

export async function getProcedimentos() {
  const xmls = `<m:getProcedimentoJSON xmlns:m="${baseURL}/getProcedimentoJSON" />`;
  return defaultRequest(xmls);
}

export async function setTriagemEnfermagem(id, year, data) {
  const xmls = `<m:setTriagemEnfermagemJSON xmlns:m="${baseURL}/setTriagemEnfermagemJSON">
      <triagem>${JSON.stringify(data)}</triagem>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemEnfermagemJSON> 
  `;
  return defaultRequest(xmls);
}

export async function setTriagemPedagogia(id, year, data) {
  const xmls = `<m:setTriagemPedagogiaJSON xmlns:m="${baseURL}/setTriagemPedagogiaJSON">
      <triagem>${JSON.stringify(data)}</triagem>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemPedagogiaJSON> 
  `;
  return defaultRequest(xmls);
}

export async function setTriagemAudioAdulto(id, year, data) {
  const xmls = `<m:setTriagemAudioNaumCriancaJSON xmlns:m="${baseURL}/setTriagemAudioNaumCriancaJSON">
      <triagem>${JSON.stringify(data)}</triagem>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemAudioNaumCriancaJSON> 
  `;
  return defaultRequest(xmls);
}

export async function setTriagemAudioCrianca(id, year, data) {
  const xmls = `<m:setTriagemAudioCriancaJSON xmlns:m="${baseURL}/setTriagemAudioCriancaJSON">
      <triagem>${JSON.stringify(data)}</triagem>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemAudioCriancaJSON> 
  `;
  return defaultRequest(xmls);
}

export async function setTriagemFisio(id, year, data) {
  const xmls = `<m:setTriagemFisioJSON xmlns:m="${baseURL}/setTriagemFisioJSON">
      <triagem>${JSON.stringify(data)}</triagem>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setTriagemFisioJSON> 
  `;
  return defaultRequest(xmls);
}

export async function setProjetoTerapeutico(id, year, data) {
  const xmls = `<m:setPacienteProjetoTerapeuticoJSON xmlns:m="${baseURL}/setPacienteProjetoTerapeuticoJSON">
      <triagem>${JSON.stringify(data)}</triagem>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setPacienteProjetoTerapeuticoJSON> 
  `;
  return defaultRequest(xmls);
}


export async function getReavaliacao(id, year) {
  const xmls = `<m:getPacienteReavaliacaoJSON xmlns:m="${baseURL}/getPacienteReavaliacaoJSON">
      <cod>${id}</cod>
      <ano>${year}</ano>
    </m:getPacienteReavaliacaoJSON>`;
  return defaultRequest(xmls);
}

export async function setReavaliacao(id, year, data, date) {
  const currentDate = new Date();
  const xmls = `<m:setPacienteReavaliacaoJSON xmlns:m="${baseURL}/setPacienteReavaliacaoJSON">
    <triagem>${JSON.stringify(data)}</triagem>
    <pac>${id}</pac>
    <ano>${year}</ano>
    <dat>${date 
      ? date 
      : `${addZero(currentDate.getDate())}.${addZero(currentDate.getMonth()+1)}.${currentDate.getFullYear()}`}</dat>
    </m:setPacienteReavaliacaoJSON>`;
  return defaultRequest(xmls);
}

export async function getAcompanhamentoFamiliar(id, year) {
  const xmls = `<m:getPacienteAcompanhamentoFamiliarJSON xmlns:m="${baseURL}/getPacienteAcompanhamentoFamiliarJSON">
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:getPacienteAcompanhamentoFamiliarJSON>`;

  return defaultRequest(xmls);
}

export async function setAcompanhamentoFamiliar(id, year, data) {
  const xmls = `<m:setPacienteAcompanhamentoFamiliarJSON xmlns:m="${baseURL}/setPacienteAcompanhamentoFamiliarJSON">
      <obj>${JSON.stringify(data)}</obj>
      <pac>${id}</pac>
      <ano>${year}</ano>
    </m:setPacienteAcompanhamentoFamiliarJSON>`;

  return defaultRequest(xmls);
}

export async function getFrequenciaAtendimentos(entryDate, exitDate, area, professional, format) {
  const xmls = `<m:relFrequenciaAtendimentosJSON xmlns:m="${baseURL}/relFrequenciaAtendimentosJSON">
    <datini>${entryDate}</datini>
    <datfim>${exitDate}</datfim>
    <area>${area ? area : 0}</area> 
    <profissional>${professional ? professional : 0}</profissional>
    <formato>${format}</formato>
  </m:relFrequenciaAtendimentosJSON>`;

  return defaultRequest(xmls);
}

export async function getResumoProcedimentos(entryDate, exitDate, area, professional, format) {
  const xmls = `<m:relResumoProcedimentosJSON xmlns:m="${baseURL}/relResumoProcedimentosJSON">
    <datini>${entryDate}</datini>
    <datfim>${exitDate}</datfim>
    <area>${area ? area : 0}</area> 
    <profissional>${professional ? professional : 0}</profissional>
    <formato>${format}</formato>
  </m:relResumoProcedimentosJSON>`;
  return defaultRequest(xmls);
}

export async function getDadosSociais(situation, format) {
  const xmls = `<m:relDadosSociaisJSON xmlns:m="${baseURL}/relDadosSociaisJSON">
    <renda_per_capita>${situation}</renda_per_capita>
    <formato>${format}</formato>
  </m:relDadosSociaisJSON>`;
  return defaultRequest(xmls);
}

export async function getDemonstrativoGeralFaltas(professional, area, entryDate, exityDate, territory, allCities) {
  // AGUARDANDO BACK-END ADICIONAR CAMPO procedure
  const xmls = `<m:relDemoPormeFaltaPorProfissioJSON xmlns:m="${baseURL}/relDemoPormeFaltaPorProfissioJSON">
    <area>${area}</area>
    <profissional>${professional}</profissional>
    <data_de>${entryDate}</data_de>
    <data_ateh>${exityDate}</data_ateh>
    <cidade>${territory}</cidade>
    <todasCidades>${allCities ? 1 : 0}</todasCidades>
  </m:relDemoPormeFaltaPorProfissioJSON>`;
  return defaultRequest(xmls);
}

export async function getPermenorizadoProfissinal(professional, area, entryDate, exityDate, territory, allCities, procedure) {
  // AGUARDANDO BACK-END ADICIONAR CAMPO procedure
  const xmls = `<m:relDemoPormeAtendPorProfissioJSON xmlns:m="${baseURL}/relDemoPormeAtendPorProfissioJSON">
    <area>${area}</area>
    <profissional>${professional}</profissional>
    <procedimento>${procedure}</procedimento>
    <data_de>${entryDate}</data_de>
    <data_ateh>${exityDate}</data_ateh>
    <cidade>${territory}</cidade>
    <todasCidades>${allCities ? 1 : 0}</todasCidades>
  </m:relDemoPormeAtendPorProfissioJSON>`;
  return defaultRequest(xmls);
}

export async function getDadosGeraisBeneficiarios(territory, userId, status, sector) {
  const xmls = `<m:relDadosGeraisDeBeneficiariosJSON xmlns:m="${baseURL}/relDadosGeraisDeBeneficiariosJSON">
    <obj>${JSON.stringify({cidade: territory, usuario: userId, status, setor: Number(sector)})}</obj>
  </m:relDadosGeraisDeBeneficiariosJSON>`;
  return defaultRequest(xmls);
}

export async function getQuantitativeStatement(territory, userId, status) {
  const xmls = `<m:relDemoQuantMuniGeneIdadeDefi xmlns:m="${baseURL}/relDemoQuantMuniGeneIdadeDefi">
    <obj>${JSON.stringify({cidade: territory, usuario: userId, status})}</obj>
  </m:relDemoQuantMuniGeneIdadeDefi>`;
  return defaultRequest(xmls);
}

export async function getGeralFrequencyAmbu(entry, exit, userId, status) {
  const xmls = `<m:relDemoGeralFreqTerapAmbulJSON xmlns:m="${baseURL}/relDemoGeralFreqTerapAmbulJSON">
    <obj>${JSON.stringify({de: entry, ate: exit, usuario: userId, status})}</obj>
  </m:relDemoGeralFreqTerapAmbulJSON>`;
  return defaultRequest(xmls);
}

export async function getDemonstrativoGeralPerfilBeneficiario(professional, area, entryDate, exityDate, territory, allCities, procedure) {
  const xmls = `<m:relDemoSinteProcePorProfissioJSON xmlns:m="${baseURL}/relDemoSinteProcePorProfissioJSON">
    <area>${area}</area>
    <profissional>${professional}</profissional>
    <procedimento>${procedure}</procedimento>
    <data_de>${entryDate}</data_de>
    <data_ateh>${exityDate}</data_ateh>
    <cidade>${territory}</cidade>
    <todasCidades>${allCities ? 1 : 0}</todasCidades>
  </m:relDemoSinteProcePorProfissioJSON>`;
  return defaultRequest(xmls);
}

export async function getRespostas2(group, category) {
  const xmls = `<m:getPerguntas_e_RespostasJSON xmlns:m="${baseURL}/getPerguntas_e_RespostasJSON">
      <grupo>${group}</grupo>
      <categ>${category}</categ>
    </m:getPerguntas_e_RespostasJSON>`;
  return defaultRequest(xmls);
}

export async function getDocTypes() {
  const xmls = `<m:getTipoDocumentoJSON xmlns:m="${baseURL}/getTipoDocumentoJSON" />`;
  return defaultRequest(xmls);
}

export async function setAltaInstitucional(id, year, professionals, alta) {
  const xmls = `<m:setPacienteAltaInstitucionalJSON xmlns:m="${baseURL}/setPacienteAltaInstitucionalJSON">
  <alta_institucional>${alta}</alta_institucional>
  <profissionais>${JSON.stringify(professionals)}</profissionais>
  <pac>${id}</pac>
  <ano>${year}</ano>
  </m:setPacienteAltaInstitucionalJSON>`;

  return defaultRequest(xmls);
}

export async function generateHistoric(id, year, historic) {
  const xmls = `<m:relPacienteHistoricoJSON xmlns:m="${baseURL}/relPacienteHistoricoJSON">
  <cod>${id}</cod>
  <ano>${year}</ano>  
  <hist>${JSON.stringify(historic)}</hist>
  </m:relPacienteHistoricoJSON>`;

  return defaultRequest(xmls);
}

export async function getDocs(id, year) {
  const xmls = `<m:getPacienteDocumentoJSON xmlns:m="${baseURL}/getPacienteDocumentoJSON">
  <cod>${id}</cod>
  <ano>${year}</ano>  
  </m:getPacienteDocumentoJSON>`;

  return defaultRequest(xmls);
}

export async function downloadDoc(id, year, seq) {
  const xmls = `<m:dwnPacienteDocumentoJSON xmlns:m="${baseURL}/dwnPacienteDocumentoJSON">
      <id>${id}</id>
      <year>${year}</year>
      <seq>0${seq}</seq>
    </m:dwnPacienteDocumentoJSON>`;
  return defaultRequest(xmls);
}

export async function deleteDoc(id, year, seq) {
  const xmls = `<m:delPacienteDocumentoJSON xmlns:m="${baseURL}/delPacienteDocumentoJSON">
      <id>${id}</id>
      <year>${year}</year>
      <seq>0${seq}</seq>
    </m:delPacienteDocumentoJSON>`;
  return defaultRequest(xmls);
}

export async function searchCPF(cpf) {
  const xmls = `<m:getCpfOkJSON xmlns:m="${baseURL}/getCpfOkJSON">
    <cpf>${cpf.replace('.', '').replace('.', '').replace('-','')}</cpf>
  </m:getCpfOkJSON>`;
  return defaultRequest(xmls);
}

export async function getCEPInfos(cep) {
  const response = await axios.get(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`);
  if (response.data.erro === 'true') {
    return {
      error: true,
      message: 'CEP não encontrado'
    };
  } else {
    return response.data;
  }
}

export async function getTiposLogradouro() {
  const xmls = `<m:getTipoLogradouroJSON xmlns:m="${baseURL}/getTipoLogradouroJSON" />`;
  return defaultRequest(xmls);
}

export async function getCIDs() {
  const xmls = `<m:getCIDJSON xmlns:m="${baseURL}/getCIDJSON" />`;
  return defaultRequest(xmls);
}