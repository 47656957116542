import { Route, Routes } from 'react-router-dom';

import { Patients } from './Patients';
import { PatientRegister, EditMedicalReport } from './PatientRegister';

export function MenuPatients() {
  return (
    <Routes>
      <Route path="/" element={<Patients />} />
      <Route path="register" element={<PatientRegister />} />
      <Route path="editMedicalReport" element={<EditMedicalReport />} />
    </Routes>
  );
}
