/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState, useRef, useContext } from 'react';

import { TitleSection } from '../../Entries/Patients/styles';
import Search from '../../../../components/Search';
import moment from 'moment';
import { danger, info } from '../../../../components/Alerts';
import {
  getAreasSaude,
  getFrequency,
  getPatientsWithSector,
  getReavaliacao,
  getRelationship,
  getUsers
} from '../../../../utils/api';
import handleGetRespostas from '../../../../utils/handleGetRespostas';

import FormRevaluation from './Components/FormRevaluation';
import PrintRevaluation from './Components/PrintRevaluation';
import { useReactToPrint } from 'react-to-print';
import { PatientName } from '../../../../components/PatientName';
import ListRevaluations from './Components/ListRevaluations';
import { AddButton } from '../Therapeutic Project/styles';
import { Button } from '../../../../styles/Button';
import { GlobalContext } from '../../../../GlobalContext';

export default function Revaluation() {
  const { currentUser } = useContext(GlobalContext);
  const [patients, setPatients] = useState([]);
  const [responses, setResponses] = useState({
    aspectosIndividuais: [],
    altaAreas: [],
    atendidoPor: [],
    atividadesDiarias: [],
    fatoresSociais: [],
    organizacaoFamiliar: [],
    saude: [],
    situacaoEmocional: []
  });
  const [relationships, setRelationships] = useState([]);
  const [selectedFamiliars, setSelectedFamiliars] = useState([]);
  const [selectedFamiliar, setSelectedFamiliar] = useState('');
  const [frequencies, setFrequencies] = useState([]);
  const [patient, setPatient] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [selectedAttendances, setSelectedAttendances] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState();
  const [selectedProfessional, setSelectedProfessional] = useState('');
  const [professionals, setProfessionals] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const [selectedProfessionals2, setSelectedProfessionals2] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [revaluations, setRevaluations] = useState([]);
  const [editDate, setEditDate] = useState(null);
  const [haveList, setHaveList] = useState(false);
  const [initialValues, setInitialValues] = useState({
    aspectosIndividuais: [],
    atividadesDiarias: [],
    saude: [],
    organizacaoFamiliar: [],
    situacaoEmocional: [],
    fatoresSociais: [],
    date: '',
    previsao: [],
    alta: '',
    notes: ''
  });


  function clear() {
    setInitialValues({
      aspectosIndividuais: [],
      atividadesDiarias: [],
      saude: [],
      organizacaoFamiliar: [],
      situacaoEmocional: [],
      fatoresSociais: [],
      date: moment().format('YYYY-MM-DD'),
      previsao: [],
      alta: '',
      notes: ''
    });
    setEditDate(null);
    setSelectedProfessionals2([]);
    setSelectedProfessionals([]);
    setSelectedFamiliars([]);
    setSelectedAttendances([]);
    setSelectedFamiliars([]);
  }

  function handleGetPatientInfos() {
    const data = patient.split('-');
    setPatientInfos(patients.find(patient => patient.id == data[0] && patient.year == data[1]));
    clear()
    if (Object.keys(responses) < 8) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      getReavaliacao(data[0], data[1]).then(json => {
        if (json.error) {
          danger(`${json.message}`);
        } else {
          if(json.data.objPacienteProjetoTerapeutico?.PPT_REAVALIACOES.P_PT_R_REAVALIACOES?.length > 0) {
            setHaveList(true);
            setShowForm(false);
            setRevaluations(() => 
              json.data.objPacienteProjetoTerapeutico.PPT_REAVALIACOES.P_PT_R_REAVALIACOES.map(revaluation => {
                const aspectosIndividuais = revaluation.P_PT_R_OBJT_DESENV_ASPCT_INDIVI.split('|');
                const atividadesDiarias = revaluation.P_PT_R_OBJT_ATIVIDA_VIDA_DIARIA.split('|');
                const saude = revaluation.P_PT_R_OBJT_SAUDE.split('|');
                const organizacaoFamiliar = revaluation.P_PT_R_OBJT_ORGANIZCAO_FAMILIAR.split('|');
                const situacaoEmocional = revaluation.P_PT_R_OBJT_SITUACAO_EMOCIONAL.split('|');
                const fatoresSociais = revaluation.P_PT_R_OBJT_FATORES_SOCIAIS.split('|');
                const selectedProfessionals2 = revaluation.P_PT_R_PROFISSIONAIS.map(professional =>
                  professionals.find(el => el.id == professional.P_PT_P_PROFISSIONAL)
                );
                const schedules = revaluation.P_PT_R_AGENDAMENTOS.map(attendance => {
                  const iniHour = attendance.PAF_HORINI.split(':');
                  const finalHour = attendance.PAF_HORFIM.split(':');
                  const user = professionals.find(el => el.id == attendance.PAF_AREA);
                  return {
                      area: user.area,
                      label: user.name,
                      id: attendance.PAF_AREA,
                      frequency: attendance.PAF_FREQUENCIA,
                      day: attendance.PAF_DOW,
                      initialHour: iniHour[0],
                      initialMinute: iniHour[1],
                      finalHour: finalHour[0],
                      finalMinute: finalHour[1],
                    }
                })
                const selectedFamiliars = revaluation.P_PT_R_FAMILIARES.map(familiar => (
                  { ...relationships.find(el => el.id == familiar.P_PT_F_GRAU_PARENTESCO), name: familiar.P_PT_F_FAMILIAR }
                ));
                const selectedProfessionals = revaluation.P_PT_R_ALTAS.map(professional => (
                  { ...professionals.find(el => el.id == professional.P_PT_R_A_RESPONSAVEL)}
                ));
                const arr = revaluation.P_PT_R_DATA.split('/');

                return {
                  date: revaluation.P_PT_R_DATA,
                  selectedProfessionals2,
                  selectedProfessionals,
                  selectedFamiliars,
                  schedules,
                  initialValues: {
                    date: `${arr[2]}-${arr[1]}-${arr[0]}`,
                    aspectosIndividuais,
                    atividadesDiarias,
                    saude,
                    organizacaoFamiliar,
                    situacaoEmocional,
                    fatoresSociais,
                    previsao: revaluation.P_PT_R_PREVISAO_REAVALIACAO,
                    alta: 
                      revaluation.P_PT_R_ALTA_AREA === 1 ? '0' 
                      : revaluation.P_PT_R_ALTA_INSTITUCIONAL === 1 ? '1' 
                      : '', 
                    notes:  revaluation.P_PT_R_OBS
                  }
                }
              })
            );
          } else {
            setHaveList(false);
            setShowForm(true);
            clear();
          }
        }
      })
      setSelectedPatient(patient);
    }
  }

  function handleEditRevaluation(revaluation) {
    console.log(revaluation);
    setInitialValues(revaluation.initialValues);
    setEditDate(revaluation.date);
    setSelectedProfessionals(revaluation.selectedProfessionals);
    setSelectedProfessionals2(revaluation.selectedProfessionals2);
    setSelectedAttendances(revaluation.schedules);
    setSelectedFamiliars(revaluation.selectedFamiliars);
    setShowForm(true);
    setHaveList(true);
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    getRelationship().then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrGrauParentesco.forEach(grau => {
          const grauObj = {
            id: grau[0],
            label: grau[1]
          };
          arr.push(grauObj);
        });
        setRelationships(arr);
        setSelectedFamiliar(`${arr[0].id}`);
      }
    });

    getFrequency().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar frequências: ${json.message}`);
      } else {
        let arr = [];
        json.data.arrFrequencia.forEach(frequency => {
          const frequencyObj = {
            id: frequency[0],
            label: frequency[1]
          };
          arr.push(frequencyObj);
        });
        arr.unshift({
          id: null,
          label: 'Frequência'
        });
        setFrequencies(arr);
      }
    });

    getUsers().then(json => {
      if (json.error) {
        danger(`Erro ao resgatar usuários: ${json.message}`);
      } else {
        const users = json.data.arrUsuarios.map(user => ({
          id: user.USR_CODIGO,
          name: user.USR_NOME,
          sector: user.ARE_DESCRITIVO,
          class: user.USR_CONSELHO_DE_CLASSE
        }));
        setProfessionals(users);
        setSelectedProfessional(users[0].id);
      }
    });

    getAreasSaude().then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar as áreas: ${json.message}`);
      } else {
        const array = json.data.arrProfissionaisDeSaude.map(area => ({
          id: area.USR_CODIGO,
          label: area.USR_NOME,
          area: {
            id: area.USR_AREA,
            label: area.ARE_DESCRITIVO
          },
          triagem: area.ARE_REALIZA_TRIAGEM
        }));
        setAreas(array);
        array.length > 0 && setSelectedAttendance(array[0].id);
      }
    });

    handleGetRespostas([
      {
        label: 'PT Reavaliacao - Atividades de Vida Diária',
        name: 'atividadesDiarias'
      },
      {
        label: 'PT Reavaliacao - Desenvolver/aprimorar a organização familiar',
        name: 'organizacaoFamiliar'
      },
      {
        label: 'PT Reavaliacao - Desenvolver/aprimorar a saúde',
        name: 'saude'
      },
      {
        label: 'PT Reavaliacao - Desenvolver/aprimorar a situação emocional',
        name: 'situacaoEmocional'
      },
      {
        label: 'PT Reavaliacao - Desenvolver/aprimorar fatores sociais',
        name: 'fatoresSociais'
      },
      {
        label: 'PT Reavaliacao - Desenvolver/aprimorar os aspectos individuais',
        name: 'aspectosIndividuais'
      },
      {
        label: 'PT Reavaliacao - Alta das àreas',
        name: 'altaAreas'
      },
      {
        label: 'PT Reavaliacao - Será atendida(o) por',
        name: 'atendidoPor'
      }
    ]).then(result => setResponses(result));

    setInitialValues(old => ({...old, date: moment().format('YYYY-MM-DD')}))

    return () => setSelectedPatient('');
  }, []);

  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  return (
    <div className="animeUp">
      <h2 className="section">Reavaliação</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          {(showForm && editDate || showForm && haveList) && 
            <Button type="button" primary onClick={() => {
              clear();
              setShowForm(false);
            }} style={{ marginRight: 16 }}>
              Voltar
            </Button>
          }
          <PatientName patientInfos={patientInfos} /><br/>
          {!showForm && <Button primary onClick={() => setShowForm(true)}>Nova Reavaliação</Button>}

          {showForm
            ? <>
              <TitleSection>Síntese do Processo de Acompanhamento</TitleSection>
              <FormRevaluation
                selectedProfessionals={selectedProfessionals}
                professionals={professionals}
                relationships={relationships}
                areas={areas}
                selectedPatient={selectedPatient}
                selectedAttendances={selectedAttendances}
                setSelectedAttendances={setSelectedAttendances}
                setSelectedProfessionals={setSelectedProfessionals}
                selectedFamiliars={selectedFamiliars}
                selectedProfessional={selectedProfessional}
                selectedFamiliar={selectedFamiliar}
                selectedAttendance={selectedAttendance}
                setSelectedFamiliars={setSelectedFamiliars}
                selectedProfessionals2={selectedProfessionals2}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                setSelectedProfessionals2={setSelectedProfessionals2}
                setSelectedFamiliar={setSelectedFamiliar}
                setSelectedAttendance={setSelectedAttendance}
                frequencies={frequencies}
                setSelectedProfessional={setSelectedProfessional}
                responses={responses}
                print={print}
                editDate={editDate}
              />
            </>
            : <ListRevaluations revaluations={revaluations} handleEditRevaluation={handleEditRevaluation} />
          }

          <div style={{ display: 'none' }}>
            <PrintRevaluation
              selectedProfessionals={selectedProfessionals}
              selectedAttendances={selectedAttendances}
              selectedFamiliars={selectedFamiliars}
              selectedProfessional={selectedProfessional}
              selectedFamiliar={selectedFamiliar}
              selectedAttendance={selectedAttendance}
              responses={responses}
              selectedPatient={selectedPatient}
              patients={patients}
              initialValues={initialValues}
              selectedProfessionals2={selectedProfessionals2}
              frequencies={frequencies}
              ref={printRef}
            />
          </div>
        </div>
      )}
    </div>
  );
}
