import React, { useContext } from 'react';
import { HiUserRemove } from 'react-icons/hi';
import { GlobalContext } from '../../../../../GlobalContext';
import PrintHeader from '../../../../../components/PrintHeader';
import { PrintContainer } from '../../../../../components/PrintHeader/styles';
import { FormRow, TitleSection } from '../../../Entries/Patients/styles';
import { FamiliarType, RemoveButton } from '../../Revaluation/styles';
import { AttendanceRow, EmptyContent, RadioRow } from '../../Therapeutic Project/styles';

const FamilyAccopanimentPrint = React.forwardRef(
  (
    {
      initialValues,
      selectedPatient,
      patients,
      selectedProfessionals,
      selectedProfessionals2,
      referrals,
      relationships
    },
    ref
  ) => {
    const { currentUser } = useContext(GlobalContext);
    const data = selectedPatient.split('-');
    const patientName = patients.find(patient => patient.id == data[0] && patient.year == data[1])
    return (
      <div ref={ref}>
        <PrintHeader currentUser={currentUser} patientName={patientName} />
        <PrintContainer>
          <TitleSection>Acompanhamento Familiar</TitleSection>
          <FormRow>
            <div className="fullInput">
              <label>Nome da pessoa contatada na família</label>
              <input value={initialValues.nome} type="text" />
            </div>
            <div className="smallInput">
              <label>Grau de parentesco</label>
              <select value={initialValues.parentesco} readOnly>
                {relationships.map(relation => (
                  <option value={relation.id}>{relation.name}</option>
                ))}
              </select>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Local do atendimento</label>
              <RadioRow>
                <label>
                  <input type="radio" checked={initialValues.local == '0'} />
                  <span className="checkMark"></span>
                  Residência da família
                </label>

                <label>
                  <input type="radio" checked={initialValues.local == '1'} />
                  <span className="checkMark"></span>
                  Associação PODE
                </label>
              </RadioRow>
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput">
              <label htmlFor="demanda">Descrição da demanda</label>
              <input value={initialValues.demanda} type="text" />
            </div>
          </FormRow>
          <FormRow>
            <div className="fullInput radioGroup">
              <label className="highlight">Encaminhamento(s) efetuados</label>
              <RadioRow>
                {referrals.map(referral => (
                  <label key={referral.id}>
                    <input
                      type="checkbox"
                      checked={initialValues.encaminhamentos.includes(referral.id)}
                    />
                    <span className="checkMark"></span>
                    {referral.label}
                  </label>
                ))}
              </RadioRow>
            </div>
          </FormRow>
          <TitleSection style={{ fontSize: 22 }}>
            Profissional responsável da Associação PODE pelo atendimento
          </TitleSection>

          <FormRow style={{ flexDirection: 'column' }}>
            {selectedProfessionals.length > 0 ? (
              selectedProfessionals.map(professional => (
                <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                  <FamiliarType key={professional.id}>
                    Área {professional.area.label}: {professional.label} {professional.class}
                  </FamiliarType>
                </AttendanceRow>
              ))
            ) : (
              <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
            )}
          </FormRow>

          <TitleSection style={{ fontSize: 21 }}>
            Profissional contatado no órgão/instituição/setor ao qual os encaminhamentos foram
            efetuados
          </TitleSection>

          <FormRow style={{ flexDirection: 'column' }}>
            {selectedProfessionals2.length > 0 ? (
              selectedProfessionals2.map(professional => (
                <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                  <FamiliarType key={professional.name}>
                    {professional.name} - {professional.area}
                  </FamiliarType>
                </AttendanceRow>
              ))
            ) : (
              <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
            )}
          </FormRow>
        </PrintContainer>
      </div>
    );
  }
);

export default FamilyAccopanimentPrint;
