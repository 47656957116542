import { danger } from '../components/Alerts';
import { getRepostas } from './api';

export default async function handleGetRespostas(requests) {
  let currentResponses = {};

  for (const request of requests) {
    getRepostas(request.label).then(json => {
      if (json.error) {
        danger(`Ocorreu um erro ao resgatar valores: ${request.label}`);
      } else {
        currentResponses[`${request.name}`] = json.data.arrRespostas.map(response => {
          const array = response.split('|');
          return {
            value: array[0],
            label: array[1],
            isDefault: array[2] === 'S',
            type: array[3] === 'S' ? 'checkbox' : 'radio',
            name: request.name
          };
        });
      }
    });
  }
  return currentResponses;
}
