import React, { useContext, useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Search from '../../../../../../components/Search';
import { danger, info, warning } from '../../../../../../components/Alerts';
import { getPatientsWithSector } from '../../../../../../utils/api';
import handleGetRespostas from '../../../../../../utils/handleGetRespostas';
import { GlobalContext } from '../../../../../../GlobalContext';
import PrintAudiologiaCrianca from './PrintAudiologiaCrianca';
import FormAudiologiaCrianca from './FormAudiologiaCrianca';
import { PatientName } from '../../../../../../components/PatientName';

export default function AudiologiaCrianca() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [patientInfos, setPatientInfos] = useState({});
  const [selectedPatient, setSelectedPatient] = useState('');
  const [initialValues, setInitialValues] = useState({
    APGAR1: 0,
    APGAR5: 0,
    APGARPC: 0,
    aborto: '',
    abortosGenitora: '',
    abortosQuantidade: 0,
    alergias: '',
    alergiasQuais: '',
    antecedentes: '',
    antecedentesNote: '',
    bebeMolinho: '',
    caxumba: '',
    consanguinidade: '',
    covid19: '',
    covid19Note: '',
    demorouChorar: '',
    outrosFatores: [],
    diabetesGestacional: '',
    diabetesGestacionalNote: '',
    dificuldadeSugar: '',
    engasgo: '',
    filhosQuantidade: 0,
    filhosVivos: '',
    fotoTerapia: '',
    fotoTerapiaNote: '',
    gripes: '',
    gripesNote: '',
    hemorragias: '',
    hemorragiasNote: '',
    hipertensao: '',
    hipertensaoNote: ' ',
    hiv: '',
    ictericia: '',
    incubadora: '',
    incubadoraNote: '',
    infeccaoUrinaria: '',
    malformacao: '',
    malformacaoNote: '',
    medicacao: ' ',
    meningite: '',
    natimorto: '',
    natimortoQuantidade: 0,
    notes: '',
    otites: '',
    outrasDoencasPreNatalNote: '',
    outrasDoencasPosNatal: '',
    outrasDoencasPosNatalQuais: '',
    outrasDoencasPreNatal: '',
    pesoNascer: 0,
    raioX: '',
    raioXNote: '',
    rubeola: '',
    sarampo: '',
    sifilis: '',
    sindrome: '',
    sindromeQuais: '',
    sondaAlimentar: '',
    sondaAlimentarNote: '',
    tempoGestacao: 0,
    tentativaAbortoNote: '',
    tipoParto: '',
    toxoplasmose: '',
    traumaAcustico: '',
    traumaFisico: '',
    ventilacaoMecanica: '',
    ventilacaoMecanicaDuracao: '',
    ventilacaoMecanicaTipo: ''
  });

  const [responses, setResponses] = useState({});

  const { currentUser } = useContext(GlobalContext);
  const printRef = useRef();
  const print = useReactToPrint({ content: () => printRef.current });

  function handleGetPatientInfos() {
    const array = patient.split('-');
    const patientData = patients.find(patient => patient.id == array[0] && patient.year == array[1]);
    setPatientInfos(patientData);
    !patientData.active && warning('Paciente inativo!'); 
    if (Object.keys(responses).length < 38) {
      setTimeout(() => {
        handleGetPatientInfos();
      }, 500);
    } else {
      setSelectedPatient(patient);
    }
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });

    handleGetRespostas([
      {
        label: 'Triagem Audio Crianca - Filhos vivos',
        name: 'filhosVivos'
      },
      {
        label: 'Triagem Audio Crianca - Natimorto',
        name: 'natimorto'
      },
      {
        label: 'Triagem Audio Crianca - Rubéola',
        name: 'rubeola'
      },
      {
        label: 'Triagem Audio Crianca - Alergias',
        name: 'alergias'
      },
      {
        label: 'Triagem Audio Crianca - Gripes',
        name: 'gripes'
      },
      {
        label: 'Triagem Audio Crianca - Hemorragias',
        name: 'hemorragias'
      },
      {
        label: 'Triagem Audio Crianca - Realizou Raio X',
        name: 'raioX'
      },
      {
        label: 'Triagem Audio Crianca - Tentativa de aborto',
        name: 'aborto'
      },
      {
        label: 'Triagem Audio Crianca - Infecção urinaria',
        name: 'infeccaoUrinaria'
      },
      {
        label: 'Triagem Audio Crianca - Sífilis',
        name: 'sifilis'
      },
      {
        label: 'Triagem Audio Crianca - Toxoplasmose',
        name: 'toxoplasmose'
      },
      {
        label: 'Triagem Audio Crianca - HIV',
        name: 'hiv'
      },
      {
        label: 'Triagem Audio Crianca - Diabetes gestacional',
        name: 'diabetesGestacional'
      },
      {
        label: 'Triagem Audio Crianca - COVID-19',
        name: 'covid19'
      },
      {
        label: 'Triagem Audio Crianca - Hipertensão arterial',
        name: 'hipertensao'
      },
      {
        label: 'Triagem Audio Crianca - Outras doenças (pré-natal)',
        name: 'outrasDoencasPreNatal'
      },
      {
        label: 'Triagem Audio Crianca - Tipo de parto',
        name: 'tipoParto'
      },
      {
        label: 'Triagem Audio Crianca - Demorou pra chorar',
        name: 'demorouChorar'
      },
      {
        label: 'Triagem Audio Crianca - Outros fatos ocorridos:',
        name: 'outrosFatores'
      },
      {
        label: 'Triagem Audio Crianca - Apresentou malformação congênita',
        name: 'malformacao'
      },
      {
        label: 'Triagem Audio Crianca - Incubadora',
        name: 'incubadora'
      },
      {
        label: 'Triagem Audio Crianca - Icterícia',
        name: 'ictericia'
      },
      {
        label: 'Triagem Audio Crianca - Foto terapia',
        name: 'fotoTerapia'
      },
      {
        label: 'Triagem Audio Crianca - Ventilação mecânica',
        name: 'ventilacaoMecanica'
      },
      {
        label: 'Triagem Audio Crianca - Sonda alimentar',
        name: 'sondaAlimentar'
      },
      {
        label: 'Triagem Audio Crianca - Bebê molinho',
        name: 'bebeMolinho'
      },
      {
        label: 'Triagem Audio Crianca - Dificuldade para sugar',
        name: 'dificuldadeSugar'
      },
      {
        label: 'Triagem Audio Crianca - Engasgo ao engolir',
        name: 'engasgo'
      },
      {
        label: 'Triagem Audio Crianca - Meningite',
        name: 'meningite'
      },
      {
        label: 'Triagem Audio Crianca - Caxumba',
        name: 'caxumba'
      },
      {
        label: 'Triagem Audio Crianca - Sarampo',
        name: 'sarampo'
      },
      {
        label: 'Triagem Audio Crianca - Otites',
        name: 'otites'
      },
      {
        label: 'Triagem Audio Crianca - Trauma físico',
        name: 'traumaFisico'
      },
      {
        label: 'Triagem Audio Crianca - Trauma acústico',
        name: 'traumaAcustico'
      },
      {
        label: 'Triagem Audio Crianca - Síndrome',
        name: 'sindrome'
      },
      {
        label: 'Triagem Audio Crianca - Outras doenças (pós-natal)',
        name: 'outrasDoencasPosNatal'
      },
      {
        label: 'Triagem Audio Crianca - Antecedentes familiares e hereditários',
        name: 'antecedentes'
      },
      {
        label: 'Triagem Audio Crianca - Consanguinidade',
        name: 'consanguinidade'
      },
      {
        label: 'Triagem Audio Crianca - Abortos da genitora',
        name: 'abortosGenitora'
      }
    ]).then(result => setResponses(result));

    return () => setSelectedPatient('');
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Avaliação Audiológica - Criança</h2>
      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      {selectedPatient && (
        <div className="animeUp">
          <PatientName patientInfos={patientInfos} />

          <FormAudiologiaCrianca
            initialValues={initialValues}
            selectedPatient={selectedPatient}
            currentUser={currentUser}
            print={print}
            responses={responses}
            patientInfos={patientInfos}
            setInitialValues={setInitialValues}
          />
          <div style={{ display: 'none' }}>
            <PrintAudiologiaCrianca
              ref={printRef}
              initialValues={initialValues}
              selectedPatient={selectedPatient}
              currentUser={currentUser}
              responses={responses}
              patients={patients}
            />
          </div>
        </div>
      )}
    </div>
  );
}
